import classes from './ColumnsBoard.module.scss';
import CustomLaneHeader from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/customLaneHeader/customLaneHeader';
import CustomCard from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/customCard/customCard';
import Board from 'react-trello';
import 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/ColumnsBoard/board.css';
import NoData from 'components/common/NoData/noData';
import React, { useEffect, useState } from 'react';
import { useBattlecardsStore } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/store/battlecardsStore';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import {
    useQueryCardsData,
    useQueryCountersData,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
import {
    CardType,
    getStatusNameByLaneId,
    initialCountersState,
    initialLanesState,
    prepareCards,
    prepareLanes,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import produce from 'immer';
import { useMutationUpdateCardLaneIdData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsMutation';
import { useHistory, useLocation } from 'react-router-dom';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useVersionStore } from 'components/common/TreeProgramFilter/VersionSelect/store/versionSelectStore';
import { cloneDeep } from 'lodash';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import colorsVars from 'styles/colors.module.scss';

const { tableFooterColor } = colorsVars;

type IEventBus = { publish: (value: any) => void } | undefined;
let eventBus: IEventBus = undefined; // used to communicate with board using events. see documentation

const boardStyle = {
    width: '100%',
    height: 'initial',
    backgroundColor: 'initial',
    display: 'flex',
    justifyContent: 'space-evenly',
};

const ColumnsBoard = () => {
    const { pathname, search } = useLocation();
    const history = useHistory();
    const { sendEventWithDimensions } = useEventWithDimensions();
    const { handleSnackBar } = useCustomSnackBar();
    const currentVersion = useVersionStore((state) => state.version);
    const { filterData, setFilterData } = useBattlecardsStore();

    const [counters, setCounters] = useState<any>(initialCountersState);
    const [cardsData, setCardsData] = useState(initialLanesState);

    const { data: cards, refetch: cardsDataRefetch, isLoading: cardsLoading } = useQueryCardsData(filterData);
    const { mutate, isLoading: updateLaneIdLoading } = useMutationUpdateCardLaneIdData();

    const {
        data: cardsCounters,
        refetch: cardsCountersRefetch,
        isLoading: cardsCountersLoading,
    } = useQueryCountersData(filterData);

    const showMore = () => {
        const filterDataWithNewPage = cloneDeep(filterData);
        const payload = {
            ...filterDataWithNewPage,
            paging: { ...filterDataWithNewPage.paging, page: filterDataWithNewPage.paging.page + 1 },
        };
        setFilterData(payload);
    };

    useEffect(() => {
        if (filterData.paging.page !== 0) {
            const filterDataWithNewPage = cloneDeep(filterData);
            const payload = { ...filterDataWithNewPage, paging: { ...filterDataWithNewPage.paging, page: 0 } };
            setFilterData(payload);
        }
    }, []);

    useEffect(() => {
        if (cards) {
            const preparedCards = prepareCards({ cards });
            const lanes = prepareLanes({ cards: preparedCards, cardsData, isLoadMore: filterData.paging.page !== 0 });

            setCardsData((prevState) => {
                return {
                    ...prevState,
                    lanes: lanes,
                };
            });
        }
    }, [cards, filterData]);

    useEffect(() => {
        if (cardsCounters) {
            setCounters(
                produce(counters, (draft) => {
                    draft.toDo = {
                        tda: cardsCounters.tdaTodoCount,
                        milestone: cardsCounters.milestoneTodoCount,
                        kcInsight: cardsCounters.kcInsightTodoCount,
                        userGenerated: cardsCounters.userGeneratedTodoCount,
                    };
                    draft.inProgress = {
                        tda: cardsCounters.tdaInProgressCount,
                        milestone: '-',
                        kcInsight: cardsCounters.kcInsightInProgressCount,
                        userGenerated: cardsCounters.userGeneratedInProgressCount,
                    };
                    draft.done = {
                        tda: cardsCounters.tdaCompletedCount,
                        milestone: cardsCounters.milestoneCompletedCount,
                        kcInsight: cardsCounters.kcInsightCompletedCount,
                        userGenerated: cardsCounters.userGeneratedCompletedCount,
                    };
                }),
            );
        }
    }, [cardsCounters]);

    const handleCardClick = (cardId) => {
        const fullId = cardId.split('_');
        const _cardId = fullId[1] === 'true' ? `${fullId[0]}-1` : fullId[0];
        sendEventWithDimensions({
            category: 'Battlecards',
            action: 'Open Specific Card',
            label: _cardId,
        });
        history.push(`${pathname}/card-${_cardId}${search}`);
    };

    const onDragEnd = (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
        if (currentVersion) {
            const params = {
                id: cardDetails.id.split('_')[0],
                laneId: getStatusNameByLaneId(cardDetails.laneId),
                isVirtual: cardDetails.isVirtual,
                versionId: currentVersion.id,
            };
            mutate(params, {
                onSuccess: () => {
                    updateCardsDataAfterDragEnd(cardId, sourceLaneId, targetLaneId, cardDetails);
                    cardDetails.type.name === CardType.PROGRAMME_ACTIVITY
                        ? handleSnackBar(
                              'Note status and date updates must be aligned in corresponding schedule file, or they will be lost in the next schedule upload.',
                              'warning',
                          )
                        : handleSnackBar('Status changed successfully', 'success');
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                    const lanes = [...cardsData.lanes];
                    if (eventBus) {
                        eventBus.publish({
                            type: 'MOVE_CARD',
                            fromLaneId: targetLaneId,
                            toLaneId: sourceLaneId,
                            cardId: cardId,
                            index: position,
                        });
                        eventBus.publish({ type: 'UPDATE_LANES', lanes: lanes });
                    }
                },
                onSettled: () => {
                    return Promise.all([cardsDataRefetch(), cardsCountersRefetch()]);
                },
            });
        }
    };

    const onDragStart = () => {
        return false;
    };

    const setEventBus = (handle) => {
        eventBus = handle;
    };

    const updateCardsDataAfterDragEnd = (cardId, sourceLaneId, targetLaneId, cardDetails) => {
        const lanes = [...cardsData.lanes];

        setCardsData((prevState) => ({
            ...prevState,
            lanes: [],
        }));

        lanes[sourceLaneId - 1].cards = lanes[sourceLaneId - 1].cards.filter((obj) => obj['id'] !== cardId);
        lanes[targetLaneId - 1].cards.push(cardDetails);

        lanes[targetLaneId - 1].cards = lanes[targetLaneId - 1].cards.sort((a, b) =>
            targetLaneId === 3 ? (a.rank < b.rank ? 1 : -1) : a.rank > b.rank ? 1 : -1,
        );

        setCardsData((prevState) => ({
            ...prevState,
            lanes: lanes,
        }));
    };

    const isLoading = cardsLoading || cardsCountersLoading || updateLaneIdLoading;

    return (
        <div className={classes.boardWrapper}>
            <div className={classes.header}>
                <div className={classes.headerItem}>
                    <CustomLaneHeader id={1} title={'To Do'} counters={counters.toDo} />
                </div>
                <div className={classes.headerItem}>
                    <CustomLaneHeader id={2} title={'In Progress'} counters={counters.inProgress} />
                </div>
                <div className={classes.headerItem}>
                    <CustomLaneHeader id={3} title={'Done'} counters={counters.done} />
                </div>
            </div>
            <div className={classes.board}>
                <Board
                    data={cardsData}
                    style={boardStyle}
                    onCardClick={handleCardClick}
                    className="programFocusScopeToGoContainerBoard"
                    components={{ Card: CustomCard }}
                    handleDragEnd={onDragEnd}
                    handleDragStart={onDragStart}
                    eventBusHandle={setEventBus}
                />
                {cards && cards.length >= 20 && (
                    <div className={classes.showMore} onClick={showMore}>
                        <span>Show More</span>
                        <MuiIcon icon={'keyboard_double_arrow_down'} color={tableFooterColor} />
                    </div>
                )}
                {cards && cards.length === 0 && <NoData />}
                {isLoading && <OverlayWithSpinner />}
            </div>
        </div>
    );
};

export default React.memo(ColumnsBoard);
