import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { IGetUserDetailResponse } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';

interface IUserStore {
    user?: IGetUserDetailResponse;
    setUser: (user?: IGetUserDetailResponse) => void;
    logout: () => void;
}

const userStore = (set) => ({
    user: undefined,
    setUser: (user) => set(() => ({ user })),
    logout: () => {
        set(() => ({ user: undefined }));
        localStorage.removeItem('loginUser');
    },
});

export const useUserStore = create(persist<IUserStore>(userStore, { name: 'loginUser' }));
