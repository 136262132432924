import { history } from 'components/App/app';

const error500Codes = [501, 502, 503, 504, 505, 506, 507, 508, 509, 510];

const interceptor = (instance) => {
    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response.status === 401) {
                history.push('/logout');
            }
            if (error.response.status === 500) {
                history.push('/error500');
            }
            if (error.response.status === 404) {
                history.push('/error404');
            }
            if (error500Codes.includes(error.response.status)) {
                error.response.data = `Network error (${error.response.status}): Gateway Timeout server error. please try again later.`;
            }
            return Promise.reject(error);
        },
    );
};

export default {
    interceptor,
};
