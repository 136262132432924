import CryptoJS from 'crypto-js';

const salt = 'ouNhNvaq96lqUvCekxR';

export const encrypt = (string: string, time: number): string => {
    const key = CryptoJS.enc.Base64.parse(time + salt);
    const srcs = CryptoJS.enc.Utf8.parse(string);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString();
};
