import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import {
    IGetPortfolioTopHeroes,
    IGetProjectScoreCardsSummary,
} from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

const getPortfolioTopHeroes = () => {
    return network
        .get(API_URL().FETCH_PORTFOLIO_TOP_HEROES)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};
const getProjectScoreCardsSummary = () => {
    return network
        .get(API_URL().FETCH_PROJECT_SCORE_CARDS_SUMMARY)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const useQueryPortfolioTopHeroes = () => {
    return useQuery<IGetPortfolioTopHeroes, QueryError>('portfolioTopHeroes', () => getPortfolioTopHeroes());
};

const useQueryProjectScoreCardsSummary = () => {
    return useQuery<IGetProjectScoreCardsSummary, QueryError>('projectScoreCardsSummary', () =>
        getProjectScoreCardsSummary(),
    );
};

export { useQueryPortfolioTopHeroes, useQueryProjectScoreCardsSummary };
