import RelationshipCountChanges from 'components/Dashboards/Project/Components/VersionCompare/components/RelationshipCountChanges/relationshipCountChanges';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { ITableWrapper } from 'components/Dashboards/Project/Components/VersionCompare/VersionCompare.consts';
import { useGlobalFilterDataStore } from 'components/common/GlobalFilterComponent/store/globalFilterDataStore';

export const RelationshipCountChangesWrapper = ({ isSinglePage }: ITableWrapper) => {
    const hashcode = Object.values(useParams()).filter(Boolean).join(',');
    const { versionList: versionFromHashMap } = useHashmapConverter(hashcode);
    const projectId = versionFromHashMap[0]?.projectId;
    const contractId = versionFromHashMap[0]?.contractId;
    const latestVersionId = versionFromHashMap[0]?.versionDbId;
    const compareVersionId = versionFromHashMap[1]?.versionDbId;
    const globalFilterData = useGlobalFilterDataStore((state) => state.globalFilterData);
    return compareVersionId ? (
        <RelationshipCountChanges
            projectId={projectId}
            contractId={contractId}
            latestVersionId={latestVersionId}
            compareVersionId={compareVersionId}
            globalFilterData={globalFilterData}
            isSinglePage={isSinglePage}
        />
    ) : null;
};
