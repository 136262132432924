import classes from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/components/FragnetSummary.module.scss';
import React from 'react';
import { IActivityCompletionPlanResponse } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/queries/activityCompletionPlanQuery';
import { CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { getScheduleAdherenceColorClass } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/utils/helper';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { ScheduleToolTip } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/components/scheduleToolTip';
import { roundNumber } from 'utilitys/helpers/general';

interface ISummaryRowProps {
    data?: IActivityCompletionPlanResponse;
}
export const FragnetSummary = ({ data }: ISummaryRowProps) => {
    const plannedFinishDate = moment(data?.planned_finish_date).format(constants.formats.date.default);
    const plannedFinishDateChange = data?.planned_finish_date_change;
    const plannedFinishDateChangeTitle = plannedFinishDateChange ? `${plannedFinishDateChange}d` : '';
    const scheduleCriticalyAdherence = roundNumber(data?.criticality_schedule_adherence.scheduleAdherence);
    const blockingPredecessors = `${data?.blocking_predecessors}%`;
    const delayingPredecessors = `${data?.delaying_predecessors}%`;
    const getScheduleAdherenceColor = getScheduleAdherenceColorClass(
        data?.criticality_schedule_adherence.scheduleAdherence,
    );

    return (
        <div className={classes.summaryRow}>
            <div data-testid={'summary-row-item'} className={classes.summaryRowItem}>
                <div className={classes.smallTitle}>Finish Date</div>
                <div className={classes.dataValueContainer}>
                    <div className={classes.title}>{plannedFinishDate}</div>
                    <CustomizedTooltip
                        tooltipPosition={TooltipPosition.Bottom}
                        tooltipContent={'Compared to Baseline'}
                        triggerElement={
                            <div className={`${classes.plannedFinishDateChange}`}>
                                {plannedFinishDateChange && plannedFinishDateChange > 0 && '+'}
                                {plannedFinishDateChangeTitle}
                            </div>
                        }
                    />
                </div>
            </div>
            <div data-testid={'summary-row-item'} className={classes.summaryRowItem}>
                <div className={classes.smallTitle}>Schedule Performance</div>
                <div className={classes.dataValueContainer}>
                    <CustomizedTooltip
                        tooltipPosition={TooltipPosition.Bottom}
                        tooltipContent={<ScheduleToolTip data={data} />}
                        triggerElement={
                            <div className={`${classes.title} ${classes[getScheduleAdherenceColor]}`}>
                                {scheduleCriticalyAdherence}
                            </div>
                        }
                    />
                </div>
            </div>
            <div data-testid={'summary-row-item'} className={classes.summaryRowItem}>
                <div className={classes.smallTitle}>% Blockers Predecessors</div>
                <div className={classes.dataValueContainer}>
                    <div className={`${classes.title} ${classes.red}`}>{blockingPredecessors}</div>
                </div>
            </div>
            <div data-testid={'summary-row-item'} className={classes.summaryRowItem}>
                <div className={classes.smallTitle}>% Delayed Predecessors</div>
                <div className={classes.dataValueContainer}>
                    <div className={`${classes.title} ${classes.yellow}`}>{delayingPredecessors}</div>
                </div>
            </div>
        </div>
    );
};
