import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/relationships/relationships.module.scss';
import { useQueryGetRelationActivity } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/relationActivityQuery';
import React, { useRef, useState } from 'react';
import {
    destructureData,
    generateTabs,
    TABS_OPTIONS,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/relationships/relationships.utils';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { CustomizedTabs } from 'components/common/CustomizedTabs/CustomizedTabs';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { PredeccessorsTableWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/relationships/tables/predeccessorsTableWrapper';
import { SuccessorsTableWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/relationships/tables/successorsTableWrapper';
import { AncestorsTableWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/relationships/tables/ancestorsTableWrapper';
import { DecedentsTableWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/relationships/tables/decedentsTableWrapper';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';

export const Relationships = ({ id, activityType }: { id?: number | string; activityType?: string }) => {
    const [activeTab, setActiveTab] = useState<TABS_OPTIONS>(activityType === 'WBS' ? 'ANCESTORS' : 'PREDECESSORS');

    const { data, isLoading } = useQueryGetRelationActivity({
        taskId: id,
    });

    const { ancestors, decedents, predecessors, successors } = destructureData(data);
    const tabsArrData = generateTabs({ data, activityType });

    const predeccessorsTableRef = useRef(null);
    const successorsTableRef = useRef(null);
    const ancestorsTableRef = useRef(null);
    const decedentsTableRef = useRef(null);
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);

    return (
        <div className={classes.relationshipsContainer}>
            {isLoading && <OverlayWithSpinner />}

            <WidgetWithTitle
                title={'Activity Relationships'}
                titleComponents={[
                    <CustomizedTooltip
                        key={'tableColumns'}
                        tooltipPosition={TooltipPosition.Top}
                        tooltipContent={'Customize table columns'}
                        triggerElement={
                            <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                                <Icon name={'tune-filter'} />
                            </CustomizedRoundIcon>
                        }
                    />,
                ]}
            >
                <div className={classes.tableContainer}>
                    <CustomizedTabs tabs={tabsArrData} activeTab={activeTab} setActiveTab={setActiveTab} />

                    {predecessors && activeTab === 'PREDECESSORS' && (
                        <PredeccessorsTableWrapper
                            data={predecessors}
                            modalColumnsOpen={modalColumnsOpen}
                            setModalColumnsOpen={setModalColumnsOpen}
                            ref={predeccessorsTableRef}
                        />
                    )}

                    {successors && activeTab === 'SUCCESSORS' && (
                        <SuccessorsTableWrapper
                            data={successors}
                            modalColumnsOpen={modalColumnsOpen}
                            setModalColumnsOpen={setModalColumnsOpen}
                            ref={successorsTableRef}
                        />
                    )}

                    {ancestors && activeTab === 'ANCESTORS' && (
                        <AncestorsTableWrapper
                            data={ancestors}
                            modalColumnsOpen={modalColumnsOpen}
                            setModalColumnsOpen={setModalColumnsOpen}
                            ref={ancestorsTableRef}
                        />
                    )}

                    {decedents && activeTab === 'DESCENDANTS' && (
                        <DecedentsTableWrapper
                            data={decedents}
                            modalColumnsOpen={modalColumnsOpen}
                            setModalColumnsOpen={setModalColumnsOpen}
                            ref={decedentsTableRef}
                        />
                    )}
                </div>
            </WidgetWithTitle>
        </div>
    );
};
