import classes from './customLaneHeader.module.scss';
import colorsVars from 'styles/colors.module.scss';

const {
    todoCardBackground,
    todoCardBackgroundInside,
    inProgressCardBackground,
    inProgressCardBackgroundInside,
    doneCardBackground,
    doneCardBackgroundInside,
} = colorsVars;

const bColor = {
    1: todoCardBackground,
    2: inProgressCardBackground,
    3: doneCardBackground,
};

const bColorInside = {
    1: todoCardBackgroundInside,
    2: inProgressCardBackgroundInside,
    3: doneCardBackgroundInside,
};

interface Props {
    id: number;
    title: string;
    counters: any;
}

const CustomLaneHeader = ({ id, title, counters }: Props): JSX.Element => {
    return (
        <div style={{ background: bColorInside[id] }} className={classes.customLaneHeaderContainer}>
            <div className={classes.customLaneTitle}>{title}</div>
            <div style={{ background: bColorInside[id] }} className={classes.customLaneMain}>
                <div className={classes.customLaneMainWrapper}>
                    <span data-testid={'custom-title'} className={classes.customLaneMainWrapperTitle}>Tasks</span>
                    <span data-testid={'custom-title-counter'} className={classes.customLaneMainWrapperCounter}>{counters.tda}</span>
                </div>
                <div className={classes.customLaneMainWrapper}>
                    <span data-testid={'custom-milestones'} className={classes.customLaneMainWrapperTitle}>Milestones</span>
                    <span data-testid={'custom-milestones-counter'} className={classes.customLaneMainWrapperCounter}>{counters.milestone}</span>
                </div>
                <div className={classes.customLaneMainWrapper}>
                    <span data-testid={'custom-recommendations'} className={classes.customLaneMainWrapperTitle}>Recommendations</span>
                    <span data-testid={'custom-recommendations-counter'} className={classes.customLaneMainWrapperCounter}>{counters.kcInsight}</span>
                </div>
                <div className={classes.customLaneMainWrapper}>
                    <span data-testid={'custom-userActions'} className={classes.customLaneMainWrapperTitle}>User Actions</span>
                    <span data-testid={'custom-userActions-counter'} className={classes.customLaneMainWrapperCounter}>{counters.userGenerated}</span>
                </div>
            </div>
        </div>
    );
};

export default CustomLaneHeader;
