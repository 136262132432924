import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import GuardedRoute from 'components/common/GuardedRoute/guardedRoute';
import { RedirectToLast } from 'components/Dashboards/Program/ProgramWrapper/redirectToLast';
import { Responsive } from 'components/Dashboards/responsive';
import useScrollToTop from 'hooks/useScrollToTop';
import useUserHook from 'hooks/useUserHook';
import NoAccess from 'components/common/NoAccess/noAccess';
import LmsWrapperPage from 'components/Dashboards/ExcellenceApps/Lms/LmsWrapper/lmsWrapperPage';
import { ClusterRCFAnalysisPage } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterRCFAnalysisPage';
import { RecommendationsPage } from 'components/Dashboards/ExcellenceApps/Recommendations/recommendationsPage';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import PageNotFound from 'components/ErrorPages/pageNotFound';
import { SummaryPage } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/pages/summaryPage';
import { CategoryPage } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/pages/categoryPage';
import { ValidationPage } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/pages/validationPage';
import { ActivitiesPage } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/pages/activitiesPage';
import { ProgramActivitiesGraphPage } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramActivitiesGraph/programActivitiesGraphPage';
import { CompletionPlanPage } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/completionPlanPage';
import { SideMenuListProps } from 'components/common/SideMenu/SideMenu';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

export const sideMenu: SideMenuListProps[] = [
    {
        ...excellenceAppsConfig.kcRecommendations,
        id: 'Recommendations',
        muiIcon: <MuiIcon icon={'assistant'} fontSize="2.5rem" color="#9eadc1" />,
        permissions: 'Recommendations',
    },
    {
        ...excellenceAppsConfig.durationForecast,
        id: 'DurationForecast',
        icon: 'duration-forecast',
        permissions: 'ClusterAnalysis',
    },
    {
        ...excellenceAppsConfig.logicQualityCheck,
        id: 'LogicQualityCheck',
        icon: 'Single-quality-check',
        permissions: 'LogicChecker',
    },
    {
        ...excellenceAppsConfig.completionPlan,
        id: 'completionPlan',
        muiIcon: <MuiIcon icon={'flaky'} fontSize="2.5rem" color="#9eadc1" />,
        permissions: 'CompletionPlan',
    },
    {
        ...excellenceAppsConfig.learningMaterials,
        id: 'LearningMaterials',
        icon: 'Academia-Icon',
        permissions: 'LMS',
    },
    {
        ...excellenceAppsConfig.activitiesGraph,
        id: 'ActivitiesGraph',
        icon: 'Menu---Cluster-RCF-Analysis',
        isAdmin: true,
        permissions: 'Admin',
    },
];

export const ExcellenceAppsRoutes = () => {
    useScrollToTop();
    const { path } = useRouteMatch();
    const { ability } = useUserHook();
    const RecommendationsAbility = ability.can('view', 'Recommendations');
    const clusterAnalysisAbility = ability.can('view', 'ClusterAnalysis');
    const logicCheckerAbility = ability.can('view', 'LogicChecker');
    const CompletionPlanAbility = ability.can('view', 'CompletionPlan');
    const redirect = sideMenu.find((item) => {
        return item.permissions && ability.can('view', item.permissions);
    });

    //TODO: create component for wrapping just routes that contains the view (programWrapper, SideDashboardMenu, mainColumn, TopHeaderContainer)
    return (
        <Responsive sideMenu={sideMenu} title={'Excellence Apps'}>
            <Switch>
                <GuardedRoute
                    path={`${path}/${excellenceAppsConfig.kcRecommendations.link}/:id/:versionToCompareId?`}
                    component={RecommendationsPage}
                    auth={RecommendationsAbility}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${excellenceAppsConfig.kcRecommendations.link}`}
                    component={() => (
                        <RedirectToLast
                            to={`/dashboard/program/${excellenceAppsConfig.kcRecommendations.link}`}
                            title={excellenceAppsConfig.kcRecommendations.title}
                        />
                    )}
                    auth={RecommendationsAbility}
                    redirect={`${path}/no-access`}
                />

                <GuardedRoute
                    path={`${path}/${excellenceAppsConfig.durationForecast.link}/:id`}
                    component={ClusterRCFAnalysisPage}
                    auth={clusterAnalysisAbility}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${excellenceAppsConfig.durationForecast.link}`}
                    component={() => (
                        <RedirectToLast
                            to={`/dashboard/program/${excellenceAppsConfig.durationForecast.link}`}
                            title={excellenceAppsConfig.durationForecast.title}
                        />
                    )}
                    auth={clusterAnalysisAbility}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${excellenceAppsConfig.logicQualityCheck.link}/:id/:category/:check/activities`}
                    component={ActivitiesPage}
                    auth={logicCheckerAbility}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${excellenceAppsConfig.logicQualityCheck.link}/:id/:category/:check`}
                    component={ValidationPage}
                    auth={logicCheckerAbility}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${excellenceAppsConfig.logicQualityCheck.link}/:id/:category`}
                    component={CategoryPage}
                    auth={logicCheckerAbility}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${excellenceAppsConfig.logicQualityCheck.link}/:id`}
                    component={SummaryPage}
                    auth={logicCheckerAbility}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${excellenceAppsConfig.logicQualityCheck.link}`}
                    component={() => (
                        <RedirectToLast
                            to={`/dashboard/program/${excellenceAppsConfig.logicQualityCheck.link}`}
                            title={excellenceAppsConfig.logicQualityCheck.title}
                        />
                    )}
                    auth={logicCheckerAbility}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${excellenceAppsConfig.completionPlan.link}/:id([0-9-]+)`}
                    component={CompletionPlanPage}
                    auth={CompletionPlanAbility}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${excellenceAppsConfig.completionPlan.link}`}
                    component={() => (
                        <RedirectToLast
                            to={`${path}/${excellenceAppsConfig.completionPlan.link}`}
                            title={excellenceAppsConfig.completionPlan.title}
                        />
                    )}
                    auth={CompletionPlanAbility}
                    redirect={`${path}/no-access`}
                />

                <GuardedRoute
                    path={`${path}/${excellenceAppsConfig.learningMaterials.link}`}
                    component={LmsWrapperPage}
                    auth={ability.can('view', 'LMS')}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${excellenceAppsConfig.activitiesGraph.link}/:id`}
                    component={ProgramActivitiesGraphPage}
                    auth={ability.can('all', 'Admin')}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${excellenceAppsConfig.activitiesGraph.link}`}
                    component={() => (
                        <RedirectToLast
                            to={`${path}/${excellenceAppsConfig.activitiesGraph.link}`}
                            title={excellenceAppsConfig.activitiesGraph.title}
                        />
                    )}
                    auth={ability.can('all', 'Admin')}
                    redirect={`${path}/no-access`}
                />

                <Route path={`${path}/no-access`} component={NoAccess} />
                <Route exact path={path}>
                    <Redirect to={`${path}/${redirect?.link}`} />
                </Route>
                <Route path="*" component={PageNotFound} />
            </Switch>
        </Responsive>
    );
};
