export const dashboard = {
    get: () => {
        return localStorage.getItem('dashboardID');
    },
    set: (ID: string) => {
        localStorage.setItem('dashboardID', ID);
    },
    clear: () => {
        localStorage.removeItem('dashboardID');
    },
};

export const token = {
    get: () => {
        return localStorage.getItem('jwtToken');
    },
    set: (token) => {
        localStorage.setItem('jwtToken', token);
    },
    clear: () => {
        localStorage.removeItem('jwtToken');
    },
};

export const user = {
    get: () => {
        const _user = localStorage.getItem('loginUser');
        return _user ? JSON.parse(_user) : null;
    },
    set: (user) => {
        localStorage.setItem('loginUser', JSON.stringify(user));
    },
    clear: () => {
        localStorage.removeItem('loginUser');
    },
};

export const env = {
    get: () => {
        const _defaultEnvVars = localStorage.getItem('defaultEnvVars');
        return _defaultEnvVars ? JSON.parse(_defaultEnvVars) : null;
    },
    set: (defaultEnvVars) => {
        localStorage.setItem('defaultEnvVars', JSON.stringify(defaultEnvVars));
    },
    clear: () => {
        localStorage.removeItem('defaultEnvVars');
    },
};
