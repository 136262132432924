import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FullStory } from '@fullstory/browser';
import { useForm } from 'react-hook-form';
import analytics from 'utilitys/helpers/Analytics/analytics';
import { useMutationLogin, useQueryGetAccessToken, useQueryServerTime } from 'components/Login/queries/useQueryLogin';
import { encrypt } from 'components/Login/login.utils';
import { useQueryGetUserDetail } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import { useUserStore } from 'components/Login/store/userStore';
import { useQueryState } from 'hooks/useQueryState';
import { Userpilot } from 'userpilot';
import { LoginComponent } from 'components/Login/LoginComponent';
import moment from 'moment-timezone';

export interface ILoginForm {
    password: string;
    username: string;
}

const Login = () => {
    const url = new URL(document.URL);
    const history = useHistory();
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<ILoginForm>({
        defaultValues: {
            password: '',
            username: '',
        },
    });
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const setUser = useUserStore((state) => state.setUser);
    const { data: serverTime } = useQueryServerTime();
    const { mutate: loginMutation, isLoading: isLoadingLogin } = useMutationLogin();
    const [xyz] = useQueryState('xyz');
    const { data: activeDirectoryUser, isLoading: isLoadingActiveDirectoryUser } = useQueryGetAccessToken({
        uuid: xyz,
    });
    const { data: user, isLoading: isLoadingUserDetail } = useQueryGetUserDetail({
        email: email || activeDirectoryUser?.username,
    });

    useEffect(() => {
        if (user) {
            const emailSuffix = user.username.split('@')[1];
            setUser(user);

            Userpilot.identify(user.username, {
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
                created_at: moment().valueOf(),
                company: {
                    id: user.customerId,
                    created_at: moment().valueOf(),
                },
            });
            analytics.mixpanelIdentify(user.username);
            analytics.mixpanelPeopleSet({ emailSuffix, $email: user.username });
            analytics.mixpanelRegister({
                customerId: user.customerId,
                username: user.username,
                domain: url.hostname,
            });
            FullStory('setIdentity', {
                uid: user.username,
                properties: {
                    displayName: `${user.firstName} ${user.lastName}`,
                    email: user.username,
                    customerId: user.customerId,
                    username: user.username,
                    domain: url.hostname,
                },
            });
            const redirectSplit = window.location.href.split('redirect=');
            const redirect = redirectSplit.length > 1 ? redirectSplit[1] : undefined;
            history.push(redirect ?? '/');
        }
    }, [user]);

    const login = (details: ILoginForm): void => {
        const time = serverTime?.time as number;
        const payload = time !== 0 ? { encrypt: encrypt(JSON.stringify(details), time), time } : details;

        loginMutation(payload, {
            onSuccess: (response) => {
                if (response.email) {
                    setEmail(response.email);
                    setError(undefined);
                } else {
                    setError('Login failed with wrong credentials.');
                }
            },
            onError: (error) => {
                setError(error?.response?.data || 'Login failed with wrong credentials.');
            },
        });
    };

    const enableSSO = window['ENV_VARIABLES']['REACT_APP_SHOW_AAD_SSO'].indexOf(url.hostname) > -1;

    return (
        <LoginComponent
            title={'Sign in'}
            handleSubmit={handleSubmit}
            login={login}
            errors={errors}
            register={register}
            control={control}
            error={error}
            isLoading={isLoadingActiveDirectoryUser || isLoadingUserDetail || isLoadingLogin}
            enableSSO={enableSSO}
        />
    );
};

export default Login;
