import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryTopOverdue } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/queries/activitiesQuery';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { CustomizedRoundIcon, CustomizedTooltip, MyCustomSelect } from 'components/common';
import { morefilterItems } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/utils';
import {
    initialState,
    columns,
    TOP_OVERDUE_OVERRUN_FILTERS,
    statusFilterInitialState,
    defaultPeriod,
    delayTypeOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/components/TopOverdueOverrunActivitiesComponent/utils';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import {
    filterMapper,
    FilterNames,
    getFiltersData,
} from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { statusOptions } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/criticalAnalysisDelayDriversFilter';
import { InputOption, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import { IComponentProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/style/table.module.scss';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { List } from 'components/common/ListWrapper/list';
import { useQueryState } from 'hooks/useQueryState';
import { isBetweenRange } from 'components/Dashboards/Project/Components/helper';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useMutationSetWidgetColumns } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySelectedTableColumns';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import SwitchComponent from 'components/common/Switch/Switch';

const RenderTopOverdueOverrunActivities = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    compareVersionId,
    filter,
    localFilters,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: IComponentProps) => {
    const [isInstance, setIsInstance] = useState(false);
    const tableRef = useRef(null);
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const id = 'TopOverdueOverrunActivities';
    const componentKey = 'topOverdueOverrunActivities';
    const title = 'Top Delayed Activities';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const updatedInitialState = useTableCurrentState({ initialState, componentKey, widgetId });
    const [statusFilter, setStatusFilter] = useState<ISelectOption<string>[]>(statusFilterInitialState);
    const [isCriticalPath, setIsCriticalPath] = useState<boolean>(false);
    const [status, setStatus] = useState<any>([]);
    const [delayTypeStatus, setDelayTypeStatus] = useState<any>({
        value: ['FINISH_PROG_OVERDUE_CARD_BOARD'],
        label: 'Overdue Finish Date',
    });
    const [nextFilter, setNextFilter] = useQueryState('nextFilter');
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate: mutateSetWidgetColumns } = useMutationSetWidgetColumns();
    const numOfMonthsToBeSelected: string = useMemo(() => {
        return widgetId && localFilters?.numOfMonths ? localFilters.numOfMonths.value : nextFilter;
    }, [widgetId, localFilters, nextFilter]);

    const [numOfMonths, setNumOfMonths] = useState<ISelectOption<string> | undefined>(undefined);

    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes,
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                      [FilterNames.OPTIONS_STATUS]: statusFilter.map((item) => item.value).map(filterMapper),
                      [FilterNames.OPTIONS_TYPE]: isCriticalPath
                          ? [
                                ...status.map((item) => item.value).map(filterMapper),
                                ...delayTypeStatus.value.map(filterMapper),
                                'CRITICAL_PATH',
                            ]
                          : [
                                ...status.map((item) => item.value).map(filterMapper),
                                ...delayTypeStatus.value.map(filterMapper),
                            ],
                  }),

        [filter, globalFilterData, statusFilter, status, delayTypeStatus, isCriticalPath],
    );
    const { sendEventWithDimensions } = useEventWithDimensions();

    const {
        data: table,
        isLoading,
        isFetching,
    } = useQueryTopOverdue({
        id: [latestVersionId],
        compareVersionId,
        filter: {
            filters,
        },
    });

    const handleStatus = useCallback((value) => {
        const selected = value.map((item) => item.value);
        setStatus(value);

        sendEventWithDimensions({
            category: 'Activities Table(s)',
            action: 'Status Filter click',
            label: selected.length > 0 ? selected.join(',') : 'Deselect All',
        });
    }, []);

    const handleDelayType = useCallback((value) => {
        setDelayTypeStatus(value);
        sendEventWithDimensions({
            category: 'Activities Table(s)',
            action: 'Status Filter click',
            label: value.label,
        });
    }, []);

    const handleStatusType = (value) => {
        setStatusFilter(value);
    };

    const filteredRows = useMemo(() => {
        return table?.data.rows
            ? table?.data.rows.filter((item) =>
                  isBetweenRange({
                      numOfMonths: Number(numOfMonthsToBeSelected),
                      startDate: item.currentStartDate,
                      endDate: item.currentFinishDate,
                  }),
              )
            : [];
    }, [table, numOfMonthsToBeSelected]);

    const mergedFilters =
        widgetId && filter?.filters
            ? {
                  filters: [
                      ...filter?.filters,
                      ...getFiltersData({
                          [FilterNames.OVERRUN_RISK_SELECTED_PERIOD]:
                              Number(localFilters?.numOfMonths?.value) > 0
                                  ? [`${localFilters?.numOfMonths?.value}M`]
                                  : ['All'],
                      }),
                  ],
              }
            : undefined;

    const { generateFilters: formatedFilter } = useGlobalFiltersHook({
        widgetId,
        filter: mergedFilters,
        latestVersionId,
    });

    const handleInstance = (instance) => {
        if (instance) {
            tableRef.current = instance;
            setIsInstance(true); // need for render component after getting instance
        }
    };

    const handleTimePeriodClick = (filter: ISelectOption<string>) => {
        const value = filter.value;
        sendEventWithDimensions({
            category: 'Activities Table(s)',
            action: 'Next Selection',
            label: value,
        });
        setNextFilter(value);
        setNumOfMonths(filter);
    };

    const setWidgetColumns = (widgetId: string) => {
        if (tableRef.current) {
            const { hiddenColumns, columnOrder } = tableRef.current.state;
            mutateSetWidgetColumns(
                { columns: { hiddenColumns, columnOrder }, widgetId },
                {
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                    },
                },
            );
        }
    };
    useEffect(() => {
        setSubTitleForExport && table && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, table]);

    useEffect(() => {
        if (!nextFilter && !widgetId) {
            setNextFilter(defaultPeriod);
        }
        if (nextFilter && !widgetId) {
            const defaultNumOfMonths = TOP_OVERDUE_OVERRUN_FILTERS.find(
                (filter) => filter.value === numOfMonthsToBeSelected,
            );
            setNumOfMonths(defaultNumOfMonths);
        }
    }, [nextFilter, widgetId, numOfMonthsToBeSelected]);
    const handleCriticalPath = (event) => {
        const isChecked = event.target.checked;
        setIsCriticalPath(isChecked);
    };
    return (
        <WidgetWithTitle
            id={id}
            title={title}
            tooltip={table?.info}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId && formatedFilter.length > 0 ? (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formatedFilter} />
                ) : (
                    <div key={'filters'} className={classes.filters}>
                        <div className={classes.selectWrapper}>
                            <div className={classes.title}>Status</div>
                            <MyCustomSelect
                                value={statusFilter}
                                options={statusOptions}
                                onChange={handleStatusType}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                isMulti={true}
                                title={'Status'}
                                placeholder={'Select Status...'}
                                components={{
                                    Option: InputOption,
                                    ValueContainer: ValueContainer,
                                }}
                            />
                        </div>
                        <div>
                            Critical Path Only
                            <SwitchComponent onChange={handleCriticalPath} checked={isCriticalPath} />
                        </div>
                        <div className={classes.selectWrapper}>
                            <div className={classes.title}>Delay Type</div>
                            <MyCustomSelect
                                value={delayTypeStatus}
                                options={delayTypeOptions}
                                onChange={handleDelayType}
                                title={'Delay Type'}
                                placeholder={'Select Delay Type...'}
                            />
                        </div>
                        <div className={classes.selectWrapper}>
                            <div className={classes.title}>More Filters</div>
                            <MyCustomSelect
                                value={status}
                                options={morefilterItems}
                                onChange={handleStatus}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                isMulti={true}
                                title={'More Filters'}
                                placeholder={'Select More Filters...'}
                                components={{
                                    Option: InputOption,
                                    ValueContainer: ValueContainer,
                                }}
                            />
                        </div>
                        <div className={classes.selectWrapper}>
                            <div className={classes.title}>{`Next:`}</div>
                            <List
                                onChange={handleTimePeriodClick}
                                options={TOP_OVERDUE_OVERRUN_FILTERS}
                                value={numOfMonths}
                            />
                        </div>
                    </div>
                ),
            ]}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <CustomizedTooltip
                    key={'tableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    projectId={projectId}
                    contractId={contractId}
                    widgetId={widgetId}
                    route={route}
                    filters={filters}
                    localFilters={{ numOfMonths }}
                    callBack={setWidgetColumns}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                isInstance && (
                    <ExportDropdown
                        key={'export'}
                        title={title}
                        data={filteredRows}
                        tableInstance={tableRef.current}
                        subTitle={subTitle}
                    />
                ),
            ]}
        >
            <div className={classes.tableContainer}>
                {updatedInitialState && (
                    <MainTable
                        getInstanceCallback={handleInstance}
                        columns={columns}
                        data={filteredRows}
                        initialState={updatedInitialState}
                        pageSize={1000}
                        isLoading={isLoading || isFetching}
                    />
                )}
            </div>

            {tableRef?.current && (
                <CustomTableColumnsModal
                    onHideModal={() => setModalColumnsOpen(false)}
                    showModal={modalColumnsOpen}
                    widgetId={widgetId}
                    componentKey={componentKey}
                    tableInstance={tableRef.current}
                    initialState={initialState}
                    columns={columns}
                />
            )}
        </WidgetWithTitle>
    );
};

export default React.memo(RenderTopOverdueOverrunActivities);
