import { create } from 'zustand';

const DEFAULT_STORE_VALUES = {
    commentsEditorStatus: {
        isOpen: false,
        isError: false,
    },
};

const commentsStore = (set) => ({
    ...DEFAULT_STORE_VALUES,
    setCommentsEditorStatus: (commentsEditorStatus) => set(() => ({ commentsEditorStatus: commentsEditorStatus })),
    resetCommentsEditorStatus: () => set(DEFAULT_STORE_VALUES),
});

export const useCommentsEditorStatus = create(commentsStore);
