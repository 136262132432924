import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Settings/Components/RolesManagement/components/addRole.module.scss';
import { Prompt, useLocation } from 'react-router';
import { Buttons } from 'components/Settings/Components/RolesManagement/components/buttons';
import { RoleNameInput } from 'components/Settings/Components/RolesManagement/components/roleNameInput';
import { CheckBoxInputs } from 'components/Settings/Components/RolesManagement/components/checkBoxInputs';
import { ErrorMessage } from 'components/Settings/Components/RolesManagement/components/errorMessage';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { settingsConfig } from 'components/Settings/settingsConfig';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryUsersList } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import { useQueryGetRolesList } from 'components/Settings/Components/UserGroups/queries/userGroupsQuery';
import {
    useMutationCreateRole,
    useMutationUpdateRole,
    useQueryPrivilegesList,
} from 'components/Settings/Components/RolesManagement/queries/useQueryRolesManagement';
import { useFieldArray, useForm } from 'react-hook-form';
import { IRoleForm } from 'components/Settings/Components/RolesManagement/rolesManagment.utils.type';
import { trimObjectValues } from 'utilitys/helpers/general';
const checkUsersRoles = (userList, roleName) => {
    return userList.map((item) => ({ ...item, checked: item.roleList.map((role) => role.name).includes(roleName) }));
};

export const AddRole = () => {
    const history = useHistory();
    const location = useLocation();
    const { roleId } = useParams<{ roleId: string }>();
    const editMode = location.pathname.includes(settingsConfig.roleManagementEditRole.link);
    const title = editMode ? 'Edit Role' : 'New Role';
    const { handleSnackBar } = useCustomSnackBar();
    const { data: users } = useQueryUsersList();
    const { data: roleList } = useQueryGetRolesList();
    const { data: sPrivileges } = useQueryPrivilegesList();
    const { mutate: mutateCreateRole, isLoading: isLoadingCreateRole } = useMutationCreateRole();
    const { mutate: mutateUpdateRole, isLoading: isLoadingUpdateRole } = useMutationUpdateRole();
    const sRole = useMemo(() => roleList?.find((item) => String(item.id) === roleId), [roleList, roleId]);

    const {
        register,
        handleSubmit,
        reset,
        control,
        watch,
        formState: { errors, isDirty, isSubmitSuccessful },
    } = useForm<IRoleForm>();

    const { fields, replace } = useFieldArray({
        control,
        name: 'privilegeList',
        rules: {
            validate: {
                minChecked: (value) => {
                    return value.filter((item) => item.value).length > 0 || 'Please add at least one privilege';
                },
            },
        },
    });

    const currentUsers = sRole && users ? checkUsersRoles(users.users, sRole.name) : [];

    useEffect(() => {
        if (sPrivileges) {
            reset({
                privilegeList: sPrivileges.map((item) => ({
                    ...item,
                    value:
                        editMode && sRole
                            ? sRole.privilegeList.map((role) => role.objectName).includes(item.objectName)
                            : false,
                })),
                roleName: editMode && sRole ? sRole.name : '',
            });
        }
        return () => {
            reset();
        };
    }, [sRole, sPrivileges, editMode]);

    const watchFieldArray = watch('privilegeList');

    const onSubmit = async (data): Promise<any> => {
        const trimmedData = trimObjectValues(data);
        const params = prepareParamsObj(trimmedData);
        if (!editMode) {
            mutateCreateRole(params, {
                onSuccess: () => {
                    handleSnackBar('Successfully saved!', 'success');
                },
                onError: (error) => {
                    handleSnackBar(error.response.data, 'error');
                },
                onSettled: () => {
                    history.goBack();
                },
            });
        } else {
            mutateUpdateRole(
                {
                    role: params,
                    id: sRole.id,
                },
                {
                    onSuccess: () => {
                        handleSnackBar('Successfully saved!', 'success');
                    },
                    onError: (error) => {
                        handleSnackBar(error.response.data, 'error');
                    },
                    onSettled: () => {
                        history.goBack();
                    },
                },
            );
        }
    };

    const prepareParamsObj = (data): IRoleNew => {
        return {
            name: data.roleName,
            privilegeList: data.privilegeList.filter((item) => item.value === true),
        };
    };

    return (
        <WidgetWithTitle id={'rolesManagementAddEdit'} title={title} className={classes.AddRoleContainer}>
            <>
                <Prompt
                    message={'You have unsaved changes, are you sure you want to leave?'}
                    when={isDirty && !isSubmitSuccessful}
                />
                {sPrivileges && currentUsers && (
                    <div className={classes.addRoleForm}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Buttons
                                isLoadingCreateRole={isLoadingCreateRole}
                                isLoadingUpdateRole={isLoadingUpdateRole}
                            />
                            <RoleNameInput register={register} errors={errors} />
                            <CheckBoxInputs
                                currentUsers={currentUsers}
                                fields={fields}
                                watchFieldArray={watchFieldArray}
                                replace={replace}
                                control={control}
                            />
                            <ErrorMessage errors={errors} />
                        </form>
                    </div>
                )}
            </>
        </WidgetWithTitle>
    );
};
