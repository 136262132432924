export const STATUSES_TYPES = {
    AHEADOFSCHEDULE: 'AHEADOFSCHEDULE',
    BEHINDSCHEDULE: 'BEHINDSCHEDULE',
    ONSCHEDULE: 'ONSCHEDULE',
    SLIGHTLYBEHINDSCHEDULE: 'SLIGHTLYBEHINDSCHEDULE',
    HIGH: 'HIGH',
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    BEHIND: 'BEHIND',
    AHEAD: 'AHEAD',
};
