import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const DEFAULT_STORE_VALUES = {
    themeStatus: false,
};

const themeStatusStore = (set) => ({
    ...DEFAULT_STORE_VALUES,
    setThemeStatus: (theme) => set(() => ({ themeStatus: theme })),
});

export const useThemeStatus = create(persist(themeStatusStore, { name: 'themeStatus' }));
