import { Filters } from 'components/Dashboards/ExcellenceApps/Recommendations/Filters';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import classes from 'components/Dashboards/Project/Components/VersionCompare/style/Table.module.scss';
import { CustomizedTabs } from 'components/common/CustomizedTabs/CustomizedTabs';
import NoData from 'components/common/NoData/noData';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import { columns, initialState } from 'components/Dashboards/ExcellenceApps/Recommendations/utils';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IComponentProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { generateRecommendationsTabs } from 'components/Dashboards/Project/Components/VersionCompare/utils/helper';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useMutationSetWidgetColumns } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySelectedTableColumns';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import { useQueryRecomendationTableData } from 'components/Dashboards/ExcellenceApps/Recommendations/queries/useQueryRecommendationTable';

export type TSeverity = 'High' | 'Medium' | 'Low';

interface IChosenFilter {
    severity: ISelectOption<TSeverity>[];
}

export const RecommendationsTable = ({
    widgetId,
    filter,
    externalComponents,
    editNarrative,
    projectId,
    contractId,
    latestVersionId,
    compareVersionId,
    setSubTitleForExport,
}: IComponentProps) => {
    const id = 'top-recommendations';
    const elementId = 'top-recommendations';

    const tableRef = useRef(null);
    const [chosenFilter, setChosenFilter] = useState<IChosenFilter>({ severity: [] });
    const [search, setSearch] = useState<string>('');
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);

    const { data, isLoading, isFetching } = useQueryRecomendationTableData({
        projectId: projectId,
        contractId: contractId,
        latestVersionId: latestVersionId,
        compareVersionId: compareVersionId,
    });

    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters
                : getFiltersData({
                      ...chosenFilter,
                      severity: chosenFilter.severity.map((item) => item.value),
                  }),

        [filter, chosenFilter],
    );

    const handleStatus = useCallback((value, key) => {
        setChosenFilter((prev) => ({ ...prev, [key]: value }));
    }, []);

    const handleSearch = useCallback((e) => {
        setSearch(e.target.value);
    }, []);

    const handleClearSearch = useCallback(() => {
        setSearch('');
        setChosenFilter((prev) => ({ ...prev, Recommendation: [] }));
    }, []);

    const handleOnSearch = useCallback(() => {
        setChosenFilter((prev) => ({ ...prev, Recommendation: [search] }));
    }, [search]);

    const [activeTab, setActiveTab] = useState<string>('all');

    const tabsArrData = generateRecommendationsTabs({ data });

    const activeData = useMemo(() => {
        if (data) {
            const currentData =
                activeTab === 'all'
                    ? data.recommendationResponse
                    : data.recommendationResponse.filter((item) => item.category.name === activeTab);
            if (currentData.length > 0 && filters) {
                const filterRecommendation = filters.find((filter) => filter.name === 'Recommendation');
                const filterSeverity = filters.find((filter) => filter.name === 'severity');

                return currentData
                    .filter((item) => {
                        if (filterRecommendation) {
                            const filter = filterRecommendation?.data?.map((item) => item.toLowerCase()) as string[];
                            const value = item.title as string;

                            return value.toLowerCase().includes(filter[0]);
                        }
                        return item;
                    })
                    .filter((item) => {
                        if (filterSeverity) {
                            const value = item.severity as string;
                            const filter = filterSeverity.data as string[];
                            return filter?.includes(value);
                        }
                        return item;
                    });
            }
            return currentData;
        }
        return [];
    }, [data, activeTab, filters]);

    const title = 'Top Recommendations';
    const componentKey = 'topRecommendations';

    const route = `${getStringWithoutHash(window.location.href)}#${elementId}`;
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const updatedInitialState = useTableCurrentState({ initialState, componentKey, widgetId });
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate: mutateSetWidgetColumns } = useMutationSetWidgetColumns();
    const { generateFilters: formattedFilter } = useGlobalFiltersHook({ widgetId, filter, latestVersionId });
    const setWidgetColumns = (widgetId: string) => {
        if (tableRef.current) {
            const { hiddenColumns, columnOrder } = tableRef.current.state;
            mutateSetWidgetColumns(
                { columns: { hiddenColumns, columnOrder }, widgetId },
                {
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                    },
                },
            );
        }
    };

    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle]);

    return (
        <WidgetWithTitle
            title={title}
            tooltip={data?.info}
            id={id}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId && formattedFilter.length > 0 ? (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formattedFilter} />
                ) : !widgetId ? (
                    <Filters
                        key={'filters'}
                        handleStatus={handleStatus}
                        chosenFilter={chosenFilter}
                        search={search}
                        handleSearch={handleSearch}
                        handleClearSearch={handleClearSearch}
                        handleOnSearch={handleOnSearch}
                    />
                ) : null,
            ]}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <CustomizedTooltip
                    key={'tableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    projectId={projectId}
                    contractId={contractId}
                    elementId={elementId}
                    widgetId={widgetId}
                    route={route}
                    filters={filters}
                    callBack={setWidgetColumns}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown title={title} key={'export'} subTitle={subTitle} />,
            ]}
        >
            <div className={classes.tableWrapperContainer}>
                {tabsArrData.length > 0 && (
                    <CustomizedTabs tabs={tabsArrData} activeTab={activeTab} setActiveTab={setActiveTab} />
                )}

                {updatedInitialState && (
                    <MainTable
                        getInstanceCallback={(instance) => (tableRef.current = instance)}
                        initialState={updatedInitialState}
                        data={activeData}
                        columns={columns}
                        pageSize={1000}
                        uniqueKey={'id'}
                        isLoading={isLoading || isFetching}
                        noData={<NoData>No Changes To Display</NoData>}
                    />
                )}

                {tableRef?.current && (
                    <CustomTableColumnsModal
                        onHideModal={() => setModalColumnsOpen(false)}
                        showModal={modalColumnsOpen}
                        widgetId={widgetId}
                        componentKey={componentKey}
                        tableInstance={tableRef.current}
                        initialState={initialState}
                        columns={columns}
                    />
                )}
            </div>
        </WidgetWithTitle>
    );
};
