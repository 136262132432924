import { ConfirmSaveModal, MyCustomSelect } from 'components/common';
import { useMemo, useState } from 'react';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import {
    defaultOverdueAndUrgentDateThreshold,
    SelectedValue,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/subProgramPreferences.utils';
import {
    useMutationSetOverdueAndUrgentDateThreshold,
    useQueryOverdueAndUrgentDateThreshold,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/queries/useQuerySubProgramPreferences';
import { useProjectStore } from 'components/common/TreeProgramFilter/zustand_store/treeHeaderStore';

export const OverdueAndUrgentDateThreshold = () => {
    const {
        selectedProject: { contract },
    } = useProjectStore();
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<SelectedValue | undefined>(undefined);
    const { handleSnackBar } = useCustomSnackBar();
    const { data, refetch } = useQueryOverdueAndUrgentDateThreshold({ subProgramId: contract?.id });
    const mutation = useMutationSetOverdueAndUrgentDateThreshold();

    const currentValue = useMemo(() => {
        return defaultOverdueAndUrgentDateThreshold.find((item) => item.value === data);
    }, [data]);

    const handleHideModal = () => {
        setShowConfirmModal(false);
        setSelectedValue(currentValue);
    };

    const handleOverdueAndUrgentDateThreshold = (value) => {
        if (value.id !== currentValue?.id) {
            setShowConfirmModal(true);
            setSelectedValue(value);
        }
    };

    const handleSave = () => {
        setShowConfirmModal(false);
        mutation.mutate(
            { subProgramId: contract?.id, option: selectedValue?.value },
            {
                onSuccess: () => handleSnackBar('Overdue & Urgent Date Threshold has changed', 'success'),
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
                onSettled: () => refetch(),
            },
        );
    };
    return (
        <>
            <span>Overdue & Urgent Date Threshold:</span>
            <MyCustomSelect
                id={'sub-program-preferences-date-threshold'}
                value={currentValue}
                options={defaultOverdueAndUrgentDateThreshold}
                onChange={handleOverdueAndUrgentDateThreshold}
                isSearchable={false}
            />
            <ConfirmSaveModal
                isShowModal={showConfirmModal}
                onHideModal={handleHideModal}
                onConfirm={handleSave}
                isLoading={mutation.isLoading}
            >
                You have made changes. Would you like to save them?
            </ConfirmSaveModal>
        </>
    );
};
