import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';
import BaselineVsActual, {
    Progress,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/BaselineVsActual/BaselineVsActual';
import React, { useEffect, useMemo, useState } from 'react';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { LoaderContainer } from 'components/common';
import NoData from 'components/common/NoData/noData';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { useQueryBaselineVsActual } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/queries/progressOverviewQuery';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { IComponentProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { useQueryState } from 'hooks/useQueryState';
import { useQueryGetMilestoneFragnet } from 'components/common/GlobalFilterComponent/queries/GlobalFilterComponentQuery';

const RenderBaselineVsActual = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    compareVersionId,
    filter,
    localFilters,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: IComponentProps) => {
    const { generateFilters: formatedFilter } = useGlobalFiltersHook({ widgetId, filter, latestVersionId });

    const [weightedQuery, setWeightedQuery] = useQueryState('progressWeighted');

    const progressWeightedInit: Progress | undefined = useMemo(() => {
        return widgetId && localFilters?.progressWeighted
            ? (localFilters.progressWeighted as Progress)
            : (weightedQuery as Progress);
    }, [widgetId, localFilters, weightedQuery]);

    const [progressWeighted, setProgressWeighted] = useState<Progress | undefined>(undefined);

    const { data: milestoneFragnet } = useQueryGetMilestoneFragnet({
        latestVersion: latestVersionId,
        filter: '',
    });

    const milestoneFragnetTaskVersionHashCode = useMemo(() => {
        if (widgetId && filter?.filters) {
            const fragnetTaskHashCode = filter?.filters.find((item) => item.name === 'FRAGNET_SEARCH')?.data;
            if (fragnetTaskHashCode && fragnetTaskHashCode.length > 0) {
                return fragnetTaskHashCode[0];
            }
        }

        if (globalFilterData?.milestoneFragnet) {
            return globalFilterData.milestoneFragnet;
        }

        if (milestoneFragnet && milestoneFragnet.length > 0) {
            return milestoneFragnet[0].task_version_hash_code;
        }
    }, [milestoneFragnet, globalFilterData]);

    useEffect(() => {
        if (!weightedQuery && !widgetId) {
            setWeightedQuery(Progress.CriticalityWeighted);
            setProgressWeighted(Progress.CriticalityWeighted);
        } else {
            setProgressWeighted(progressWeightedInit);
        }
    }, [weightedQuery, widgetId, progressWeightedInit]);

    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes,
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                  }),

        [filter, globalFilterData],
    );

    const { data: dataBaseline, isLoading: loadingBaseline } = useQueryBaselineVsActual({
        contractId: contractId,
        projectId: projectId,
        actualMetaDataId: latestVersionId,
        baselineMetaDataId: compareVersionId,
        filter: {
            filters,
        },
    });

    const actualFinishDate = dataBaseline?.actual.finishDate;
    const actualStartDate = dataBaseline?.actual.startDate;
    const baseLineFinishDate = dataBaseline?.baseLine.finishDate;
    const baseLineStartDate = dataBaseline?.baseLine.startDate;

    const isNoData =
        !Boolean(actualFinishDate) &&
        !Boolean(actualStartDate) &&
        !Boolean(baseLineFinishDate) &&
        !Boolean(baseLineStartDate);

    const id = 'BaselineVsActual';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;

    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const title = 'Progress Summary';
    const componentKey = 'progressSummary';
    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle]);

    return (
        <ErrorBoundary>
            <WidgetWithTitle
                id={id}
                title={title}
                tooltip={dataBaseline?.info}
                titleComponents={[
                    externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                    <AddWidget
                        key={'AddWidget'}
                        componentKey={componentKey}
                        title={title}
                        projectId={projectId}
                        contractId={contractId}
                        widgetId={widgetId}
                        route={route}
                        filters={filters}
                        localFilters={{ progressWeighted }}
                    />,
                    <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                    <ExportDropdown key={'export'} title={title} subTitle={subTitle} />,
                ]}
                titleFilters={[
                    editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                    widgetId && formatedFilter.length > 0 ? (
                        <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formatedFilter} />
                    ) : null,
                ]}
            >
                {dataBaseline && !loadingBaseline && !isNoData && (
                    <>
                        <BaselineVsActual
                            data={dataBaseline}
                            loading={loadingBaseline}
                            widgetId={widgetId}
                            progressWeighted={progressWeighted}
                            setWeightedQuery={setWeightedQuery}
                            milestoneFragnetTaskVersionHashCode={milestoneFragnetTaskVersionHashCode}
                        />
                    </>
                )}
                {loadingBaseline && !dataBaseline && isNoData && (
                    <LoaderContainer>
                        <OverlayWithSpinner />
                    </LoaderContainer>
                )}
                {!loadingBaseline && isNoData && <NoData />}
            </WidgetWithTitle>
        </ErrorBoundary>
    );
};
export default RenderBaselineVsActual;
