import classes from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/CompareTo/CompareTo.module.scss';
import { CustomizedDropdownMenu } from 'components/common';

import { startCase } from 'lodash';
import { useQueryState } from 'hooks/useQueryState';
import { useClusterRCFAnalysisStore } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/zustand_store/clusterRCFAnalysisStore';
import { COMPARE } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterRCFAnalysis.type';
import { useEffect } from 'react';

export const CompareTo = () => {
    const [compare, setCompare] = useQueryState('clusterScope');
    const { setSelectedClusterId } = useClusterRCFAnalysisStore();

    useEffect(() => {
        if (!compare) {
            setCompare(COMPARE.ITSELF);
        }
    }, [compare]);

    const handleCompare = (item) => {
        setCompare(item);
        setSelectedClusterId(null);
    };
    return (
        <div className={classes.compareContainer}>
            <div className={classes.compareTitle}>Cluster Scope: </div>
            <CustomizedDropdownMenu
                triggerElement={
                    <div className={classes.compareValue}>{typeof compare === 'string' ? startCase(compare) : ''}</div>
                }
            >
                {Object.values(COMPARE).map((item) => {
                    return (
                        <div key={item} className={classes.compareOption} onClick={() => handleCompare(item)}>
                            {startCase(item)}
                        </div>
                    );
                })}
            </CustomizedDropdownMenu>
        </div>
    );
};
