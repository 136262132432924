import classes from './cardWidget.module.scss';
import { OptionValue } from 'components/common/CustomizedStatusSelect/CustomizedStatusSelect.types';
import CustomizedStatusSelect, {
    IOptionComponent,
} from 'components/common/CustomizedStatusSelect/CustomizedStatusSelect';
import {
    CardType,
    ICreateCardObjectReturn,
    statusOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import { LoaderSpinner } from 'components/common/LodaerSpinner/LoaderSpinner';
import Icon from 'components/common/Icons/icon';
import { AssigneeAvatarWithSelection } from 'components/common/AssigneeAvatarWithSelection/AssigneeAvatarWithSelection';
import React from 'react';
import { useMutationCreateEditCard } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/cardModalMutation';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useMutationSetAssociateCardWithLogicChecker } from 'components/Dashboards/ExcellenceApps/LogicChecker/queries/useQueryLogicChecker';
import {
    IAssignee,
    IStatus,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import { useQueryClient } from 'react-query';
import moment from 'moment-timezone';
import { useLocation } from 'react-router-dom';

interface ICardWidgetProps {
    projectId: number;
    contractId: number;
    versionName: string;
    cardId?: number | null;
    status?: IStatus;
    assignee?: IAssignee;
    title: string;
    metaDataId?: number;
    checkId?: number;
}

export const CardWidget = ({
    projectId,
    contractId,
    versionName,
    cardId,
    status,
    assignee,
    title,
    metaDataId,
    checkId,
}: ICardWidgetProps) => {
    const { pathname } = useLocation();
    const queryClient = useQueryClient();
    const { handleSnackBar } = useCustomSnackBar();
    const optionValue = status || statusOptions[0];

    const startDate = moment().valueOf();
    const endDate = moment().add(7, 'days').valueOf();

    const card: ICreateCardObjectReturn = {
        id: cardId,
        projectId: projectId,
        contractId: contractId,
        assigneeUserName: assignee?.userName,
        category: 'QUALITY',
        details: `Quality issues found in version ${versionName}: <a href="${pathname}" target="_self">${title}</a>`,
        mentionedList: [],
        startDate: startDate,
        endDate: endDate,
        externalLinks: [],
        status: optionValue.name,
        tagList: [],
        title: `Review & fix quality issues: ${title}`,
        relatedCardIds: null,
        type: CardType.USER_GENERATED,
        isImportant: true,
    };

    const { mutate: createEditCardMutate, isLoading: createEditCardMutateLoading } = useMutationCreateEditCard();
    const { mutate: setAssociateCardMutate, isLoading: setAssociateCardMutateLoading } =
        useMutationSetAssociateCardWithLogicChecker();

    const handleStatusSelection = (value) => {
        const cardParams = { ...card, status: value.name };
        handleMutate(cardParams);
    };

    const handleAssigneeSelection = (value) => {
        const cardParams = { ...card, assigneeUserName: value };
        handleMutate(cardParams);
    };

    const handleMutate = (cardParams) => {
        createEditCardMutate(cardParams, {
            onSuccess: (res) => {
                const params = {
                    metaDataId: metaDataId,
                    checkId: checkId,
                    cardId: res.id,
                };

                if (cardId) {
                    return queryClient.invalidateQueries('getLogicCheckerCategoryResults');
                }

                setAssociateCardMutate(params, {
                    onSuccess: () => {
                        handleSnackBar('Successfully Saved', 'success');
                        return queryClient.invalidateQueries('getLogicCheckerCategoryResults');
                    },
                    onError: (error) => {
                        handleSnackBar(error.response.data || 'Something went wrong', 'error');
                    },
                });
            },
            onError: (error) => {
                handleSnackBar(error.response.data || 'Something went wrong', 'error');
            },
        });
    };

    return (
        <div className={classes.cardWidgetContainer}>
            <CustomizedStatusSelect<OptionValue>
                options={statusOptions}
                optionValue={optionValue}
                handleChange={handleStatusSelection}
                className={classes.statusCustomSelectContainer}
                optionComponent={SelectItem}
                isLoading={createEditCardMutateLoading || setAssociateCardMutateLoading}
                disabled={createEditCardMutateLoading}
            />
            <AssigneeAvatarWithSelection assignee={assignee} handleSelection={handleAssigneeSelection} />
        </div>
    );
};

const SelectItem = ({ item, handleSelect, disabled, isLoading }: IOptionComponent<OptionValue>) => {
    return (
        <div
            className={`${classes.statusCustomSelectItem} ${classes[item.name]}`}
            onClick={(e) => {
                handleSelect(item, e);
            }}
        >
            {isLoading ? (
                <LoaderSpinner />
            ) : (
                <>
                    <span>{item.label}</span>
                    {!disabled && (
                        <div className={classes.arrowIconWrapper}>
                            <Icon name="header-arrow-down" size={'16'} color="#ffffff" />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
