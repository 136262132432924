import { useHistory } from 'react-router-dom';

/**
 * custom hook for manipulating querystring URL
 * @returns {{removeQueryKey: removeQueryKey, addQueryKey: addQueryKey}}
 */

export type UseQuerystring = {
    addQueryKey: ({ key, value }: { key: string; value: string }) => void;
    removeQueryKey: ({ key }: { key: string }) => void;
    removeAllQueryKey: () => void;
    getQueryKey: ({ key }: { key: string }) => string | null;
};

const clean = (urlSearchParams) => urlSearchParams.toString().replace(/%2C/g, ',');

export const useQuerystring = (): UseQuerystring => {
    const history = useHistory();

    const addQueryKey = ({ key, value }: { key: string; value: string }): void => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        if (urlSearchParams.has(key)) {
            urlSearchParams.set(key, value);
        } else {
            urlSearchParams.append(key, value);
        }
        history.replace({
            search: clean(urlSearchParams),
        });
    };

    const removeQueryKey = ({ key }: { key: string }): void => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.delete(key);
        history.replace({
            search: clean(urlSearchParams),
        });
    };

    const removeAllQueryKey = () => {
        history.replace({
            search: '',
        });
    };

    const getQueryKey = ({ key }: { key: string }): string | null => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const value = urlSearchParams.get(key);

        return value ? value.replace(/ /g, '+') : value;
    };

    return { removeQueryKey, addQueryKey, getQueryKey, removeAllQueryKey };
};
