import { useQuery } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import qs from 'qs';

export interface IActivityCodesResponse {
    alias: string | null;
    description: string;
    id: number;
    name: string;
    scope: string;
    typeName: string;
    typeUid: number;
    uid: number;
    activitiesCount: number;
    activtyCodeValueId: string;
}

export interface IMilestoneFragnetResponse {
    id: number;
    activity_id: string;
    name: string;
    activity_status: string;
    activity_type: string;
    preceding_activities: number;
    task_version_hash_code: string;
}

export interface IWBSResponse {
    activity_id: string;
    id: number;
    name: string;
    task_version_hash_code: string;
}

interface IActivityCodesByVersionPayload {
    latestVersion?: number;
}

interface IGetMilestoneFragnetPayload {
    latestVersion?: number;
    filter: string;
}

interface ITagResponse {
    name: string;
    tagId: number;
}

const getActivityCodes = ({ latestVersion }: { latestVersion: number }) => {
    const url = `${API_URL().FETCH_PROGRAM_DASHBOARD_GET_ACTIVITY_CODES}?versionId=${latestVersion}`;
    return network
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getMilestoneFragnet = ({
    latestVersion,
    filter,
    taskVersionHashCode,
}: {
    latestVersion: number;
    filter?: string;
    taskVersionHashCode?: string | null;
}) => {
    const querystring = qs.stringify(
        { metaDataId: latestVersion, filter, taskVersionHashCode },
        { addQueryPrefix: true, skipNulls: true },
    );
    const url = `${API_URL().GET_FRAGNET_MILESTONES}${querystring}`;

    return network
        .post(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getWbs = ({
    latestVersion,
    filter,
    taskId,
}: {
    latestVersion: number;
    filter?: string;
    taskId?: string | null;
}) => {
    const querystring = qs.stringify(
        { metaDataId: latestVersion, filter, taskId },
        { addQueryPrefix: true, skipNulls: true },
    );
    const url = `${API_URL().GET_WBS}${querystring}`;

    return network
        .post(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getTags = (customerId: number) => {
    const url = new URL(API_URL().GET_STORED_TAGS);
    url.searchParams.set('customerId', customerId.toString());
    return network
        .get(url.toString())
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryActivityCodesByVersion = (params: IActivityCodesByVersionPayload) => {
    return useQuery<IActivityCodesResponse[], QueryError>(
        ['activityCodesByVersion', params],
        () => getActivityCodes({ latestVersion: params.latestVersion as number }),
        {
            enabled: Boolean(params.latestVersion),
        },
    );
};

export const useQueryActivityCodesByVersionAndWidgetId = (params) => {
    return useQuery<IActivityCodesResponse[], QueryError>(
        ['activityCodesForWidget', params],
        () => getActivityCodes(params),
        {
            enabled: Boolean(params.latestVersion) && Boolean(params.widgetId) && Boolean(params.hasActivityCode),
        },
    );
};

export const useQueryGetMilestoneFragnet = (params: IGetMilestoneFragnetPayload) => {
    return useQuery<IMilestoneFragnetResponse[], QueryError>(
        ['getMilestoneFragnet', params],
        () =>
            getMilestoneFragnet({
                latestVersion: params.latestVersion as number,
                filter: params.filter,
            }),
        {
            enabled: Boolean(params.latestVersion),
        },
    );
};

export const useQueryGetWbs = (params) => {
    return useQuery<IWBSResponse[], QueryError>(
        ['getWbs', params],
        () =>
            getWbs({
                latestVersion: params.latestVersion as number,
                filter: params.filter,
            }),
        {
            enabled: Boolean(params.latestVersion),
        },
    );
};

export const useQueryGetSingleMilestoneFragnet = (params: {
    latestVersion?: number;
    taskVersionHashCode?: string | null;
}) => {
    return useQuery<IMilestoneFragnetResponse[], QueryError>(
        ['getSingleMilestoneFragnet', params],
        () =>
            getMilestoneFragnet({
                latestVersion: params.latestVersion as number,
                taskVersionHashCode: params.taskVersionHashCode,
            }),
        {
            enabled: Boolean(params.latestVersion) && Boolean(params.taskVersionHashCode),
        },
    );
};

export const useQueryGetSingleWbs = (params: { latestVersion?: number; taskId?: string | null }) => {
    return useQuery<IWBSResponse[], QueryError>(
        ['getWbs', params],
        () =>
            getWbs({
                latestVersion: params.latestVersion as number,
                taskId: params.taskId,
            }),
        {
            enabled: Boolean(params.latestVersion) && Boolean(params.taskId),
        },
    );
};

export const useQueryGetTags = ({ customerId }: { customerId?: number }) => {
    return useQuery<ITagResponse[], QueryError>('getTags', () => getTags(customerId as number), {
        enabled: Boolean(customerId),
    });
};
