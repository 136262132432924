import { create } from 'zustand';

interface IProgramLibraryStore {
    isMultiple: boolean;
    setIsMultiple: () => void;
}

const programLibraryStore = (set) => ({
    isMultiple: false,
    setIsMultiple: () => set((state) => ({ isMultiple: !state.isMultiple })),
});

export const useProgramLibraryStore = create<IProgramLibraryStore>(programLibraryStore);
