import classes from './SideMenu.module.scss';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { CustomizedDropdownMenu, CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import Icon from 'components/common/Icons/icon';
import { ActionMenuItemWithIcon } from 'components/common/ActionMenuItemWithIcon/actionMenuItemWithIcon';
import { generatePath, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import qs from 'qs';
import LabelWithIcon from 'components/common/LabelWithIcon/LabelWithIcon';
import { IStatus } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { UseFormSetValue } from 'react-hook-form';
import { IFields } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/ProgramActivityCard.types';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

interface ISideMenuProps {
    title: string;
    taskId: number;
    taskVersionHashCode: string;
    originalStatus: IStatus;
    status: IStatus;
    setValue: UseFormSetValue<IFields>;
    category: string;
}

export const SideMenu = ({
    title,
    taskId,
    taskVersionHashCode,
    originalStatus,
    status,
    setValue,
    category,
}: ISideMenuProps) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const params = useParams();
    const { search } = useLocation();

    const handleCreateUserAction = (taskId: number) => {
        const newSearch = qs.stringify({ ...qs.parse(search), taskId: taskId });
        const pathname = generatePath(path, { ...params, cardId: 'newCard' });
        setTimeout(() => {
            history.push({
                pathname,
                search: newSearch,
            });
        }, 0);
    };

    const handleRollbackStatusToOriginal = () => {
        setValue('status', { ...originalStatus, originalStatus: originalStatus.name }, { shouldDirty: true });
    };

    const handleOpenCompletionPlan = () => {
        history.push({
            pathname: `/dashboard/program/${excellenceAppsConfig.completionPlan.link}`,
            search: `?milestoneFragnet=${taskVersionHashCode}`,
        });
    };

    const handleOpenProgressMilestoneFragnet = () => {
        history.push({
            pathname: `/dashboard/project/${projectConfig.progressOverview.link}`,
            search: `?milestoneFragnet=${taskVersionHashCode}`,
        });
    };

    const handleOpenProgressWbs = () => {
        history.push({
            pathname: `/dashboard/project/${projectConfig.progressOverview.link}`,
            search: `?wbs=${taskId}`,
        });
    };

    const handleOpenGannt = () => {
        history.push({
            pathname: `/dashboard/project/${projectConfig.ganttChart.link}`,
            search: `?milestoneFragnet=${taskVersionHashCode}`,
        });
    };

    return (
        <div className={classes.sideMenuContainer}>
            <ShareInsightButton title={`activity "${title}"`} link={window.location.href.split('?')[0]} size={'3rem'} />

            <CustomizedDropdownMenu
                triggerElement={
                    <CustomizedTooltip
                        tooltipContent={`Manage Activity`}
                        triggerElement={
                            <CustomizedRoundIcon size={'3rem'}>
                                <Icon name={'three-dot-icon'} />
                            </CustomizedRoundIcon>
                        }
                    />
                }
            >
                {taskId && (
                    <ActionMenuItemWithIcon
                        handleClick={() => {
                            handleCreateUserAction(taskId);
                        }}
                        iconText={'Add Associated User Action'}
                        iconType={'plus-icon'}
                        iconSize={'1.3rem'}
                    />
                )}

                {originalStatus.name !== status.name && (
                    <LabelWithIcon
                        text={'Rollback Status to Original'}
                        muiIcon={<MuiIcon icon={'settings_backup_restore'} fontSize={'1.5rem'} />}
                        onClick={handleRollbackStatusToOriginal}
                    />
                )}

                {taskVersionHashCode && (
                    <LabelWithIcon
                        text={'Open Completion Plan'}
                        muiIcon={<MuiIcon icon={'flaky'} fontSize={'1.5rem'} />}
                        onClick={handleOpenCompletionPlan}
                    />
                )}

                {taskVersionHashCode && category !== 'WBS' && (
                    <LabelWithIcon
                        text={'Open Progress'}
                        id={'open-progress'}
                        icon={'clock'}
                        size={'1.4rem'}
                        onClick={handleOpenProgressMilestoneFragnet}
                    />
                )}
                {taskId && category === 'WBS' && (
                    <LabelWithIcon
                        text={'Open Progress'}
                        id={'open-progress'}
                        icon={'clock'}
                        size={'1.4rem'}
                        onClick={handleOpenProgressWbs}
                    />
                )}

                {taskVersionHashCode && (
                    <LabelWithIcon text={'Open Gannt'} icon={'gantt'} size={'1.4rem'} onClick={handleOpenGannt} />
                )}
            </CustomizedDropdownMenu>
        </div>
    );
};
