import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { VersionSection } from 'components/common/PageContainer/versionSection';
import { TreeProgramTopHeader, VersionCompareSelectorWrapper } from 'components/common';
import { projectConfig as config } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { MainContent } from 'components/common/PageContainer/MainContent';
import PageContainer from 'components/common/PageContainer/PageContainer';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { MyTrackedActivitiesWrapper } from 'components/Dashboards/widgets/myTrackedActivities/myTrackedActivitiesWrapper';
import { MyProjectScoreCardWidget } from 'components/Dashboards/widgets/myProjectScoreCard/myProjectScoreCardWidget';
import { MyPrioritiesWrapper } from 'components/Dashboards/widgets/myPriorities/myPrioritiesWrapper';
import { MyNotificationsWidget } from 'components/Dashboards/widgets/myNotifications/myNotificationsWidget';
import { MyFocusHeader } from 'components/Dashboards/widgets/myFocusHeader/myFocusHeader';

import { MainCardRoutingModal } from 'components/common/MainCardRoutingModal/mainCardRoutingModal';
import { ProgramStatusSummaryReportQueryKeys } from 'components/Dashboards/Project/Components/VersionCompare/queries/tableDataQuery';
import { cardsDataQueryKey } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';

export const MyFocusPage = () => {
    useFullStoryPageView({ pageName: config.myFocusPage.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <VersionSection>
                        <TreeProgramTopHeader pageTitle={config.myFocusPage.title}>
                            <ShareInsightButton title={config.myFocusPage.title} link={window.location.href} />
                        </TreeProgramTopHeader>
                        <VersionCompareSelectorWrapper isBaselineSelected={true} allowSingleVersion={true} />
                    </VersionSection>
                </VersionWrapper>
            </HeaderWrapper>

            <MainContent>
                <MyFocusHeader />
                <div style={{ display: 'flex', gap: '48px' }}>
                    <div style={{ flex: 2 }}>
                        <MyPrioritiesWrapper />
                    </div>
                    <div style={{ flex: 1 }}>
                        <MyProjectScoreCardWidget />
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '48px' }}>
                    <div style={{ flex: 2 }}>
                        <MyTrackedActivitiesWrapper />
                    </div>
                    <div style={{ flex: 1 }}>
                        <MyNotificationsWidget />
                    </div>
                </div>
            </MainContent>
            <MainCardRoutingModal queryKeys={[cardsDataQueryKey, 'summaryTrackedActivitiesTableData']} />
        </PageContainer>
    );
};
