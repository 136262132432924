import React, { useCallback } from 'react';
import classes from 'components/Dashboards/ExcellenceApps/Recommendations/recommendations.module.scss';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { MyCustomSelect } from 'components/common';
import { InputOption, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import { TSeverity } from 'components/Dashboards/ExcellenceApps/Recommendations/recommendationsTable';

const severityOptions: ISelectOption<TSeverity>[] = [
    { value: 'High', label: 'High' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Low', label: 'Low' },
];

export const Filters = ({ handleStatus, chosenFilter, search, handleSearch, handleClearSearch, handleOnSearch }) => {
    const onKeyPress = useCallback(
        (e) => {
            if (e.charCode === 13) {
                handleOnSearch();
            }
        },
        [search],
    );

    return (
        <div className={classes.filterWrapper}>
            <div className={classes.selectWrapper}>
                <div className={classes.title}>Title</div>
                <TextSearchInput
                    placeholder="Search Recommendations"
                    value={search}
                    handleChange={handleSearch}
                    handleClear={handleClearSearch}
                    maxLength={30}
                    handleClick={handleOnSearch}
                    onKeyPress={onKeyPress}
                    className={classes.inputText}
                />
            </div>
            <div className={classes.selectWrapper}>
                <div className={classes.title}>Severity</div>
                <div className={classes.filterItemResults}>
                    <MyCustomSelect
                        value={chosenFilter.severity}
                        options={severityOptions}
                        onChange={(value) => handleStatus(value, 'severity')}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isMulti={true}
                        title={'Severity'}
                        placeholder={'Select Severity...'}
                        components={{
                            Option: InputOption,
                            ValueContainer: ValueContainer,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
