import React, { useEffect, useState } from 'react';
import classes from './InlineSelect.module.scss';
import Select from 'react-select';
import WindowedSelect from 'react-windowed-select';
import useDetectClickOutSideComponentHook from 'components/common/CustomHook/DetectClickOutSideComponentHook/useDetectClickOutSideComponentHook';
import { ISelectOptionOrData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import { SelectStyles } from 'components/common/MyCustomSelect/myCustomSelect.style';
import colorsVars from 'styles/colors.module.scss';

const { myCustomSelectBorderColor, myCustomSelectBg, myCustomSelectTextColor, disabledInput, errorRedColor } =
    colorsVars;

export interface IOption extends ISelectOption<string> {
    username?: string;
}

interface InlineSelectProps {
    options: IOption[];
    value: ISelectOptionOrData<string> | null;
    onSelection: (value: IOption, isSavedByCopyToClipboard?: boolean) => void;
    isWindowedSelect?: boolean;
    isDisabled?: boolean;
    isError?: boolean;
    id?: string;
}

const InlineSelect = React.forwardRef(
    (
        {
            options,
            value,
            onSelection,
            isWindowedSelect = false,
            isDisabled = false,
            isError = false,
            id,
        }: InlineSelectProps,
        ref: any,
    ) => {
        const {
            ref: clickOutSidRef,
            isComponentVisible,
            setIsComponentVisible,
        } = useDetectClickOutSideComponentHook(true);
        const [isEdit, setIsEdit] = useState<boolean>(false);
        const [menuOpen, setMenuOpen] = useState<boolean>(false);

        useEffect(() => {
            if (!isComponentVisible) {
                setIsEdit(false);
                setMenuOpen(false);
            }
        }, [isComponentVisible]);

        const toggleEdit = (e) => {
            e.stopPropagation();
            e.preventDefault();

            if (isDisabled) {
                return;
            }

            setIsEdit(true);
            setMenuOpen(true);
            setIsComponentVisible(true);
        };

        const handleSelection = (value) => {
            setIsEdit(false);
            setIsComponentVisible(false);
            setMenuOpen(false);
            onSelection(value);
        };

        const windowedSelectStyles = {
            ...SelectStyles,
            ...{
                menu: (styles) => ({
                    ...styles,
                    minWidth: '100%',
                    width: 'max-content',
                    backgroundColor: 'var(--select_menu_background)',
                    borderRadius: '1.6rem',
                    color: 'var(--select_menu_color)',
                    border: 'solid 1px var(--select_menu_border)',
                    overflow: 'hidden',
                }),
                control: (styles, { isDisabled, selectProps }) => ({
                    ...styles,
                    width: '350px',
                    height: '35px',
                    borderRadius: '8px',
                    border: `1px solid ${selectProps?.isError ? errorRedColor : myCustomSelectBorderColor}`,
                    backgroundColor: isDisabled ? disabledInput : myCustomSelectBg,
                    color: myCustomSelectTextColor,
                    boxShadow: 'none',
                    '&:hover': {
                        border: `1px solid ${myCustomSelectBorderColor}`,
                    },
                }),
                option: (styles, { isSelected }) => {
                    return {
                        ...styles,
                        backgroundColor: isSelected ? 'var(--select_menu_hover_background)' : null,
                        color: isSelected ? 'var(--select_menu_hover_color)' : null,
                        ':active': {
                            backgroundColor: 'none',
                        },
                        ':hover': {
                            cursor: 'pointer',
                            backgroundColor: 'var(--select_menu_hover_background)',
                            color: 'var(--select_menu_hover_color)',
                        },
                    };
                },
            },
        };

        const toggleMenu = () => {
            setIsEdit(!isEdit);
            setMenuOpen(!menuOpen);
        };

        const editMode = isEdit || !value;

        return (
            <div className={classes.inlineSelectContainer} ref={clickOutSidRef} onClick={toggleMenu}>
                {editMode ? (
                    isWindowedSelect ? (
                        <WindowedSelect
                            options={options}
                            onChange={handleSelection}
                            value={value}
                            menuIsOpen={menuOpen}
                            isDisabled={isDisabled}
                            styles={windowedSelectStyles}
                            windowThreshold={100}
                            id={id}
                            isError={isError}
                            ref={ref}
                        />
                    ) : (
                        <Select
                            options={options}
                            onChange={handleSelection}
                            value={value}
                            menuIsOpen={menuOpen}
                            isDisabled={isDisabled}
                            styles={SelectStyles}
                            id={id}
                            isError={isError}
                            ref={ref}
                        />
                    )
                ) : (
                    <div
                        data-testid={'inline-value'}
                        id={id}
                        className={`${classes.inlineValue} ${!isDisabled && classes.editable}`}
                        onClick={toggleEdit}
                    >
                        <span title={value?.label}>{value?.label}</span>
                    </div>
                )}
            </div>
        );
    },
);

export default InlineSelect;
