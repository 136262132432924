import React from 'react';
import { ITab } from 'components/common/CustomizedTabs/CustomizedTabs';
import TableTab from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/TableTab/tableTab';
import {
    IAssignee,
    ICardIndicators,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';

export type IActivityCompletionList = {
    activity_id: string;
    activity_name: string;
    activity_status: string;
    activity_type: string;
    card_board: {
        assignee: IAssignee | null;
        cardIndicators: ICardIndicators;
        submittedDurationComplete: number;
    };
    days_to_target: number;
    finish_date: number;
    finish_date_change: number;
    blocking: boolean;
    completed: boolean;
    delaying: boolean;
    narrative: string;
    schedule: boolean;
    parentName: string;
    path_length: number;
    remaining_duration: number;
    succeeding_activities: number;
    successors_in_fragnet: number;
    successors_num: number;
};

export const generateTabs = ({
    data,
    activeCount,
    activeTab,
}: {
    data: IActivityCompletionList[] | undefined;
    activeCount: number;
    activeTab: string;
}): ITab[] => {
    if (data) {
        const blockingData = data.filter((item) => item.blocking);
        const delayingData = data.filter((item) => item.delaying);
        const scheduleData = data.filter((item) => item.schedule);
        const completedData = data.filter((item) => item.completed);
        const blockingDataCount = activeTab === 'blocking' ? activeCount : blockingData.length;
        const delayingDataCount = activeTab === 'delaying' ? activeCount : delayingData.length;
        const scheduleDataCount = activeTab === 'schedule' ? activeCount : scheduleData.length;
        const completedDataDataCount = activeTab === 'completed' ? activeCount : completedData.length;
        const allDataDataCount = activeTab === 'all' ? activeCount : data.length;
        return [
            {
                tabKey: 'blocking',
                filterKey: 'BLOCKING_FILTER',
                component: <TableTab title={`Blockers (${blockingDataCount})`} className={`red`} />,
            },
            {
                tabKey: 'delaying',
                filterKey: 'DELAYING_FILTER',
                component: <TableTab title={`Delayed (${delayingDataCount})`} className={`yellow`} />,
            },

            {
                tabKey: 'schedule',
                filterKey: 'ON_SCHEDULE_FILTER',
                component: <TableTab title={`On Schedule (${scheduleDataCount})`} />,
            },

            {
                tabKey: 'completed',
                filterKey: 'COMPLETED_FILTER',
                component: <TableTab title={`Completed (${completedDataDataCount})`} />,
            },
            {
                tabKey: 'all',
                filterKey: '',
                component: <TableTab title={`All (${allDataDataCount})`} />,
            },
        ];
    }
    return [];
};

export const getScheduleAdherenceColorClass = (value: number | undefined) => {
    if (!value) return '';
    if (value < 0.9) {
        return 'red';
    } else if (value >= 0.9 && value < 1) {
        return 'amber';
    } else if (value >= 1) {
        return 'green';
    }
    return 'red';
};
