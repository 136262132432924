export const mapper = (i) => ({ id: i.id, value: i, label: i.versionName });

export enum defaultBaseLinePerVersionValues {
    LATEST_BASELINE = 'LATEST_BASELINE',
    OLDEST_BASELINE = 'OLDEST_BASELINE',
}
export const defaultBaseLinePerVersionList = [
    {
        id: 1,
        value: defaultBaseLinePerVersionValues.LATEST_BASELINE,
        label: 'Latest Baseline',
    },
    {
        id: 2,
        value: defaultBaseLinePerVersionValues.OLDEST_BASELINE,
        label: 'Oldest Baseline',
    },
];

export const uniqueActivityKeyList = [
    {
        id: 1,
        value: 'ActivityId',
        label: 'Activity Id',
    },
    {
        id: 2,
        value: 'ActivityType',
        label: 'Activity Type',
    },
    {
        id: 3,
        value: 'Name',
        label: 'Name',
    },
    {
        id: 4,
        value: 'ParentName',
        label: 'Parent Name',
    },
    {
        id: 5,
        value: 'FullPathParentNames',
        label: 'Full Path Parent Names',
    },
    {
        id: 6,
        value: 'Uuid',
        label: 'UUID',
    },
    {
        id: 7,
        value: 'WbsLevel',
        label: 'WBS Level',
    },
];
