import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/renameVersionModal/renameVersionModal.module.scss';
import { CustomizedButton, CustomizedFormErrorMessage, CustomizedModalBase } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { useMutationRenameVersion } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/queries/useQueryProgramLibrary';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryClient } from 'react-query';
import React, { useEffect } from 'react';

interface IRenameVersionModal {
    id: number;
    versionName: string;
    showRenameVersionModal: boolean;
    setShowRenameVersionModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RenameVersionModal = ({
    id,
    versionName,
    showRenameVersionModal,
    setShowRenameVersionModal,
}: IRenameVersionModal) => {
    const { handleSnackBar } = useCustomSnackBar();
    const queryClient = useQueryClient();
    const {
        handleSubmit,
        setValue,
        control,
        watch,
        formState: { errors },
    } = useForm({
        values: {
            versionName: versionName,
        },
    });

    const { mutate: mutateRenameVersion, isLoading } = useMutationRenameVersion();

    const closeModal = () => {
        setShowRenameVersionModal(false);
        setValue('versionName', versionName);
    };

    const handleSave = (data) => {
        mutateRenameVersion(
            {
                metaDataid: id,
                versionName: data.versionName,
            },
            {
                onSuccess: () => {
                    handleSnackBar('Version Renamed', 'success');
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
                onSettled: () => {
                    closeModal();
                    return queryClient.invalidateQueries('getProjectVersions');
                },
            },
        );
    };

    const isSaveDisabled = versionName === watch('versionName');

    return (
        <CustomizedModalBase isModalOpen={showRenameVersionModal} handleModalClose={closeModal}>
            <div className={classes.renameVersionModalContainer}>
                <form onSubmit={handleSubmit(handleSave)}>
                    <div data-testid={'rename-version'} className={classes.title}>Rename Version</div>
                    <div className={classes.inputTitle}>Name *</div>
                    <div className={classes.inputWrapper}>
                        <Controller
                            render={({ field }) => (
                                <TextSearchInput
                                    {...field}
                                    placeholder={'Version name...'}
                                    isSearch={false}
                                    maxLength={100}
                                    handleClear={() => {
                                        setValue('versionName', '');
                                    }}
                                />
                            )}
                            name={'versionName'}
                            control={control}
                            rules={{
                                required: 'Version Name is Required',
                                minLength: {
                                    value: 3,
                                    message: 'Version Name is too short',
                                },
                                pattern: {
                                    value: /^(?!\s+$).*/,
                                    message: 'Name cannot be empty',
                                },
                            }}
                        />
                    </div>
                    {errors.versionName && <CustomizedFormErrorMessage text={errors.versionName.message} />}
                    <div className={classes.footer}>
                        <div className={classes.container}>
                            <CustomizedButton size={'large'} color={'secondary'} onClick={closeModal}>
                                Cancel
                            </CustomizedButton>

                            <CustomizedButton
                                type={'submit'}
                                size={'large'}
                                color={'primary'}
                                isLoading={isLoading}
                                disabled={isSaveDisabled}
                            >
                                Save
                            </CustomizedButton>
                        </div>
                    </div>
                </form>
            </div>
        </CustomizedModalBase>
    );
};
