import React, { useMemo, useState } from 'react';
import classes from 'components/Dashboards/ExcellenceApps/LogicChecker/ValidationConfigSlider.module.scss';
import { LogicCheckerSlider } from 'components/common/Slider/LogicCheckerSlider';
import { ConfirmSaveModal, CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import {
    ILogicCheckerConfigurationResponse,
    useMutationResetConfigParams,
    useMutationSetCheckerConfigParams,
} from 'components/Dashboards/ExcellenceApps/LogicChecker/queries/useQueryLogicChecker';
import { useValidationStore } from 'components/Dashboards/ExcellenceApps/LogicChecker/store/validationStore';
import { useQueryClient } from 'react-query';
import produce from 'immer';
import { failColor, passColor } from 'components/Dashboards/ExcellenceApps/LogicChecker/helper';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

const ValidationConfigSlider = ({ fileMetaDataId, disabled }: { fileMetaDataId: number; disabled: boolean }) => {
    const { validation, prevValue, updateValidation } = useValidationStore();
    const { mutate: saveValidation, isLoading: isLoadingSaveValidation } = useMutationSetCheckerConfigParams();
    const { mutate: resetConfigParams } = useMutationResetConfigParams();
    const [showModal, setShowModal] = useState(false);
    const queryClient = useQueryClient();
    const { sendEventWithDimensions } = useEventWithDimensions();
    const { handleSnackBar } = useCustomSnackBar();

    const mainValue = useMemo(() => {
        return validation?.thresholds[0].upper_limit_value || 0;
    }, [validation]);

    const update = ({ validation, value }: { validation: ILogicCheckerConfigurationResponse; value: number }) => {
        updateValidation(
            produce(validation, (draft) => {
                draft.thresholds[0].upper_limit_value = value;
                return draft;
            }),
        );
    };

    const setCheckerConfigParams = () => {
        if (!validation || disabled) {
            return;
        }
        const configObject = {
            configId: validation.thresholds[0].threshold_id,
            lowerLimit: validation.thresholds[0].lower_limit_value,
            upperLimit: validation.thresholds[0].upper_limit_value,
            metaDataId: fileMetaDataId,
        };
        saveValidation(configObject, {
            onSuccess: () => {
                Promise.all([
                    queryClient.invalidateQueries('getLogicCheckerConfiguration'),
                    queryClient.invalidateQueries('getValidationTypeDrillDown'),
                    queryClient.invalidateQueries('getLogicCheckerSummary'),
                ]).then(() => {
                    handleSnackBar('Config saved successfully.', 'success');
                });
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong saving the config.', 'error');
            },
            onSettled: () => {
                setShowModal(false);
            },
        });
    };
    if (!validation) return null;

    const thresholds = validation.thresholds[0]; // local alias, for shorter code here

    const handleMouseUpSliderValues = (value: number) => {
        const label = `${validation.validationName} ${value}`;
        sendEventWithDimensions({
            category: 'Logic Checker',
            action: 'Threshold change',
            label,
        });

        update({ validation, value });

        if (disabled) {
            update({ validation, value: prevValue ?? 0 });
            handleSnackBar(
                'You do not have permission to change this threshold value. Contact your account admin to apply such changes.',
                'error',
            );
        } else {
            setShowModal(true);
        }
    };

    const handleCancel = () => {
        update({ validation, value: prevValue ?? 0 });
        setShowModal(false);
    };

    const handleReset = () => {
        if (validation) {
            resetConfigParams(
                { configId: validation.validationId, metaDataId: fileMetaDataId },
                {
                    onSuccess: () => {
                        handleSnackBar('Config saved successfully.', 'success');
                        queryClient.invalidateQueries('getLogicCheckerConfiguration');
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                    },
                },
            );
        }
    };

    return (
        <>
            <div className={classes.sliderContainer}>
                <LogicCheckerSlider
                    value={mainValue}
                    handleMouseUpSliderValues={handleMouseUpSliderValues}
                    min={thresholds.minValue}
                    max={thresholds.maxValue}
                    step={thresholds.step}
                    lowColor={thresholds.color_low_level === 'PASS' ? passColor : failColor}
                    highColor={thresholds.color_high_level === 'PASS' ? passColor : failColor}
                />

                <div className={classes.unitLabel}>{thresholds.units}</div>
                {!disabled && (
                    <>
                        <div className={classes.verticalBar} />
                        <CustomizedTooltip
                            tooltipContent={'Reset threshold to default'}
                            triggerElement={
                                <CustomizedRoundIcon
                                    size={'2.8rem'}
                                    onClick={handleReset}
                                    id={'logicCheckRestoreConfigurationButton'}
                                >
                                    <MuiIcon icon="settings_backup_restore" />
                                </CustomizedRoundIcon>
                            }
                        />
                    </>
                )}
            </div>
            <ConfirmSaveModal
                isShowModal={showModal}
                onHideModal={handleCancel}
                onConfirm={setCheckerConfigParams}
                isLoading={isLoadingSaveValidation}
                actionButtonLabel={'Yes'}
            >
                Are you sure you wish to change the threshold value for this quality check?
                <br />
                Note it will apply to all schedules in this sub-program.
            </ConfirmSaveModal>
        </>
    );
};

export default ValidationConfigSlider;
