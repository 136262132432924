import RenderCriticalAnalysisHeatMap from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/CriticalAnalysisHeatMapComponent/RenderCriticalAnalysisHeatMap';
import RenderCriticalAnalysisIntensity from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisIntensityComponent/RenderCriticalAnalysisIntensity';
import RenderCriticalAnalysisDelayDrivers from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/CriticalAnalysisDelayDriversComponent/RenderCriticalAnalysisDelayDrivers';
import RenderTopDurationOverrunRisk from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/TopDurationOverrunRiskComponent/RenderTopDurationOverrunRisk';
import RenderBaselineVsActual from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/components/BaselineVsActualComponent/RenderBaselineVsActual';
import ActivitiesCompleteScurveGraph from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/ActivitiesCompleteScurveGraph/activitiesCompleteScurveGraph';
import RenderTopOverdueOverrunActivities from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/components/TopOverdueOverrunActivitiesComponent/RenderTopOverdueOverrunActivities';
import RenderHighLevelProgram from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/components/HighLevelProgramComponent/RenderHighLevelProgram';
import RenderCriticalAnalysisMilestone from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/components/CriticalAnalysisMilestoneComponent/RenderCriticalAnalysisMilestone';
import WidgetCardsComponent from 'components/Dashboards/Project/Components/VersionCompare/components/widgetCardsComponent';
import ScoreCardsComponent from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/scoreCardsComponent';
import PrioritiesMatrixComponent from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/PrioritiesMatrixSection/PrioritiesMatrixWrapper/prioritiesMatrixComponent';
import ProjectScorecards from 'components/Dashboards/Portfolio/PortfolioOverview/components/ProjectScorecards';
import OverallStatus from 'components/Dashboards/Portfolio/PortfolioOverview/components/OverallStatus';
import RowsBoard from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/RowsBoard/RowsBoard';
import {
    NoPermissionMessage,
    NoPermissionMessagePortfolioPage,
} from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.contants';

import { IHashmap } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { PhasePerformanceChart } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceChart/phasePerformanceChart';
import { PhasePerformanceBreakdown } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceBreakdown/phasePerformanceBreakdown';
import { SingleStory } from 'components/Dashboards/Project/Components/DataStories/SingleStory/singleStory';
import StatusChanges from 'components/Dashboards/Project/Components/VersionCompare/components/StatusChanges/statusChanges';
import StartDateChanges from 'components/Dashboards/Project/Components/VersionCompare/components/StartDateChanges/startDateChanges';
import FinishDateChanges from 'components/Dashboards/Project/Components/VersionCompare/components/FinishDateChanges/finishDateChanges';
import LargestDurationVariance from 'components/Dashboards/Project/Components/VersionCompare/components/LargestDurationVariance/largestDurationVariance';
import LargestFloatChanges from 'components/Dashboards/Project/Components/VersionCompare/components/LargestFloatChanges/largestFloatChanges';
import RelationshipTypeChanges from 'components/Dashboards/Project/Components/VersionCompare/components/RelationshipTypeChanges/relationshipTypeChanges';
import RelationshipCountChanges from 'components/Dashboards/Project/Components/VersionCompare/components/RelationshipCountChanges/relationshipCountChanges';
import LongestAddedActivities from 'components/Dashboards/Project/Components/VersionCompare/components/LargestAddedActivities/largestAddedActivities';
import LargestDeletedActivities from 'components/Dashboards/Project/Components/VersionCompare/components/LargestDeletedActivities/largestDeletedActivities';
import VersionCompareTrackedActivities from 'components/Dashboards/Project/Components/VersionCompare/components/VersionCompareTrackedActivities/versionCompareTrackedActivities';
import { RecommendationsTable } from 'components/Dashboards/ExcellenceApps/Recommendations/recommendationsTable';
import { ActivitiesPredecessors } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/components/activitiesPredecessors';
import CriticalPathAdded from 'components/Dashboards/Project/Components/VersionCompare/components/CriticalPathAdded/criticalPathAdded';
import CriticalPathRemoved from 'components/Dashboards/Project/Components/VersionCompare/components/CriticalPathRemoved/criticalPathRemoved';
import { GanttChart } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/GanttChart';
import { KeyAchievementsTableComponent } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/TableSection/KeyAchievementsTableWrapper/keyAchievementsTableComponent';
import SummaryTrackedActivitiesComponent from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/TableSection/SummaryTrackedActivities/summaryTrackedActivitiesComponent';
import KeyActivitiesWidget from 'components/Dashboards/widgets/keyActivities/keyActivitiesWidget';
import { SharedResourcesAvailability } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/SharedResourcesAvailability/sharedResourcesAvailability';
export const hashmap = (): IHashmap => ({
    riskHeatMap: {
        Component: RenderCriticalAnalysisHeatMap,
        NoPermissionMessage,
    },
    criticalityWeightedSCurve: {
        Component: RenderCriticalAnalysisIntensity,
        NoPermissionMessage,
    },

    topCriticalActivitiesDelayDrivers: {
        Component: RenderCriticalAnalysisDelayDrivers,
        NoPermissionMessage,
    },
    topDurationOverrunRisk: {
        Component: RenderTopDurationOverrunRisk,
        NoPermissionMessage,
    },
    progressSummary: {
        Component: RenderBaselineVsActual,
        NoPermissionMessage,
    },
    activitiesCompletionSCurve: {
        Component: ActivitiesCompleteScurveGraph,
        NoPermissionMessage,
    },
    topOverdueOverrunActivities: {
        Component: RenderTopOverdueOverrunActivities,
        NoPermissionMessage,
    },
    highLevelProgramLatest: {
        Component: RenderHighLevelProgram,
        NoPermissionMessage,
    },
    milestonesWeightedSCurve: {
        Component: RenderCriticalAnalysisMilestone,
        NoPermissionMessage,
    },
    mySummaryTrackedActivities: {
        Component: SummaryTrackedActivitiesComponent,
        NoPermissionMessage,
    },
    myVersionCompareTrackedActivities: {
        Component: VersionCompareTrackedActivities,
        NoPermissionMessage,
    },
    topStatusChanges: {
        Component: StatusChanges,
        NoPermissionMessage,
    },
    topStartDateChanges: {
        Component: StartDateChanges,
        NoPermissionMessage,
    },
    topFinishDateChanges: {
        Component: FinishDateChanges,
        NoPermissionMessage,
    },
    largestDurationVariance: {
        Component: LargestDurationVariance,
        NoPermissionMessage,
    },
    largestFloatChanges: {
        Component: LargestFloatChanges,
        NoPermissionMessage,
    },
    topRelationshipsTypeChanges: {
        Component: RelationshipTypeChanges,
        NoPermissionMessage,
    },
    topRelationshipsCountChanges: {
        Component: RelationshipCountChanges,
        NoPermissionMessage,
    },
    largestAddedActivities: {
        Component: LongestAddedActivities,
        NoPermissionMessage,
    },
    largestDeletedActivities: {
        Component: LargestDeletedActivities,
        NoPermissionMessage,
    },
    criticalPathAdded: {
        Component: CriticalPathAdded,
        NoPermissionMessage,
    },
    criticalPathRemoved: {
        Component: CriticalPathRemoved,
        NoPermissionMessage,
    },
    topRecommendations: {
        Component: RecommendationsTable,
        NoPermissionMessage,
    },
    versionSummary: {
        Component: WidgetCardsComponent,
        NoPermissionMessage,
    },
    projectSummary: {
        Component: ScoreCardsComponent,
        NoPermissionMessage,
    },
    prioritiesMatrix: {
        Component: PrioritiesMatrixComponent,
        NoPermissionMessage,
    },
    keyAchievements: {
        Component: KeyAchievementsTableComponent,
        NoPermissionMessage,
    },
    projectScorecards: {
        Component: ProjectScorecards,
        NoPermissionMessage: NoPermissionMessagePortfolioPage,
        titleWithoutProjectAndContract: 'Portfolio',
        permission: 'PortfolioPage',
    },
    overallStatus: {
        Component: OverallStatus,
        NoPermissionMessage: NoPermissionMessagePortfolioPage,
        titleWithoutProjectAndContract: 'Portfolio',
        permission: 'PortfolioPage',
    },
    dataStories: {
        Component: SingleStory,
        permission: 'DataStoryRead',
        NoPermissionMessage,
        titleWithoutProjectAndContract: 'Data Story',
    },

    battlecardsRowsBoard: {
        Component: RowsBoard,
        NoPermissionMessage,
    },

    phasePerformanceChart: {
        Component: PhasePerformanceChart,
        titleWithoutProjectAndContract: 'Phase Performance',
        NoPermissionMessage,
        permission: 'PortfolioPage',
    },
    phasePerformanceBreakdown: {
        Component: PhasePerformanceBreakdown,
        titleWithoutProjectAndContract: 'Phase Performance Breakdown',
        NoPermissionMessage,
        permission: 'PortfolioPage',
    },
    activityPredecessors: {
        Component: ActivitiesPredecessors,
        NoPermissionMessage,
    },
    ganttChart: {
        Component: GanttChart,
        NoPermissionMessage,
    },

    sharedResourcesAvailability: {
        Component: SharedResourcesAvailability,
        NoPermissionMessage,
    },
    keyActivities: {
        Component: KeyActivitiesWidget,
        NoPermissionMessage,
    },
});
