import React, { useState } from 'react';
import classes from 'components/common/WidgetWithCards/WidgetCard/CardItem/cardItem.module.scss';
import { WidgetCardDivider } from 'components/common/WidgetWithCards/WidgetCard/WidgetCardDivider/WidgetCardDivider';
import { CardTooltip } from 'components/common/WidgetWithCards/WidgetCard/CardItem/ScoreCardTooltip/CardTooltip';
import { TooltipData } from 'components/common/WidgetWithCards/WidgetCard/widgetCard.types';

import { Link } from 'react-router-dom';
import { TooltipIcon } from 'components/common/TooltipIcon/tooltipIcon';

interface CardItemProps {
    title?: string;
    showDivider?: boolean;
    showTooltip?: boolean;
    tooltipData?: TooltipData;
    infoTooltipText?: IToolTip;
    link?: string;
    showCardInfoTooltip?: boolean;
    id?: string;
    children: React.ReactNode;
}

const ConditionalLink = ({ children, to, condition, className, id }) =>
    !!condition && to ? (
        <Link className={className} to={to} id={id}>
            {children}
        </Link>
    ) : (
        <span className={className} id={id}>
            {children}
        </span>
    );

export const CardItem = ({
    children,
    title = '',
    showDivider = false,
    showTooltip = true,
    tooltipData,
    infoTooltipText,
    link,
    id,
}: CardItemProps) => {
    const showChildrenWithTooltip = children && showTooltip && tooltipData;
    const showChildrenWithoutTooltip = children && !showTooltip;

    return (
        <div data-testid={'card-item'} className={classes.cardItem}>
            <div className={`${classes.cardItemContent} ${!!link && classes.isLink}`}>
                <div className={classes.cardItemContentLink}>
                    {title && (
                        <ConditionalLink className={classes.itemTitle} condition={link} to={link} id={id}>
                            {title}
                        </ConditionalLink>
                    )}
                    {infoTooltipText && (
                        <div className={classes.infoTooltip}>
                            <TooltipIcon tooltip={infoTooltipText} iconSize={'1.4rem'} size={'2.4rem'} />
                        </div>
                    )}
                </div>
                {showChildrenWithoutTooltip && children}
                {showChildrenWithTooltip && <CardTooltip {...tooltipData}>{children}</CardTooltip>}
            </div>
            {showDivider && <WidgetCardDivider />}
        </div>
    );
};
