import React from 'react';
import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import moment from 'moment/moment';
import constants from 'components/common/Constants/constants';
import ActivityCell from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/Cells/activityCell';
import { sortFloat } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/utils';
import { removeHtmlTagsFromString } from 'utilitys/helpers/general';
import { renderToString } from 'react-dom/server';
import { CriticalityScoreCell } from 'components/common/Tables/Cells/CriticalityScoreCell';
import { customSortWithNull } from 'components/Dashboards/Project/Components/VersionCompare/TableColumns/TableColumns.utils';

const narrowCols = [
    'actualduration',
    'criticalpath',
    'delaydriver',
    'projectweekfinish',
    'projectweekstart',
    'projectweekend',
    'projectmonthstart',
    'projectmonthfinish',
    'projectmonthend',
    'criticalityscore',
    'remainingduration',
    'float',
    'lag',
    'durationoverun',
    'latefinish',
    'latestart',
    'plannedduration',
    'completedduration',
    'forecastduration',
    'forecastoverrun',
    'overrundays',
    'overrunpercent',
    'type',
];

const smallCols = [
    'startdate',
    'finishdate',
    'numofpredecessors',
    'numofsuccessors',
    'projectname',
    'contractname',
    'activitystatus',
];

const mediumCols = [
    'plannedstartdate',
    'plannedfinishdate',
    'actualstartdate',
    'currentstartdate',
    'currentfinishdate',
    'actualfinishdate',
    'activitystatus',
];

const largeCols = ['activitytype', 'activityid', 'versionname', 'constrainttype'];

// humongous cols
const extraLargeCols = ['activityname', 'grandparentname', 'parentname', 'resourcename'];

const tooltipCells = [
    'activityId',
    'activityName',
    'activityType',
    'activityCode',
    'parentName',
    'grandParentName',
    'successorActivityName',
    'successorActivityId',
];

const isDateColumns = [
    'plannedStartDate',
    'plannedFinishDate',
    'actualStartDate',
    'actualFinishDate',
    'currentStartDate',
    'currentFinishDate',
    'finishDate',
    'startDate',
    'activityStartDate',
    'activityEndDate',
    'successorActivityStartDate',
    'successorActivityEndDate',
    'constraintTime',
    'baselineStart',
    'baselineFinish',
];

export const getColumns = ({ allColumns }) => {
    return allColumns
        .map((oneCol) => {
            // not all properties in a validation should map to a column
            const notValidColumns = ['activityDrilldown', 'delayDriver'];
            if (notValidColumns.includes(oneCol.accessor)) {
                return null;
            } else {
                const generatedColumn = {
                    ...oneCol,
                    Header: oneCol.header,
                    Filter: false,
                };

                const preppedAccessor = generatedColumn.accessor.toLowerCase().trim();
                if (narrowCols.includes(preppedAccessor)) {
                    generatedColumn.width = 90;
                } else if (smallCols.includes(preppedAccessor)) {
                    generatedColumn.width = 130;
                } else if (mediumCols.includes(preppedAccessor)) {
                    generatedColumn.width = 190;
                } else if (largeCols.includes(preppedAccessor)) {
                    generatedColumn.width = 220;
                } else if (extraLargeCols.includes(preppedAccessor)) {
                    generatedColumn.width = 370;
                } else {
                    generatedColumn.width = 190;
                }

                const cleanedHeader = oneCol.header.trim().toLowerCase();
                if (!generatedColumn.Cell) {
                    if (tooltipCells.includes(generatedColumn.accessor)) {
                        generatedColumn.Cell = ({ value }) => <TooltipCellWithCopy text={value} />;
                    } else {
                        generatedColumn.Cell = ({ value }) => {
                            return isDateColumns.includes(generatedColumn.accessor) && value
                                ? moment(value).format(constants.formats.date.default)
                                : value;
                        };
                    }

                    if (cleanedHeader === 'critical path') {
                        generatedColumn.id = 'criticalPath';
                        generatedColumn.accessor = (row) => (row.criticalPath ? 'Yes' : 'No');
                    }

                    if (cleanedHeader === 'criticality score') {
                        generatedColumn.sortType = customSortWithNull;
                    }

                    if (generatedColumn.accessor === 'lateStart') {
                        generatedColumn.sortType = customSortWithNull;
                    }

                    if (generatedColumn.accessor === 'lateFinish') {
                        generatedColumn.sortType = customSortWithNull;
                    }

                    if (cleanedHeader === 'activity name') {
                        generatedColumn.Cell = ({ value, row, toggleAllRowsSelected }) => {
                            const { toggleRowSelected } = row;
                            return (
                                <ActivityCell
                                    id={row.original.taskId}
                                    value={value}
                                    toggleRowSelected={toggleRowSelected}
                                    toggleAllRowsSelected={toggleAllRowsSelected}
                                />
                            );
                        };
                    }
                    if (cleanedHeader === 'float') {
                        generatedColumn.sortType = sortFloat;
                    }
                }
                return generatedColumn;
            }
        })
        .filter((col) => (col ? col : null));
};
export const isAllColumnsSelected = (data) =>
    data.filter((item) => item.Header !== '').every((el) => el.isVisible === true);

export const getLabelFromColumnHeader = (header: string | Function): string => {
    return typeof header === 'function'
        ? removeHtmlTagsFromString(renderToString(header()).replace(/<br\s*\/?>/gi, ' '))
        : header.replace(/<\/?[^>]+(>|$)/g, '');
};
