import { create } from 'zustand';
import { ILogicCheckerConfigurationResponse } from 'components/Dashboards/ExcellenceApps/LogicChecker/queries/useQueryLogicChecker';
import { persist } from 'zustand/middleware';

interface IUseValidationStore {
    validation?: ILogicCheckerConfigurationResponse;
    prevValue?: number;
    updateValidation: (validation?: ILogicCheckerConfigurationResponse) => void;
    updatePrevValue: (prevValue?: number) => void;
    isDcma: boolean;
    isActive: boolean;
    setIsDcma: (isDcma: boolean) => void;
    setIsActive: (isActive: boolean) => void;
}
const validationStore = (set) => ({
    validation: undefined,
    prevValue: undefined,
    isDcma: false,
    isActive: false,
    updateValidation: (validation) => set({ validation }),
    updatePrevValue: (prevValue) => set({ prevValue }),
    setIsDcma: (isDcma) => set({ isDcma }),
    setIsActive: (isActive) => set({ isActive }),
});

export const useValidationStore = create(
    persist<IUseValidationStore>(validationStore, { name: 'logicCheckerValidation' }),
);
