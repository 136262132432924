import classes from './singleTemplateComponent.module.scss';
import { CustomizedConfirmDeleteModal, CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import Icon from 'components/common/Icons/icon';
import React, { useState } from 'react';
import { TemplateProjectsComponent } from 'components/Dashboards/Portfolio/phasePerformance/manage/singleTemplateComponent/templateProjectsComponent/templateProjectsComponent';
import { ITemplate } from 'components/Dashboards/Portfolio/phasePerformance/manage/manage.types';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';
import { Link } from 'react-router-dom';
import { useMutationDeleteTemplate } from 'components/Dashboards/Portfolio/phasePerformance/manage/queries/useMutationPhasePerformanceManage';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryClient } from 'react-query';

export const SingleTemplateComponent = ({
    template,
    isOpenInitial = false,
}: {
    template: ITemplate;
    isOpenInitial?: boolean;
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(isOpenInitial);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
    const { handleSnackBar } = useCustomSnackBar();
    const queryClient = useQueryClient();

    const mutation = useMutationDeleteTemplate();

    const handleDeleteTemplate = () => {
        mutation.mutate(template.id, {
            onSuccess: () => {
                handleSnackBar('Successfully deleted!', 'success');
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong, please try again', 'error');
            },
            onSettled: () => {
                setShowDeleteConfirm(false);
                queryClient.invalidateQueries('phasePerformanceTemplates');
            },
        });
    };

    const handleToggleProjects = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={classes.singleTemplateContainer}>
            <div className={classes.templateContent}>
                <div className={classes.content}>
                    <div className={classes.title} id={`manage_phase_template_name_${template.name}`}>
                        Template: {template.name}
                    </div>
                    <div className={classes.projectSCount}> {template?.projects?.length} projects </div>
                </div>
                <div className={classes.actionIcon}>
                    <Link
                        className={classes.linkStyle}
                        to={`${portfolioConfig.phasePerformanceManage.link}/${portfolioConfig.phasePerformanceManageAddTemplate.link}?templateId=${template.id}`}
                        id={`manage_phase_template_${template.name}_edit`}
                    >
                        <CustomizedTooltip
                            tooltipContent={'Edit Template'}
                            triggerElement={
                                <CustomizedRoundIcon>
                                    <Icon name={'edit-user-profile'} />
                                </CustomizedRoundIcon>
                            }
                        />
                    </Link>

                    <CustomizedTooltip
                        tooltipContent={'Delete Template'}
                        triggerElement={
                            <CustomizedRoundIcon
                                onClick={() => setShowDeleteConfirm(true)}
                                id={`manage_phase_template_${template.name}_delete`}
                            >
                                <Icon name={'BIN'} />
                            </CustomizedRoundIcon>
                        }
                    />

                    <CustomizedTooltip
                        tooltipContent={isOpen ? 'Close Projects' : 'Open Projects'}
                        triggerElement={
                            <CustomizedRoundIcon
                                onClick={handleToggleProjects}
                                id={`manage_phase_template_${template.name}_toggle`}
                            >
                                <Icon name={isOpen ? 'header-arrow-top' : 'header-arrow-down'} />
                            </CustomizedRoundIcon>
                        }
                    />
                </div>
            </div>

            <CustomizedConfirmDeleteModal
                isShowModal={showDeleteConfirm}
                hideModal={() => setShowDeleteConfirm(false)}
                text={`Are you sure you want to delete this template?`}
                onConfirm={() => {
                    handleDeleteTemplate();
                }}
                isLoading={mutation?.isLoading}
            />

            {isOpen && <TemplateProjectsComponent projects={template.projects || []} templateId={template.id} />}
        </div>
    );
};
