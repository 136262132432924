import React from 'react';
import classes from './ErrorBoundary.module.scss';

// consider sending error data to server or external logging service
const logErrorToMyService = (error, errorInfo) => {
    console.log('========= ErrorBoundary Start =========');
    console.log({ error, errorInfo });
    console.log('========= ErrorBoundary End =========');
};

export default class ErrorBoundary extends React.Component<{ pageLevel: boolean; children: React.ReactNode }> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI

            if (this.props.pageLevel) {
                return (
                    <div className={classes.internalServer}>
                        <div className={classes.description}>
                            <h1>Oops!</h1>
                            <h2>Something went wrong 🙁</h2>
                        </div>
                        <a href="/">Go to homepage</a>
                    </div>
                );
            }
            return null; // component level
        }

        return this.props.children;
    }
}
