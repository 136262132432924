import { create } from 'zustand';
import { IClustersListItem } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterRCFAnalysis.type';

interface IClusterRCFAnalysisStore {
    selectedClusterId?: IClustersListItem | null;
    searchWord: string | undefined;
    isAutomatedClusteringOption: boolean;
    isClustersList: boolean;
    setSelectedClusterId: (value: IClustersListItem | null) => void;
    setSearchWord: (value: string | undefined) => void;
    setIsAutomatedClusteringOption: (value: boolean) => void;
    setIsIsClustersList: (value: boolean) => void;
}

const clusterRCFAnalysisStore = (set) => ({
    selectedClusterId: null,
    searchWord: null,
    isAutomatedClusteringOption: true,
    isClustersList: true,
    setSelectedClusterId: (value) => set({ selectedClusterId: value }),
    setSearchWord: (value) => set({ searchWord: value }),
    setIsAutomatedClusteringOption: (value) => set({ isAutomatedClusteringOption: value }),
    setIsIsClustersList: (value) => set({ isClustersList: value }),
});

export const useClusterRCFAnalysisStore = create<IClusterRCFAnalysisStore>(clusterRCFAnalysisStore);
