import React from 'react';
import classes from './textSearchInput.module.scss';
import Icon from 'components/common/Icons/icon';
import colorsVars from 'styles/colors.module.scss';
const { textColor } = colorsVars;

/**
 * @type any
 * @param placeholder => string
 * @param value => string
 * @param handleChange => execute function on input change
 * @param handleClear => execute function on clear icon click
 * @param handleClick => execute function to trigger search on parent component
 * @param isDropList => will show arrow icon if true
 * @param isSearch => will show search icon if true
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */

interface Props {
    placeholder?: string;
    value: string;
    handleChange: (e: React.ChangeEvent) => void;
    handleClear: (e: React.ChangeEvent) => void;
    handleClick?: (e) => void;
    isDropList?: boolean;
    isSearch?: boolean;
    disabled?: boolean;
    className?: string;
    [rest: string]: any;
}
const TextSearchInput = React.forwardRef(
    (
        {
            placeholder = 'Search...',
            value,
            handleChange,
            handleClear,
            handleClick,
            isDropList = false,
            isSearch = true,
            disabled = false,
            className,
            ...rest
        }: Props,
        ref: React.LegacyRef<HTMLInputElement>,
    ) => {
        const handleChangeInput = (e) => {
            handleChange(e);
        };

        const clearInput = (e) => {
            handleClear(e);
        };

        return (
            <div className={classes.textSearchInputContainer} data-testid={'textSearchInput'}>
                <input
                    data-testid={'textSearchInputElement'}
                    className={`${classes.input} ${className}`}
                    placeholder={placeholder}
                    onChange={handleChangeInput}
                    maxLength={150}
                    value={value}
                    disabled={disabled}
                    ref={ref}
                    {...rest}
                />

                <div data-testid={'clear-input'} className={classes.iconsWrapper}>
                    {value.length > 0 && !disabled && (
                        <span onClick={clearInput}>
                            <Icon name="filter-x-icon" size="2.4rem" color="#9299aa" />
                        </span>
                    )}
                    {isSearch && handleClick && (
                        <span onClick={handleClick} className={classes.searchIcon}>
                            <Icon
                                name="search"
                                size="1.6rem"
                                color={value.length > 0 && !disabled ? textColor : '#cccccc'}
                            />
                        </span>
                    )}
                    {isDropList && (
                        <span>
                            <Icon name="header-arrow-down" color="#9299aa" />
                        </span>
                    )}
                </div>
            </div>
        );
    },
);

export default TextSearchInput;
