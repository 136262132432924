export type SelectedValue = {
    id: number;
    value: string;
    label: string;
};

export enum defaultOverdueAndUrgentDateThresholdValues {
    TODAY = 'TODAY',
    VERSION_DATE = 'VERSION_DATE',
}

export const defaultOverdueAndUrgentDateThreshold = [
    {
        id: 1,
        value: defaultOverdueAndUrgentDateThresholdValues.TODAY,
        label: 'Today',
    },
    {
        id: 2,
        value: defaultOverdueAndUrgentDateThresholdValues.VERSION_DATE,
        label: 'Version Date',
    },
];
