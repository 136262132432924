import React, { useCallback, useEffect, useState } from 'react';
import { IVersion, useQueryGetProjectVersionsList } from './Queries/useQueryGetProjectVersionsList';
import { useQueryGetProgramGeneralHierarchyList } from './Queries/useQueryGetProgramGeneralHierarchyList';
import MultipleRoundSpinner from 'components/common/MultipleRoundSpinner/multipleRoundSpinner';
import { TreeCrumbs } from '../TreeCrumbs/TreeCrumbs';
import useUserHook from 'hooks/useUserHook';
import { useProjectStore } from 'components/common/TreeProgramFilter/zustand_store/treeHeaderStore';
import {
    useMutationCreateContract,
    useMutationCreateProject,
} from 'components/common/TreeProgramFilter/Queries/treeProgramFilterMutation';
import classes from './treeProgramFilter.module.scss';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useBasePath } from 'hooks/useBasePath';
import { useHistory, useParams } from 'react-router-dom';
import { VersionSelect } from 'components/common/TreeProgramFilter/VersionSelect/VersionSelect';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { searchInTree } from 'components/common/TreeCrumbs/treeCrumbs.utils';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { AddSubProgramModal } from 'components/Dashboards/Program/ProgramWrapper/DashboardComponents/Common/ProgramProjectFilter/AddSubProgramModal/AddSubProgramModal';
import { AddProjectModal } from 'components/Dashboards/Program/ProgramWrapper/DashboardComponents/Common/ProgramProjectFilter/AddProjectModal/AddProjectModal';
import { TModal, useModalStore } from 'components/common/TreeProgramFilter/VersionSelect/store/modalStore';

interface Props {
    versionSelectorEnabled: boolean;
    showAll: boolean;
    isDisabled?: boolean;
}

export const TreeProgramFilter = ({ versionSelectorEnabled, showAll, isDisabled = false }: Props) => {
    const [forceUpdateWithUuid, setForceUpdateWithUuid] = useState<string | undefined | null>(null);
    const { selectedProject, setSelectedProject } = useProjectStore();
    const modalType = useModalStore((state) => state.modalType);
    const { setModalType } = useModalStore();
    const [selectedNodeUUID, setSelectedNodeUUID] = useState('');
    const [updatedNodeUUID, setUpdatedNodeUUID] = useState<string | null>(null);
    const history = useHistory();
    const { handleSnackBar } = useCustomSnackBar();
    const { sendEventWithDimensions } = useEventWithDimensions();
    const { id } = useParams<{ id: string }>();
    const { contractUUID, projectUUID, versionList: versionFromHashMap } = useHashmapConverter(id);
    const { data: versionsList, isLoading: loadingVersionsList } = useQueryGetProjectVersionsList({
        projectId: versionFromHashMap[0]?.projectId,
        contractId: versionFromHashMap[0]?.contractId,
        versionHashCode: id,
    });

    const {
        data: projectHierarchyList,
        isLoading: loadingGeneralList,
        refetch: refetchGeneralList,
    } = useQueryGetProgramGeneralHierarchyList({
        key: 'TreeProgramFilter',
        selectedProject,
    });

    const basePath = useBasePath();
    const { mutate: mutationCreateProject, isLoading: isLoadingCreateProject } = useMutationCreateProject();
    const { mutate: mutationCreateContract, isLoading: isLoadingCreateContract } = useMutationCreateContract();
    const projectAndContractExistInVersionHashCode = contractUUID && projectUUID;
    const { customerId } = useUserHook();

    /**
     * select first contract if selectedProject.contract === null && showAll === null
     */
    useEffect(() => {
        if (!selectedProject.contract && !showAll) {
            const p = projectHierarchyList?.children.find((c) => c.id === selectedProject.project?.id);
            if (p) {
                const c = p.children[0];
                if (c) {
                    setSelectedProject({
                        project: selectedProject.project,
                        contract: {
                            id: c.id,
                            title: c.name,
                            uuid: c.uuid,
                        },
                    });
                }
            }
        }
    }, [projectHierarchyList, showAll, selectedProject]);

    /**
     * if selectedProject is changed - setForceUpdateWithUuid from contract or project
     */
    useEffect(() => {
        if (selectedProject) {
            const uuid = selectedProject.contract ? selectedProject.contract?.uuid : selectedProject.project?.uuid;
            setForceUpdateWithUuid(uuid);
        }
        return () => setForceUpdateWithUuid(null);
    }, [selectedProject]);

    /**
     * when preforming new request for projectHierarchyList - setSelectedNodeUUID based on selectedProject.contract && selectedProject.contract
     */
    useEffect(() => {
        const isContractSaved = selectedProject.contract;
        const isProjectSaved = selectedProject.project;

        if (!isContractSaved && isProjectSaved) {
            setSelectedNodeUUID(selectedProject.project?.uuid || '');
        }
        if (isContractSaved) {
            setSelectedNodeUUID(selectedProject.contract?.uuid || '');
        }
        return () => setUpdatedNodeUUID(null);
    }, [projectHierarchyList]);

    /**
     * select project and contract based on version
     */
    useEffect(() => {
        if (projectHierarchyList && projectAndContractExistInVersionHashCode && !updatedNodeUUID) {
            const contractNode = searchInTree(projectHierarchyList, contractUUID);
            const projectNode = searchInTree(projectHierarchyList, projectUUID);
            setSelectedProject({
                project: {
                    id: projectNode.id,
                    title: projectNode.name,
                    uuid: projectNode.uuid,
                },
                contract: {
                    id: contractNode.id,
                    title: contractNode.name,
                    uuid: contractNode.uuid,
                },
            });
            setUpdatedNodeUUID(contractUUID);
        }
    }, [projectHierarchyList, id, projectUUID, contractUUID]);

    const handleToast = (content, status) => {
        handleSnackBar(content, status);
    };

    const onSelectionFinish = useCallback(
        ({ project, contract }) => {
            setSelectedProject({ project, contract });
            if (project.id !== selectedProject.project?.id || contract?.id !== selectedProject.contract?.id) {
                sendEventWithDimensions({
                    category: 'Project/Sub-program',
                    action: 'Selection',
                    label: `${project?.title} - ${contract?.title}`,
                });

                history.push(basePath);
            }
        },
        [selectedProject.project, selectedProject.contract],
    );

    const handleModalOpen = useCallback(
        (modalTypeToOpen: TModal) => {
            if (!isDisabled) {
                setModalType(modalTypeToOpen);
            }
        },
        [setModalType, isDisabled],
    );

    const handleSaveProject = (value) => {
        mutationCreateProject(
            { ...value, customerId },
            {
                onSuccess: (response) => {
                    setModalType(null);
                    setUpdatedNodeUUID(response.projectUid);
                    refetchGeneralList().then(() => handleToast('Project created successfully', 'success'));
                },
                onError: (error) => {
                    handleToast(error.response?.data, 'error');
                },
            },
        );
    };
    const handleSaveContract = (value) => {
        if (selectedProject.project?.id) {
            mutationCreateContract(
                {
                    contractName: value,
                    projectId: selectedProject.project?.id,
                },
                {
                    onSuccess: (response) => {
                        setModalType(null);
                        setUpdatedNodeUUID(response.contractUid);
                        refetchGeneralList().then(() => handleToast('Sub-Program created successfully', 'success'));
                    },
                    onError: (error) => {
                        handleToast(`'${value}' ${error.response?.data}`, 'error');
                    },
                },
            );
        }
    };

    return (
        <div className={classes.treeProgramFilterContainer}>
            {loadingGeneralList ? (
                <MultipleRoundSpinner />
            ) : (
                <TreeCrumbs
                    updatedNodeUUID={updatedNodeUUID}
                    selectedNode={selectedNodeUUID}
                    onSelectionFinish={onSelectionFinish}
                    data={projectHierarchyList}
                    forceUpdateWithUuid={forceUpdateWithUuid}
                    showAll={showAll}
                    onModalOpen={handleModalOpen}
                    isDisabled={isDisabled}
                />
            )}

            {versionSelectorEnabled && (
                <SelectorSection versionsList={versionsList} loadingVersionsList={loadingVersionsList} />
            )}

            <AddSubProgramModal
                handleSave={(value) => handleSaveContract(value)}
                onHideModal={() => setModalType(null)}
                showModal={modalType === 'subProgram'}
                isLoadingCreateContract={isLoadingCreateContract}
            />
            <AddProjectModal
                handleSave={(value) => handleSaveProject(value)}
                onHideModal={() => setModalType(null)}
                showModal={modalType === 'project'}
                isLoadingCreateProject={isLoadingCreateProject}
            />
        </div>
    );
};

const SelectorSection = ({
    versionsList,
    loadingVersionsList,
}: {
    versionsList: IVersion[];
    loadingVersionsList: boolean;
}) => {
    return loadingVersionsList ? <MultipleRoundSpinner /> : <VersionSelect versionsList={versionsList} />;
};
