import CustomizedStatusSelect, {
    IOptionComponent,
} from 'components/common/CustomizedStatusSelect/CustomizedStatusSelect';
import {
    getStatusLabelByOriginalStatus,
    statusOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import useUserHook from 'hooks/useUserHook';
import { useQueryClient } from 'react-query';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useMutationUpdateCardLaneIdData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsMutation';
import classes from './CustomizedStatusSelectWrapper.module.scss';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import Icon from 'components/common/Icons/icon';
import {
    addAPropsToSelectOption,
    setStatusOptionsByCategory,
} from 'components/common/CustomizedStatusSelect/CustomizedStatusSelect.utils';
import { LoaderSpinner } from 'components/common/LodaerSpinner/LoaderSpinner';
import { useState } from 'react';
import { IStatusExtraProps } from 'components/common/CustomizedStatusSelect/CustomizedStatusSelect.types';
import { useVersionStore } from 'components/common/TreeProgramFilter/VersionSelect/store/versionSelectStore';

export const CustomizedStatusSelectWrapper = ({
    category,
    isProgramActivity,
    status,
    taskId,
    isVirtual,
    invalidateQueriesKey,
    originalStatus,
}) => {
    const statusOptionsByCategory = setStatusOptionsByCategory({
        options: statusOptions,
        category: category ? category.name : null,
        isProgramActivity: isProgramActivity,
    });

    const finalOptions = addAPropsToSelectOption({
        options: statusOptionsByCategory,
        isProgramActivity: isProgramActivity,
        originalStatus: originalStatus,
    });

    const optionValue = { ...status, originalStatus: originalStatus?.name, isProgramActivity: isProgramActivity };

    const { ability } = useUserHook();
    const isEditActivityStatusPrivilege = ability.can('view', 'EditActivityStatus');
    const isStatusDisabled = isProgramActivity && !isEditActivityStatusPrivilege;
    const currentVersion = useVersionStore((state) => state.version);
    const queryClient = useQueryClient();
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate } = useMutationUpdateCardLaneIdData();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleChange = (value) => {
        if (isStatusDisabled) {
            return;
        }

        setIsLoading(true);

        const selectedStatus = statusOptions.find((item) => item.name === value.name);

        if (currentVersion && selectedStatus) {
            const params = {
                id: taskId.toString(),
                laneId: selectedStatus.name,
                isVirtual: isVirtual,
                versionId: currentVersion.id,
            };
            mutate(params, {
                onSuccess: () => {
                    return Promise.all(invalidateQueriesKey.map((key) => queryClient.invalidateQueries(key))).then(
                        () => {
                            handleSnackBar('Status Change Successfully', 'success');
                            isProgramActivity &&
                                handleSnackBar(
                                    'Note status and date updates must be aligned in corresponding schedule file, or they will be lost in the next schedule upload',
                                    'warning',
                                );
                        },
                    );
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
                onSettled: () => {
                    setIsLoading(false);
                },
            });
        }
    };

    return (
        <CustomizedStatusSelect<IStatusExtraProps>
            options={finalOptions}
            optionValue={optionValue}
            disabled={isStatusDisabled}
            handleChange={handleChange}
            className={classes.statusCustomSelectContainer}
            optionComponent={SelectItem}
            isLoading={isLoading}
        />
    );
};

const SelectItem = ({ item, handleSelect, disabled, isLoading }: IOptionComponent<IStatusExtraProps>) => {
    return (
        <div
            data-testid={'status-item'}
            className={`${classes.statusCustomSelectItem} ${classes[item.name]}`}
            onClick={(e) => {
                handleSelect(item, e);
            }}
        >
            {isLoading ? (
                <LoaderSpinner />
            ) : (
                <>
                    {item.isProgramActivity && item.name !== item.originalStatus && (
                        <CustomizedTooltip
                            tooltipContent={
                                <div>
                                    <Icon name="edited_status" size="1.6rem" color="#000000" />
                                    <span className={classes.editedStatusTooltipContent}>
                                        Status updated, schedule submitted status was:{' '}
                                        {getStatusLabelByOriginalStatus(item.originalStatus)}
                                        <br /> Note status and date updates must be aligned in corresponding schedule
                                        file, or they will be lost in the next schedule upload.
                                    </span>
                                </div>
                            }
                            triggerElement={
                                <div className={classes.editedStatusIconWrapper}>
                                    <Icon name="edited_status" size="1.6rem" color="#ffffff" />
                                </div>
                            }
                        />
                    )}

                    <span>{item.label}</span>
                    {!disabled && (
                        <div className={classes.arrowIconWrapper}>
                            <Icon name="header-arrow-down" size={'1.6rem'} color="#ffffff" />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
