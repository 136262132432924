import classes from './TooltipIcon.module.scss';
import { isValidUrl } from 'utilitys/helpers/general';
import Icon from 'components/common/Icons/icon';

export const TooltipSpans = ({ tooltip }: { tooltip: IToolTip }) => {
    const tooltipComponents =
        typeof tooltip.infoList === 'string' ? (
            <li>{tooltip.infoList}</li>
        ) : (
            tooltip.infoList?.map((oneTooltip) => {
                return (
                    <li key={oneTooltip.content}>
                        {oneTooltip.color ? (
                            <span className={classes.colorIndicator} style={{ background: oneTooltip.color }} />
                        ) : (
                            ''
                        )}
                        {oneTooltip.content}
                    </li>
                );
            })
        );
    return (
        <div className={classes.tooltipInner}>
            <ul>{tooltipComponents}</ul>
            {tooltip.link && isValidUrl(tooltip.link) && (
                <div className={classes.linkInformationText}>
                    For further information click
                    <span className={classes.tooltipIconWrapper}>
                        <Icon name="info-icon" size={'1rem'} />
                    </span>
                    icon
                </div>
            )}
        </div>
    );
};
