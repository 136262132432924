import classes from './InitialFilters.module.scss';
import { CustomizedRoundIcon, CustomizedTooltip, MyCustomSelect } from 'components/common';
import { Control, Controller, UseFormHandleSubmit, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { InputOptionsWithIcon, OptionsWithExclude } from 'components/common/MyCustomSelect/myCustomSelect';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import React from 'react';
import SwitchComponent from 'components/common/Switch/Switch';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { AdvancedWrapperTooltipContent } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainFilters/advancedWrapperTooltipContent/advancedWrapperTooltipContent';
import Icon from 'components/common/Icons/icon';
import scssVariables from 'styles/variables.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import colorsVars from 'styles/colors.module.scss';
import { DateRangeFilter } from 'components/common/CustomFilters/DateRangeFilter/DateRangeFilter';
import { getInputValue } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainFilters/MainFilters.utils';
import { AssigneeSelect } from 'components/common/assigneeSelect';
import {
    IFields,
    IFieldsOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainFilters/MainFilters.types';
import { TabOptions } from 'components/common/CustomFilters/DateRangeFilter/DateRangeFilter.utils';
import { listStyleWithExclude } from 'components/common/MyCustomSelect/myCustomSelect.style';

const { iconColor } = colorsVars;

interface IInitialFilters {
    setValue: UseFormSetValue<IFields>;
    control: Control<IFields, IFields>;
    fieldsOptions: IFieldsOptions;
    watch: UseFormWatch<IFields>;
    handleSubmit: UseFormHandleSubmit<IFields>;
    handleApplyFilters: (data: IFields) => void;
}

export const InitialFilters = ({
    setValue,
    control,
    fieldsOptions,
    watch,
    handleSubmit,
    handleApplyFilters,
}: IInitialFilters) => {
    const executeApplyOnSelection = () => {
        handleSubmit(handleApplyFilters)();
    };

    const { mediaLaptop } = scssVariables;
    const isLaptop = useMediaQuery(`(max-width: ${mediaLaptop})`);

    const endDateExistingData = {
        type: watch('finishDate').type,
        value: watch('finishDate').value.length === 0 ? watch('flags').endDatesRange : watch('finishDate').value,
    };

    const handleFinishDateSelection = (data: { value?: string[] | string; type?: TabOptions }) => {
        if (data.type === 'FIXED_DATE') {
            setValue('finishDate', { value: Array.isArray(data.value) ? data.value.map(String) : [], type: data.type });
            const tempFlags = watch('flags');
            delete tempFlags.endDatesRange;
            setValue('flags', tempFlags);
        } else {
            setValue('finishDate', { value: [], type: data.type });
            setValue('flags', {
                ...watch('flags'),
                endDatesRange: data.value as string,
            });
        }

        executeApplyOnSelection();
    };

    const handleRiskIndicators = (value) => {
        const isAnyRisk = value.find((i) => i.value === 'ANY_INDICATOR');
        if (isAnyRisk) {
            setValue('riskIndicators', [isAnyRisk]);
        } else {
            setValue('riskIndicators', value);
        }

        executeApplyOnSelection();
    };

    return (
        <>
            <div className={classes.selectWrapper}>
                <span className={classes.title}>Activity Type</span>
                <Controller
                    render={({ field }) => (
                        <MyCustomSelect
                            id={'battlecards-filters-activity-type'}
                            {...field}
                            isMulti
                            options={fieldsOptions.cardActivityTypeOptions}
                            onChange={(value) => {
                                field.onChange(value);
                                executeApplyOnSelection();
                            }}
                        />
                    )}
                    name={`cardActivity`}
                    control={control}
                />
            </div>

            <div className={classes.selectWrapper}>
                <span className={classes.title}>Assignee</span>
                <Controller
                    render={({ field }) => (
                        <AssigneeSelect
                            id={'battlecards-filters-assignee'}
                            {...field}
                            onChange={(value) => {
                                field.onChange(value);
                                executeApplyOnSelection();
                            }}
                            isClearable={true}
                        />
                    )}
                    name={`assignee`}
                    control={control}
                />
            </div>

            <div className={classes.selectWrapper}>
                <span className={classes.title}>Status</span>
                <Controller
                    render={({ field }) => (
                        <MyCustomSelect
                            id={'battlecards-filters-status'}
                            {...field}
                            isMulti
                            options={fieldsOptions.statusOptions}
                            isClearable={true}
                            components={{
                                Option: InputOptionsWithIcon as any,
                            }}
                            onChange={(value) => {
                                field.onChange(value);
                                executeApplyOnSelection();
                            }}
                        />
                    )}
                    name={`status`}
                    control={control}
                />
            </div>

            <div className={classes.selectWrapper}>
                <span className={classes.title}>Finish Date</span>
                <DateRangeFilter
                    onSelection={handleFinishDateSelection}
                    existingData={endDateExistingData}
                    inputDefaultValue={getInputValue(endDateExistingData)}
                />
            </div>

            <div className={classes.selectWrapper}>
                <span className={classes.title}>Risk Indicators</span>
                <Controller
                    render={({ field }) => (
                        <MyCustomSelect
                            id={'battlecards-filters-risk-indicators'}
                            {...field}
                            isMulti
                            options={fieldsOptions.riskIndicatorsOptions}
                            onChange={handleRiskIndicators}
                        />
                    )}
                    name={`riskIndicators`}
                    control={control}
                />
            </div>

            <div className={classes.selectWrapper}>
                <span className={classes.title}>Important</span>
                <Controller
                    render={({ field }) => (
                        <MyCustomSelect
                            id={'battlecards-filters-important'}
                            {...field}
                            options={fieldsOptions.importantOptions}
                            isClearable={true}
                            onChange={(value) => {
                                field.onChange(value);
                                executeApplyOnSelection();
                            }}
                        />
                    )}
                    name={`important`}
                    control={control}
                />
            </div>

            <div className={classes.selectWrapper}>
                <span className={classes.title}>Urgent</span>
                <Controller
                    render={({ field }) => (
                        <MyCustomSelect
                            id={'battlecards-filters-urgent'}
                            {...field}
                            options={fieldsOptions.cardUrgentOptions}
                            isClearable={true}
                            onChange={(value) => {
                                field.onChange(value);
                                executeApplyOnSelection();
                            }}
                        />
                    )}
                    name={`urgent`}
                    control={control}
                />
            </div>

            <div className={classes.selectWrapper}>
                <span className={classes.title}>Tags</span>
                <Controller
                    render={({ field }) => (
                        <MyCustomSelect
                            id={'battlecards-filters-tags'}
                            {...field}
                            isMulti
                            options={fieldsOptions.tagsOptions}
                            isClearable={true}
                            onChange={(value) => {
                                field.onChange(value);
                                executeApplyOnSelection();
                            }}
                            components={{
                                Option: OptionsWithExclude,
                            }}
                            styles={listStyleWithExclude}
                        />
                    )}
                    name={`tags`}
                    control={control}
                />
            </div>

            <div className={`${classes.selectWrapper} ${classes.textSearchInputWrapper}`}>
                <Controller
                    render={({ field }) => (
                        <TextSearchInput
                            id={'battlecards-filters-card-search'}
                            {...field}
                            placeholder={'Search by name or Id...'}
                            handleChange={field.onChange}
                            handleClear={() => {
                                setValue('cardSearch', '');
                                executeApplyOnSelection();
                            }}
                            maxLength={300}
                            handleClick={() => {
                                executeApplyOnSelection();
                            }}
                        />
                    )}
                    name={`cardSearch`}
                    control={control}
                />

                <div className={classes.advancedWrapper}>
                    <SwitchComponent
                        checked={watch('flags').isAdvancedTextSearch ? watch('flags').isAdvancedTextSearch : false}
                        onChange={() => {
                            setValue('flags', {
                                ...watch('flags'),
                                isAdvancedTextSearch: !watch('flags').isAdvancedTextSearch,
                            });
                        }}
                    />
                    <div className={classes.title}>Advanced</div>
                    <CustomizedTooltip
                        tooltipPosition={TooltipPosition.Bottom}
                        contentStyles={{
                            maxWidth: isLaptop ? '400px' : '650px',
                        }}
                        key={'advancedInfo'}
                        tooltipContent={<AdvancedWrapperTooltipContent />}
                        triggerElement={
                            <CustomizedRoundIcon enableHover={true} size={'2.4rem'}>
                                <Icon name={'info-icon'} size="14" color={iconColor} />
                            </CustomizedRoundIcon>
                        }
                    />
                </div>
            </div>
        </>
    );
};
