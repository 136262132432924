import { Control, Controller } from 'react-hook-form';
import CustomizedStatusSelect, {
    IOptionComponent,
} from 'components/common/CustomizedStatusSelect/CustomizedStatusSelect';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/ProgramActivityCardStatusSelect/ProgramActivityCardStatusSelect.module.scss';
import Icon from 'components/common/Icons/icon';
import { getStatusOptionsByCategory } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/ProgramActivityCard.utils';
import useUserHook from 'hooks/useUserHook';
import { CustomizedTooltip } from 'components/common';
import { getStatusLabelByOriginalStatus } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import { IStatus } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import {
    IFields,
    IStatusExtraProps,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/ProgramActivityCard.types';
import colorsVars from 'styles/colors.module.scss';

const { iconColor } = colorsVars;

interface ICreateCardStatusSelect {
    control: Control<IFields, IFields>;
    category: null | ISelectOption<string>;
    originalStatus: IStatus;
}

export const ProgramActivityCardStatusSelect = ({ control, category, originalStatus }: ICreateCardStatusSelect) => {
    const { ability } = useUserHook();
    const isEditActivityStatusPrivilege = ability.can('view', 'EditActivityStatus');

    const customStatusOptions = getStatusOptionsByCategory(category?.value, originalStatus.name);

    return (
        <Controller
            render={({ field }) => (
                <CustomizedStatusSelect<IStatusExtraProps>
                    options={customStatusOptions}
                    optionValue={field.value}
                    className={classes.statusCustomSelectContainer}
                    optionComponent={SelectItem}
                    handleChange={field.onChange}
                    disabled={!isEditActivityStatusPrivilege}
                />
            )}
            name={`status`}
            control={control}
        />
    );
};

const SelectItem = ({ item, handleSelect, disabled }: IOptionComponent<IStatusExtraProps>) => {
    return (
        <div
            className={`${classes.statusCustomSelectItem} ${classes[item.name]}`}
            onClick={(e) => {
                handleSelect(item, e);
            }}
        >
            {item.name !== item.originalStatus && (
                <CustomizedTooltip
                    tooltipContent={
                        <div>
                            <Icon name="edited_status" size="16" color={iconColor} />
                            <span className={classes.editedStatusTooltipContent}>
                                Status updated, schedule submitted status was:{' '}
                                {getStatusLabelByOriginalStatus(item.originalStatus)}
                                <br /> Note status and date updates must be aligned in corresponding schedule file, or
                                they will be lost in the next schedule upload.
                                <br /> {getTooltipExtraNoteByStatus(item.name)}
                            </span>
                        </div>
                    }
                    triggerElement={
                        <div className={classes.editedStatusIconWrapper}>
                            <Icon name="edited_status" size="16" color="#ffffff" />
                        </div>
                    }
                />
            )}

            <span>{item.label}</span>
            {!disabled && (
                <div className={classes.arrowIconWrapper}>
                    <Icon name="header-arrow-down" size={'20'} color="#ffffff" />
                </div>
            )}
        </div>
    );
};

const getTooltipExtraNoteByStatus = (status) => {
    switch (status) {
        case 'TODO':
            return 'Note for ‘To Do’ Status update, the planned Start & Finish Dates can be updated below for reference, but it will not be applied in P6 Import. ';
        case 'IN_PROGRESS':
            return 'Note for ‘In Progress’ Status update, the planned Finish Date can be updated below for reference, but it will not be applied in P6 Import.';
        case 'DONE':
            return 'Note for ‘Done’ Status update, the Start & Finish Dates can be updated below, and will also be applied in P6 Import.';
        default:
            return '';
    }
};
