import {
    useQueryActivityCodesByVersionAndWidgetId,
    useQueryGetSingleMilestoneFragnet,
    useQueryGetSingleWbs,
} from 'components/common/GlobalFilterComponent/queries/GlobalFilterComponentQuery';
import { generateFilterByType } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFiltersConfig';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { FilterNames } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { useMemo } from 'react';

const activityCodesNames = [FilterNames.ACTIVITY_CODES, FilterNames.ACTIVITY_CODE_CARD_BOARD];
const fragnetMilestoneNames = [FilterNames.FRAGNET_MILESTONE_ID_CARD_BOARD, FilterNames.FRAGNET_SEARCH];
const wbsNames = [FilterNames.WBS_SEARCH_ID_CARD_BOARD, FilterNames.WBS_SEARCH];

interface IUseActivityCodesHook {
    widgetId?: string;
    filter?: {
        filters: IFilter[];
    };
    latestVersionId?: number;
}

export const useGlobalFiltersHook = ({
    widgetId,
    filter,
    latestVersionId,
}: IUseActivityCodesHook): { generateFilters: IFilter[] } => {
    const { data: activityCodes } = useQueryActivityCodesByVersionAndWidgetId({
        latestVersion: latestVersionId,
        widgetId: widgetId,
        hasActivityCode: filter?.filters.some((item) => activityCodesNames.includes(item.name)),
    });

    const milestoneFragnetId = useMemo(() => {
        if (widgetId && filter) {
            const milestoneFragnet = filter.filters.find((item) => fragnetMilestoneNames.includes(item.name));
            return milestoneFragnet?.data?.[0];
        }
    }, [filter]);

    const { data: milestoneFragnet } = useQueryGetSingleMilestoneFragnet({
        latestVersion: latestVersionId,
        taskVersionHashCode: milestoneFragnetId, // make sure BE can perform search by task_version_hash_code
    });

    const wbsId = useMemo(() => {
        if (widgetId && filter) {
            const wbs = filter.filters.find((item) => wbsNames.includes(item.name));
            return wbs?.data?.[0];
        }
    }, [filter]);

    const { data: wbs } = useQueryGetSingleWbs({
        latestVersion: latestVersionId,
        taskVersionHashCode: wbsId, // make sure BE can perform search by task_version_hash_code
    });

    const generateFilters =
        widgetId && filter
            ? filter?.filters.map((item) => generateFilterByType(item, activityCodes, milestoneFragnet, wbs))
            : [];

    return { generateFilters };
};
