import {
    useQueryGetTableColumns,
    useQueryGetWidgetColumns,
} from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySelectedTableColumns';
import { ColumnCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';

export type ISortBy = {
    id: ColumnCommonConfig;
    desc: boolean;
};

export interface ITableColumnState {
    hiddenColumns?: ColumnCommonConfig[];
    sortBy?: ISortBy[];
    groupBy?: ColumnCommonConfig[];
    columnOrder?: ColumnCommonConfig[];
}

interface ITableState {
    componentKey: string;
    widgetId?: string;
    initialState: ITableColumnState;
}

export default function useTableCurrentState({
    componentKey,
    widgetId,
    initialState,
}: ITableState): ITableColumnState | null {
    const [state, setState] = useState<ITableColumnState | null>(null);
    const { data: tableData } = useQueryGetTableColumns({ componentKey });
    const { data: widgetData } = useQueryGetWidgetColumns({ widgetId });

    useEffect(() => {
        if (Boolean(widgetData) && widgetId) {
            setState({ ...initialState, ...widgetData });
        }
        if (Boolean(tableData) && !widgetId) {
            setState({ ...initialState, ...tableData });
        }
        if ((tableData === null && !widgetId) || (widgetData === null && widgetId)) {
            setState(initialState);
        }
    }, [componentKey, widgetId, initialState, widgetData, tableData]);

    return state;
}
