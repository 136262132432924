import moment from 'moment-timezone';

export const moreFilterOptions: ISelectOption<string>[] = [
    {
        label: 'Late Finish Date',
        value: 'LateFinishDate',
    },
    {
        label: 'Late Start Date',
        value: 'LateStartDate',
    },
    {
        label: 'Critical Path',
        value: 'CriticalPath',
    },
    {
        label: 'Milestones',
        value: 'Milestones',
    },
];

export const nextDateOptions: ISelectOption<string>[] = [
    {
        label: '1M',
        value: '30',
    },
    {
        label: '3M',
        value: '90',
    },
    {
        label: '6M',
        value: '180',
    },
    {
        label: 'ALL',
        value: '-1',
    },
];

export const statusOptions: ISelectOption<string>[] = [
    {
        id: 1,
        value: 'TODO',
        label: 'To Do',
    },
    {
        id: 2,
        value: 'IN_PROGRESS',
        label: 'In Progress',
    },
    {
        id: 3,
        value: 'DONE',
        label: 'Done',
    },
];

export const getNextDateCalculation = (selectedDateFilter, startDate, endDate) => {
    const periodStart = moment().utc().valueOf();
    const periodEnd = moment().utc().add(selectedDateFilter, 'days').valueOf();
    return moment(startDate).isBetween(periodStart, periodEnd) || moment(endDate).isBetween(periodStart, periodEnd);
};
