import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { ScoreCardsData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReportTypes';

const getScoreCardsData = ({ comparedVersionId, contractId, latestVersionId, filter }: ScoreCardsQueryParams) => {
    const params = {
        compareVersionId: comparedVersionId,
        contractId,
        latestVersionId,
        filter,
    };

    return network
        .post(API_URL().FETCH_PROGRAM__STATUS_SCORE_CARDS, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

type ScoreCardsQueryParams = {
    comparedVersionId: number | undefined;
    contractId: number | null | undefined;
    latestVersionId: number | undefined;
    filter: any;
};

export const useGetScoreCardsData = (params: ScoreCardsQueryParams) => {
    return useQuery<ScoreCardsData, Error>(['useGetScoreCardsData', params], () => getScoreCardsData(params), {
        enabled: Boolean(params.latestVersionId) && Boolean(params.contractId) && Boolean(params.comparedVersionId),
        cacheTime: 0,
    });
};
