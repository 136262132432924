import { useMutation, useQuery } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';

type AcrossVersionHashCodeConfigResponse = { configName: string; isSet: boolean };

type BaseLineDateOption = {
    projectId: number | undefined;
    option?: string;
};

export type AcrossVersionHashCodeConfig = {
    projectId: number;
    isSetActivityId: boolean;
    isSetActivityType: boolean;
    isSetFullPathParentNames: boolean;
    isSetName: boolean;
    isSetParentName: boolean;
    isSetUuid: boolean;
    isSetWbsLevel: boolean;
};

const getBaseLineDateOption = (projectId) => {
    return network
        .get(`${API_URL().FETCH_PROGRAM_GET_BASE_LINE_DATE_OPTION}?projectId=${projectId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getAcrossVersionHashCodeConfig = (projectId) => {
    return network
        .get(`${API_URL().FETCH_PROGRAM_GET_ACROSS_VERSION_HASH_CODE_CONFIG}?projectId=${projectId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const setBaseLineDateOption = ({ projectId, option }: BaseLineDateOption) => {
    const preparedURL = new URL(API_URL().FETCH_PROGRAM_SET_BASE_LINE_DATE_OPTION);
    projectId && preparedURL.searchParams.set('projectId', projectId.toString());
    option && preparedURL.searchParams.set('option', option);

    return network
        .post(preparedURL.toString())
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const setAcrossVersionHashCodeConfig = ({
    projectId,
    isSetActivityId,
    isSetActivityType,
    isSetFullPathParentNames,
    isSetName,
    isSetParentName,
    isSetUuid,
    isSetWbsLevel,
}: AcrossVersionHashCodeConfig) => {
    const preparedURL = new URL(API_URL().FETCH_PROGRAM_SET_ACROSS_VERSION_HASH_CODE_CONFIG);
    preparedURL.searchParams.set('projectId', projectId.toString());
    preparedURL.searchParams.set('isSetActivityId', isSetActivityId.toString());
    preparedURL.searchParams.set('isSetActivityType', isSetActivityType.toString());
    preparedURL.searchParams.set('isSetFullPathParentNames', isSetFullPathParentNames.toString());
    preparedURL.searchParams.set('isSetName', isSetName.toString());
    preparedURL.searchParams.set('isSetParentName', isSetParentName.toString());
    preparedURL.searchParams.set('isSetUuid', isSetUuid.toString());
    preparedURL.searchParams.set('isSetWbsLevel', isSetWbsLevel.toString());

    return network
        .post(preparedURL.toString())
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const useQueryGetBaseLineDateOption = ({ projectId }) => {
    return useQuery(['useQueryGetBaseLineDateOption', projectId], () => getBaseLineDateOption(projectId), {
        enabled: Boolean(projectId),
        staleTime: 0,
    });
};

const useQueryGetAcrossVersionHashCodeConfig = ({ projectId }) => {
    return useQuery<AcrossVersionHashCodeConfigResponse[], Error>(
        ['useQueryGetAcrossVersionHashCodeConfig', projectId],
        () => getAcrossVersionHashCodeConfig(projectId),
        {
            enabled: Boolean(projectId),
        },
    );
};

const useMutationSetBaseLineDateOption = () => {
    return useMutation<unknown, QueryError, BaseLineDateOption>((params: BaseLineDateOption) =>
        setBaseLineDateOption(params),
    );
};

const useMutationSetAcrossVersionHashCodeConfig = () => {
    return useMutation<unknown, QueryError, AcrossVersionHashCodeConfig>((params: AcrossVersionHashCodeConfig) =>
        setAcrossVersionHashCodeConfig(params),
    );
};

export {
    useQueryGetBaseLineDateOption,
    useQueryGetAcrossVersionHashCodeConfig,
    useMutationSetBaseLineDateOption,
    useMutationSetAcrossVersionHashCodeConfig,
};
