import { useQuery, useMutation } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import qs from 'qs';

const getUsersList = () => {
    return network
        .get(API_URL().FETCH_SETTING_USERS_LIST)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const deleteUser = (params) => {
    const querystring = qs.stringify(
        { userName: params.email },
        { addQueryPrefix: true, skipNulls: true, encode: true },
    );
    let url = `${API_URL().DELETE_USER_BY_USERNAME}${querystring}`;
    return network
        .delete(url)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const createUser = (params) => {
    return network
        .post(API_URL().CREATE_USER, params)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};
const updateUser = (params) => {
    return network
        .post(API_URL().UPDATE_USER, params)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const updateUserImage = (params) => {
    const querystring = qs.stringify({ userName: params.userName }, { addQueryPrefix: true, skipNulls: true });
    const url = `${API_URL().UPLOAD_USER_IMAGE}${querystring}`;
    const formData = new FormData();
    if (params.file !== null) {
        if (typeof params.file.size !== 'undefined') {
            formData.append('file', params.file, params.file.name);
        }
    }
    return network
        .post(url, formData, {
            transformRequest: [
                (data, headers) => {
                    if (headers) {
                        headers['Content-Type'] = 'multipart/form-data';
                    }
                    return data;
                },
            ],
        })
        .then((response) => {
            response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getUserImage = (params) => {
    const querystring = qs.stringify({ email: params.email }, { addQueryPrefix: true, skipNulls: true });
    const url = `${API_URL().GET_USER_IMAGE}${querystring}`;
    return network
        .post(url, null, {
            transformRequest: [
                (data, headers) => {
                    if (headers) {
                        headers.Accept = 'image/png';
                    }
                    return data;
                },
            ],
            responseType: 'blob',
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getUserDetails = (params) => {
    const querystring = qs.stringify({ username: params.email }, { addQueryPrefix: true, skipNulls: true });
    const url = `${API_URL().GET_USER_DETAILS}${querystring}`;
    return network
        .get(url)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

type IPrivilegesMap = {
    [K in keyof IPrivileges]: {
        [K2 in K]: IPrivilegesType;
    };
}[keyof IPrivileges];

export interface IGetUserDetailResponse {
    badLoginAttempts: number;
    customerId: number;
    defaultProjectId: number;
    email: string;
    firstName: string;
    globalFilterStatus: {
        showFilterToggleButton: boolean;
        showActivityCodeFilter: boolean;
        isFilterExpanded: boolean;
    };
    homePageUrl: string;
    image: string;
    lastLogin: number;
    lastName: string;
    logo: string;
    phone: string;
    privilegeMap: IPrivilegesMap;
    roleList: IRole[];
    status: 'ACTIVE';
    title: string;
    userGroupList: string[];
    username: string;
    uuid: string;
}

export const useQueryGetUserDetail = (params: { email?: string }) => {
    return useQuery<IGetUserDetailResponse, QueryError>(['getUserDetail', params], () => getUserDetails(params), {
        enabled: Boolean(params.email),
    });
};

export const useQueryUsersList = () => {
    return useQuery('usersList', () => getUsersList());
};

interface DeleteUserParams {
    email: string;
}
export const useMutationDeleteUser = () => {
    return useMutation<unknown, QueryError, DeleteUserParams>('deleteUser', (params: DeleteUserParams) =>
        deleteUser(params),
    );
};
export const useMutationCreateUser = () => {
    return useMutation<unknown, QueryError, unknown>('createUser', (params) => createUser(params));
};
export const useMutationUpdateUser = () => {
    return useMutation<unknown, QueryError, unknown>('updateUser', (params) => updateUser(params));
};

export const useMutationUploadUserImage = () => {
    return useMutation<unknown, QueryError, unknown>('updateUserImage', (params) => updateUserImage(params));
};

export const useQueryGetUserImage = (params) => {
    return useQuery<string | null, QueryError>(['getUserImage', params], () => getUserImage(params), {
        enabled: Boolean(params.email) && !params.isUnassigned && Boolean(params.imageName !== ''),
        staleTime: 1000 * 60 * 5,
    });
};

const changePassword = (params) => {
    return network
        .post(API_URL().CHANGE_PASSWORD, params)
        .then((response) => {
            return response.status;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationChangePassword = () => {
    return useMutation<unknown, QueryError, any>('changePassword', (params) => changePassword(params));
};
