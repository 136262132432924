import React, { useState } from 'react';
import classes from './addStoryContainer.module.scss';
import moment from 'moment-timezone';
import Icon from 'components/common/Icons/icon';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import SelectListWithCreate from 'components/common/selectListWithCreate/selectListWithCreate';
import RichTextArea from 'components/common/richTextArea/richTextArea';
import { isValidFileSize, isValidFileType, isValidUrl } from 'utilitys/helpers/general';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { CustomizedButton, CustomizedFormErrorMessage } from 'components/common';
import { useQueryGetCustomerUsersList } from 'components/Dashboards/Project/Components/DataStories/queries/dataStoriesQuery';
import { RadioComponent } from 'components/common/RadioComponent/radioComponent';
import colorsVars from 'styles/colors.module.scss';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { Controller, useForm } from 'react-hook-form';
import constants from 'components/common/Constants/constants';
import { sortBy } from 'lodash';
import { CustomizedDatePicker } from 'components/common/CustomizedDatePicker/CustomizedDatePicker';
const { radioButtonDarkerColor, radioButtonsTitlesColor } = colorsVars;

const userMentionMapper = (i) => ({
    text: `${i.firstName} ${i.lastName}`,
    value: `${i.firstName} ${i.lastName}`,
    url: i.userName,
});

interface IAddStoryContainerForm {
    id: number;
    contractId: number;
    contractName: string;
    createdDate: number;
    description: string;
    embeddedCode: string;
    file: File | { name: string } | null;
    mentionedList: string[];
    projectId: number;
    tagList: ISelectOption<string>[];
    title: string;
}

const AddStoryContainer = ({
    closeAddStory,
    saveStory,
    project,
    contract,
    tags,
    editData,
    isAdmin,
    isLoadingCreateEditStory,
}) => {
    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
        setValue,
        watch,
        trigger,
    } = useForm<IAddStoryContainerForm>({
        defaultValues: {
            id: editData !== null ? editData.id : -1,
            projectId: project !== null ? project.id : '-1',
            contractId: contract !== null ? contract.id : '-1',
            contractName: contract !== null ? contract.title : 'All',
            title: editData !== null ? editData.title : '',
            description: editData !== null ? editData.description : '',
            mentionedList: editData !== null ? editData.mentionedList : [],
            embeddedCode: '',
            file: editData !== null ? { name: editData.file } : null,
            tagList: editData !== null ? editData.tagslist.map((item) => ({ label: item.name, value: item.name })) : [],
            createdDate: editData !== null ? editData.createdDate : moment().valueOf(),
        },
    });

    const fileName = watch('file');

    const { sendEventWithDimensions } = useEventWithDimensions();

    const [radioChecked, setRadioChecked] = useState('image');
    const { data: customerUsersList, isLoading } = useQueryGetCustomerUsersList();

    const handleEditorDescription = (editorState, mentionedList) => {
        setValue('description', editorState);
        setValue('mentionedList', mentionedList);
    };

    const handleRadioChecked = (e) => {
        setRadioChecked(e.target.value);
    };

    const handleChosenStartDate = (value) => {
        setValue('createdDate', moment(value).valueOf());
    };

    const controlProps = (item) => ({
        checked: radioChecked === item,
        onChange: handleRadioChecked,
        value: item,
        name: 'testRadioBtn',
        color: radioButtonDarkerColor,
    });

    const submitFrom = (value) => {
        const payload = {
            ...value,
            title: value.title.trim(),
            tagList: value.tagList.map((item) => ({ name: item.value })),
        };
        saveStory(payload);

        sendEventWithDimensions({
            category: 'Data-Story',
            action: editData ? 'Update' : 'Create',
            label: value.id,
        });
    };

    const embeddedCodeValidation = (value) => {
        if (radioChecked !== 'embeddedCode' || value.length === 0) {
            return true;
        }

        return isValidUrl(value);
    };

    const handleImageSelection = (e) => {
        setValue('file', e.target.files[0]);
        return trigger('file');
    };

    const tagsOptions = tags ? tags.map((item) => ({ label: item.name, value: item.name })) : [];

    return (
        <div className={classes.addStoryContainer}>
            {isLoading && <OverlayWithSpinner />}
            <form onSubmit={handleSubmit(submitFrom)}>
                <div className={classes.wrapper}>
                    {!project && !contract ? (
                        <div className={classes.content}>
                            <span className={classes.title}>Portfolio</span>
                        </div>
                    ) : (
                        <>
                            <div className={classes.content}>
                                <span className={classes.title}>Project*</span>
                                <span className={classes.contentValue}>{project.title || 'All'}</span>
                            </div>
                            <div className={classes.content}>
                                <span className={classes.title}>Sub-Program*</span>
                                <span className={classes.contentValue}>{contract?.title || 'All'}</span>
                            </div>
                        </>
                    )}

                    <div className={classes.content}>
                        <span className={classes.title}>Date*</span>
                        <div className={classes.dateInputWrapper}>
                            <Controller
                                render={({ field }) => (
                                    <CustomizedDatePicker
                                        value={field.value}
                                        handleChange={handleChosenStartDate}
                                        minDate={moment().subtract(20, 'year').valueOf()}
                                    />
                                )}
                                name={'createdDate'}
                                control={control}
                            />
                        </div>
                    </div>

                    <div className={classes.content}>
                        <span className={classes.title}>Title*</span>
                        <div className={classes.contentValueWrapper}>
                            <Controller
                                render={({ field }) => (
                                    <TextSearchInput
                                        {...field}
                                        handleClear={() => {
                                            setValue('title', '');
                                        }}
                                        id={'story-title'}
                                        isSearch={false}
                                        placeholder="Enter Title"
                                        maxLength={200}
                                        className={classes.inputContentValue}
                                    />
                                )}
                                name={'title'}
                                control={control}
                                rules={{
                                    required: 'Title is Required',
                                    minLength: { value: 2, message: 'Title is to short' },
                                }}
                            />
                            {errors.title && <CustomizedFormErrorMessage text={errors.title.message} />}
                        </div>
                    </div>
                    <div className={classes.content}>
                        <span className={`${classes.description} ${classes.title}`}>Description:</span>
                        <div className={`${classes.descriptionTextAreaWrapper} ${classes.contentValue}`}>
                            <Controller
                                render={() => (
                                    <RichTextArea
                                        onEditorDescription={handleEditorDescription}
                                        existingData={editData !== null ? editData.description : ''}
                                        editorContentHeight={220}
                                        mention={{
                                            separator: ' ',
                                            trigger: '@',
                                            suggestions:
                                                sortBy(customerUsersList?.map(userMentionMapper), 'text') || [],
                                        }}
                                    />
                                )}
                                name={'description'}
                                control={control}
                            />
                        </div>
                    </div>

                    {isAdmin && (
                        <div className={classes.content}>
                            <span className={classes.title}>
                                <label>
                                    <RadioComponent {...controlProps('embeddedCode')} /> External BI URL
                                </label>
                            </span>

                            <div className={classes.contentValueWrapper}>
                                <Controller
                                    render={({ field }) => (
                                        <TextSearchInput
                                            {...field}
                                            handleClear={() => {
                                                setValue('embeddedCode', '');
                                            }}
                                            id={'story-embeddedCode'}
                                            isSearch={false}
                                            placeholder=""
                                            maxLength={200}
                                            className={classes.inputContentValue}
                                            disabled={radioChecked !== 'embeddedCode'}
                                        />
                                    )}
                                    name={'embeddedCode'}
                                    control={control}
                                    rules={{
                                        validate: (value) => embeddedCodeValidation(value) || 'Invalid URL',
                                    }}
                                />
                                {errors.embeddedCode && (
                                    <CustomizedFormErrorMessage text={errors.embeddedCode.message} />
                                )}
                            </div>
                        </div>
                    )}

                    <div className={classes.content}>
                        <span className={classes.title}>
                            <label>
                                <RadioComponent {...controlProps('image')} /> Image
                            </label>
                        </span>
                        <label htmlFor="image-upload" className={classes.customImageUpload}>
                            <Icon
                                name="Download-icon"
                                size="20"
                                color={radioChecked === 'image' ? radioButtonDarkerColor : radioButtonsTitlesColor}
                            />
                            <span
                                className={classes.imageUploadTitle}
                                style={{
                                    color: radioChecked === 'image' ? radioButtonDarkerColor : radioButtonsTitlesColor,
                                }}
                            >
                                Upload Image
                            </span>

                            {errors.file ? (
                                <CustomizedFormErrorMessage text={errors.file.message} textAlign={'right'} />
                            ) : (
                                <span className={classes.imageUploadTitle}>{fileName && fileName.name}</span>
                            )}
                        </label>
                        <input
                            id="image-upload"
                            type="file"
                            accept="image/png, image/jpeg, image/gif"
                            disabled={radioChecked !== 'image'}
                            {...register('file', {
                                validate: {
                                    isValidFileType: (value) =>
                                        value === null ||
                                        value.name === null ||
                                        isValidFileType(value, ['image/gif', 'image/jpeg', 'image/png']) ||
                                        'Only jpg/jpeg/png and gif files are allowed!',
                                    isValidFileSize: (value) =>
                                        value === null ||
                                        value.name === null ||
                                        isValidFileSize(value, 5242880) ||
                                        'Maximum file size allowed for upload 5mb',
                                },
                            })}
                            onChange={handleImageSelection}
                        />
                    </div>
                    <div className={classes.content}>
                        <span className={classes.title}>Tags:</span>
                        <div className={classes.contentValue}>
                            <Controller
                                render={({ field }) => (
                                    <SelectListWithCreate {...field} options={tagsOptions} id={'story-tagslist'} />
                                )}
                                name={'tagList'}
                                control={control}
                            />
                        </div>
                    </div>
                </div>

                <div className={classes.actionButtons}>
                    <CustomizedButton size={'medium'} color={'secondary'} onClick={closeAddStory}>
                        Cancel
                    </CustomizedButton>

                    <CustomizedButton
                        type={'submit'}
                        size={'medium'}
                        color={'primary'}
                        isLoading={isLoadingCreateEditStory}
                        disabled={isLoadingCreateEditStory}
                    >
                        Save
                    </CustomizedButton>
                </div>
            </form>
        </div>
    );
};

export default AddStoryContainer;
