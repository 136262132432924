import { MultiLineSeriesChart } from 'components/common/Graphs/graphConfiguration';

export const handleCriticalAnalysisGraphConfig = (data: any) => {
    const { id, title, info, main, volume, nowDate } = data;

    const configMain = new MultiLineSeriesChart(
        main.dataset,
        title,
        id,
        info,
        [{ category: main.categories }],
        mainChart,
    );
    const configVolume = new MultiLineSeriesChart(
        volume.dataset,
        title,
        id,
        info,
        [{ category: volume.categories }],
        volumeChart,
    );
    return { data: { main: configMain, volume: configVolume, dataset: main.data, nowDate } };
};

export const handleCriticalAnalysisIntensityConfig = (data: IProgramCriticalAnalysisIntensity) => {
    const { id, title, info, categories, dataset } = data;
    const config = new MultiLineSeriesChart(dataset, title, id, info, [{ category: categories }], intensity);
    return config;
};

const mainChart = {
    drawAnchors: '1',
    anchorSides: 0,
    anchorRadius: 8,
    anchorBorderThickness: '0px',
    numDivLines: 5,
    numVDivLines: 5,
    divLineColor: '#161e29',
    vDivLineColor: '#161e29',
    divLineThickness: 3,
    vDivLineThickness: 3,
    showAlternateHGridColor: true,
    showAlternateVGridColor: true,
    alternateHGridColor: '#161e29',
    alternateVGridColor: '#fff',
    showLegend: false,
    plottooltext: '<b>$yDataValue</b> worth <b>$seriesNames</b> were sold,<br>when temperature was <b>$xdataValue</b>',
};
const volumeChart = {
    numDivLines: 1,
    divLineColor: '#161e29',
    divLineThickness: 3,
    divLineDashed: true,
    showLegend: false,
    usePlotGradientColor: false,
    formatnumberscale: '1',
    palettecolors: '#FF0000, #0372AB',
    labelFontColor: '#f5f7f7',
    plotSpacePercent: 50,
    labelFontSize: '10',
    rotateLabels: '0',
    labelDisplay: 'wrap',
    yAxisName: 'Volume of Milestones',
    yAxisValueFontSize: '1.2rem',
    yAxisValueFontColor: '#7687a2',
    yAxisNameFontColor: '#7687a2',
};

const intensity = {
    drawAnchors: '1',
    formatNumberScale: '0',
    showLegend: '0',
    palettecolors: 'B6C1D4,29fd48,8820DD,FFEA00,65AEAA',
    baseFontColor: '#7687a2',
    showToolTip: true,
    toolTipBgColor: '#262b33',
    toolTipColor: '#fff',
    toolTipBorderColor: '#465772',
    showToolTipShadow: true,
    plotBorderThickness: '4',
    inheritPlotBorderColor: '1',
    valueFont: '1.2rem',
    valueFontColor: '#fff',
    palette: '1',
    labelFontSize: '10',
    labelFontColor: '#FEFEFE',
    yAxisNameFont: 'roboto',
    yAxisNameFontSize: '14',
    showPlotBorder: '1',
    drawFullAreaBorder: '0',
    plotFillAlpha: '10',
    yAxisMaxValue: '100',
    drawCustomLegend: 1,
    legendIconBgColor: '#000000',
    legendIconBorderThickness: 4,
    toolbarButtonColor: '#000000',
    useCrossLine: 0,
    plotToolText: `$seriesName: $label, $dataValue %`,
    // showZeroPlane: '1',
    // zeroPlaneColor: '#29fd48',
    // zeroPlaneAlpha: '100',
    // zeroPlaneThickness: '3',
};
