import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { ITableWidgetWrapperProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import NoData from 'components/common/NoData/noData';

import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import classes from 'components/Dashboards/Project/Components/VersionCompare/style/Table.module.scss';
import { TableFooter } from 'components/Dashboards/Project/Components/VersionCompare/TableFotter/TableFooter';
import {
    columns,
    initialState,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/TableSection/SummaryTrackedActivities/utils';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import { smallPageSize } from 'components/Dashboards/Project/Components/VersionCompare/utils/table.utils';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useMutationSetWidgetColumns } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySelectedTableColumns';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import { Link } from 'react-router-dom';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { useQuerySummaryTrackedActivities } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/TableSection/SummaryTrackedActivities/useQuerySummaryTrackedActivities';

const SummaryTrackedActivitiesComponent = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    compareVersionId,
    filter,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: ITableWidgetWrapperProps) => {
    const tableRef = useRef(null);
    const elementId = 'summary-tracked-activities';
    const id = 'summary-tracked-activities';
    const route = `${getStringWithoutHash(window.location.href)}#${elementId}`;
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const componentKey = 'mySummaryTrackedActivities';
    const updatedInitialState = useTableCurrentState({ initialState, componentKey, widgetId });
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes,
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                  }),

        [filter, globalFilterData],
    );

    const { data, isLoading, isFetching } = useQuerySummaryTrackedActivities({
        projectId: projectId,
        contractId: contractId,
        latestVersionId: latestVersionId,
        compareVersionId: compareVersionId,
        filterData: {
            filters,
        },
    });

    const { generateFilters: formattedFilter } = useGlobalFiltersHook({ widgetId, filter, latestVersionId });
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate: mutateSetWidgetColumns } = useMutationSetWidgetColumns();

    const [pageSize, setPageSize] = useState(smallPageSize);

    const activeData = useMemo(() => {
        if (data) {
            const current = data.periodItemsList.find((item) => item.id === 'all');
            return current ? current.itemsList : [];
        }
        return [];
    }, [data]);
    const setWidgetColumns = (widgetId: string) => {
        if (tableRef.current) {
            const { hiddenColumns, columnOrder } = tableRef.current.state;
            mutateSetWidgetColumns(
                { columns: { hiddenColumns, columnOrder }, widgetId },
                {
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                    },
                },
            );
        }
    };

    const title = `My Tracked Activities (${activeData.length})`;

    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);

    return (
        <WidgetWithTitle
            title={title}
            tooltip={data?.info}
            id={id}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId && formattedFilter.length > 0 && (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formattedFilter} />
                ),
            ]}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <CustomizedTooltip
                    key={'tableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    projectId={projectId}
                    contractId={contractId}
                    elementId={elementId}
                    widgetId={widgetId}
                    route={route}
                    filters={filters}
                    callBack={setWidgetColumns}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown title={title} key={'export'} subTitle={subTitle} />,
            ]}
        >
            <div className={classes.tableWrapperContainer}>
                {updatedInitialState && (
                    <MainTable
                        getInstanceCallback={(instance) => (tableRef.current = instance)}
                        data={activeData}
                        columns={columns}
                        initialState={updatedInitialState}
                        pageSize={pageSize}
                        noData={
                            <NoData>
                                No tracked activities added yet. Find and add via{' '}
                                <Link to={`/dashboard/project/${projectConfig.battlecards.link}`}>
                                    {projectConfig.battlecards.title}
                                </Link>
                            </NoData>
                        }
                        isLoading={isLoading || isFetching}
                    />
                )}

                {activeData.length > smallPageSize && <TableFooter setPageSize={setPageSize} pageSize={pageSize} />}
            </div>
            {tableRef?.current && (
                <CustomTableColumnsModal
                    onHideModal={() => setModalColumnsOpen(false)}
                    showModal={modalColumnsOpen}
                    widgetId={widgetId}
                    componentKey={componentKey}
                    tableInstance={tableRef.current}
                    initialState={initialState}
                    columns={columns}
                />
            )}
        </WidgetWithTitle>
    );
};
export default React.memo(SummaryTrackedActivitiesComponent);
