import classes from './singlePhase.module.scss';
import { CustomizedRoundIcon, CustomizedFormErrorMessage, CustomizedTooltip } from 'components/common';
import Icon from 'components/common/Icons/icon';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { Control, Controller, FieldErrors, UseFieldArrayRemove, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { ISinglePhase } from 'components/Dashboards/Portfolio/phasePerformance/manage/manage.types';
import { IFields } from 'components/Dashboards/Portfolio/phasePerformance/manage/addTemplate/phases/phases';
import React from 'react';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import colorsVars from 'styles/colors.module.scss';
const { textColor } = colorsVars;
interface ISinglePhaseProps {
    setValue: UseFormSetValue<IFields>;
    isLastPhase: boolean;
    control: Control<IFields>;
    index: number;
    remove: UseFieldArrayRemove;
    errors: FieldErrors;
    currentFields: ISinglePhase[];
    watch: UseFormWatch<IFields>;
}

export const SinglePhase = ({
    setValue,
    isLastPhase = false,
    control,
    index,
    remove,
    errors,
    currentFields,
    watch,
}: ISinglePhaseProps) => {
    const isDisabled = index !== 0;

    const nameError = errors?.phaseFields?.[index]?.name ? { border: '2px solid red' } : {};
    const startGateError = errors?.phaseFields?.[index]?.start_gate ? { border: '2px solid red' } : {};
    const endGateError = errors?.phaseFields?.[index]?.end_gate ? { border: '2px solid red' } : {};

    const handleEndGate = (e) => {
        setValue(`phaseFields.${index}.end_gate`, e.target.value, { shouldDirty: true });
        !isLastPhase && setValue(`phaseFields.${index + 1}.start_gate`, e.target.value, { shouldDirty: true });
    };

    const handleRemoveGate = () => {
        const prevValue = watch(`phaseFields.${index - 1}.end_gate`);
        prevValue && !isLastPhase && setValue(`phaseFields.${index + 1}.start_gate`, prevValue, { shouldDirty: true });
        remove(index);
    };

    const isNameDuplicate = (value) => {
        const nameGates: string[] = currentFields.map((item) => item.name);
        const isDuplicate = nameGates.some((e, i, arr) => {
            return arr.indexOf(e) !== i && value === e;
        });
        return isDuplicate ? 'Phase Name Duplication' : true;
    };

    const isStartGateDuplicate = (value: string) => {
        const startGates: string[] = currentFields.map((item) => item.start_gate);
        const isDuplicate = startGates.some((item, i, arr) => {
            return arr.indexOf(item) !== i && value === item;
        });
        return isDuplicate ? 'Start Gate Duplication' : true;
    };

    const isEndGateDuplicate = (value: string) => {
        const endGates: string[] = currentFields.map((item) => item.end_gate);
        const isDuplicate = endGates.some((item, i, arr) => {
            return arr.indexOf(item) !== i && value === item;
        });
        return isDuplicate ? 'End Gate Duplication' : true;
    };

    const isSameGateDuplicate = (value: string) => {
        const isDuplicate = currentFields.some((item, i) => {
            const isNextStepValue = i !== index + 1;
            return value === item.start_gate && isNextStepValue;
        });

        return isDuplicate ? 'Same Gate Duplication' : true;
    };

    return (
        <div className={classes.singlePhaseContainer} id={`manage_phase_template_gate_${index}`}>
            <div className={classes.number}>
                <CustomizedRoundIcon hoverColor={'none'}>{index + 1}</CustomizedRoundIcon>
                <div className={`${classes.lineBetweenNumbers} ${isLastPhase ? classes.lastPhase : ''}`} />
            </div>
            <div className={classes.content}>
                <div className={classes.phaseName}>
                    <Controller
                        render={({ field }) => (
                            <TextSearchInput
                                className={classes.inputError}
                                {...field}
                                placeholder={'Phase Name'}
                                isSearch={false}
                                handleClear={() => {
                                    setValue(`phaseFields.${index}.name`, '', { shouldDirty: true });
                                }}
                                style={nameError}
                            />
                        )}
                        name={`phaseFields.${index}.name`}
                        control={control}
                        rules={{
                            required: 'Phase Name is Required',
                            validate: {
                                isDuplicate: isNameDuplicate,
                            },
                        }}
                    />
                    {errors?.phaseFields?.[index]?.name ? (
                        <CustomizedFormErrorMessage text={errors?.phaseFields?.[index]?.name.message} />
                    ) : (
                        <div className={classes.errorSpace} />
                    )}
                </div>
                <div className={classes.gateNames}>
                    <div className={classes.inputWrapper}>
                        <Controller
                            render={({ field }) => (
                                <TextSearchInput
                                    {...field}
                                    placeholder={'Start Gate'}
                                    isSearch={false}
                                    disabled={isDisabled}
                                    className={`${isDisabled ? classes.disabledInput : ''}`}
                                    handleClear={() => {
                                        setValue(`phaseFields.${index}.start_gate`, '', { shouldDirty: true });
                                    }}
                                    style={startGateError}
                                    maxLength={5}
                                />
                            )}
                            name={`phaseFields.${index}.start_gate`}
                            control={control}
                            rules={{
                                required: 'Start Gate is Required',
                                validate: {
                                    isDuplicate: isStartGateDuplicate,
                                },
                            }}
                        />
                        {errors?.phaseFields?.[index]?.start_gate ? (
                            <CustomizedFormErrorMessage text={errors?.phaseFields?.[index]?.start_gate.message} />
                        ) : (
                            <div className={classes.errorSpace} />
                        )}
                    </div>
                    <div className={classes.arrowIconWrapper}>
                        <MuiIcon icon={'arrow_forward'} fontSize={'2.2rem'} color={textColor} />
                    </div>
                    <div className={classes.inputWrapper}>
                        <Controller
                            render={({ field }) => (
                                <TextSearchInput
                                    {...field}
                                    placeholder={'End Gate'}
                                    isSearch={false}
                                    handleClear={() => {
                                        setValue(`phaseFields.${index}.end_gate`, '', { shouldDirty: true });
                                        !isLastPhase &&
                                            setValue(`phaseFields.${index + 1}.start_gate`, '', { shouldDirty: true });
                                    }}
                                    style={endGateError}
                                    maxLength={5}
                                    onChange={handleEndGate}
                                />
                            )}
                            name={`phaseFields.${index}.end_gate`}
                            control={control}
                            rules={{
                                required: 'End Gate is Required',
                                validate: {
                                    isDuplicate: isEndGateDuplicate,
                                    isSameDuplicate: isSameGateDuplicate,
                                },
                            }}
                        />
                        {errors?.phaseFields?.[index]?.end_gate ? (
                            <CustomizedFormErrorMessage text={errors?.phaseFields?.[index]?.end_gate.message} />
                        ) : (
                            <div className={classes.errorSpace} />
                        )}
                    </div>
                    <div className={classes.inputWrapper}>
                        <Controller
                            render={({ field }) => (
                                <div className={classes.expectedDurationWrapper}>
                                    <TextSearchInput
                                        className={classes.expectedDuration}
                                        {...field}
                                        placeholder={'Expected Duration'}
                                        isSearch={false}
                                        handleClear={() => {
                                            setValue(`phaseFields.${index}.expected_duration`, 0, {
                                                shouldDirty: true,
                                            });
                                        }}
                                    />
                                    <div>days</div>
                                </div>
                            )}
                            name={`phaseFields.${index}.expected_duration`}
                            control={control}
                            rules={{
                                validate: {
                                    isValidNumber: (value) => value > -1 || 'must be positive number',
                                },
                            }}
                        />
                        {errors?.phaseFields?.[index]?.expected_duration ? (
                            <CustomizedFormErrorMessage
                                text={errors?.phaseFields?.[index]?.expected_duration.message}
                            />
                        ) : (
                            <div className={classes.errorSpace} />
                        )}
                    </div>
                </div>
            </div>
            <div className={classes.actionIcon}>
                <CustomizedTooltip
                    tooltipContent={'Delete Phase'}
                    triggerElement={
                        <CustomizedRoundIcon
                            onClick={handleRemoveGate}
                            id={`manage_phase_template_remove_gate_${index}`}
                        >
                            <Icon name={'BIN'} />
                        </CustomizedRoundIcon>
                    }
                />
            </div>
        </div>
    );
};
