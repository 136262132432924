import { create } from 'zustand';

export type TModal = 'project' | 'subProgram';

interface Props {
    modalType: TModal | null;
    setModalType: (value: TModal | null) => void;
}

const store = (set): Props => ({
    modalType: null,
    setModalType: (value) => set(() => ({ modalType: value })),
});

export const useModalStore = create(store);
