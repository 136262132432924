import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import classes from 'components/Dashboards/ExcellenceApps/LogicChecker/LogicChecker.module.scss';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import React, { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';
import {
    useQueryGetLogicCheckerCategoryResults,
    useQueryGetLogicCheckerSummary,
} from 'components/Dashboards/ExcellenceApps/LogicChecker/queries/useQueryLogicChecker';
import { categoryColumns } from 'components/Dashboards/ExcellenceApps/LogicChecker/helper';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import SwitchComponent from 'components/common/Switch/Switch';
import { useValidationStore } from 'components/Dashboards/ExcellenceApps/LogicChecker/store/validationStore';
import { useLogicCheckerFilterHook } from 'components/Dashboards/ExcellenceApps/LogicChecker/hooks/useLogicCheckerFilterHook';

export const CategoryTable = () => {
    const isDcma = useValidationStore((state) => state.isDcma);
    const isActive = useValidationStore((state) => state.isActive);
    const { setIsDcma, setIsActive } = useValidationStore();
    const [isInstance, setIsInstance] = useState(false);
    const tableRef = useRef(null);
    const { id: versionId, category } = useParams<{ id: string; category: string }>();
    const { idList, versionList } = useHashmapConverter(versionId);
    const projectId = versionList[0]?.projectId;
    const contractId = versionList[0]?.contractId;
    const fileMetaDataId = idList.length > 0 ? parseInt(idList.join(',')) : undefined;
    const filters = useLogicCheckerFilterHook();
    const {
        data: logicCheckerData,
        isLoading,
        isFetching,
    } = useQueryGetLogicCheckerCategoryResults({
        fileMetaDataId,
        filter: {
            filters,
        },
    });
    const { data: summaryData } = useQueryGetLogicCheckerSummary({
        fileMetaDataId,
        filter: {
            filters,
        },
    });

    const parsedCategory = category.replace('_', '/');

    const versionName = `${versionList[0]?.versionName} (${moment(versionList[0]?.versionDate).format(
        constants.formats.date.default,
    )})`;

    const generatedCols = useMemo(() => {
        return categoryColumns(
            category,
            summaryData?.inScoreNumber,
            projectId,
            contractId,
            versionName,
            fileMetaDataId,
        );
    }, [category, summaryData]);

    const generatedData = useMemo(() => {
        if (logicCheckerData) {
            const categoryData = logicCheckerData.categoriesSummaryRows.find(
                (item) => item.category === parsedCategory,
            );
            if (categoryData?.validationRows) {
                return categoryData?.validationRows
                    .filter((item) => (isDcma ? item.dcma : true))
                    .filter((item) => (isActive ? item.mandatory : true));
            }
            return [];
        } else {
            return [];
        }
    }, [logicCheckerData, isDcma, isActive]);

    const handleInstance = (instance) => {
        if (instance) {
            tableRef.current = instance;
            setIsInstance(true); // need for render component after getting instance
        }
    };

    const title = 'Category: ' + parsedCategory;

    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId: idList[0] });

    return (
        <WidgetWithTitle
            title={title}
            titleComponents={
                isInstance && (
                    <ExportDropdown
                        title={title}
                        data={generatedData}
                        tableInstance={tableRef.current}
                        subTitle={subTitle}
                    />
                )
            }
            titleFilters={
                <>
                    DCMA Only
                    <SwitchComponent onChange={(e) => setIsDcma(e.target.checked)} checked={isDcma} />
                    Active Only
                    <SwitchComponent onChange={(e) => setIsActive(e.target.checked)} checked={isActive} />
                </>
            }
        >
            <div className={classes.tableContainer}>
                <MainTable
                    getInstanceCallback={handleInstance}
                    columns={generatedCols}
                    data={generatedData}
                    maxHeight={820}
                    isLoading={isLoading || isFetching}
                    uniqueKey={'validationId'}
                />
            </div>
        </WidgetWithTitle>
    );
};
