import classes from './settingHeader.module.scss';
import { pageTitle } from 'services/pageTitle';

interface Props {
    title: string;
    subTitle?: string;
}
const SettingHeader = ({ title, subTitle }: Props) => {
    pageTitle.set(title);

    return (
        <div className={classes.settingsTopHeader}>
            <div className={classes.titlesWrapper} id="accountAdministrationPage_TitlesWrapper">
                <div className={classes.settingsUserDetailsTitle} id="accountAdministrationPage_Title">
                    {title}
                </div>
                <div className={classes.editUserDetailsTitle} id="accountAdministrationPage_SubTitle">
                    {subTitle}
                </div>
            </div>
        </div>
    );
};
export default SettingHeader;
