import React, { useMemo, useState } from 'react';
import { LoaderContainer, WidgetCard, WidgetCardsWrapper } from 'components/common';
import NoData from 'components/common/NoData/noData';
import { ProjectLevelChangeCard } from 'components/Dashboards/Project/Components/VersionCompare/ProjectLevelChangeCard/projectLevelChangeCard';
import { CardItemGraph } from 'components/common/WidgetWithCards/WidgetCard/CardItemGraph/CardItemGraph';
import { IComponentProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { useQuerySummaryData } from 'components/Dashboards/Project/Components/VersionCompare/queries/tableDataQuery';
import { maxBy } from 'lodash';
import { maxValueScale } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/cardsSection.utils';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';

export enum ToggleBy {
    FluxIndex = 'Flux index',
    CriticalityScore = 'Criticality Score',
}

const WidgetCardsComponent = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    compareVersionId,
    originalRoute = '',
    filter,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: IComponentProps) => {
    const title = 'Change Summary';
    const id = 'version-summary';

    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters || []
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes,
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                  }),

        [filter, globalFilterData],
    );
    const { data: summaryData, isFetching: isSummaryDataFetching } = useQuerySummaryData({
        latestVersionId: latestVersionId,
        compareVersionId: compareVersionId,
        contractId,
        projectId,
        filter: {
            filters,
        },
    });

    const projectLevelData = summaryData?.summaryCards
        ? summaryData.summaryCards.find((item) => item?.cardTitle?.cardType === 'ProjectLevel')
        : [];

    const activityLevelData = summaryData?.summaryCards
        ? summaryData.summaryCards.find((item) => item?.cardTitle?.cardType === 'ActivityLevel')
        : [];

    const graphData = summaryData?.summaryCards
        ? summaryData.summaryCards.find((item) => item?.cardTitle?.cardType === 'FluxIndex')
        : [];

    const isSummaryData = Boolean(projectLevelData?.cardProperties) && Boolean(activityLevelData?.cardProperties);

    const [toggle, setToggle] = useState<ToggleBy>(ToggleBy.FluxIndex);
    const selectToggle = (event) => {
        setToggle(event.target.value);
    };
    const cardTrendsIndex = Object.values(ToggleBy).findIndex((item) => item === toggle);
    const maxValuePoint = graphData?.cardTrends && maxBy(graphData.cardTrends[cardTrendsIndex].graphPoints, 'score');
    const maxValue = maxValuePoint ? String(maxValuePoint?.score * maxValueScale) : '';
    const componentKey = 'versionSummary';

    return (
        <WidgetCardsWrapper
            projectId={projectId}
            contractId={contractId}
            latestVersionId={latestVersionId}
            title={title}
            tooltipText={summaryData?.info}
            isLoading={isSummaryDataFetching}
            id={id}
            widgetId={widgetId}
            originalRoute={originalRoute}
            filter={{ filters }}
            externalComponents={externalComponents}
            editNarrative={editNarrative}
            componentKey={componentKey}
            setSubTitleForExport={setSubTitleForExport}
        >
            {isSummaryData && !summaryData && (
                <LoaderContainer>
                    <OverlayWithSpinner />
                </LoaderContainer>
            )}
            {!isSummaryData && !summaryData && <NoData />}
            {summaryData && (
                <>
                    <ProjectLevelChangeCard data={projectLevelData} />
                    <ProjectLevelChangeCard data={activityLevelData} />
                    {graphData?.cardTrends?.length > 0 && (
                        <WidgetCard
                            contentHeight={'100%'}
                            showToggleRadioInput={true}
                            titleText={'Change'}
                            selectToggle={selectToggle}
                            toggle={toggle}
                        >
                            <CardItemGraph
                                xaxisname={'Versions'}
                                yaxisname={toggle}
                                yAxisMaxValue={maxValue}
                                points={graphData?.cardTrends[cardTrendsIndex].graphPoints}
                                contentHeight={'350'}
                                contentWidth={'100%'}
                            />
                        </WidgetCard>
                    )}
                </>
            )}
        </WidgetCardsWrapper>
    );
};
export default React.memo(WidgetCardsComponent);
