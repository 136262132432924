import classes from 'components/Dashboards/Project/Components/VersionCompare/components/VersionCompareTrackedActivities/tableLocalFilters/tableLocalFilters.module.scss';
import { Controller } from 'react-hook-form';
import { MyCustomSelect } from 'components/common';
import { statusOptions } from 'components/Dashboards/widgets/keyActivities/components/filter.utils';
import { InputOption, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';

export const StatusFilter = ({ control }) => {
    return (
        <div className={classes.selectWrapper}>
            <div className={classes.title}>Status</div>
            <Controller
                render={({ field }) => (
                    <MyCustomSelect
                        {...field}
                        options={statusOptions}
                        isClearable={true}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isMulti={true}
                        placeholder={'Select Status...'}
                        components={{
                            Option: InputOption,
                            ValueContainer: ValueContainer,
                        }}
                    />
                )}
                name="status"
                control={control}
            />
        </div>
    );
};
