import React, { useState } from 'react';
import classes from 'components/Dashboards/Project/Components/DataStories/DataStoriesHeader/headerContent.module.scss';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import Icon from 'components/common/Icons/icon';
import moment from 'moment-timezone';
import { CustomizedButton, CustomizedTooltip } from 'components/common';
import MonthDatePicker from 'components/common/MonthDatePicker/monthDatePicker';
import constants from 'components/common/Constants/constants';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';

export const HeaderContent = ({
    handleDateData,
    handleClearDate,
    onTextSearch,
    onClearSearch,
    isReadOnly,
    addStory,
    startDate,
    endDate,
}) => {
    const [datePickerTitle, setDatePickerTitle] = useState<any>('Select Date');

    const [textSearch, setTextSearch] = useState('');

    const handleChosenDate = (value) => {
        setDatePickerTitle(null);
        handleDateData(value);
    };

    const handleClearDatePicker = () => {
        setDatePickerTitle('Select Date');
        handleClearDate();
    };

    const searchInputChange = (e) => {
        setTextSearch(e.target.value);
        if (e.target.value.length === 0) {
            onClearSearch();
        }
    };

    const handleTextSearch = () => {
        onTextSearch(textSearch);
    };

    const handleTextSearchOnEnter = (e) => {
        if (e.charCode === 13) {
            onTextSearch(textSearch);
        }
    };

    const handleClearSearch = () => {
        setTextSearch('');
        onClearSearch();
    };
    return (
        <div className={classes.headerContentContainer}>
            <div className={classes.searchInputWrapper}>
                <TextSearchInput
                    id={'news-feed-search'}
                    placeholder={'Search...'}
                    value={textSearch}
                    handleChange={searchInputChange}
                    handleClear={handleClearSearch}
                    handleClick={handleTextSearch}
                    onKeyPress={handleTextSearchOnEnter}
                />
            </div>
            <div className={classes.datePickerContainer}>
                <MonthDatePicker
                    datePickerTitle={datePickerTitle}
                    title={false}
                    placement={'bottom'}
                    staticStartDate={moment(new Date(startDate))
                        .subtract(1, 'months')
                        .format(constants.formats.date.yearMonthDay)}
                    staticEndDate={moment(new Date(endDate)).format(constants.formats.date.yearMonthDay)}
                    currentChosenDate={moment(new Date(startDate)).format(constants.formats.date.yearMonthDay)}
                    handleDateData={handleChosenDate}
                    highlight={[]}
                    disabled={false}
                    handleClearDatePicker={handleClearDatePicker}
                />
            </div>
            {isReadOnly !== false && (
                <CustomizedTooltip
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={<div>{`Create Story`}</div>}
                    triggerElement={
                        <CustomizedButton
                            disableRipple
                            isRoundButton={true}
                            size={'smallest'}
                            color={'primary'}
                            onClick={addStory}
                        >
                            <Icon name="plus-icon" size={20} color="#ffffff" />
                        </CustomizedButton>
                    }
                />
            )}
        </div>
    );
};
