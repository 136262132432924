import Pptxgen from 'pptxgenjs';
import React from 'react';
import { toBlob } from 'html-to-image';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { find, sortBy, chunk } from 'lodash';
import { removeHtmlTagsFromString, sortNumberArray, ImageExists } from 'utilitys/helpers/general';
import {
    getStatusColorHex,
    getStatusColorHexByLaneId,
    getStatusLabel,
} from 'components/Dashboards/Project/Components/VersionCompare/TableColumns/TableColumns.utils';
import {
    IChildRefs,
    IMultipleDataStructureSortedList,
} from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import {
    IAssignee,
    IStatus,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import { blobToURL } from 'components/Dashboards/Project/Components/DataStories/dataStories.utils';
import { ICardLastComment } from 'components/common/Tables/Cells/DetailsCell';

type ConvertComponentToImageArgs = {
    elementRef: React.RefObject<HTMLDivElement>;
    widgetTitle: string;
    subTitle: string;
};
type IConvertComponentsToPPTXArgs = {
    elementRef: React.RefObject<IChildRefs[]>;
    widgetTitle: string;
    logoSRC?: string;
    linkToWidget?: string;
};

export const convertComponentToImage = ({ elementRef, widgetTitle, subTitle }: ConvertComponentToImageArgs) => {
    const element = elementRef.current;
    if (element) {
        toBlob(element).then((dataUrl) => {
            const blob = blobToURL(dataUrl);
            const link = document.createElement('a');
            link.download = `${widgetTitle}_${subTitle}.jpg`;
            link.href = blob;
            link.click();
        });
    }
};

export const convertComponentsToPPTX = async ({
    elementRef,
    widgetTitle,
    logoSRC,
    linkToWidget,
}: IConvertComponentsToPPTXArgs) => {
    const sorted = sortBy(elementRef?.current, 'order');

    const pres = new Pptxgen();
    const promises: Promise<Blob | null>[] = [];

    sorted?.forEach((element) => {
        if (element.el) {
            const imagePromise = toBlob(element.el);
            promises.push(imagePromise);
        }
    });
    const isLogoExists = logoSRC ? await ImageExists(logoSRC) : false;

    try {
        const results = await Promise.all(promises);
        results.forEach((result, index) => {
            const blob = blobToURL(result);
            const elObj: IChildRefs | undefined = find(elementRef?.current, { order: index });
            const el = elObj?.el;
            const subText = elObj?.subTitle || '';
            const title = elObj?.title || widgetTitle;
            if (el && result) {
                const width = el.clientWidth;
                const height = el.clientHeight;
                const scale = window.devicePixelRatio;
                const slide = pres.addSlide();
                slide.addImage({
                    path: blob,
                    y: '15%',
                    x: '10%',
                    altText: 'Powered by Foresight',
                    w: width / 196 / scale,
                    h: height / 196 / scale,
                    sizing: { type: 'contain', w: '80%', h: '75%' },
                });
                slide.addImage({
                    path: require('styles/assets/png/foresight_logo.png'),
                    x: '95%',
                    y: '1%',
                    sizing: { type: 'contain', w: '5%', h: '5%' },
                });

                isLogoExists &&
                    slide.addImage({
                        path: logoSRC,
                        x: '1%',
                        y: '1%',
                        sizing: { type: 'contain', w: '5%', h: '5%' },
                    });
                slide.addText(title, {
                    x: '10%',
                    y: '5%',
                    color: '363636',
                    fill: { color: 'F1F1F1' },
                    align: pres.AlignH.center,
                });

                slide.addText(subText, {
                    x: '10%',
                    y: '10%',
                    color: '363636',
                    fill: { color: 'F1F1F1' },
                    fontSize: 14,
                    align: pres.AlignH.center,
                });
                slide.addText('Click here to open in Foresight', {
                    x: '10%',
                    y: '95%',
                    color: '#0080ff',
                    fill: { color: '#0080ff' },
                    align: pres.AlignH.center,
                    fontSize: 10,
                    hyperlink: {
                        url: `${linkToWidget}#${el?.id}`,
                    },
                });

                slide.background = { color: 'F1F1F1' };
            }
        });
        pres.writeFile({ fileName: `${widgetTitle}.pptx` }).catch(() => {
            throw 'Error creating pinboard to pptx.';
        });
    } catch {
        throw 'Error exporting pinboard to pptx.';
    }
};

export interface IConvertTableToPPTXProps<T> {
    elementRef: React.RefObject<HTMLDivElement>;
    widgetTitle: string;
    logoSRC: string | undefined;
    linkToWidget: string;
    pptxData: T[][];
    subTitle: string;
}

export const convertTableToPPTX = async ({
    elementRef,
    widgetTitle,
    logoSRC,
    linkToWidget,
    pptxData,
    subTitle,
}: IConvertTableToPPTXProps<IDataStructureTableToPPTXItem>) => {
    const element = elementRef.current;
    const pres = new Pptxgen();
    const isLogoExists = logoSRC ? await ImageExists(logoSRC) : false;

    pptxData.forEach((pptxel) => {
        const slide = pres.addSlide();

        slide.addTable(<any>pptxel, {
            align: 'left',
            w: 9,
            colW: [2, 2, 5],
            border: { color: '#ffffff' },
            fontSize: 8,
            autoPage: true,
            autoPageRepeatHeader: true,
            y: 1.2,
        });

        slide.addImage({
            path: require('styles/assets/png/foresight_logo.png'),
            x: '95%',
            y: '1%',
            sizing: { type: 'contain', w: '5%', h: '5%' },
        });
        isLogoExists &&
            slide.addImage({
                path: logoSRC,
                x: '1%',
                y: '1%',
                sizing: { type: 'contain', w: '5%', h: '5%' },
            });

        slide.addText(widgetTitle, {
            x: '10%',
            y: 0.3,
            color: '363636',
            fill: { color: 'F1F1F1' },
            align: pres.AlignH.center,
        });
        slide.addText(subTitle, {
            x: '10%',
            y: 0.6,
            color: '363636',
            fill: { color: 'F1F1F1' },
            fontSize: 14,
            align: pres.AlignH.center,
        });

        slide.addText('Click here to open in Foresight', {
            x: '10%',
            y: 0.9,
            color: '#0080ff',
            fill: { color: '#0080ff' },
            align: pres.AlignH.center,
            fontSize: 10,
            hyperlink: {
                url: `${linkToWidget}#${element?.id}`,
            },
        });
        slide.background = { color: 'F1F1F1' };
    });
    await pres.writeFile({ fileName: `${widgetTitle}.pptx` }); //TODO: add fileName property so the file name will be from outside and not calculated
};

type Header = {
    key: string;
    name: string;
};
export const prepareDataForExportCsv = ({ tableInstance }) => {
    const tableHeaders = tableInstance.visibleColumns;
    const datesTimestampProps = [
        'currentFinishDate',
        'currentStartDate',
        'plannedFinishDate',
        'plannedStartDate',
        'actualFinishDate',
        'actualStartDate',
        'finish_date',
        'finishDate',
        'activityStartDate',
        'activityEndDate',
        'successorActivityStartDate',
        'successorActivityEndDate',
        'baselineStart',
        'baselineFinish',
    ];

    const headers: Header[] = tableHeaders
        ? tableHeaders.map((item) => ({
              key: item.id,
              name: item.Header.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, ' '),
          }))
        : [];
    const items: any[] = tableInstance.sortedRows.map((item) => {
        const row = {};
        headers.forEach((header) => {
            const key = header.key;
            const value = item.values[key];
            row[key] =
                value && datesTimestampProps.includes(key)
                    ? moment(value).format(constants.formats.date.default)
                    : value;
        });
        return row;
    });

    return { headers, items };
};

interface IDataStructureTableToPPTXItem {
    text: string;
    options: any;
}

interface MultipleDataStructureProps {
    data:
        | {
              periodItemsList?: {
                  id: string;
                  summary: {
                      endDate: number;
                      id: string;
                      items: number;
                      percent: number;
                      startDate: number;
                      title: string;
                  };
                  itemsList: {
                      latestVersion: IMultipleDataStructureSortedList[];
                  }[];
              }[];
          }
        | undefined;

    sortBy: { key?: keyof IMultipleDataStructureSortedList[]; sortOrder: string };
    headers: IDataStructureTableToPPTXItem[];
}
// TODO: fix function so it will be much more simple - not bound to specific data (data.periodItemsList ?!?!?)
export const serializedMultipleDataStructureTableToPPTX = ({
    data,
    sortBy,
    headers,
}: MultipleDataStructureProps): IDataStructureTableToPPTXItem[][] | undefined => {
    if (!data) return undefined;
    if (!data.periodItemsList) return undefined;
    const prevData = data.periodItemsList.find((item) => item.id === 'prev');
    const allData = data.periodItemsList.find((item) => item.id === 'all');
    const tableDataArr = allData ? allData.itemsList : prevData?.itemsList;

    const convertToOneLevelArr = tableDataArr
        ? tableDataArr.map((item) => {
              return item.latestVersion;
          })
        : [];
    const sortedList: IMultipleDataStructureSortedList = sortNumberArray(
        convertToOneLevelArr,
        sortBy?.key,
        sortBy?.sortOrder,
    );

    const arrTabRows = [headers];

    sortedList.forEach((item, index) => {
        const isEven = index % 2;
        const activity = `${item.activityName}\n(${item.activityId})`;
        const status = `${getStatusLabel(item.activityStatus)} | ${item.assignee?.firstName} ${
            item.assignee?.lastName
        }\n(${moment(item.startDate).format(constants.formats.date.default)} - ${moment(item.finishDate).format(
            constants.formats.date.default,
        )})`;

        const lastComment = item.cardLastComment
            ? `${removeHtmlTagsFromString(item.cardLastComment.lastComment)}\nLatest comment, ${moment(
                  item.cardLastComment.lastCommentCreatedDate,
              ).format(constants.formats.date.fullDateWithMilliSeconds)}, by ${
                  item.cardLastComment.lastCommentFirstUserName
              } ${item.cardLastComment.lastCommentLastUserName}`
            : null;

        const narrative = lastComment || removeHtmlTagsFromString(item.cardDetails);

        arrTabRows.push([
            {
                text: activity,
                options: <any>{ fill: { color: isEven === 0 ? '#CFD5EA' : '#E9EBF5' }, breakLine: true },
            },
            {
                text: status,
                options: <any>{
                    fill: { color: isEven === 0 ? '#CFD5EA' : '#E9EBF5' },
                    breakLine: true,
                    color: getStatusColorHex(item.activityStatus),
                },
            },
            {
                text: narrative,
                options: <any>{ fill: { color: isEven === 0 ? '#CFD5EA' : '#E9EBF5' } },
            },
        ]);
    });
    return chunk(arrTabRows, 7);
};

interface IOneLevelDataStructureProps {
    data: IOneLevelDataStructureSortedList[] | undefined;
    sortBy: { key: string; sortOrder: string };
    headers: IDataStructureTableToPPTXItem[];
}

interface IOneLevelDataStructureSortedList {
    id: string | number;
    title: string;
    status: IStatus;
    startDate: number;
    endDate: number;
    details: string;
    cardLastComment: null | ICardLastComment;
    assignee: IAssignee;
}

export const serializedOneLevelDataStructureTableToPPTX = ({
    data,
    sortBy,
    headers,
}: IOneLevelDataStructureProps): IDataStructureTableToPPTXItem[][] | undefined => {
    const sortedList: IOneLevelDataStructureSortedList[] = data
        ? sortNumberArray(data, sortBy.key, sortBy.sortOrder)
        : [];
    const arrTabRows = [headers];
    sortedList.forEach((item, index) => {
        const isEven = index % 2;

        const activityId = `C-${typeof item.id === 'string' ? item.id.split('_')[0] : item.id}`;
        const activity = `${item.title}\n(${activityId})`;
        const status = `${item.status.label} | ${item.assignee.firstName} ${item.assignee.lastName}\n(${moment(
            item.startDate,
        ).format(constants.formats.date.default)} - ${moment(item.endDate).format(constants.formats.date.default)})`;

        const lastComment = item.cardLastComment
            ? `${removeHtmlTagsFromString(item.cardLastComment.lastComment)}\nLatest comment, ${moment(
                  item.cardLastComment.lastCommentCreatedDate,
              ).format(constants.formats.date.fullDateWithMilliSeconds)}, by ${
                  item.cardLastComment.lastCommentFirstUserName
              } ${item.cardLastComment.lastCommentLastUserName}`
            : null;

        const narrative = lastComment || removeHtmlTagsFromString(item.details);

        arrTabRows.push([
            {
                text: activity,
                options: <any>{ fill: { color: isEven === 0 ? '#CFD5EA' : '#E9EBF5' }, breakLine: true },
            },
            {
                text: status,
                options: <any>{
                    fill: { color: isEven === 0 ? '#CFD5EA' : '#E9EBF5' },
                    breakLine: true,
                    color: getStatusColorHexByLaneId(item.status.id),
                },
            },
            {
                text: narrative,
                options: <any>{ fill: { color: isEven === 0 ? '#CFD5EA' : '#E9EBF5' } },
            },
        ]);
    });
    return chunk(arrTabRows, 7);
};

export const pptxTableNarrativeHeaders: IDataStructureTableToPPTXItem[] = [
    {
        text: 'Activity\n(ID)',
        options: { color: '#ffffff', fill: { color: '#4472c4' }, breakLine: true },
    },
    {
        text: 'Status | Assignee\n(Dates)',
        options: { color: '#ffffff', fill: { color: '#4472c4' }, breakLine: true },
    },
    {
        text: 'Narrative',
        options: { color: '#ffffff', valign: 'middle', fill: { color: '#4472c4' } },
    },
];
