import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useMutation, useQuery } from 'react-query';
import qs from 'qs';

const getKeyActivitiesTags = (params) => {
    const querystring = qs.stringify({ contractId: params.contractId }, { addQueryPrefix: true, skipNulls: true });
    return network
        .get(`${API_URL().GET_KEY_ACTIVITIES_TAGS}${querystring}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getKeyActivities = (params) => {
    return network
        .post(API_URL().GET_KEY_ACTIVITIES, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const setKeyActivitiesTags = (params) => {
    return network
        .post(API_URL().SET_KEY_ACTIVITIES_TAGS, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetKeyActivities = (params) => {
    return useQuery<IGetKeyActivities, QueryError>(['getKeyActivities', params], () => getKeyActivities(params), {
        enabled: Boolean(params.compareVersionId) && Boolean(params.latestVersionId) && params.tagIdList.length > 0,
    });
};

export const useMutationsSetKeyActivitiesTags = () => {
    return useMutation<any, QueryError, unknown>((params) => setKeyActivitiesTags(params));
};

export const useQueryGetKeyActivitiesTags = (params) => {
    return useQuery<{ id: number; contractId: number; tagId: number }[], QueryError>(
        ['getKeyActivitiesTags', params],
        () => getKeyActivitiesTags(params),
        {
            enabled: Boolean(params.contractId),
        },
    );
};

interface IGetKeyActivitiesList {
    id: string;
    summary: null | string;
    itemsList: any[];
}

interface IGetKeyActivities {
    info: IToolTip;
    periodItemsList: IGetKeyActivitiesList[];
}
