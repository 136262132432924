import { IWBSResponse } from 'components/common/GlobalFilterComponent/queries/GlobalFilterComponentQuery';
const MAX_LENGTH = 90;
export const mapper = (item: IWBSResponse): { id: number; label: string; value: string } => {
    const label = `${item.name} (ID: ${item.activity_id})`;
    const finalLabel = label.length > MAX_LENGTH ? label.slice(0, MAX_LENGTH) + '...)' : label;
    return {
        id: item.id,
        label: finalLabel,
        value: String(item.id),
    };
};
