import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/AssociatedDocuments/addAssociatedDocuments/addAssociatedDocuments.module.scss';
import { isValidUrl } from 'utilitys/helpers/general';
import { CustomizedButton, CustomizedFormErrorMessage, CustomizedModalBase } from 'components/common';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { Controller, useForm } from 'react-hook-form';

interface IFields {
    title: string;
    url: string;
}

interface IAddAssociatedDocuments {
    handleClose: () => void;
    handleAdd: (value: IFields) => void;
    showAddAssociatedDocuments: boolean;
}

const AddAssociatedDocuments = ({ handleClose, handleAdd, showAddAssociatedDocuments }: IAddAssociatedDocuments) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm<IFields>({
        defaultValues: {
            title: '',
            url: '',
        },
    });

    const handleCancel = () => {
        reset();
        handleClose();
    };

    const handleSave = (data) => {
        handleAdd(data);
        reset();
    };

    return (
        <CustomizedModalBase
            modalStyles={{
                width: '450px',
            }}
            isModalOpen={showAddAssociatedDocuments}
            handleModalClose={handleCancel}
        >
            <form
                className={classes.addAssociatedDocumentsContainer}
                onSubmit={(e) => {
                    e.stopPropagation();
                    return handleSubmit(handleSave)(e);
                }}
            >
                <div className={classes.title}>Add Associated Document</div>
                <div className={classes.inputsWrapper}>
                    <div>
                        <div className={classes.inputTitle}>External Link Title</div>

                        <Controller
                            render={({ field }) => (
                                <TextSearchInput
                                    {...field}
                                    isSearch={false}
                                    placeholder=""
                                    maxLength={50}
                                    handleClear={() => {
                                        setValue('title', '');
                                    }}
                                    handleChange={(e) => setValue('title', e.target.value)}
                                />
                            )}
                            name="title"
                            control={control}
                            rules={{
                                validate: {
                                    min: (value) => (value && value.length > 1) || 'must be at least 2 characters',
                                },
                            }}
                        />
                        {errors.title && <CustomizedFormErrorMessage text={errors.title.message} />}
                    </div>
                    <div>
                        <div className={classes.inputTitle}>External Link target</div>
                        <Controller
                            render={({ field }) => (
                                <TextSearchInput
                                    {...field}
                                    isSearch={false}
                                    placeholder=""
                                    maxLength={50}
                                    handleClear={() => {
                                        setValue('url', '');
                                    }}
                                    handleChange={(e) => setValue('url', e.target.value)}
                                />
                            )}
                            name="url"
                            control={control}
                            rules={{
                                validate: {
                                    min: (value) => isValidUrl(value.trim()) || 'invalid link',
                                },
                            }}
                        />
                        {errors.url && <CustomizedFormErrorMessage text={errors.url.message} />}
                    </div>
                </div>

                <div className={classes.footer}>
                    <div className={classes.container}>
                        <CustomizedButton size={'large'} color={'secondary'} onClick={handleCancel}>
                            Cancel
                        </CustomizedButton>

                        <CustomizedButton size={'large'} color={'primary'} type={'submit'}>
                            Add
                        </CustomizedButton>
                    </div>
                </div>
            </form>
        </CustomizedModalBase>
    );
};

export default AddAssociatedDocuments;
