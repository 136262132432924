import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface IUseAnnotationStore {
    isShowCriticalityAnnotation: boolean;
    setIsShowCriticalityAnnotation: (isShowCriticalityAnnotation: boolean) => void;
    isShowActivitiesAnnotation: boolean;
    setIsShowActivitiesAnnotation: (isShowActivitiesAnnotation: boolean) => void;
}
const annotationStore = (set) => ({
    isShowCriticalityAnnotation: false,
    setIsShowCriticalityAnnotation: (isShowCriticalityAnnotation) => set({ isShowCriticalityAnnotation }),
    isShowActivitiesAnnotation: false,
    setIsShowActivitiesAnnotation: (isShowActivitiesAnnotation) => set({ isShowActivitiesAnnotation }),
});

export const useAnnotationStore = create(
    persist<IUseAnnotationStore>(annotationStore, { name: 'S-CurveAnnotationStore' }),
);
