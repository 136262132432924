import React from 'react';
import { Router, Switch } from 'react-router-dom';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import Zoomline from 'fusioncharts/fusioncharts.zoomline';
import Powercharts from 'fusioncharts/fusioncharts.powercharts';
import constants from '../common/Constants/constants';
import { createBrowserHistory } from 'history';
import { PublicRoutes } from 'components/App/routes/publicRoutes';
import AuthenticateLayout from 'components/AuthenticateLayout/authenticateLayout';
import useUserHook from 'hooks/useUserHook';
import ProtectedRoute from 'components/App/routes/ProtectedRoute';
import { useThemeStatus } from 'components/Header/zustand_store/themeStatus';
import { SnackbarWrapper } from 'utilitys/SnackbarWrapper';

ReactFC.fcRoot(FusionCharts, Charts, Zoomline, FusionTheme, Powercharts);

FusionCharts.options['license']({
    key: constants.fusionChart.key,
    creditLabel: false,
});

require('./app.scss');

/**
 *
 * App
 *
 * This component is the skeleton around the actual Views.
 */

// ================ theme ====================================================================================
useThemeStatus.getState().themeStatus
    ? document?.querySelector('body')?.setAttribute('data-theme', 'dark')
    : document?.querySelector('body')?.setAttribute('data-theme', 'light');
// ===========================================================================================================

export const history = createBrowserHistory();

const App = () => {
    const { email } = useUserHook();
    const auth = Boolean(email);

    return (
        <Router history={history}>
            <SnackbarWrapper>
                <Switch>
                    {PublicRoutes()}
                    <ProtectedRoute path="/" auth={auth} component={AuthenticateLayout} />
                </Switch>
            </SnackbarWrapper>
        </Router>
    );
};

export default App;
