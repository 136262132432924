import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/customCard/customCard.module.scss';
import { Pills } from 'components/common/pills/pills';
import Icon from 'components/common/Icons/icon';
import moment from 'moment-timezone';
import React from 'react';
import { CustomizedRoundProgressBar } from 'components/common/CustomizedRoundProgressBar/CustomizedRoundProgressBar';
import { CustomizedTooltip } from 'components/common';
import constants from 'components/common/Constants/constants';

export const CustomCardMain = ({
    isStartOverdue,
    startDate,
    isFinishOverdue,
    endDate,
    durationToFixed,
    floatToFixed,
    submittedDurationComplete,
    type,
}) => {
    return (
        <div data-testid={'custom-card-main'} className={classes.main}>
            <Pills type={isStartOverdue && 'risk'}>
                <Icon name="clock" size="1.2rem" color="#fd0d1b" />
                <span>Start: {moment(startDate).format(constants.formats.date.default)}</span>
            </Pills>

            <Pills type={isFinishOverdue && 'risk'}>
                <Icon name="clock" size="1.2rem" color="#fd0d1b" />
                <span>End: {moment(endDate).format(constants.formats.date.default)}</span>
            </Pills>

            {(floatToFixed || durationToFixed) && (
                <Pills>
                    {durationToFixed} {floatToFixed}
                </Pills>
            )}

            {type.id === 2 && !isNaN(Number(submittedDurationComplete)) && (
                <CustomizedTooltip
                    tooltipContent={`Submitted Completion: ${submittedDurationComplete}%`}
                    triggerElement={<CustomizedRoundProgressBar value={Number(submittedDurationComplete)} />}
                />
            )}
        </div>
    );
};
