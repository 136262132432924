import { ConfirmSaveModal, CustomizedButton, CustomizedModalBase } from 'components/common';
import classes from './bulkDeleteAction.module.scss';
import React, { useState } from 'react';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useMutationBulkDeleteAction } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/bulkUserMutation';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { ICountersDataResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
import { getObjectSumValues } from 'utilitys/helpers/general';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';

interface Props {
    isShowModal: boolean;
    handleModalClose: () => void;
    cardsCounters?: Record<keyof ICountersDataResponse, number>;
    setIsBulkLoading: React.Dispatch<React.SetStateAction<boolean>>;
    searchedFilterData: any;
    bulkDeleteCardsUniqIdNotification: number;
}

export const BulkDeleteAction = ({
    isShowModal,
    handleModalClose,
    cardsCounters,
    setIsBulkLoading,
    searchedFilterData,
    bulkDeleteCardsUniqIdNotification,
}: Props) => {
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate } = useMutationBulkDeleteAction();

    const { sendEventWithDimensions } = useEventWithDimensions();

    const handleConfirmModal = () => {
        if (getObjectSumValues(cardsCounters) === 0) {
            handleSnackBar('You cannot run a bulk delete on zero activities. Update your filter.', 'warning');
        } else {
            setConfirmModal(true);
        }
    };

    const handleDeleteAction = () => {
        const params = {
            ...searchedFilterData,
            uniqIdNotification: bulkDeleteCardsUniqIdNotification,
        };

        mutate(params, {
            onSuccess: () => {
                setIsBulkLoading(true);
                sendEventWithDimensions({
                    category: 'Battlecards',
                    action: 'Bulk Delete',
                    label: `filter: ${JSON.stringify(params.filter)}`,
                });
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Bulk request Failed', 'error');
            },
            onSettled: () => {
                closeModal();
            },
        });
    };

    const closeModal = () => {
        handleModalClose();
        setConfirmModal(false);
    };

    return (
        <>
            <CustomizedModalBase isModalOpen={isShowModal} handleModalClose={closeModal}>
                <div className={classes.bulkDeleteActionContent}>
                    <div className={classes.title}>Bulk Delete - User Actions & Recommendations</div>
                    <div className={classes.selectTitle}>
                        Delete User Actions & Recommendations matching the current filter
                    </div>

                    <div className={classes.note}>(Note only User Actions / Recommendations will be deleted)</div>

                    <div className={classes.footer}>
                        <div className={classes.container}>
                            <CustomizedButton size={'large'} color={'secondary'} onClick={closeModal}>
                                Cancel
                            </CustomizedButton>

                            <CustomizedButton size={'large'} color={'error'} onClick={handleConfirmModal}>
                                Delete
                            </CustomizedButton>
                        </div>
                    </div>
                </div>
            </CustomizedModalBase>

            <ConfirmSaveModal
                isShowModal={confirmModal}
                onHideModal={() => setConfirmModal(false)}
                onConfirm={handleDeleteAction}
                actionButtonLabel={'Yes'}
            >
                Are you sure you want to delete {getObjectSumValues(cardsCounters).toLocaleString()} battlecards?
            </ConfirmSaveModal>
        </>
    );
};
