import classes from './templateProjectsComponent.module.scss';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import React, { useState } from 'react';
import Icon from 'components/common/Icons/icon';
import { SingleProject } from 'components/Dashboards/Portfolio/phasePerformance/manage/singleTemplateComponent/templateProjectsComponent/singleProject/singleProject';
import { IProject } from 'components/Dashboards/Portfolio/phasePerformance/manage/manage.types';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';
import { Link } from 'react-router-dom';

export const TemplateProjectsComponent = ({ projects, templateId }: { projects: IProject[]; templateId: number }) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchWord, setSearchWord] = useState<string>('');

    const handleSearchInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleOnSearch = () => {
        setSearchWord(searchValue);
    };

    const onKeyPress = (e) => {
        if (e.charCode === 13) {
            handleOnSearch();
        }
    };

    const mappedProjects =
        searchWord.length > 0
            ? projects.filter((item) => item.project_name.toLowerCase().includes(searchWord.toLowerCase()))
            : projects;

    return (
        <div className={classes.templateProjectsContainer}>
            <div className={classes.title}>Template Mappings</div>
            <div className={classes.topContent}>
                <div className={classes.searchInputContainer}>
                    <TextSearchInput
                        placeholder="Search Existing Mappings..."
                        value={searchValue}
                        handleChange={handleSearchInputChange}
                        handleClear={(e) => {
                            setSearchValue('');
                            setSearchWord('');
                            e.stopPropagation();
                        }}
                        handleClick={handleOnSearch}
                        onKeyPress={onKeyPress}
                    />
                </div>
                <Link
                    className={classes.linkStyle}
                    to={`${portfolioConfig.phasePerformanceManage.link}/${portfolioConfig.phasePerformanceManageMapProject.link}?templateId=${templateId}`}
                >
                    <CustomizedTooltip
                        tooltipContent={'Add Mapping'}
                        triggerElement={
                            <CustomizedRoundIcon backGroundColor={'#2C7D90'}>
                                <Icon name={'plus-icon'} size="1.4rem" color={'#fff'} />
                            </CustomizedRoundIcon>
                        }
                    />
                </Link>
            </div>
            <div className={classes.projectsWrapper}>
                {mappedProjects.map((item, index) => (
                    <SingleProject key={index} project={item} templateId={templateId} />
                ))}
            </div>
        </div>
    );
};
