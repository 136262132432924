import classes from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters.module.scss';
import { Pills } from 'components/common/pills/pills';
import { FilterLabels } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { IWidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

const maxLength = 10;
/**
 * Dashboard Widget Filters (only view mode).
 * @param {string}  widgetId - unique ID created when adding a new widget to a specific pinboard.
 * @param {IFilterLegacy[]}  filters - list of filters used in the API for fetching data.
 * @returns Widget Filters JSX Component using Pills
 * */
export const WidgetFilters = ({ widgetId, filters }: IWidgetFilters) => {
    return (
        <div className={classes.widgetFiltersContainer}>
            {filters.map((filter) => {
                const name = filter.name;
                const data = filter.data || [];
                if (data.length === 0) return null;
                return (
                    <div key={`${name}-${widgetId}`} className={classes.filtersContainer}>
                        <span key={`title-${name}-${widgetId}`} className={classes.title}>
                            {FilterLabels[name] || name}:
                        </span>
                        <span key={`filters-${name}-${widgetId}`} className={classes.value}>
                            {data.slice(0, maxLength).map((item, index) => {
                                return (
                                    <Pills key={`filters-value-${name}-${widgetId}-${index}`} className={classes.pill}>
                                        {item}
                                    </Pills>
                                );
                            })}
                            <div className={classes.more} title={name}>
                                {data.length > maxLength && `& ${data.length - maxLength} more...`}
                            </div>
                        </span>
                    </div>
                );
            })}
        </div>
    );
};
