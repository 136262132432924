import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryActivityCompletionPlan } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/queries/activityCompletionPlanQuery';
import { generateTabs } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/utils/helper';
import classes from 'components/Dashboards/Project/Components/VersionCompare/style/Table.module.scss';
import { CustomizedTabs } from 'components/common/CustomizedTabs/CustomizedTabs';
import { columns, initialState } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/utils/utils';
import { CustomizedRoundIcon, CustomizedTooltip, LoaderContainer } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { IComponentProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import { prepareVersionSelectTitle } from 'components/common/TreeProgramFilter/VersionSelect/versionSelect.utils';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import { FragnetSummary } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/components/fragnetSummary';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import {
    activityTypeOptions,
    statusOptions,
    SortFilterPanel,
    isCriticalPathFilter,
    submittedDurationFilter,
} from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/components/sortFilterPanel';
import { useMutationSetWidgetColumns } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySelectedTableColumns';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import NoData from 'components/common/NoData/noData';

export const ActivitiesPredecessors = ({
    contractId,
    latestVersionId,
    projectId,
    widgetId,
    filter,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
    globalFilterData,
    localFilters,
    hashcode,
}: IComponentProps) => {
    const tableRef = useRef(null);
    const id = 'activitiy-predecessors';
    const elementId = 'activitiy-predecessors';
    const componentKey = 'activityPredecessors';
    const [activeTab, setActiveTab] = useState<string>('blocking');
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const [activityTypeFilter, setActivityTypeFilter] = useState<ISelectOption<string>[]>(
        widgetId && localFilters && localFilters.activityTypeFilter
            ? localFilters.activityTypeFilter
            : activityTypeOptions,
    );

    const [statusFilter, setStatusFilter] = useState<ISelectOption<string>[]>(
        widgetId && localFilters && localFilters.statusFilter ? localFilters.statusFilter : statusOptions,
    );

    const [isCriticalPath, setIsCriticalPath] = useState<boolean>(
        Boolean(widgetId && localFilters && localFilters.isCriticalPath),
    );

    const minValue =
        widgetId && localFilters?.submittedCompletionFilter ? localFilters.submittedCompletionFilter[0] : 0;
    const maxValue =
        widgetId && localFilters?.submittedCompletionFilter ? localFilters.submittedCompletionFilter[1] : 100;

    const submittedCompletionDefaultValue = [minValue, maxValue];
    const [submittedCompletionFilter, setSubmittedCompletioniFlter] = useState<number[]>(
        submittedCompletionDefaultValue,
    );

    const handlesubmittedCompletionFilter = useCallback((value) => {
        setSubmittedCompletioniFlter(value);
    }, []);

    const filters = widgetId
        ? filter?.filters
        : getFiltersData({
              [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
          });

    const milestoneFilter = filters?.find((filter) => filter.name === FilterNames.FRAGNET_SEARCH);
    const taskVersionHashCode = milestoneFilter?.data?.[0];

    const { data, isLoading, isFetching } = useQueryActivityCompletionPlan({
        contractId,
        metaDataId: latestVersionId,
        taskVersionHashCode,
    });

    const formattedLocalFilters =
        widgetId && localFilters
            ? getFiltersData({
                  [FilterNames.activityTypeFilter]: localFilters?.activityTypeFilter?.map((item) => item.value) || [],
                  [FilterNames.OPTIONS_STATUS]: localFilters?.statusFilter?.map((item) => item.value) || [],
                  [FilterNames.CRITICAL_PATH]: [localFilters?.isCriticalPath ? 1 : 0],
                  [FilterNames.SUBMITTED_COMPLETION_CARD_BOARD]: submittedCompletionFilter,
              })
            : [];

    const mergedFilters =
        widgetId && filter?.filters
            ? {
                  filters: [...filter?.filters, ...formattedLocalFilters],
              }
            : undefined;

    const { generateFilters: formatedFilter } = useGlobalFiltersHook({
        widgetId,
        filter: mergedFilters,
        latestVersionId,
    });
    const activitiesData = data?.activity_completion_list;

    const filteredActivitiesDataData = useMemo(() => {
        if (activitiesData) {
            return activitiesData
                .filter((item) => activityTypeFilter.map((i) => i.value).includes(item.activity_type))
                .filter((item) => statusFilter.map((i) => i.value).includes(item.activity_status))
                .filter((item) => isCriticalPathFilter({ isCriticalPath: isCriticalPath, item: item }))
                .filter((item) =>
                    submittedDurationFilter({ submittedCompletionFilter: submittedCompletionFilter, item: item }),
                );
        }
        return activitiesData;
    }, [activitiesData, activityTypeFilter, statusFilter, isCriticalPath, submittedCompletionFilter]);

    const filteredData = useMemo(() => {
        if (filteredActivitiesDataData) {
            return activeTab === 'all'
                ? filteredActivitiesDataData
                : filteredActivitiesDataData.filter((item) => item[activeTab]);
        }
        return [];
    }, [data, activeTab, filteredActivitiesDataData]);
    const tabsArrData = generateTabs({ data: activitiesData, activeCount: filteredData.length, activeTab });

    const completionPlanSegment = useMemo(() => {
        if (data) {
            return tabsArrData.filter((item) => item.tabKey === activeTab)[0].filterKey;
        }
        return '';
    }, [data, activeTab]);

    const title = `${taskVersionHashCode === -1 ? 'Project Finish' : 'Activity'} Completion Plan`;
    const route = `${getStringWithoutHash(window.location.href)}#${elementId}`;
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const updatedInitialState = useTableCurrentState({ initialState, componentKey, widgetId });
    const subTitleForExport = `${excellenceAppsConfig.completionPlan.title}, ${prepareVersionSelectTitle()}`;
    const [isInstance, setIsInstance] = useState(false);
    const { mutate: mutateSetWidgetColumns } = useMutationSetWidgetColumns();
    const { handleSnackBar } = useCustomSnackBar();
    const handleInstance = (instance) => {
        if (instance) {
            tableRef.current = instance;
            setIsInstance(true); // need for render component after getting instance
        }
    };
    const setWidgetColumns = (widgetId: string) => {
        if (tableRef.current) {
            const { hiddenColumns, columnOrder } = tableRef.current.state;
            mutateSetWidgetColumns(
                { columns: { hiddenColumns, columnOrder }, widgetId },
                {
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                    },
                },
            );
        }
    };
    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle: subTitleForExport, title });
    }, [widgetId, subTitleForExport, title]);
    return (
        <WidgetWithTitle
            title={title}
            tooltip={data?.info}
            id={id}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <CustomizedTooltip
                    key={'tableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    projectId={projectId}
                    contractId={contractId}
                    elementId={elementId}
                    widgetId={widgetId}
                    route={route}
                    filters={filters}
                    callBack={setWidgetColumns}
                    localFilters={{
                        activityTypeFilter,
                        statusFilter,
                        isCriticalPath,
                        submittedCompletionFilter,
                    }}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                isInstance && (
                    <ExportDropdown
                        key={'export'}
                        title={title}
                        data={filteredData}
                        tableInstance={tableRef.current}
                        subTitle={subTitle}
                        activeTabTitle={activeTab}
                    />
                ),
            ]}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId && formatedFilter.length > 0 ? (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formatedFilter} />
                ) : null,
            ]}
        >
            <div className={classes.tableWrapperContainer}>
                {tabsArrData.length > 0 && <FragnetSummary data={data} />}
                {tabsArrData.length > 0 && (
                    <CustomizedTabs tabs={tabsArrData} activeTab={activeTab} setActiveTab={setActiveTab} />
                )}
                {!isLoading && !widgetId && tabsArrData.length > 0 && (
                    <SortFilterPanel
                        taskVersionHashCode={taskVersionHashCode}
                        completionPlanSegment={completionPlanSegment}
                        activityTypeFilter={activityTypeFilter}
                        setActivityTypeFilter={setActivityTypeFilter}
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        isCriticalPath={isCriticalPath}
                        setIsCriticalPath={setIsCriticalPath}
                        submittedCompletionDefaultValue={submittedCompletionDefaultValue}
                        handleSubmittedCompletionFilter={handlesubmittedCompletionFilter}
                        submittedCompletionFilter={submittedCompletionFilter}
                        hashcode={hashcode}
                    />
                )}

                {updatedInitialState && (
                    <MainTable
                        getInstanceCallback={handleInstance}
                        data={filteredData}
                        columns={columns}
                        initialState={updatedInitialState}
                        pageSize={10000}
                        uniqueKey={'card_board.taskId'}
                        isLoading={isLoading || isFetching}
                        noData={
                            <NoData>
                                To view Completion Plan for the <strong>Project Finish</strong> activity select the{' '}
                                <strong>first</strong> activity in fragnet filter list above.
                                <br />
                                To view Completion Plan for a <strong>specific</strong> milestone/activity select it
                                from the fragnet filter list above.
                            </NoData>
                        }
                    />
                )}
            </div>
            {tableRef?.current && (
                <CustomTableColumnsModal
                    onHideModal={() => setModalColumnsOpen(false)}
                    showModal={modalColumnsOpen}
                    widgetId={widgetId}
                    componentKey={componentKey}
                    tableInstance={tableRef.current}
                    initialState={initialState}
                    columns={columns}
                />
            )}
        </WidgetWithTitle>
    );
};
