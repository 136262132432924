import { ActivityCell } from 'components/common/Tables/Cells/ActivityCell';
import { StatusCell } from 'components/common/Tables/Cells/StatusCell';
import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import React from 'react';
import colorsVars from 'styles/colors.module.scss';
import { RowOptionsCell } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/components/cell/rowOptionsCell';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import { CustomizedRoundProgressBar } from 'components/common/CustomizedRoundProgressBar/CustomizedRoundProgressBar';
import { CustomizedTooltip } from 'components/common';
import { TrackedUntrackedActionCellWrapper } from 'components/common/TrackedUntrackedAction/trackedUntrackedActionCellWrapper/trackedUntrackedActionCellWrapper';
import { ITableColumnState } from 'hooks/useTableCurrentState';
import { BasicDateCell } from 'components/common/Tables/Cells/basicDateCell';
import { DateCell } from 'components/common/Tables/Cells/DateCell';
import { VarianceCell } from 'components/common/Tables/Cells/VarianceCell';

const { color6: orange, delayDriverColor, lightTableText, textColor } = colorsVars;

const invalidateKeys = ['activityCompletionPlan'];

export const initialState: ITableColumnState = {
    hiddenColumns: [
        'totalSuccessors',
        'criticalityScore',
        'baselineStartDate',
        'baselineFinishDate',
        'lateFinish',
        'lateStart',
    ],
    sortBy: [
        {
            id: 'remainingDuration',
        },
    ],
};

export const columns = [
    {
        Header: 'Activity',
        accessor: 'activity_name',
        ...columnsCommonConfig.activity,
        Cell: ({ row, toggleAllRowsSelected }) => {
            const activityName = row.original.activity_name;
            const activityId = row.original.activity_id;
            const taskId =
                typeof row.original.card_board.id === 'string'
                    ? row.original.card_board.id.split('_')[0]
                    : row.original.card_board.id;
            const isVirtual = row.original.card_board.isVirtual;
            const cardIndicators = row.original.card_board.cardIndicators;
            const taskVersionHashCode = row.original.card_board.taskVersionHashCode;
            const isTracked = row.original.card_board.isTracked;
            const { toggleRowSelected } = row;

            return (
                <ActivityCell
                    activityName={activityName}
                    activityId={activityId}
                    taskId={taskId}
                    cardIndicators={cardIndicators}
                    isVirtual={isVirtual}
                    toggleRowSelected={toggleRowSelected}
                    toggleAllRowsSelected={toggleAllRowsSelected}
                    trackedUntrackedActionCell={
                        <TrackedUntrackedActionCellWrapper
                            isTracked={isTracked}
                            taskVersionHashCode={taskVersionHashCode}
                            invalidateQueriesKey={invalidateKeys}
                        />
                    }
                />
            );
        },
    },
    {
        Header: 'Status',
        ...columnsCommonConfig.status,
        accessor: 'card_board.status.label',
        Cell: ({ row }) => {
            const assignee = row.original.card_board.assignee;
            const status = row.original.card_board.status;
            const isVirtual = row.original.card_board.isVirtual;
            const originalStatus = row.original.card_board.originalStatus;
            const taskId = row.original.card_board.id;
            const cardType = row.original.card_board.type.name;
            const category = row.original.card_board.category;

            return (
                <StatusCell
                    assignee={assignee}
                    status={status}
                    isVirtual={isVirtual}
                    taskId={taskId}
                    originalStatus={originalStatus}
                    invalidateQueriesKey={invalidateKeys}
                    cardType={cardType}
                    category={category}
                />
            );
        },
    },
    {
        Header: 'Activity Type',
        ...columnsCommonConfig.activityType,
        accessor: 'activity_type',
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },
    {
        Header: 'Remaining Duration',
        ...columnsCommonConfig.remainingDuration,
        accessor: 'remaining_duration',
    },
    {
        Header: 'Submitted Completion',
        ...columnsCommonConfig.submittedDurationComplete,
        accessor: 'card_board.submittedDurationComplete',
        Cell: ({ value }) => {
            return (
                <CustomizedTooltip
                    tooltipContent={`Submitted Completion: ${Number(value).toFixed(0)}%`}
                    triggerElement={<CustomizedRoundProgressBar value={Number(value)} />}
                />
            );
        },
    },

    {
        Header: 'Narrative',
        ...columnsCommonConfig.narrative,
        accessor: 'narrative',
    },
    {
        Header: 'Immediate Parent',
        accessor: 'parentName',
        ...columnsCommonConfig.parentName,
    },
    {
        Header: 'Grand Parent',
        accessor: 'grandParentName',
        ...columnsCommonConfig.grandParentName,
    },
    {
        Header: 'Finish Date',
        accessor: 'finish_date',
        ...columnsCommonConfig.finishDate,
        Cell: ({ value, row }) => {
            const changeMapDate = row.original.card_board.finishVariance;
            const comparedVersion = row.original.card_board.baselineFinishDate;
            return (
                <DateCell
                    latestVersionDate={value}
                    changeMapDate={changeMapDate}
                    comparedVersion={comparedVersion}
                    isChangeMap={true}
                />
            );
        },
    },

    {
        Header: 'Float',
        ...columnsCommonConfig.float,
        accessor: 'card_board.float',
    },
    {
        Header: 'Successors In Fragnet',
        ...columnsCommonConfig.successorsInFragnet,
        accessor: 'successors_in_fragnet',
    },

    {
        Header: 'Total Successors',
        ...columnsCommonConfig.totalSuccessors,
        accessor: 'successors_num',
    },

    {
        Header: 'Critical Path',
        ...columnsCommonConfig.criticalPath,
        accessor: (row) => (row.card_board?.cardIndicators?.isCriticalPath?.isIndicatorOn ? 'Yes' : 'No'),
    },
    {
        Header: 'Criticality Score',
        ...columnsCommonConfig.criticalityScore,
        accessor: 'card_board.criticalityScore',
        Cell: ({ value, row }) => {
            const delayDriver = row.original.delaying;
            const color = delayDriver ? delayDriverColor : lightTableText;
            return <span style={{ color }}>{value}</span>;
        },
        sortType: 'basic',
    },
    {
        Header: 'Baseline Start Date ',
        accessor: 'card_board.baselineStartDate',
        ...columnsCommonConfig.baselineStartDate,
        Cell: ({ value }) => {
            return <BasicDateCell value={value} />;
        },
    },
    {
        Header: 'Baseline Finish Date',
        accessor: 'card_board.baselineFinishDate',
        ...columnsCommonConfig.baselineFinishDate,
        Cell: ({ value }) => {
            return <BasicDateCell value={value} />;
        },
    },
    {
        Header: 'Start Variance',
        accessor: 'card_board.startVariance',
        ...columnsCommonConfig.lateStart,
        Cell: ({ value }) => {
            return <VarianceCell value={value} />;
        },
        sortType: 'basic',
    },
    {
        Header: 'Finish Variance',
        accessor: 'card_board.finishVariance',
        ...columnsCommonConfig.lateFinish,
        Cell: ({ value }) => {
            return <VarianceCell value={value} />;
        },
    },
    {
        Header: '',
        ...columnsCommonConfig.rowOptions,
        width: 50,
        Cell: ({ row }) => {
            const { taskId, id: cardId, isVirtual } = row.original.card_board;
            return <RowOptionsCell taskId={taskId} cardId={cardId} isVirtual={isVirtual} />;
        },
    },
];
