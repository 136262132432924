import { CustomizedConfirmDeleteModal, CustomizedRoundIcon } from 'components/common';
import Icon from 'components/common/Icons/icon';
import { useState } from 'react';
import classes from './rowOptionsCell.module.scss';
import { useMutationDeleteAlias } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/queries/useQueryAliasPreferences';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryClient } from 'react-query';
import { EditAlias } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/AliasPreferences/EditAlias/editAlias';

export const RowOptionsCell = ({ row, data }): JSX.Element => {
    const [showEditAliasModal, setShowEditAliasModal] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const { handleSnackBar } = useCustomSnackBar();
    const mutation = useMutationDeleteAlias();
    const queryClient = useQueryClient();

    const handleEditAlias = () => {
        setShowEditAliasModal(true);
    };

    const handleDelete = () => {
        mutation.mutate(row.original?.id, {
            onSuccess: () => {
                handleSnackBar('Successfully deleted!', 'success');
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong, please try again', 'error');
            },
            onSettled: () => {
                setShow(false);
                queryClient.invalidateQueries('useQueryGetActivityCodesAliases');
            },
        });
    };

    const handleHideEditAliasModal = () => {
        setShowEditAliasModal(false);
    };

    return (
        <div className={classes.rowOptionsCellContainer}>
            <CustomizedRoundIcon onClick={handleEditAlias}>
                <Icon name={'edit-user-profile'} />
            </CustomizedRoundIcon>

            <CustomizedRoundIcon onClick={() => setShow(true)}>
                <Icon name={'BIN'} />
            </CustomizedRoundIcon>
            <CustomizedConfirmDeleteModal
                isShowModal={show}
                hideModal={() => setShow(false)}
                text={`Are you sure you want to delete this alias mapping?`}
                onConfirm={handleDelete}
                isLoading={mutation?.isLoading}
            />

            <EditAlias
                showModal={showEditAliasModal}
                onHideModal={handleHideEditAliasModal}
                data={row.original}
                tableData={data}
            />
        </div>
    );
};
