import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/components/crossProgramRelationships.module.scss';
import { ActivityCell } from 'components/common/Tables/Cells/ActivityCell';
import { DefaultColumnFilter } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryFilter/programLibraryFilter';
import { CustomizedTooltip } from 'components/common';
import { RelationTypeCell } from 'components/common/Tables/Cells/relationTypeCell';
import { TooltipCell } from 'components/common/Tables/Cells/tooltipCell';

export const initialState = {
    sortBy: [
        {
            id: 'predecessor.originalProjectId',
        },
    ],
};

export const defaultColumn = {
    Filter: DefaultColumnFilter,
};

export const columns = [
    {
        Header: () => <div className={classes.header}>Predecessor</div>,
        id: 'Predecessor',
        columns: [
            {
                Header: 'Version ID',
                accessor: 'predecessor.originalProjectId',
                width: 120,
                fixedSize: true,
            },
            {
                Header: 'Version Name',
                accessor: 'predecessor.originalProjectName',
                Cell: ({ value }) => {
                    return <TooltipCell value={value} />;
                },
            },
            {
                Header: 'Activity',
                id: 'predecessor.activityName',
                accessor: (row) => `${row.predecessor.activityName} ${row.predecessor.activityId}`,
                Cell: ({ row }) => {
                    const { taskId, activityId, activityName } = row.original.predecessor;
                    return (
                        <div className={classes.overflow}>
                            <ActivityCell activityName={activityName} activityId={activityId} taskId={taskId} />
                        </div>
                    );
                },
            },

            {
                Header: 'Relation Type',
                accessor: 'relationType',
                width: 130,
                fixedSize: true,
                Cell: ({ value }) => <RelationTypeCell value={value} />,
            },
        ],
    },
    {
        Header: () => <div className={classes.header}>Successor</div>,
        id: 'Successor',
        columns: [
            {
                Header: 'Version ID',
                accessor: 'successor.originalProjectId',
                width: 120,
                fixedSize: true,
            },
            {
                Header: 'Version Name',
                accessor: 'successor.originalProjectName',
                Cell: ({ value }) => {
                    return <TooltipCell value={value} />;
                },
            },
            {
                Header: 'Activity',
                id: 'successor.activityName',
                accessor: (row) => `${row.successor.activityName} ${row.successor.activityId}`,
                Cell: ({ row }) => {
                    const { taskId, activityId, activityName } = row.original.successor;
                    return (
                        <div className={classes.overflow}>
                            <ActivityCell activityName={activityName} activityId={activityId} taskId={taskId} />
                        </div>
                    );
                },
            },
        ],
    },
];
