import { create } from 'zustand';
import { IGlobalFilterData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';

interface IProgramCriticalAnalysis {
    dateFilter: ISelectOption<string>;
    setDateFilter: (value: ISelectOption<string>) => void;
}

const programCriticalAnalysisStore = (set) => ({
    dateFilter: {
        label: 'All Months',
        id: 0,
        value: 'All Months',
    },

    setDateFilter: (value) => set({ dateFilter: value }),
});

export const useProgramCriticalAnalysisStore = create<IProgramCriticalAnalysis>(programCriticalAnalysisStore);
