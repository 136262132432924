import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { CustomizedRoundIcon } from 'components/common/CustomizedRoundIcon/CustomizedRoundIcon';
import Icon from 'components/common/Icons/icon';
import { CustomizedButton } from 'components/common/CustomizeButton/CustomizedButton';
import classes from 'components/common/GlobalFilterComponent/GlobalFilterComponent.module.scss';
import React, { useEffect, useMemo, useState } from 'react';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { useForm } from 'react-hook-form';
import { LoaderContainer } from 'components/common/LoaderContainer/LoaderContainer';
import { createInitialState } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { useQueryStringAndStoreHook } from 'components/common/GlobalFilterComponent/hooks/useQueryStringAndStoreHook';
import { useGlobalFilterDataStore } from 'components/common/GlobalFilterComponent/store/globalFilterDataStore';

interface Props {
    children: React.ReactNode;
    defaultValues: any;
}

export interface IState {
    [key: string]: {
        isReady: boolean;
        isEmpty: boolean;
    };
}

export const GlobalFilterComponent = ({ children, defaultValues }: Props) => {
    const totalFields = useMemo(
        () => React.Children.toArray(children).filter((child) => React.isValidElement(child) && child.props.name),
        [children],
    );
    const toggleGlobalFilterComponent = useGlobalFilterDataStore((store) => store.toggleGlobalFilterComponent);
    const { setDefaultValues } = useGlobalFilterDataStore();
    const [state, setState] = useState<IState>(createInitialState(totalFields));
    const [isLoadingState, setIsLoadingState] = useState(true);
    const methods = useForm({ defaultValues });
    const { handleSubmit, watch, reset } = methods;

    useEffect(() => {
        setDefaultValues(defaultValues);
    }, [defaultValues]);

    useEffect(() => {
        let numberOfReadyFields = 0;
        let isAllEmpty = 0;

        for (const property in state) {
            if (state[property].isReady) {
                numberOfReadyFields += 1;
            }

            if (state[property].isEmpty) {
                isAllEmpty += 1;
            }
        }

        if (numberOfReadyFields === totalFields.length && isAllEmpty !== totalFields.length) {
            setIsLoadingState(false);
            onSubmit(watch());
        }

        if (isAllEmpty === totalFields.length) {
            setIsLoadingState(false);
        }
    }, [state]);

    const handleClearAll = () => {
        reset();
        onSubmit(methods.watch());
    };

    const handleFilters = () => {
        onSubmit(watch());
    };
    const [setValueQueryStringAndZustandValue] = useQueryStringAndStoreHook();
    const onSubmit = (value) => {
        setValueQueryStringAndZustandValue(value);
    };

    return toggleGlobalFilterComponent && state ? (
        <div data-testid={'global-filter-component'} className={classes.container}>
            <div className={classes.form}>
                <form onSubmit={handleSubmit(onSubmit)} className={classes.globalFilterComponentContainer}>
                    {isLoadingState && (
                        <LoaderContainer>
                            <OverlayWithSpinner />
                        </LoaderContainer>
                    )}
                    {React.Children.map(children, (child) => {
                        return React.isValidElement(child) && child.props.name
                            ? React.createElement(child.type, {
                                  ...{
                                      ...child.props,
                                      methods: methods,
                                      key: child.props.name,
                                      setState: setState,
                                      handleFilters: handleFilters,
                                  },
                              })
                            : child;
                    })}
                </form>
            </div>

            <div className={classes.actions}>
                <CustomizedButton
                    startIcon={<Icon name={'filter-clear'} size="1.5rem" color={'#2C8FA5'} />}
                    color={'secondary'}
                    onClick={handleClearAll}
                >
                    Clear Filter
                </CustomizedButton>
            </div>
        </div>
    ) : null;
};
