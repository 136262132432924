import CustomizedStatusSelect, {
    IOptionComponent,
} from 'components/common/CustomizedStatusSelect/CustomizedStatusSelect';
import { statusOptions } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import Icon from 'components/common/Icons/icon';
import classes from './UserGeneratedCardStatusSelect.module.scss';
import { IStatus } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import { Control, Controller } from 'react-hook-form';
import { IFields } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/CreateCard/CreateCard.types';
import React from 'react';

interface ICreateCardStatusSelect {
    control: Control<IFields, IFields>;
}

export const UserGeneratedCardStatusSelect = ({ control }: ICreateCardStatusSelect) => {
    return (
        <Controller
            render={({ field }) => (
                <CustomizedStatusSelect
                    options={statusOptions}
                    optionValue={field.value}
                    className={classes.statusCustomSelectContainer}
                    optionComponent={SelectItem}
                    handleChange={field.onChange}
                />
            )}
            name={`status`}
            control={control}
        />
    );
};

const SelectItem = ({ item, handleSelect }: IOptionComponent<IStatus>) => {
    return (
        <div
            className={`${classes.statusCustomSelectItem} ${classes[item.name]}`}
            onClick={(e) => {
                handleSelect(item, e);
            }}
        >
            <span>{item.label}</span>
            <div className={classes.arrowIconWrapper}>
                <Icon name="header-arrow-down" size={'20'} color="#ffffff" />
            </div>
        </div>
    );
};
