import classes from './ActionMenu.module.scss';
import React from 'react';
import { ActionMenuTooltip } from './ActionMenuTooltip';
import { getLinks, prepareLinks } from './actionMenu.utils';
import { CustomizedDropdownMenu } from 'components/common';
import CopyValue from 'components/common/CopyValue/CopyValue';
import { useProjectStore } from 'components/common/TreeProgramFilter/zustand_store/treeHeaderStore';
import { ActionMenuPending } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/actionMenuPending';
import { ActionButtonError } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ActionMenu/actionButtonError';

const ActionMenu = ({ status, versionIdList, errorMessage, isMultiple = false, id }) => {
    const { contract } = useProjectStore((store) => store.selectedProject);
    const { singleProgram, multipleWithContractProgram, multipleWithoutContractProgram } = prepareLinks(versionIdList);
    const errorTooltip = `Error loading file. Please contact support. ${errorMessage}`;
    const okTooltip = 'View Analytics';

    const CopyErrorButton = () => (
        <CopyValue
            trigger={ActionButtonError}
            value={errorTooltip}
            copySuccessMessage={'Error message copied.'}
            onHoverMessage={
                <div className={classes.errorMessage}>
                    <div> {errorTooltip} </div>
                    <div className={classes.copyErrorText}>Click the red icon to copy error message to clipboard</div>
                </div>
            }
        />
    );

    return (
        <div id={id}>
            {status.toLowerCase() === 'pending' && <ActionMenuPending />}
            {status.toLowerCase() === 'error' && <CopyErrorButton />}
            {status.toLowerCase() === 'ok' && (
                <span>
                    <CustomizedDropdownMenu
                        menuStyles={{ maxHeight: '100%' }}
                        triggerElement={<ActionMenuTooltip value={okTooltip} />}
                    >
                        {!isMultiple && getLinks(singleProgram)}
                        {isMultiple && contract !== null && getLinks(multipleWithContractProgram)}
                        {isMultiple && contract === null && getLinks(multipleWithoutContractProgram)}
                    </CustomizedDropdownMenu>
                </span>
            )}
        </div>
    );
};

export default React.memo(ActionMenu);
