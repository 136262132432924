import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';

const getGeneralList = () => {
    return network
        .get(API_URL().FETCH_PROGRAM_GENERAL_HIERARCHY_LIST)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetProgramGeneralHierarchyList = (params) => {
    return useQuery(['GetProgramGeneralHierarchyList', params], () => getGeneralList(), {
        cacheTime: 0,
    });
};
