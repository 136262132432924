import React from 'react';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.powercharts';
import ReactFC from 'react-fusioncharts';
import CandyTheme from 'fusioncharts/themes/fusioncharts.theme.candy';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { MyCustomSelect } from 'components/common';
import classes from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/RCF/RCF.module.scss';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { IClusterDataRcfDurationOrRatio } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterRCFAnalysis.type';
import { fetchDataSource } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterRCFAnalysis.utils';

interface IRCFProps {
    rcfDurationData?: IClusterDataRcfDurationOrRatio[];
    rcfDurationRatioData?: IClusterDataRcfDurationOrRatio[];
    tooltipArg?: IToolTip;
    loadingCluster: boolean;
    subTitle: string;
}

// Typing for the FusionChart's datasource, we need to explicitly type this to enable us to null out properties
type TDataSource = {
    chart: Record<any, any>;
    annotations: Object;
    trendlines: any[];
    dataset: any[];
};

const RCF = ({ rcfDurationData, rcfDurationRatioData, tooltipArg, loadingCluster, subTitle }: IRCFProps) => {
    // we need to plot both duration in days and durationRatio
    const [durationOrDurationRatio, setDurationOrDurationRatio] = React.useState<'Duration' | 'DurationRatio'>(
        'DurationRatio',
    );

    ReactFC.fcRoot(FusionCharts, Charts, CandyTheme);

    // get initial object that fusionCharts accepts as dataSource
    const dataSource: TDataSource = fetchDataSource();

    // adding data from props to dataSource object, transform it a bit first
    // see which selector the user wants to see, only transform what we need, put it into the dataSource object
    if (durationOrDurationRatio === 'Duration') {
        if (rcfDurationData) {
            dataSource.dataset[0].data = rcfDurationData.map((dataPoint) => {
                return { x: dataPoint.percentile, y: dataPoint.value };
            });
        }
    } else {
        if (rcfDurationRatioData) {
            dataSource.dataset[0].data = rcfDurationRatioData.map((dataPoint) => {
                return { x: dataPoint.percentile, y: dataPoint.value };
            });
        }
    }

    // remove 'on time' annotation, trend line if we're looking at duration in days
    // change Y title
    if (durationOrDurationRatio === 'Duration') {
        dataSource.annotations = {};
        dataSource.trendlines = [];
        dataSource.chart.yaxisname = 'Duration (days)';
    } else {
        // calculate where the "ON TIME" annotation should go if maxValue > 6 (because then our max Y axis value gets overridden by have too large data)
        // for that we need to know where '1' is in the plot
        if (rcfDurationRatioData) {
            const ratioValues = rcfDurationRatioData.map((item) => item.value);
            const maxValue = Math.max(...ratioValues);

            if (maxValue > 6) {
                // the line will be at 1/max of the plot, counted from the bottom, the bottom starts at x from the top
                // FusionCharts takes the first number of the max value, does +1 and takes that as max but fixing this corner case
                // is overkill - right now with this function the plot looks fine up to durationRatio=100
                const oneLineYfromBottomFraction = 1 / Math.round(maxValue);
                const oneLineYfromBottomPixels = oneLineYfromBottomFraction * 390;

                // now 'calibrate' it to the origin of the plot
                const calibrationFactor = 10;
                // @ts-ignore
                dataSource.annotations.groups[0].items[0].y = 390 - oneLineYfromBottomPixels + calibrationFactor;
            }
        }
    }

    const chartConfig = {
        type: 'scatter',
        width: '100%',
        height: 468,
        dataFormat: 'json',
        dataSource: dataSource,
    };

    const title = 'Cluster RCF';
    const options = [
        { id: 'Duration', label: 'Duration (days)', value: 'Duration' },
        { id: 'DurationRatio', label: ' Duration Ratio', value: 'DurationRatio' },
    ];
    const handleChange = (value) => {
        setDurationOrDurationRatio(value.id);
    };
    const handleValue = (value) => options.find((item) => item.id === value) || null;
    return (
        <WidgetWithTitle
            title={title}
            tooltip={tooltipArg}
            titleComponents={[
                <MyCustomSelect
                    options={options}
                    id={'duration-select'}
                    onChange={handleChange}
                    placeholder={'Select activity or start typing its name...'}
                    value={handleValue(durationOrDurationRatio)}
                />,

                <ExportDropdown title={title} key={'export'} subTitle={subTitle} />,
            ]}
            className={classes.RCFWidget}
        >
            <div className={classes.boxContainer}>
                <div className={classes.boxWrapper}>
                    {!loadingCluster && dataSource.dataset[0].data.length > 0 && <ReactFC {...chartConfig} />}
                    {!loadingCluster && dataSource.dataset[0].data.length === 0 && (
                        <p className={classes.text}>No actual (completed) data exists</p>
                    )}
                    {loadingCluster && <p className={classes.text}>Waiting for data...</p>}
                </div>
            </div>
        </WidgetWithTitle>
    );
};

export default RCF;
