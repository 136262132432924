import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { handleCriticalAnalysisIntensityConfig } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisMilestone/programCriticalAnalysisConfiguration';
import {
    buildCategoriesWithValidDate,
    serializeMilestonesWeightCategories,
    serializeMilestonesWeightDataset,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/utils';

const getHeatMapActivityStart = (params) => {
    return network.post(API_URL().FETCH_PROGRAM_DASHBOARD_HEATMAP_START, params).then((response) => {
        return {
            id: 3,
            title: 'Risk Density by',
            info: response.data.info,
            data: response.data.heatMapItemResponseList,
        };
    });
};

const getHeatMapActivityDuration = (params) => {
    return network.post(API_URL().FETCH_PROGRAM_DASHBOARD_HEATMAP_DURATION, params).then((response) => {
        return {
            id: 3,
            title: 'Risk Density by',
            info: response.data.info,
            data: response.data.heatMapItemResponseList,
        };
    });
};

const getHeatMapActivityStartOrFinish = (params) => {
    return network.post(API_URL().FETCH_PROGRAM_DASHBOARD_HEATMAP_START_OR_FINISH, params).then((response) => {
        return {
            id: 3,
            title: 'Risk Density by',
            info: response.data.info,
            data: response.data.heatMapItemResponseList,
        };
    });
};

const getHeatMapActivityResources = (params) => {
    return network.post(API_URL().FETCH_PROGRAM_DASHBOARD_HEATMAP_RESOURCES, params).then((response) => {
        return {
            id: 3,
            title: 'Risk Density by',
            info: response.data.info,
            data: response.data.heatMapItemResponseList,
        };
    });
};

const getCriticalityWeight = (params) => {
    const newParams = {
        actualMetaDataId: params.latestVersion,
        baselineMetaDataId: params.comparedVersion,
        filter: params.filter,
    };

    return network.post(API_URL().FETCH_PROGRAM_DASHBOARD_CRITICALITY_WEIGHT, newParams).then((response) => {
        const dataSet = serializeMilestonesWeightDataset(response.data);
        const categories = serializeMilestonesWeightCategories(dataSet);
        const data = {
            info: response.data.info,
            categories: buildCategoriesWithValidDate(categories, response.data.nowDate, dataSet),
            dataset: dataSet,
        };
        const config = handleCriticalAnalysisIntensityConfig(data);
        config.chart.showLegend = '1';
        config.annotations = response.data.nowDate;
        return config;
    });
};

const useQueryHeatMapActivityStart = (params) => {
    return useQuery(['heatMapActivityStart', params], () => getHeatMapActivityStart(params), {
        enabled: params.id.length > 0,
    });
};

const useQueryHeatMapActivityDuration = (params) => {
    return useQuery(['heatMapActivityDuration', params], () => getHeatMapActivityDuration(params), {
        enabled: params.id.length > 0,
    });
};

const useQueryHeatMapActivityStartOrFinish = (params) => {
    return useQuery(['heatMapActivityStartOrFinish', params], () => getHeatMapActivityStartOrFinish(params), {
        enabled: params.id.length > 0,
    });
};

const useQueryHeatMapActivityResources = (params) => {
    return useQuery(['heatMapActivityResources', params], () => getHeatMapActivityResources(params), {
        enabled: params.id.length > 0,
    });
};

const useQueryCriticalityWeight = (params) => {
    return useQuery(['criticalityWeight', params], () => getCriticalityWeight(params), {
        enabled: params.id.length > 0 && Boolean(params.comparedVersion) && Boolean(params.latestVersion),
    });
};

export {
    useQueryHeatMapActivityStart,
    useQueryHeatMapActivityDuration,
    useQueryHeatMapActivityStartOrFinish,
    useQueryHeatMapActivityResources,
    useQueryCriticalityWeight,
};
