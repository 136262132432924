import React from 'react';
import Select, { components, OptionProps, Options, Props, ValueContainerProps } from 'react-select';
import { orderBy } from 'lodash';
import { CheckboxComponent } from 'components/common/CheckboxComponent/checkboxComponent';
import SwitchComponent from 'components/common/Switch/Switch';
import classes from './myCustomSelect.module.scss';
import { MenuListProps } from 'react-select/dist/declarations/src/components/Menu';
import { useMyCustomSelectorStatus } from 'components/common/MyCustomSelect/zustand_store/myCustomSelectStore';
import { SelectStyles } from 'components/common/MyCustomSelect/myCustomSelect.style';
import Icon from 'components/common/Icons/icon';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import { AssigneeAvatar } from 'components/common/AssigneeAvatar/AssigneeAvatar';

interface MyCustomSelectProps extends Props {
    styles?: any;
    title?: string;
    sortOption?: { key: string; order: boolean | 'asc' | 'desc' };
    isError?: boolean | null;
}

/**
 * proxy for Select component with custom style
 * @type MyCustomSelectProps
 * @param Rest
 * @returns {JSX.Element}
 * @constructor
 */
export const MyCustomSelect = React.forwardRef<any, MyCustomSelectProps>(
    ({ sortOption, options, ...rest }, ref): JSX.Element => {
        const sortedOptions = sortOption
            ? orderBy(options, (item: any) => item[sortOption.key].toLowerCase(), sortOption.order)
            : options;
        return <Select styles={SelectStyles} options={sortedOptions} {...rest} ref={ref} />;
    },
);

export const OptionsWithExclude = (props) => {
    const { value, label } = props.data;
    const { setValue, getValue } = props;

    const selectedOptions = getValue();

    const handleExclude = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (selectedOptions.length > 2) return;

        setValue(
            [
                ...selectedOptions,
                {
                    label: `Exclude:${label}`,
                    value: {
                        name: value.name,
                        isExclude: true,
                    },
                },
            ],
            'select-option',
        );
    };

    return (
        <>
            {!selectedOptions.find((item) => item.value.name === value.name) && (
                <components.Option {...props}>
                    <div className={classes.labelWithExcludeWrapper}>
                        {label}
                        <div className={classes.hoverBox}>
                            <div data-testid={'include-text'} className={classes.includeExcludeTextHover}>Include</div>
                            <div data-testid={'exclude-text'} className={classes.includeExcludeTextHover} onClick={(e) => handleExclude(e)}>
                                Exclude
                            </div>
                        </div>
                    </div>
                </components.Option>
            )}
        </>
    );
};

export const OptionsWithAssignee = (props: OptionProps & { data: { label: string; assignee: any } }) => {
    const { assignee, label } = props.data;

    return (
        <components.Option {...props}>
            <div className={classes.labelWithAssigneeWrapper}>
                <AssigneeAvatar assignee={assignee} />
                {label}
            </div>
        </components.Option>
    );
};

export const InputOptionsWithIcon = (
    props: OptionProps & {
        data: {
            label: string;
            icon?: string;
            muiIcon?: string;
        };
    },
) => {
    const { icon, muiIcon, label } = props.data;

    const iconElement = Boolean(icon || muiIcon) ? (
        icon ? (
            <Icon name={icon} size="1.6rem" />
        ) : (
            <MuiIcon icon={muiIcon} />
        )
    ) : null;

    return (
        <components.Option {...props}>
            {iconElement ? (
                <div className={classes.labelWithIconWrapper}>
                    <div className={classes.iconWrapper}>{iconElement}</div>
                    {label}
                </div>
            ) : (
                label
            )}
        </components.Option>
    );
};

export const ValueContainer = ({ children, getValue, options, selectProps, ...rest }: ValueContainerProps) => {
    const value = getValue() as Options<ISelectOption<string>>;

    let title = '';
    if (value.length === 1) title = value[0].label;
    if (value.length === 2) title = value.map((item) => item.label).join(', ');
    if (value.length > 2 && value.length < options.length)
        title = `${value[0].label}, ${value[1].label} & ${value.length - 2} more`;
    if (value.length === options.length && options.length > 0) title = `All (${options.length})`;

    const defaultChildren = React.Children.toArray(children).filter((child: any) =>
        [components.Input, components.Placeholder].includes(child.type),
    );

    return (
        <components.ValueContainer {...rest} getValue={getValue} options={options} selectProps={selectProps}>
            {[title, ...defaultChildren]}
        </components.ValueContainer>
    );
};

export const InputOption = ({
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
}: OptionProps) => {
    const style = {
        alignItems: 'center',
        backgroundColor: 'transparent',
        color: 'inherit',
        display: 'flex ',
        gap: '0.8rem',
    };

    // prop assignment
    const props = {
        ...innerProps,
        style,
    };

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            <CheckboxComponent checked={isSelected} />
            {children}
        </components.Option>
    );
};

export const MenuList = ({ children, ...rest }: MenuListProps) => {
    const { myCustomSelector, setShowOnlyAliasStatus } = useMyCustomSelectorStatus();

    const toggleSwitch = () => {
        setShowOnlyAliasStatus(!myCustomSelector.showOnlyAliasStatus);
    };

    const filteredChildren = React.Children.toArray(children).filter((child: any) => child.props.data?.alias);

    const filteredChildrenFinal =
        filteredChildren.length === 0 ? (
            <components.NoOptionsMessage {...rest}>No options</components.NoOptionsMessage>
        ) : (
            filteredChildren
        );

    return (
        <div>
            <div className={classes.menuListSwitchContainer}>
                <span>Show only activity codes with aliases</span>
                <SwitchComponent checked={myCustomSelector.showOnlyAliasStatus} onChange={toggleSwitch} />
            </div>
            <components.MenuList {...rest}>
                {myCustomSelector.showOnlyAliasStatus ? filteredChildrenFinal : children}
            </components.MenuList>
        </div>
    );
};

export const MenuListSelectAll = ({ children, setValue, options, getValue, ...rest }: MenuListProps) => {
    const allSelected = options.length === getValue().length;
    const selectDeselectTitle = !allSelected ? 'Select all' : 'De-select all';
    const handleSelectDeSelectAll = () => {
        !allSelected ? setValue(options, 'select-option') : setValue([], 'select-option');
    };

    return (
        <div>
            <div className={classes.menuListSwitchContainer}>
                <span className={classes.selectDeselectTitle} onClick={handleSelectDeSelectAll}>
                    {selectDeselectTitle}
                </span>
            </div>
            <components.MenuList setValue={setValue} options={options} getValue={getValue} {...rest}>
                {children}
            </components.MenuList>
        </div>
    );
};
