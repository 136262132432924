import RenderCriticalAnalysisIntensity from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisIntensityComponent/RenderCriticalAnalysisIntensity';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useProjectStore } from 'components/common/TreeProgramFilter/zustand_store/treeHeaderStore';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { useQueryGetProjectVersionsList } from 'components/common/TreeProgramFilter/Queries/useQueryGetProjectVersionsList';
import { useGlobalFilterDataStore } from 'components/common/GlobalFilterComponent/store/globalFilterDataStore';

export const CriticalAnalysisIntensityWrapper = () => {
    const hashcode = Object.values(useParams()).filter(Boolean).join(',');
    const { selectedProject } = useProjectStore();
    const projectId = selectedProject.project?.id;
    const contractId = selectedProject.contract?.id;

    const { versionList: versionFromHashMap } = useHashmapConverter(hashcode);
    const latestVersionId = versionFromHashMap[0]?.versionDbId;

    const globalFilterData = useGlobalFilterDataStore((store) => store.globalFilterData);
    const { data: versionsList } = useQueryGetProjectVersionsList({
        projectId,
        contractId,
    });
    const designatedBaselineId = useMemo(() => {
        return versionsList?.find((item) => item.isDesignatedBaseline)?.id;
    }, [versionsList]);
    return (
        <RenderCriticalAnalysisIntensity
            projectId={projectId}
            contractId={contractId}
            latestVersionId={latestVersionId}
            compareVersionId={designatedBaselineId}
            globalFilterData={globalFilterData}
        />
    );
};
