export interface IClustersListItem {
    clusterId: number;
    clusterName: string;
    completedDurationHigh: number | null;
    completedDurationTypical: number | null;
    completedOverrunDaysHigh: number | null;
    completedOverrunDaysTypical: number | null;
    completedOverrunRatioHigh: number | null;
    completedOverrunRatioTypical: number | null;
    fileMetaData: number | null;
    plannedDurationHigh: number | null;
    plannedDurationTypical: number | null;
}

export interface IClustersListResponse {
    clusterList: IClustersListItem[];
}

interface IClusterDataTableData {
    mean: number;
    median: number;
    std: number;
}

interface IClusterDataNarrativeData {
    pTypical: number;
    pExtreme: number;
}

interface IClusterDataDurationDistributionPlannedOrActual {
    min: number;
    firstQuartile: number;
    median: number;
    thirdQuartile: number;
    max: number;
    outliers: number[];
}

export interface IClusterDataRcfDurationOrRatio {
    percentile: number;
    value: number;
}

export interface IClusterDataClusterActivities {
    id: number;
    activityId: string;
    activityName: string;
    activityType: string;
    plannedStartDate: number;
    plannedFinishDate: number;
    actualStartDate: number;
    actualFinishDate: number;
    plannedDuration: number;
    completedDuration: number;
    overrunDays: number;
    overrunPercent: number;
    projectWeekStart: number;
    projectWeekFinish: number;
    projectMonthStart: number;
    projectMonthFinish: number;
    percentileRank: null;
    criticalPath: boolean;
    criticalityScore: number;
    delayDriver: boolean;
    parentName: string;
    grandParentName: string;
    actualDuration: number;
    remainingDuration: number;
    float: null;
    currentStartDate: number;
    currentFinishDate: number;
    baselineStartDate: number;
    baselineFinishDate: number;
    forecastDuration: null;
    activityStatus: string;
    lateStart: number;
    lateFinish: number;
    durationOverun: number;
    forecastOverrun: null;
    versionName: string;
    numOfPredecessors: number;
    numOfSuccessors: number;
    taskHashCode: null;
    taskId: null;
    precedingActivities: number;
    succeedingActivites: number;
    precedingRisk: string;
    succeedingRisk: string;
    projectName: string;
    contractName: string;
    clusterId: null;
}

export interface IClusterDataStatistics {
    totalActivities: number;
    completedActivities: number;
    tableData: {
        plannedDuration: IClusterDataTableData;
        actualDuration: IClusterDataTableData;
        daysOverrun: IClusterDataTableData;
        percentOverrun: IClusterDataTableData;
    };
    narrativeData: {
        plannedDuration: IClusterDataNarrativeData;
        completedDuration: IClusterDataNarrativeData;
        overrunDays: IClusterDataNarrativeData;
        overrunRatio: IClusterDataNarrativeData;
    };
    info: IToolTip;
}

export interface IClusterDataDurationDistribution {
    planned: IClusterDataDurationDistributionPlannedOrActual;
    actual: IClusterDataDurationDistributionPlannedOrActual;
    info: IToolTip;
}

export interface IClusterDataResponse {
    id: number;
    name: string;
    statistics: IClusterDataStatistics;
    durationDistribution: IClusterDataDurationDistribution;
    rcf: {
        duration: IClusterDataRcfDurationOrRatio[];
        ratio: IClusterDataRcfDurationOrRatio[];
        info: IToolTip;
    };
    data: {
        info: IToolTip;
        clusterActivities: IClusterDataClusterActivities[];
    };
    narrative: null;
}

export interface IClustersDataBySearchWordResponse {
    clusterData: IClusterDataResponse;
    clusterList: IClustersListResponse;
}

export enum COMPARE {
    ITSELF = 'single-version',
    PROJECT = 'project',
    PORTFOLIO = 'portfolio',
}
