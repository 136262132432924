import { useMemo } from 'react';
import classes from '../cardWrapper.module.scss';
import { WidgetCard } from 'components/common';
import { CardData } from '../../programStatusSummaryReportTypes';
import { maxBy, orderBy } from 'lodash';
import { getColorByStatus } from '../../programStatusSummaryReport.utils';
import { CardItemGraph } from 'components/common/WidgetWithCards/WidgetCard/CardItemGraph/CardItemGraph';
import { RiskCardMetrics } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/RiskCard/RiskCardMetrics';
import { ToggleCollapse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/ToggleCollapse';
import { maxHeight, maxValueScale } from '../cardsSection.utils';
import { NoApplicableData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/noApplicableData';
import { useThemeStatus } from 'components/Header/zustand_store/themeStatus';

export const RiskCard = ({ cardProperties, cardTitle, cardTrends, collapse, setCollapse, overallScore }: CardData) => {
    const sortedMetricsList = useMemo(() => orderBy(cardProperties.metrics, ['order', 'desc']), [cardProperties]);
    const showGraphSection = cardTrends?.[0];
    const height = !collapse && showGraphSection ? maxHeight : '100%';
    const maxValuePoint = maxBy(cardTrends?.[0]?.graphPoints, 'score');
    const maxValue = maxValuePoint ? String(maxValuePoint?.score * maxValueScale) : '';
    const status = cardTitle.status;
    const tooltipContent =
        status === 'N/A' ? 'Not applicable with applied filter' : `Overall Score is ${overallScore.value}`;
    const id = `statusSummary_risk`;

    const { themeStatus } = useThemeStatus();

    return (
        <WidgetCard
            titleColor={getColorByStatus({ status, theme: themeStatus })}
            titleText={cardTitle.title}
            scoreValue={status}
            contentHeight={height}
            tooltipContent={tooltipContent}
            id={id}
        >
            {status === 'N/A' && <NoApplicableData id={id} />}
            {status !== 'N/A' && (
                <div className={classes.cardWrapper}>
                    <div className={classes.topSection}>
                        <div className={`${classes.metricsListContainer} ${collapse && classes.collapse}`}>
                            <RiskCardMetrics sortedMetricsList={sortedMetricsList} id={id} />
                        </div>
                        <ToggleCollapse setCollapse={setCollapse} collapse={collapse} id={id} />
                    </div>
                    {showGraphSection && (
                        <div className={classes.graphContainer}>
                            <CardItemGraph
                                xaxisname={'Version'}
                                yaxisname={'Risk'}
                                getGraphPointColor={getColorByStatus}
                                points={cardTrends[0]?.graphPoints}
                                yAxisMaxValue={maxValue}
                            />
                        </div>
                    )}
                </div>
            )}
        </WidgetCard>
    );
};
