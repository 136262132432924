import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import classes from 'components/Dashboards/Portfolio/PortfolioOverview/PortfolioOverview.module.scss';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { serializeScoreCardsData } from 'components/Dashboards/Portfolio/PortfolioOverview/helper';
import { columns, initialState } from 'components/Dashboards/Portfolio/PortfolioOverview/components/utils';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import React, { useEffect, useRef, useState } from 'react';
import { useQueryProjectScoreCardsSummary } from 'components/Dashboards/Portfolio/PortfolioOverview/queries/portfolioSummaryQueries';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { IPortfolioProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { prepareVersionSelectTitle } from 'components/common/TreeProgramFilter/VersionSelect/versionSelect.utils';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { useMutationSetWidgetColumns } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySelectedTableColumns';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
const scoreCardId = 'score-card';

const ProjectScorecards = ({
    widgetId,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: IPortfolioProps) => {
    const tableRef = useRef(null);
    const {
        data: scoreCards,
        isLoading: isLoadingScoreCards,
        isFetching: isFetchingScoreCards,
    } = useQueryProjectScoreCardsSummary();
    const route = `${getStringWithoutHash(window.location.href)}#${scoreCardId}`;
    const componentKey = 'projectScorecards';
    const title = 'Project Scorecards';
    const subTitleForExport = `${portfolioConfig.portfolioOverview.title}, ${prepareVersionSelectTitle()}`;
    const scoreCardsTableData = serializeScoreCardsData(scoreCards?.componentsList);
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate: mutateSetWidgetColumns } = useMutationSetWidgetColumns();
    const updatedInitialState = useTableCurrentState({ initialState, componentKey, widgetId });

    const setWidgetColumns = (widgetId: string) => {
        if (tableRef.current) {
            const { hiddenColumns, columnOrder } = tableRef.current.state;
            mutateSetWidgetColumns(
                { columns: { hiddenColumns, columnOrder }, widgetId },
                {
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                    },
                },
            );
        }
    };
    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle: subTitleForExport, title });
    }, [widgetId, subTitleForExport, title]);
    return (
        <WidgetWithTitle
            title={title}
            tooltip={scoreCards?.info}
            id={scoreCardId}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <CustomizedTooltip
                    key={'tableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    widgetId={widgetId}
                    route={route}
                    projectId={null}
                    contractId={null}
                    callBack={setWidgetColumns}
                />,
                <ShareInsightButton
                    key={'shareInsight'}
                    title={title}
                    link={`${getStringWithoutHash(window.location.href)}#${scoreCardId}`}
                />,
                <ExportDropdown title={title} key={'export'} subTitle={subTitleForExport} />,
            ]}
            titleFilters={[editNarrative && <div key={'editNarrative'}>{editNarrative}</div>]}
        >
            <div className={classes.tableContainer}>
                {updatedInitialState && (
                    <MainTable
                        getInstanceCallback={(instance) => (tableRef.current = instance)}
                        initialState={updatedInitialState}
                        columns={columns}
                        data={scoreCardsTableData}
                        isLoading={isLoadingScoreCards || isFetchingScoreCards}
                    />
                )}

                {tableRef?.current && (
                    <CustomTableColumnsModal
                        onHideModal={() => setModalColumnsOpen(false)}
                        showModal={modalColumnsOpen}
                        widgetId={widgetId}
                        componentKey={componentKey}
                        tableInstance={tableRef.current}
                        initialState={initialState}
                        columns={columns}
                    />
                )}
            </div>
        </WidgetWithTitle>
    );
};
export default React.memo(ProjectScorecards);
