import RowsBoard from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/RowsBoard/RowsBoard';
import { useBattlecardsStore } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/store/battlecardsStore';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { useQueryCountersData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';

export const RowsBoardWrapper = () => {
    const { filterData } = useBattlecardsStore();

    const { id } = useParams<any>();
    const { versionList } = useHashmapConverter(id);

    const projectId = versionList[0]?.projectId;
    const contractId = versionList[0]?.contractId;
    const latestVersionId = versionList[0]?.versionDbId;

    const { data: cardsCounters } = useQueryCountersData(filterData);

    return (
        <RowsBoard
            counters={cardsCounters}
            filterData={filterData}
            projectId={projectId}
            contractId={contractId}
            latestVersionId={latestVersionId}
        />
    );
};
