import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { getStatusLabelByOriginalStatus } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import { CustomizedRoundProgressBar } from 'components/common/CustomizedRoundProgressBar/CustomizedRoundProgressBar';
import { CustomizedTooltip } from 'components/common';
import React from 'react';
import colorsVars from 'styles/colors.module.scss';
import CriticalityScoreCell from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/Cells/criticalityScoreCell';
import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import { sortFloat } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/utils';
import { PillsCell } from 'components/common/Tables/Cells/PillsCell';
import { TrackedUntrackedActionCellWrapper } from 'components/common/TrackedUntrackedAction/trackedUntrackedActionCellWrapper/trackedUntrackedActionCellWrapper';
import { cardsDataQueryKey } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
import { ITableColumnState } from 'hooks/useTableCurrentState';
import { ActivityCell } from 'components/common/Tables/Cells/ActivityCell';
import { StatusCell } from 'components/common/Tables/Cells/StatusCell';
import { DetailsCell } from 'components/common/Tables/Cells/DetailsCell';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import { VarianceCell } from 'components/common/Tables/Cells/VarianceCell';

const { color6: orange, textColor, delayDriverColor } = colorsVars;

const invalidateKeys = [cardsDataQueryKey, 'summaryTrackedActivitiesTableData'];

export const initialState: ITableColumnState = {
    hiddenColumns: [
        'startDate',
        'finishDate',
        'currentStartDate',
        'currentFinishDate',
        'lateFinish',
        'lateStart',
        'baselineStartDate',
        'baselineFinishDate',
        'plannedStartDate',
        'plannedFinishDate',
        'actualStartDate',
        'actualFinishDate',
        'plannedDuration',
        'actualDuration',
        'remainingDuration',
        'completedDuration',
        'durationOverrun',
        'numOfPredecessors',
        'numOfSuccessors',
        'projectWeekStart',
        'projectWeekFinish',
        'projectMonthStart',
        'projectMonthFinish',
        'parentName',
        'grandParentName',
        'tagList',
        'criticalPath',
        'originalStatus',
    ],
};
export const columns = [
    {
        Header: 'Activity',
        accessor: 'title',
        ...columnsCommonConfig.activity,
        Cell: ({ row, toggleAllRowsSelected }) => {
            const { taskVersionHashCode, isTracked, isVirtual } = row.original;
            const activityName = row.original.title;
            const activityId =
                row.original.type.id === 2
                    ? row.original.taskActivityId
                    : `C-${typeof row.original.id === 'string' ? row.original.id.split('_')[0] : row.original.id}`;
            const taskId = typeof row.original.id === 'string' ? row.original.id.split('_')[0] : row.original.id;

            const cardIndicators = row.original.cardIndicators;
            const { toggleRowSelected } = row;

            return (
                <ActivityCell
                    activityName={activityName}
                    activityId={activityId}
                    taskId={taskId}
                    isVirtual={isVirtual}
                    cardIndicators={cardIndicators}
                    taskVersionHashCode={taskVersionHashCode}
                    isTracked={isTracked}
                    toggleRowSelected={toggleRowSelected}
                    toggleAllRowsSelected={toggleAllRowsSelected}
                    trackedUntrackedActionCell={
                        <TrackedUntrackedActionCellWrapper
                            isTracked={isTracked}
                            taskVersionHashCode={taskVersionHashCode}
                            invalidateQueriesKey={invalidateKeys}
                        />
                    }
                />
            );
        },
    },
    {
        Header: 'Status',
        accessor: 'status.label',
        ...columnsCommonConfig.status,
        Cell: ({ row }) => {
            const { assignee, startDate, endDate, status, isVirtual, originalStatus, id } = row.original;
            const startDateFormatted = moment(startDate).format(constants.formats.date.default);
            const endDateFormatted = moment(endDate).format(constants.formats.date.default);
            const wasStatus = `${startDateFormatted} - ${endDateFormatted}`;
            const cardType = row.original.type.name;
            const category = row.original.category;

            return (
                <StatusCell
                    assignee={assignee}
                    status={status}
                    isVirtual={isVirtual}
                    taskId={id}
                    originalStatus={originalStatus}
                    invalidateQueriesKey={invalidateKeys}
                    wasStatus={wasStatus}
                    cardType={cardType}
                    category={category}
                />
            );
        },
    },
    {
        Header: 'Submitted Completion',
        ...columnsCommonConfig.submittedDurationComplete,
        accessor: 'submittedDurationComplete',
        Cell: ({ value }) => {
            return (
                <CustomizedTooltip
                    tooltipContent={`Submitted Completion: ${value}%`}
                    triggerElement={<CustomizedRoundProgressBar value={Number(value)} />}
                />
            );
        },
    },
    {
        Header: 'Activity Type',
        ...columnsCommonConfig.activityType,
        accessor: 'taskActivityType',
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },
    {
        Header: 'Details/Last Comment',
        accessor: 'details',
        ...columnsCommonConfig.details,
        Cell: ({ row }) => {
            const { cardLastComment, details, id, isVirtual } = row.original;
            return (
                <DetailsCell
                    details={details}
                    cardLastComment={cardLastComment}
                    id={id}
                    invalidateQueriesKey={invalidateKeys}
                    isVirtual={isVirtual}
                />
            );
        },
    },
    {
        Header: 'Critical Path',
        accessor: (row) => (row.cardIndicators.isCriticalPath.isIndicatorOn ? 'Yes' : 'No'),
        ...columnsCommonConfig.criticalPath,
    },
    {
        Header: 'Tags',
        accessor: 'tagList',
        ...columnsCommonConfig.tagList,
        Cell: ({ value }) => {
            const list = value.map((item) => item.name);
            return list.length > 0 ? <PillsCell list={list} type={'tag'} /> : null;
        },
    },
    {
        Header: 'Criticality Score',
        accessor: 'criticalityScore',
        ...columnsCommonConfig.criticalityScore,
        Cell: ({ value, row }) => {
            const delayDriver = row.original.cardIndicators.isDelayDriver.isIndicatorOn;
            const style = delayDriver ? { color: delayDriverColor, fontWeight: 600 } : {};
            const numOfPredecessors = row.original.numOfPredecessors || row.original.predeccessors?.length;
            const numOfSuccessors = row.original.numOfSuccessors || row.original.successors?.length;
            const precedingActivities = row.original.precedingActivities;
            const succeedingActivites = row.original.succeedingActivites;
            const precedingRisk = row.original.precedingRisk;
            const succeedingRisk = row.original.succeedingRisk;
            return (
                <CriticalityScoreCell
                    succeedingRisk={succeedingRisk}
                    precedingRisk={precedingRisk}
                    numOfSuccessors={numOfSuccessors}
                    numOfPredecessors={numOfPredecessors}
                    succeedingActivites={succeedingActivites}
                    precedingActivities={precedingActivities}
                    style={style}
                    value={value}
                />
            );
        },
        sortType: 'basic',
    },
    {
        Header: 'Float',
        accessor: 'float',
        ...columnsCommonConfig.float,
        sortType: sortFloat,
    },
    {
        Header: 'Start Date',
        ...columnsCommonConfig.startDate,
        accessor: 'startDate',
        Cell: ({ value }) => {
            return value ? moment(value).format(constants.formats.date.default) : value;
        },
    },
    {
        Header: 'Finish Date',
        ...columnsCommonConfig.finishDate,
        accessor: 'endDate',
        Cell: ({ value }) => {
            return value ? moment(value).format(constants.formats.date.default) : value;
        },
    },
    {
        Header: 'Finish Variance',
        ...columnsCommonConfig.lateFinish,
        accessor: 'finishVariance',
        Cell: ({ value }) => {
            return <VarianceCell value={value} />;
        },
    },
    {
        Header: 'Start Variance',
        ...columnsCommonConfig.lateStart,
        accessor: 'startVariance',
        Cell: ({ value }) => {
            return <VarianceCell value={value} />;
        },
    },
    {
        Header: 'Baseline Start Date ',
        accessor: 'baselineStartDate',
        ...columnsCommonConfig.baselineStartDate,
        Cell: ({ value }) => {
            return value ? moment(value).format(constants.formats.date.default) : value;
        },
    },
    {
        Header: 'Baseline Finish Date',
        accessor: 'baselineFinishDate',
        ...columnsCommonConfig.baselineFinishDate,
        Cell: ({ value }) => {
            return value ? moment(value).format(constants.formats.date.default) : value;
        },
    },
    {
        Header: 'Planned Start Date ',
        accessor: 'plannedStartDate',
        ...columnsCommonConfig.plannedStartDate,
        Cell: ({ value }) => {
            return value ? moment(value).format(constants.formats.date.default) : value;
        },
    },
    {
        Header: 'Planned Finish Date',
        accessor: 'plannedFinishDate',
        ...columnsCommonConfig.plannedFinishDate,
        Cell: ({ value }) => {
            return value ? moment(value).format(constants.formats.date.default) : value;
        },
    },
    {
        Header: 'Actual Start Date',
        accessor: 'actualStartDate',
        ...columnsCommonConfig.actualStartDate,
        Cell: ({ value }) => {
            return value ? moment(value).format(constants.formats.date.default) : value;
        },
    },
    {
        Header: 'Actual Finish Date',
        accessor: 'actualFinishDate',
        ...columnsCommonConfig.actualFinishDate,
        Cell: ({ value }) => {
            return value ? moment(value).format(constants.formats.date.default) : value;
        },
    },
    {
        Header: 'Planned<br /> Duration',
        ...columnsCommonConfig.plannedDuration,
        accessor: 'plannedDurationInDays',
    },
    {
        Header: 'Actual<br /> Duration',
        ...columnsCommonConfig.actualDuration,
        accessor: 'actualDurationInDays',
    },
    {
        Header: 'Remaining<br /> Duration',
        ...columnsCommonConfig.remainingDuration,
        accessor: 'remainingDurationInDays',
    },
    {
        Header: 'Current/<br />Completed<br /> Duration',
        ...columnsCommonConfig.completedDuration,
        accessor: 'completedDurationInDays',
    },
    {
        Header: 'Current Duration<br /> Overrun',
        ...columnsCommonConfig.durationOverrun,
        accessor: 'completedDurationOverrun',
        Cell: ({ value }) => {
            const color = value > 0 ? orange : textColor;
            return <span style={{ color }}>{value}</span>;
        },
    },
    {
        Header: 'Pred.',
        accessor: 'numOfPredecessors',
        ...columnsCommonConfig.numOfPredecessors,
    },
    {
        Header: 'Succ.',
        accessor: 'numOfSuccessors',
        ...columnsCommonConfig.numOfSuccessors,
    },
    {
        Header: 'Project Week Start ',
        accessor: 'projectWeekStart',
        ...columnsCommonConfig.projectWeekStart,
    },
    {
        Header: 'Project Week Finish',
        accessor: 'projectWeekFinish',
        ...columnsCommonConfig.projectWeekFinish,
    },
    {
        Header: 'Project Month Start',
        accessor: 'projectMonthStart',
        ...columnsCommonConfig.projectMonthStart,
    },
    {
        Header: 'Project Month Finish',
        accessor: 'projectMonthFinish',
        ...columnsCommonConfig.projectMonthFinish,
    },
    {
        Header: 'Immediate Parent Name ',
        ...columnsCommonConfig.parentName,
        accessor: 'parentActivity',
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },
    {
        Header: 'Grand Parent Name',
        ...columnsCommonConfig.grandParentName,
        accessor: 'grandParentActivity',
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },
    {
        Header: 'Last Submitted Status',
        ...columnsCommonConfig.originalStatus,
        accessor: 'originalStatus',
        Cell: ({ value }) => getStatusLabelByOriginalStatus(value),
    },
];

export const initialFiltersState = {
    filter: {
        filters: [],
    },
    paging: {
        page: 0,
        size: 40,
    },
    withDetails: true,
};

export const statusOptions = [
    {
        id: '1',
        value: 'TODO',
        label: 'To Do',
    },
    {
        id: '2',
        value: 'IN_PROGRESS',
        label: 'In Progress',
    },
    {
        id: '3',
        value: 'DONE',
        label: 'Done',
    },
];

export const moreOptionsListItems = [
    { value: 'LateFinishDate', label: 'Late Finish Date' },
    { value: 'LateStartDate', label: 'Late Start Date' },
    { value: 'CriticalPath', label: 'Critical Path' },
    { value: 'Milestones', label: 'Milestones' },
];

export const NEXT_DATE_FILTERS: ISelectOption<string>[] = [
    {
        label: '7D',
        value: '7',
    },
    {
        label: '14D',
        value: '14',
    },
    {
        label: '30D',
        value: '30',
    },
    {
        label: 'ALL',
        value: '-1',
    },
];

export const getRiskIndicatorsQueryStringValue = (value) => {
    switch (value) {
        case 'LateFinishDate':
            return '11';
        case 'LateStartDate':
            return '12';
        case 'CriticalPath':
            return '9';
        default:
            return '';
    }
};

export const getEndDatesRangeQueryStringValue = (value) => {
    switch (value) {
        case '7':
            return 'TODAY_REF_NEXT_7_DAYS';
        case '14':
            return 'TODAY_REF_NEXT_14_DAYS';
        case '30':
            return 'TODAY_REF_NEXT_30_DAYS';
        case '-1':
            return 'TODAY_REF_NEXT_90_DAYS'; // TODO: ask Itay
        default:
            return null;
    }
};
