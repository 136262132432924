import { GanttChart } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/GanttChart';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { getMaxNumberByKey } from 'utilitys/helpers/general';
import { useGlobalFilterDataStore } from 'components/common/GlobalFilterComponent/store/globalFilterDataStore';

export const GanttChartWrapper = () => {
    const { id } = useParams<{ id: string }>();
    const { idList, versionList } = useHashmapConverter(id);
    const projectId = versionList[0]?.projectId;
    const contractId = versionList[0]?.contractId;
    const initialMaxLevel = useMemo(() => getMaxNumberByKey({ list: versionList, key: 'maxLevel' }), [versionList]);
    const globalFilterData = useGlobalFilterDataStore((state) => state.globalFilterData);

    return initialMaxLevel && idList.length > 0 ? (
        <GanttChart
            latestVersionId={idList[0]}
            projectId={projectId}
            contractId={contractId}
            initialMaxLevel={initialMaxLevel}
            globalFilterData={globalFilterData}
        />
    ) : null;
};
