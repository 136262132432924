import { Draggable } from 'react-beautiful-dnd';
import classes from 'components/Dashboards/Project/Components/CustomDashboard/EditDashboardWidgetsModal/draggableList.module.scss';
import { IDraggableListItemProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import Icon from 'components/common/Icons/icon';
import colorsVars from 'styles/colors.module.scss';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { hashmap } from 'components/Dashboards/Project/Components/CustomDashboard/utils/hashmap';
import useUserHook from 'hooks/useUserHook';
import { useQueryGetProgramGeneralHierarchyList } from 'components/common/TreeProgramFilter/Queries/useQueryGetProgramGeneralHierarchyList';
import { getPermissionsToViewComponent } from 'components/Dashboards/Project/Components/CustomDashboard/customDashboard.utils';

/**
 * Draggable widget.
 * @param {IWidget[]}  item - current widget.
 * @param {number}  index - widget index.
 * @param {(number) => void}  onRemoveItem - remove the widget from list handler.
 * @param isDisableOrderAll
 * @returns  the Widget JSX Component
 * */
export const DraggableListItem = ({ item, index, onRemoveItem, isDisableOrderAll }: IDraggableListItemProps) => {
    const { ability } = useUserHook();
    const title = item.title;
    const componentKey = item.componentKey;
    const projectId = item.projectId;
    const tooltipContent =
        item.projectName && item.contractName ? `${title} ${item.projectName} / ${item.contractName}` : title;
    const componentObj = Boolean(componentKey) ? hashmap()[componentKey] : null;
    const permission = componentObj ? componentObj.permission : undefined;
    const { data: projectHierarchyList } = useQueryGetProgramGeneralHierarchyList('Dashboards');

    const { isHavePermissions } = getPermissionsToViewComponent({
        projectHierarchyList,
        projectId,
        permission: permission !== undefined ? ability.can('view', permission) : undefined,
    });

    return (
        <Draggable draggableId={(item && item.id?.toString()) || ''} index={index} isDragDisabled={isDisableOrderAll}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`${classes.listItem} ${snapshot.isDragging ? classes.draggingListItem : ''}`}
                >
                    <div className={classes.listItemWrapper}>
                        <div>
                            <CustomizedTooltip
                                tooltipContent={tooltipContent}
                                triggerElement={
                                    <div className={classes.listItemTitleWrapper}>
                                        <span className={classes.listItemTitle}>{title}</span>
                                        <span className={classes.listItemSubTitle}>
                                            {item.projectName &&
                                                item.contractName &&
                                                `${item.projectName} / ${item.contractName}`}
                                        </span>
                                    </div>
                                }
                            />
                        </div>

                        <Icon
                            name={'reorder-widget'}
                            color={
                                snapshot.isDragging
                                    ? colorsVars.organizeCustomDashboardBlue
                                    : colorsVars.organizeCustomDashboardGray
                            }
                            size={'1.8rem'}
                            title={'Reorder Widget'}
                        />
                    </div>

                    {isHavePermissions && (
                        <CustomizedRoundIcon title={'Remove'} size={'3rem'} onClick={() => onRemoveItem(item?.id)}>
                            <Icon
                                name={'remove-widget'}
                                color={
                                    snapshot.isDragging
                                        ? colorsVars.organizeCustomDashboardBlue
                                        : colorsVars.organizeCustomDashboardGray
                                }
                                size={'12'}
                            />
                        </CustomizedRoundIcon>
                    )}
                </div>
            )}
        </Draggable>
    );
};
