import classes from 'components/Settings/Components/UserProfile/components/userProfile.module.scss';
import { MyCustomSelect } from 'components/common';
import { USER_STATUS_OPTIONS } from 'components/Settings/Components/UserProfile/userProfile.constants';
import { Controller } from 'react-hook-form';

export const UserStatus = ({ control }) => {
    return (
        <div className={classes.userStatusContainer}>
            <div>Status: </div>
            <Controller
                render={({ field }) => <MyCustomSelect {...field} options={USER_STATUS_OPTIONS} />}
                name="status"
                control={control}
            />
        </div>
    );
};
