import React, { useEffect, useState } from 'react';
import { CustomizedModalBase } from 'components/common/CustomizedModal/CustomizedModalBase';
import classes from 'components/common/Modals/CustomTableColumns/customTableColumnsModal.module.scss';
import { CustomizedButton } from 'components/common/CustomizeButton/CustomizedButton';
import { DropResult } from 'react-beautiful-dnd';
import { reorder } from 'components/Dashboards/Project/Components/CustomDashboard/customDashboard.utils';
import { DraggableList } from 'components/common/Modals/CustomTableColumns/draggableList';
import produce from 'immer';

import { isAllColumnsSelected } from 'components/common/Modals/CustomTableColumns/helper';
import {
    useMutationSetTableColumns,
    useMutationSetWidgetColumns,
} from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySelectedTableColumns';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { IHashmap } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { ITableColumnState } from 'hooks/useTableCurrentState';
import { useQueryClient } from 'react-query';

interface ICustomTableColumnsModal {
    showModal: boolean;
    onHideModal: () => void;
    widgetId?: string;
    componentKey: keyof IHashmap;
    tableInstance: any;
    initialState: ITableColumnState;
    columns: any[];
    onChangeVisibility?: React.Dispatch<React.SetStateAction<{}>>;
    onChangeOrder?: React.Dispatch<React.SetStateAction<string[]>>;
}

export type IStateItem = {
    id: string;
    Header: string;
    isVisible: boolean;
    isDisableReorder: boolean;
    isDisableVisibility: boolean;
    accessor: string;
};

const mapper = (item) => ({
    id: item.id || item.accessor,
    Header: item.Header || item.header,
    isVisible: item.isVisible,
    isDisableReorder: item.isDisableReorder || false,
    isDisableVisibility: item.isDisableVisibility || false,
    accessor: item.accessor || item.accessorKey,
});

/**
 * Modal for re-organizing and hidding/showing table columnOrder.
 * @param {boolean}  showModal - determines if this modal is visible.
 * @param {() => void}  onHideModal - hide modal function.
 * @param {boolean}  isLoading - determines if this table is loading.
 * @param tableInstance
 * @param {ITableColumnState} initialState - initial state of the table columns

 * @param columns
 * @returns  Modal
 * */

export const CustomTableColumnsModal = ({
    showModal,
    onHideModal,
    widgetId,
    componentKey,
    tableInstance,
    initialState,
    columns,
    onChangeVisibility,
    onChangeOrder,
}: ICustomTableColumnsModal) => {
    const [state, setState] = useState<IStateItem[]>([]);
    const { mutate: mutateSetTableColumns, isLoading: isLoadingSetTableColumns } = useMutationSetTableColumns();
    const { mutate: mutateSetWidgetColumns, isLoading: isLoadingSetWidgetColumns } = useMutationSetWidgetColumns();
    const { handleSnackBar } = useCustomSnackBar();
    const allSelected = isAllColumnsSelected(state);
    const selectDeselectTitle = !allSelected ? 'Select all' : 'De-select all';
    const queryClient = useQueryClient();
    const onDragEnd = ({ destination, source }: DropResult) => {
        // dropped outside the list
        if (!destination) return;
        const destinationColumn = state[destination.index];
        if (!destinationColumn || destinationColumn.isDisableReorder) return;
        const newItems = reorder(state, source.index, destination.index);
        setState(newItems);
    };

    useEffect(() => {
        const tableColumns = tableInstance.allColumns.filter((item) => item.id !== 'rowOptions');
        const defaultColumns = tableColumns.map(mapper);
        setState(defaultColumns);
    }, [tableInstance]);

    const handleEditTableColumns = () => {
        const table = tableInstance;
        const hiddenColumns = state.filter((item) => !item.isVisible).map((item) => item.id);
        const columnOrder = state.map((item) => item.id);

        mutateSetTableColumns(
            { columnOrder, hiddenColumns, componentKey },
            {
                onSuccess: () => {
                    table.setColumnOrder(columnOrder);
                    table.setHiddenColumns(hiddenColumns);
                    queryClient.invalidateQueries('getTableColumns');
                    onHideModal();
                    onChangeVisibility &&
                        onChangeVisibility(
                            state.reduce((obj, item) => {
                                return {
                                    ...obj,
                                    [item.id]: !hiddenColumns.includes(item.id),
                                };
                            }, {}),
                        );
                    onChangeOrder && onChangeOrder(columnOrder);
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                },
            },
        );
    };
    const handleEditWidgetColumns = () => {
        const table = tableInstance;
        const hiddenColumns = state.filter((item) => !item.isVisible).map((item) => item.id);
        const columnOrder = state.map((item) => item.id);

        mutateSetWidgetColumns(
            {
                columns: {
                    columnOrder,
                    hiddenColumns,
                },
                widgetId,
            },
            {
                onSuccess: () => {
                    table.setColumnOrder(columnOrder);
                    table.setHiddenColumns(hiddenColumns);
                    queryClient.invalidateQueries('getWidgetColumns');
                    onHideModal();
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                },
            },
        );
    };
    const onHideShowItem = (e, column) => {
        const newItems = produce(state, (draft) => {
            return draft.map((item: IStateItem) => {
                if (item.id === column.id) {
                    return {
                        ...item,
                        isVisible: !item.isVisible,
                    };
                }
                return item;
            });
        });
        setState(newItems);
    };

    const onReset = ({ state }) => {
        if (!state) return null;
        const updated = columns
            .filter((item) => item.Header !== '')
            .map((item) => ({
                ...item,
                isVisible: state.hiddenColumns ? !state.hiddenColumns.includes(item.id || item.accessor) : true,
            }));
        setState(updated.map(mapper));
    };

    const onCancel = () => {
        onReset({ state: tableInstance.state });
        onHideModal();
    };

    const handleSelectAll = () => {
        const updated = columns
            .filter((item) => item.Header !== '')
            .map((item) => ({
                ...item,
                isVisible: !item.isDisableVisibility ? !allSelected : true,
            }));
        setState(updated.map(mapper));
    };

    const isLoading = isLoadingSetTableColumns || isLoadingSetWidgetColumns;

    return (
        <CustomizedModalBase isModalOpen={showModal} handleModalClose={onCancel}>
            <div className={classes.modalContainer}>
                <div className={classes.title}>Show, Hide and Reorder Columns</div>
                <div className={classes.subTitle}>
                    <div>Unselect the columns you'd like to hide.</div>
                    <div>Drag columns to reorder</div>
                </div>
                <div className={classes.selectDeselect}>
                    <span data-testid={'select-deselect-all'} className={classes.selectDeselectTitle} onClick={handleSelectAll}>
                        {selectDeselectTitle}
                    </span>
                </div>
                <DraggableList columns={state} onDragEnd={onDragEnd} onHideShowItem={onHideShowItem} />
                <div className={classes.footer}>
                    <div className={classes.container}>
                        <div>
                            <CustomizedButton
                                size={'large'}
                                color={'secondary'}
                                onClick={() => onReset({ state: initialState })}
                                className={classes.reset}
                            >
                                Reset
                            </CustomizedButton>
                        </div>
                        <div className={classes.actions}>
                            <CustomizedButton size={'large'} color={'secondary'} onClick={onCancel}>
                                Cancel
                            </CustomizedButton>

                            <CustomizedButton
                                size={'large'}
                                color={'primary'}
                                onClick={widgetId ? handleEditWidgetColumns : handleEditTableColumns}
                                isLoading={isLoading}
                                disabled={isLoading}
                            >
                                Save
                            </CustomizedButton>
                        </div>
                    </div>
                </div>
            </div>
        </CustomizedModalBase>
    );
};
