import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import React, { memo, useEffect } from 'react';
import { IDataStory, ISingleStoryProps } from 'components/Dashboards/Project/Components/DataStories/dataStories.types';
import { generatePath, useHistory } from 'react-router-dom';
import {
    useQueryExpandStory,
    useQueryGetStoryImage,
} from 'components/Dashboards/Project/Components/DataStories/queries/dataStoriesQuery';
import { typedArrayToURL } from 'components/Dashboards/Project/Components/DataStories/dataStories.utils';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import classes from 'components/Dashboards/Project/Components/DataStories/SingleStory/singleStory.module.scss';
import moment from 'moment/moment';
import EmbedTableau from 'components/common/EmbedTableau/embedTableau';
import { Pills } from 'components/common/pills/pills';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';

export const SingleStory = memo(
    ({
        widgetId,
        externalComponents = null,
        editNarrative = null,
        localFilters,
        edit,
        username,
        deleteStory,
        isAdmin = false,
        token,
        projectId,
        contractId,
        storyId,
        setSubTitleForExport,
        path,
    }: ISingleStoryProps) => {
        const history = useHistory();
        const { data: storyData } = useQueryExpandStory(widgetId && localFilters ? localFilters.storyId : storyId);

        const data =
            storyData && Array.isArray(storyData.dataStory) && storyData.dataStory.length > 0
                ? storyData.dataStory[0]
                : ({} as IDataStory);
        const {
            id,
            createdBy,
            createdByUsername,
            title,
            createdDate,
            contractName,
            description,
            embeddedCode,
            imageLink,
            tagslist,
        } = data;

        const { data: imageBlob } = useQueryGetStoryImage({
            id,
        });

        const exportBlob = Boolean(imageLink) && Boolean(imageBlob);

        const handleEdit = () => {
            edit(data);
        };

        const handleExpand = () => {
            path && history.push(`${generatePath(path, { projectId, contractId })}/${id}`);
        };
        const subTitleForExport = `${projectConfig.dataStories.title}`;

        useEffect(() => {
            setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle: subTitleForExport, title });
        }, [widgetId, subTitleForExport, title, exportBlob]);

        const allowToEdit = createdByUsername === username || isAdmin;
        const blob = typedArrayToURL(imageBlob, 'image/png');
        const route = `${getStringWithoutHash(window.location.href)}#${id}`;
        const componentKey = 'dataStories';
        const componentId = `newsFeed${storyId}`;

        return (
            <ErrorBoundary>
                <WidgetWithTitle
                    id={componentId}
                    title={title}
                    titleComponents={[
                        externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                        <AddWidget
                            key={'AddWidget'}
                            componentKey={componentKey}
                            title={`Data Story: ${title}`}
                            projectId={projectId}
                            widgetId={widgetId}
                            route={route}
                            localFilters={{
                                storyId,
                            }}
                        />,
                        allowToEdit && !Boolean(widgetId) && (
                            <CustomizedTooltip
                                tooltipPosition={TooltipPosition.Top}
                                tooltipContent={'Edit Story'}
                                triggerElement={
                                    <CustomizedRoundIcon onClick={handleEdit}>
                                        <Icon name="edit-user-profile" />
                                    </CustomizedRoundIcon>
                                }
                            />
                        ),
                        allowToEdit && !Boolean(widgetId) && (
                            <CustomizedTooltip
                                tooltipPosition={TooltipPosition.Top}
                                tooltipContent={'Delete Story'}
                                triggerElement={
                                    <CustomizedRoundIcon onClick={() => deleteStory(id)}>
                                        <Icon name="delete-icon" />
                                    </CustomizedRoundIcon>
                                }
                            />
                        ),
                        exportBlob && <ExportDropdown key={'export'} title={title} subTitle={subTitleForExport} />,
                        allowToEdit && !Boolean(widgetId) && (
                            <CustomizedTooltip
                                tooltipPosition={TooltipPosition.Top}
                                tooltipContent={'Expand Window'}
                                triggerElement={
                                    <CustomizedRoundIcon onClick={handleExpand}>
                                        <Icon name="expand-window" />
                                    </CustomizedRoundIcon>
                                }
                            />
                        ),
                    ]}
                    titleFilters={[editNarrative && <div>{editNarrative}</div>]}
                >
                    <div className={classes.SingleStory}>
                        <div className={classes.date}>
                            Data Valid as of: {moment(new Date(createdDate)).format('DD MMMM YYYY')}
                        </div>
                        {projectId && (
                            <div className={classes.contract}>
                                Sub-Program: <span>{contractName}</span>
                            </div>
                        )}
                        <div className={classes.description}>
                            Description: <div dangerouslySetInnerHTML={{ __html: description }} />
                        </div>
                        {exportBlob && (
                            <>
                                <div
                                    className={classes.imageContainer}
                                    style={{
                                        backgroundImage: `url(${blob})`,
                                    }}
                                />
                            </>
                        )}
                        {embeddedCode && (
                            <div className={classes.imageContainer}>
                                <EmbedTableau embeddedCode={embeddedCode} token={token} />
                                <div className={classes.hideToolBar} />
                            </div>
                        )}
                        <div className={classes.tags}>
                            {tagslist?.map((tag) => (
                                <Pills type={'tag'} key={tag.id}>
                                    {tag.name}
                                </Pills>
                            ))}
                        </div>
                        <div className={classes.createdBy}>
                            Created By: <span>{createdBy}</span>
                        </div>
                    </div>
                </WidgetWithTitle>
            </ErrorBoundary>
        );
    },
);
