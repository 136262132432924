import SwitchComponent from 'components/common/Switch/Switch';
import { useThemeStatus } from 'components/Header/zustand_store/themeStatus';
import classes from './darkMode.module.scss';
import React from 'react';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

export const DarkMode = () => {
    const { themeStatus, setThemeStatus } = useThemeStatus();
    const handleTheme = () => {
        themeStatus
            ? document.body.setAttribute('data-theme', 'light')
            : document.body.setAttribute('data-theme', 'dark');
        setThemeStatus(!themeStatus);
    };

    return (
        <div className={classes.darkModeContainer}>
            <div className={classes.titleWrapper}>
                <MuiIcon icon={'dark_mode'} fontSize={'2.2rem'} color="#8c919a" />
                <span className={classes.title}>Dark Theme</span>
            </div>
            <SwitchComponent
                checked={themeStatus}
                onChange={handleTheme}
                onClick={(e: React.MouseEvent) => e.stopPropagation()}
            />
        </div>
    );
};
