import PageContainer from 'components/common/PageContainer/PageContainer';
import { ProgramTopHeader } from 'components/common/ProgramTopHeader/ProgramTopHeader';
import OverallStatus from 'components/Dashboards/Portfolio/PortfolioOverview/components/OverallStatus';
import ProjectScorecards from 'components/Dashboards/Portfolio/PortfolioOverview/components/ProjectScorecards';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { TitleSection } from 'components/common/PageContainer/titleSection';
import { MainCardRoutingModal } from 'components/common/MainCardRoutingModal/mainCardRoutingModal';

export const PortfolioOverviewPage = () => {
    useFullStoryPageView({ pageName: portfolioConfig.portfolioOverview.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <TitleSection>
                        <ProgramTopHeader pageTitle={portfolioConfig.portfolioOverview.title} />
                    </TitleSection>
                </VersionWrapper>
            </HeaderWrapper>

            <MainContent>
                <OverallStatus />
                <ProjectScorecards />
            </MainContent>
            <MainCardRoutingModal queryKeys={['portfolioTrackedActivities']} />
        </PageContainer>
    );
};
