import { useProjectStore } from 'components/common/TreeProgramFilter/zustand_store/treeHeaderStore';
import React, { useEffect, useMemo, useState } from 'react';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import {
    useMutationSetTagList,
    useQueryGetTagList,
    useQueryGetTagListInit,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/queries/useQuerySubProgramPreferences';
import { ConfirmSaveModal, CustomizedButton } from 'components/common';
import SelectListWithCreate from 'components/common/selectListWithCreate/selectListWithCreate';

import { SelectStyles } from 'components/common/MyCustomSelect/myCustomSelect.style';
import classes from './tagList.module.scss';
import { isArrayEqual } from 'utilitys/helpers/general';

export const TagList = () => {
    const {
        selectedProject: { contract },
    } = useProjectStore();
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<ISelectOption<string>[]>([]);
    const { handleSnackBar } = useCustomSnackBar();
    const { data: tagListDataInit, refetch: tagListInitRefetch } = useQueryGetTagListInit();
    const { data: tagListData, refetch: tagListRefetch } = useQueryGetTagList({ contractId: contract?.id });
    const mutation = useMutationSetTagList();

    const [actionButtonDisabled, setActionButtonDisabled] = useState<boolean>(true);

    const currentValue = useMemo(() => {
        return tagListData ? tagListData.tagList.map((item) => ({ label: item.name, value: item.name })) : [];
    }, [tagListData]);

    useEffect(() => {
        tagListData && setSelectedValue(tagListData.tagList.map((item) => ({ label: item.name, value: item.name })));
    }, [tagListData]);

    const handleHideModal = () => {
        setActionButtonDisabled(true);
        setShowConfirmModal(false);
        setSelectedValue(currentValue);
    };

    const handleSave = () => {
        setShowConfirmModal(false);
        mutation.mutate(
            {
                contractId: contract?.id,
                tagList: selectedValue.map((item) => ({ name: item.value })),
            },
            {
                onSuccess: () => handleSnackBar('Tags has changed', 'success'),
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
                onSettled: () => {
                    tagListRefetch();
                    tagListInitRefetch();
                    setActionButtonDisabled(true);
                },
            },
        );
    };

    const handleTagSelect = (value) => {
        const currentValueWithoutIdProp = currentValue.map((item) => {
            delete item.id;
            return item;
        });

        if (isArrayEqual(value, currentValueWithoutIdProp)) {
            setActionButtonDisabled(true);
        } else {
            setActionButtonDisabled(false);
        }

        setSelectedValue(value);
    };

    const tagsOptions = tagListDataInit
        ? tagListDataInit.tags.map((item) => ({ label: item.name, value: item.name }))
        : [];

    return (
        <div className={classes.tagListContainer}>
            <span className={classes.title}>Tags: </span>
            <div className={classes.container}>
                <SelectListWithCreate options={tagsOptions} value={selectedValue} onChange={handleTagSelect} />

                <div className={classes.actionButtons}>
                    <CustomizedButton color={'secondary'} onClick={handleHideModal}>
                        Cancel
                    </CustomizedButton>
                    <CustomizedButton
                        color={'primary'}
                        onClick={() => setShowConfirmModal(true)}
                        disabled={actionButtonDisabled}
                    >
                        Save
                    </CustomizedButton>
                </div>
            </div>

            <ConfirmSaveModal
                isShowModal={showConfirmModal}
                onHideModal={handleHideModal}
                onConfirm={handleSave}
                isLoading={mutation.isLoading}
            >
                You have made changes. Would you like to save them?
            </ConfirmSaveModal>
        </div>
    );
};
