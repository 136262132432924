import React, { useState } from 'react';
import './CustomizedDateRangePicker.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';

/**
 * @type any
 * @param data array with 2 values(start-end date range)
 * @param onSelection callback to update parent data
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */

interface IRangeDatePickerDark {
    data: number[];
    onSelection: (value: number[]) => void;
    id?: string;
}

const CustomizedDateRangePicker = ({ data, onSelection, id }: IRangeDatePickerDark) => {
    const startDate = data[0] ? moment(data[0]).toDate() : null;
    const endDate = data[1] ? moment(data[1]).toDate() : null;

    const handleStartDateRange = (value) => {
        onSelection(value);
    };

    const getPlaceHolder = () => {
        if (data[1]) {
            return `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`;
        } else if (data[0]) {
            return `${moment(startDate).format('DD/MM/YYYY')} - `;
        } else {
            return 'Select Date';
        }
    };

    const handleClickOutside = () => {
        if (isNaN(startDate) || isNaN(endDate)) {
            onSelection([]);
        }
    };

    const handleClearDate = () => {
        onSelection([]);
    };

    return (
        <div className={'customizedDateRangePicker'} id={id}>
            <DatePicker
                value={getPlaceHolder()}
                selected={startDate}
                onClickOutside={handleClickOutside}
                startDate={startDate}
                endDate={endDate}
                onChange={handleStartDateRange}
                monthsShown={2}
                dateFormat="MMM yyyy"
                selectsRange
                inline
            />
        </div>
    );
};

export default CustomizedDateRangePicker;
