import { Link, useLocation } from 'react-router-dom';
import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import React from 'react';

const ActivityCell = ({ id, value = '', toggleAllRowsSelected, toggleRowSelected }) => {
    const { pathname, search } = useLocation();
    const handleSelectRow = () => {
        if (toggleRowSelected && toggleAllRowsSelected) {
            toggleAllRowsSelected(false);
            toggleRowSelected(true);
        }
    };
    return id ? (
        <Link to={`${pathname}/card-${id}-1${search}`} onClick={handleSelectRow}>
            {value}
        </Link>
    ) : (
        <TooltipCellWithCopy text={value} showAlways={true} />
    );
};

export default React.memo(ActivityCell);
