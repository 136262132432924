import React, { useEffect, useRef, useState } from 'react';
import classes from './PillsCell.module.scss';
import { Pills } from 'components/common/pills/pills';

interface Props {
    list: string[];
    type?: string;
}
export const PillsCell = ({ list, type = 'user' }: Props) => {
    const [count, setCount] = useState<number>(list.length);
    const [visibleMore, setVisibleMore] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    const childRefs = useRef<HTMLDivElement[]>([]);

    const handleIntersection = (entries, observer) => {
        const notIntersecting = entries.filter((entry) => !entry.isIntersecting).length;
        entries.forEach((entry) => {
            if (!entry.isIntersecting) {
                entry.target.remove();
            }
        });
        observer.disconnect();
        setCount(notIntersecting);
        setVisibleMore(true);
    };

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight;
        }
        const options = {
            root: ref.current,
            rootMargin: '0px',
            threshold: 1,
        };
        const observer = new IntersectionObserver(handleIntersection, options);
        childRefs.current.forEach((childRef) => {
            if (childRef) {
                observer.observe(childRef);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, [ref]);

    return (
        <div className={classes.pillsWrapper} ref={ref}>
            {list.map((item, index) => (
                <Pills key={index} type={type} ref={(el) => el && childRefs.current.push(el)}>
                    {item}
                </Pills>
            ))}
            {count > 0 && <div className={`${classes.more} ${visibleMore && classes.visible}`}>& {count} more...</div>}
        </div>
    );
};
