import { create } from 'zustand';
import { Details } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/programLibrary.types';

interface IProgramLibraryStore {
    modalShow: boolean;
    details: Details | null;
    isUploading: boolean;
    setModalShow: (modalShow: boolean) => void;
    setDetails: (details: Details | null) => void;
    setIsUploading: (isUploading: boolean) => void;
}
export const useProgramLibraryStore = create<IProgramLibraryStore>((set) => ({
    modalShow: false,
    details: null,
    isUploading: false,
    setModalShow: (modalShow) => set({ modalShow }),
    setDetails: (details) => set({ details }),
    setIsUploading: (isUploading) => set({ isUploading }),
}));
