import { create } from 'zustand';
import { IVersion } from 'components/common/TreeProgramFilter/Queries/useQueryGetProjectVersionsList';

interface Props {
    version: IVersion | null;
    setVersion: (value: IVersion | null) => void;
}

const store = (set): Props => ({
    version: null,
    setVersion: (value) => set(() => ({ version: value })),
});

export const useVersionStore = create(store);
