import React, { useState } from 'react';
import classes from 'components/Dashboards/ExcellenceApps/Lms/lms.module.scss';
import { pageTitle } from 'services/pageTitle';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { InputOption, MyCustomSelect, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import { categoryOptions, data } from 'components/Dashboards/ExcellenceApps/Lms/lms.utils';
import { LmsCards } from 'components/Dashboards/ExcellenceApps/Lms/Components/lmsCards';

const Lms = () => {
    const { cards } = data;
    const [category, setCategory] = useState<ISelectOption<string>[]>(categoryOptions);
    const [searchText, setSearchText] = useState<string>('');
    pageTitle.set('Learning Materials Gallery');

    const handleCategory = (value) => {
        setCategory(value);
    };

    const handleSearchText = (e) => {
        setSearchText(e.target.value);
    };

    const handleClearSearchText = () => {
        setSearchText('');
    };
    const info: IToolTip = {
        infoList: [
            {
                content: 'Learning Materials Gallery tooltip info',
                color: null,
            },
        ],
        link: '',
    };
    return (
        <WidgetWithTitle
            className={classes.lmsContainer}
            title={'Filter gallery by keyword or type'}
            tooltip={info}
            titleFilters={[
                <div className={classes.filters}>
                    <TextSearchInput
                        placeholder="Search Gallery by Keyword"
                        value={searchText}
                        handleChange={handleSearchText}
                        handleClear={handleClearSearchText}
                        className={classes.inputSearch}
                        key={'TextSearchInput'}
                    />
                    <MyCustomSelect
                        key={'MyCustomSelect'}
                        value={category}
                        options={categoryOptions}
                        onChange={handleCategory}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isMulti={true}
                        title={'Category'}
                        placeholder={'Search by Category...'}
                        components={{
                            Option: InputOption,
                            ValueContainer: ValueContainer,
                        }}
                    />
                </div>,
            ]}
        >
            {cards && <LmsCards cards={cards} category={category.map((item) => item.value)} searchText={searchText} />}
        </WidgetWithTitle>
    );
};

export default Lms;
