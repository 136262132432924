export const activeDirectory = {
    get: () => {
        return localStorage.getItem('ActiveDirectory');
    },
    set: (activeDirectory) => {
        localStorage.setItem('ActiveDirectory', activeDirectory);
    },
    clear: () => {
        localStorage.removeItem('ActiveDirectory');
    },
};
