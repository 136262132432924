import { ConfirmSaveModal, CustomizedButton, CustomizedDropdownMenu } from 'components/common';
import Icon from 'components/common/Icons/icon';
import React, { useState } from 'react';
import {
    useMutationGenerateMitigation,
    useMutationGenerateResourcesMitigation,
    useMutationGenerateRiskSummaryMitigation,
    useMutationGenerateSubTaskMitigation,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/commentsMutation';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { IGetCardResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/queries/useQueryMainCard';
import { useVersionStore } from 'components/common/TreeProgramFilter/VersionSelect/store/versionSelectStore';

type ModalType = 'MITIGATION' | 'TASK_BREAKDOWN' | 'RESOURCE' | 'RISK_SUMMARY';

interface ICommentsAiMenu {
    isLoadingMitigation: boolean;
    setIsLoadingMitigation: React.Dispatch<React.SetStateAction<boolean>>;
    card: IGetCardResponse;
}
export const CommentsAiMenu = ({ isLoadingMitigation, setIsLoadingMitigation, card }: ICommentsAiMenu) => {
    const [modalType, setModalType] = useState<ModalType | null>(null);
    const { mutate: generateMitigationMutate, isLoading: generateMitigationLoading } = useMutationGenerateMitigation();
    const { mutate: generateSubTaskMitigationMutate, isLoading: generateSubTaskMitigationLoading } =
        useMutationGenerateSubTaskMitigation();
    const { mutate: generateResourceMitigationMutate, isLoading: generateResourceMitigationLoading } =
        useMutationGenerateResourcesMitigation();

    const { mutate: generateRiskSummaryMitigationMutate, isLoading: generateRiskSummaryMitigationLoading } =
        useMutationGenerateRiskSummaryMitigation();

    const { handleSnackBar } = useCustomSnackBar();
    const { sendEventWithDimensions } = useEventWithDimensions();
    const version = useVersionStore((state) => state.version);

    const isLoading =
        generateMitigationLoading ||
        generateSubTaskMitigationLoading ||
        generateResourceMitigationLoading ||
        generateRiskSummaryMitigationLoading;

    const onConfirm = () => {
        let mutation;
        let label;
        switch (modalType) {
            case 'MITIGATION': {
                mutation = generateMitigationMutate;
                label = 'Mitigation';
                break;
            }
            case 'TASK_BREAKDOWN': {
                mutation = generateSubTaskMitigationMutate;
                label = 'Sub Task Mitigation';
                break;
            }
            case 'RESOURCE': {
                mutation = generateResourceMitigationMutate;
                label = 'Resource Mitigation';
                break;
            }

            case 'RISK_SUMMARY': {
                mutation = generateRiskSummaryMitigationMutate;
                label = 'Resource Mitigation';
                break;
            }
        }

        sendEventWithDimensions({
            category: 'Gen AI',
            action: 'create',
            label: label,
        });
        setModalType(null);
        setIsLoadingMitigation(true);

        const params = version?.id
            ? {
                  taskId: card.taskId,
                  laneId: card.status.id,
                  isVirtual: card.isVirtual,
                  cardId: card.isVirtual ? null : card.id,
                  versionId: version.id,
              }
            : {
                  taskId: card.taskId,
                  laneId: card.status.id,
                  isVirtual: card.isVirtual,
                  cardId: card.isVirtual ? null : card.id,
              };

        mutation(params, {
            onError: (error) => {
                setIsLoadingMitigation(false);
                handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
            },
        });
    };

    return (
        <>
            <CustomizedDropdownMenu
                disabled={isLoadingMitigation}
                triggerElement={
                    <CustomizedButton
                        endIcon={<Icon name={'header-arrow-down'} size="15" color={'#2C8FA5'} />}
                        color={'secondary'}
                        isLoading={isLoadingMitigation}
                    >
                        Generative AI Helper
                    </CustomizedButton>
                }
            >
                <div onClick={() => setModalType('TASK_BREAKDOWN')}>Suggest Task Breakdown</div>
                <div onClick={() => setModalType('RESOURCE')}>Suggest Required Resources</div>
                <div onClick={() => setModalType('MITIGATION')}>Suggest Mitigation</div>
                <div onClick={() => setModalType('RISK_SUMMARY')}>Summarize Risk</div>
            </CustomizedDropdownMenu>
            <ConfirmSaveModal
                isShowModal={modalType !== null}
                onHideModal={() => setModalType(null)}
                onConfirm={onConfirm}
                actionButtonLabel={'Yes'}
                isLoading={isLoading}
            >
                A new comment with the Generative AI response will be added to this card. <br /> Note this request might
                take a while… <br /> You’ll get a notification when its ready. <br /> Continue?
            </ConfirmSaveModal>
        </>
    );
};
