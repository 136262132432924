import React, { useEffect, useMemo, useState } from 'react';

import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/programCriticalAnalysis.module.scss';
import CriticalAnalysisHeatMap from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisHeatMap/criticalAnalysisHeatMap';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { LoaderContainer, MyCustomSelect } from 'components/common';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import NoData from 'components/common/NoData/noData';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import {
    useQueryHeatMapActivityDuration,
    useQueryHeatMapActivityResources,
    useQueryHeatMapActivityStart,
    useQueryHeatMapActivityStartOrFinish,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/queries/criticalAnalysisQuery';

import { noop } from 'lodash';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { IRenderCriticalAnalysisHeatMap } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { useQueryState } from 'hooks/useQueryState';
import {
    defaultHeatMapOption,
    heatMapInitialData,
    heatMapOptions,
    HeatMapOptionsValue,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/utils';

const serializeHeatmap = (data) => {
    if (!data) return null;
    return data.map((item, index) => {
        return {
            id: index,
            value: item.percentile,
            numOfTasksPerMonth: item.numOfTasksPerMonth,
            numOfDelayDrivers: item.numOfDelayDrivers,
            label: item.date,
        };
    });
};

const RenderCriticalAnalysisHeatMap = ({
    projectId,
    contractId,
    handleClick = noop,
    widgetId,
    latestVersionId,
    localFilters,
    filter,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: IRenderCriticalAnalysisHeatMap) => {
    const idList = useMemo(() => (latestVersionId ? [latestVersionId] : []), [latestVersionId]);
    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes,
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                  }),

        [filter, globalFilterData],
    );
    const { generateFilters: formatedFilter } = useGlobalFiltersHook({ widgetId, filter, latestVersionId });
    const { data: heatMapActivityStarts, isLoading: heatMapActivityStartLoading } = useQueryHeatMapActivityStart({
        id: idList,
        filter: {
            filters,
        },
    });
    const { data: heatMapActivityDuration, isLoading: heatMapActivityDurationLoading } =
        useQueryHeatMapActivityDuration({
            id: idList,
            filter: {
                filters,
            },
        });
    const { data: heatMapActivityStartOrFinish, isLoading: heatMapActivityStartOrFinishLoading } =
        useQueryHeatMapActivityStartOrFinish({
            id: idList,
            filter: {
                filters,
            },
        });
    const { data: heatMapActivityResources, isLoading: heatMapActivityResourcesLoading } =
        useQueryHeatMapActivityResources({
            id: idList,
            filter: {
                filters,
            },
        });

    const isLoading =
        heatMapActivityStartLoading &&
        heatMapActivityDurationLoading &&
        heatMapActivityStartOrFinishLoading &&
        heatMapActivityResourcesLoading;

    const heatmap = {
        heatMapActivityStarts,
        heatMapActivityDuration,
        heatMapActivityStartOrFinish,
        heatMapActivityResources,
    };
    const [riskDensityBy, setRiskDensityBy] = useQueryState('riskDensityBy');

    const selectByInitialValue: string | undefined = useMemo(() => {
        return widgetId && localFilters?.selectBy ? localFilters.selectBy.value : riskDensityBy;
    }, [widgetId, localFilters, riskDensityBy]);

    const [selectBy, setSelectBy] = useState<ISelectOption<HeatMapOptionsValue> | undefined>(undefined);
    useEffect(() => {
        if (!selectByInitialValue && !widgetId) {
            setRiskDensityBy(defaultHeatMapOption.value);
            setSelectBy(defaultHeatMapOption);
        } else {
            setSelectBy(heatMapOptions.find((filter) => filter.value === selectByInitialValue));
        }
    }, [selectByInitialValue, widgetId]);

    const { title, info, data } = (selectBy && heatmap[selectBy?.value]) || heatMapInitialData;
    const serialized = serializeHeatmap(data);

    const handleChange = (value) => {
        setRiskDensityBy(value.value);
    };

    const id = 'RiskDensityByActivityStarts';
    const componentKey = 'riskHeatMap';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;

    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });

    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);

    return (
        <ErrorBoundary>
            <WidgetWithTitle
                id={id}
                title={title}
                tooltip={info}
                titleComponents={[
                    <div className={classes.titleComponents} key={'filterCriticalAnalysisHeatMap'}>
                        {widgetId ? (
                            <h3>{selectBy?.label}</h3>
                        ) : (
                            <MyCustomSelect
                                id={'filterCriticalAnalysisHeatMap'}
                                value={selectBy}
                                options={heatMapOptions}
                                onChange={handleChange}
                                key={'filterCriticalAnalysisHeatMap-Key'}
                            />
                        )}
                        {externalComponents && <div key={'externalComponents'}>{externalComponents}</div>}
                    </div>,
                    <AddWidget
                        key={'AddWidget'}
                        title={title}
                        componentKey={componentKey}
                        projectId={projectId}
                        contractId={contractId}
                        widgetId={widgetId}
                        route={route}
                        filters={filters}
                        localFilters={{ selectBy }}
                    />,
                    <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                    <ExportDropdown key={'export'} title={title} subTitle={subTitle} />,
                ]}
                titleFilters={[
                    editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,

                    widgetId && formatedFilter.length > 0 ? (
                        <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formatedFilter} />
                    ) : null,
                ]}
            >
                {!isLoading && serialized?.length > 0 && (
                    <>
                        {!widgetId && (
                            <div className={classes.helpText}>
                                Click any month to filter the activities table below.
                            </div>
                        )}
                        <CriticalAnalysisHeatMap data={serialized} handleClick={handleClick} />
                    </>
                )}
                {isLoading && !data && (
                    <LoaderContainer>
                        <OverlayWithSpinner />
                    </LoaderContainer>
                )}
                {!isLoading && (!serialized || serialized.length === 0) && <NoData />}
            </WidgetWithTitle>
        </ErrorBoundary>
    );
};

export default RenderCriticalAnalysisHeatMap;
