import { Link, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import classes from 'components/Dashboards/ExcellenceApps/LogicChecker/BreadCrumbs.module.scss';
import { getCheck } from 'components/Dashboards/ExcellenceApps/LogicChecker/helper';
import {
    useQueryGetLogicCheckerCategoryResults,
    useQueryGetValidationTypeDrillDown,
} from 'components/Dashboards/ExcellenceApps/LogicChecker/queries/useQueryLogicChecker';
import useHashmapConverter from 'hooks/useHashmapConverter';
import qs from 'qs';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import { useLogicCheckerFilterHook } from 'components/Dashboards/ExcellenceApps/LogicChecker/hooks/useLogicCheckerFilterHook';

const Breadcrumbs = ({ displayLevel3Activities = false }: { displayLevel3Activities?: boolean }) => {
    const { id: versionId, category, check } = useParams<{ id: string; category?: string; check?: string }>();
    const { idList } = useHashmapConverter(versionId);
    const fileMetaDataId = idList.length > 0 ? parseInt(idList.join(',')) : undefined;
    const filters = useLogicCheckerFilterHook();
    const { data: logicCheckerData } = useQueryGetLogicCheckerCategoryResults({
        fileMetaDataId,
        filter: {
            filters,
        },
    });
    const parsedCategory = category ? category.replace('_', '/') : '';
    const parsedCheck = check ? check.replace('_', '/') : '';

    const drillDownParams = useMemo(() => {
        const urlParams = new URLSearchParams(location.search);
        return { accessor: urlParams.get('accessor'), rowId: urlParams.get('rowId') };
    }, [location.search]);

    const pickedCheck = getCheck({
        categoriesSummaryRows: logicCheckerData?.categoriesSummaryRows,
        parsedCategory,
        parsedCheck,
    });

    const { data: validationTypeDrillDown } = useQueryGetValidationTypeDrillDown({
        fileMetaDataId,
        componentId: pickedCheck?.validationId,
        filter: {
            filters,
        },
    });

    const cell = useMemo(() => {
        if (drillDownParams.rowId && drillDownParams.accessor && validationTypeDrillDown?.rows) {
            const row = validationTypeDrillDown.rows[drillDownParams.rowId];
            return row['drillDownParams-' + drillDownParams.accessor];
        } else {
            return undefined;
        }
    }, [drillDownParams.rowId, drillDownParams.accessor, validationTypeDrillDown]);
    const rowAccessor = cell?.rowAccessor || null;
    const baseLocation = `/dashboard/program/${excellenceAppsConfig.logicQualityCheck.link}`;

    const activitiesDrilldown = rowAccessor ? `Activities Drilldown (${rowAccessor})` : `Activities Drilldown`;

    const queryString = qs.stringify(
        { accessor: drillDownParams.accessor, rowId: drillDownParams.rowId },
        { addQueryPrefix: true, skipNulls: true },
    );
    const activitiesDrilldownLink = `/activities${queryString}`;
    return (
        <div className={classes.breadcrumbsContainer}>
            <Link to={`${baseLocation}/${versionId}`}>Summary</Link>

            {category && (
                <>
                    <span className={classes.arrowSeparator}> {'>'} </span>
                    <Link to={`${baseLocation}/${versionId}/${category}`}>{category} </Link>
                </>
            )}

            {check && !displayLevel3Activities && (
                <>
                    <span className={classes.arrowSeparator}> {'>'} </span>
                    <Link to={`${baseLocation}/${versionId}/${category}/${check}`}>{check}</Link>
                </>
            )}
            {check && displayLevel3Activities && (
                <>
                    <span className={classes.arrowSeparator}> {'>'} </span>
                    <Link to={`${baseLocation}/${versionId}/${category}/${check}`}>{check} </Link>
                    <span className={classes.arrowSeparator}> {'>'} </span>
                    <Link to={`${baseLocation}/${versionId}/${category}/${check}${activitiesDrilldownLink}`}>
                        {activitiesDrilldown}
                    </Link>
                </>
            )}
        </div>
    );
};

export default Breadcrumbs;
