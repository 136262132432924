import { TreeProgramTopHeader } from 'components/common';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import Breadcrumbs from 'components/Dashboards/ExcellenceApps/LogicChecker/BreadCrumbs';
import PageContainer from 'components/common/PageContainer/PageContainer';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { ActionsMenu } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/actionsMenu';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { SummaryWrapper } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/summaryWrapper';
import { MilestoneFragnetFilter } from 'components/common/GlobalFilterComponent/components/MilestoneFragnetFilter/MilestoneFragnetFilter';
import { TagsFilter } from 'components/common/GlobalFilterComponent/components/TagsFilter/TagsFilter';
import { ActivityCodesFilter } from 'components/common/GlobalFilterComponent/components/ActivityCodesFilter/ActivityCodesFilter';
import { WbsFilter } from 'components/common/GlobalFilterComponent/components/WbsFilter/wbsFilter';
import { GlobalFilterComponent } from 'components/common/GlobalFilterComponent/GlobalFilterComponent';
import { GlobalFilterButton } from 'components/common/GlobalFilterButton/GlobalFilterButton';

export const SummaryPage = () => {
    useFullStoryPageView({ pageName: excellenceAppsConfig.logicQualityCheck.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <TreeProgramTopHeader pageTitle={excellenceAppsConfig.logicQualityCheck.title}>
                    <ShareInsightButton
                        title={excellenceAppsConfig.logicQualityCheck.title}
                        link={window.location.href}
                    />
                    <ActionsMenu />
                    <GlobalFilterButton />
                </TreeProgramTopHeader>
                <GlobalFilterComponent defaultValues={{ milestoneFragnet: null, tags: [], activityCodes: [] }}>
                    <MilestoneFragnetFilter name={'milestoneFragnet'} />
                    <TagsFilter name={'tags'} />
                    <ActivityCodesFilter name={'activityCodes'} />
                </GlobalFilterComponent>
                <Breadcrumbs />
            </HeaderWrapper>
            <MainContent>
                <SummaryWrapper />
            </MainContent>
        </PageContainer>
    );
};
