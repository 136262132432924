import classes from './TimezoneSelector.module.scss';
import { MyCustomSelect } from 'components/common/MyCustomSelect/myCustomSelect';
import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { timezoneOptions } from 'components/common/TimezoneSelector/TimezoneSelector.utils';
import { useTimezoneStore } from 'components/common/TimezoneSelector/store/timezoneStore';

export const TimezoneSelector = () => {
    const { setTimezone } = useTimezoneStore();
    const timezone = useTimezoneStore((state) => state.timezone);

    const handleChange = (value) => {
        moment.tz.setDefault(value.value);
        setTimezone(value);
    };

    return (
        <div className={classes.timezoneSelectorContainer}>
            <label className={classes.label}>Default Timezone</label>
            <MyCustomSelect value={timezone} options={timezoneOptions} onChange={handleChange} menuPlacement={'auto'} />
            <div className={classes.label}>(UTC: {moment().format('Z')})</div>
        </div>
    );
};
