import { SummaryRow } from 'components/Dashboards/ExcellenceApps/LogicChecker/SummaryRow';
import { SummaryTable } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/tables/summaryTable';
import NoData from 'components/common/NoData/noData';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { useQueryGetLogicCheckerSummary } from 'components/Dashboards/ExcellenceApps/LogicChecker/queries/useQueryLogicChecker';
import { useLogicCheckerFilterHook } from 'components/Dashboards/ExcellenceApps/LogicChecker/hooks/useLogicCheckerFilterHook';

export const SummaryWrapper = () => {
    const { id: versionId } = useParams<{ id: string }>();
    const { idList } = useHashmapConverter(versionId);
    const fileMetaDataId = idList.length > 0 ? parseInt(idList.join(',')) : undefined;
    const filters = useLogicCheckerFilterHook();
    const { data } = useQueryGetLogicCheckerSummary({
        fileMetaDataId,
        filter: {
            filters: filters,
        },
    });
    return data && data.totalActivities ? (
        <>
            <SummaryRow />
            <SummaryTable />
        </>
    ) : (
        <NoData />
    );
};
