import React, { useEffect, useMemo, useState } from 'react';
import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';
import CriticalAnalysisIntensity from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisIntensity/criticalAnalysisIntensity';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { LoaderContainer } from 'components/common';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import NoData from 'components/common/NoData/noData';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { useQueryCriticalityWeight } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/queries/criticalAnalysisQuery';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { IComponentProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import SwitchComponent from 'components/common/Switch/Switch';
import { useAnnotationStore } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisIntensity/store/annotationStore';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisIntensity/criticalAnalysisIntensity.module.scss';

const RenderCriticalAnalysisIntensity = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    compareVersionId,

    filter,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: IComponentProps) => {
    const idList = useMemo(() => (latestVersionId ? [latestVersionId] : []), [latestVersionId]);
    const isShowCriticalityAnnotation = useAnnotationStore((store) => store.isShowCriticalityAnnotation);
    const { setIsShowCriticalityAnnotation } = useAnnotationStore();
    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes,
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                  }),

        [filter, globalFilterData],
    );
    const { generateFilters: formatedFilter } = useGlobalFiltersHook({ widgetId, filter, latestVersionId });
    const { data: criticality, isLoading: criticalityLoading } = useQueryCriticalityWeight({
        id: [idList],
        wbsId: -1,
        latestVersion: latestVersionId,
        comparedVersion: compareVersionId || latestVersionId,
        filter: {
            filters,
        },
    });

    const title = 'Criticality Weighted S-Curve';
    const componentKey = 'criticalityWeightedSCurve';
    const id = 'IntensityCriticalityWeight';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);

    return (
        <ErrorBoundary>
            <WidgetWithTitle
                id={id}
                title={title}
                tooltip={criticality?.info}
                titleComponents={[
                    externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                    <div className={classes.annotationFilter}>
                        {isShowCriticalityAnnotation ? 'Hide Annotations' : 'Show Annotations'}
                        <SwitchComponent
                            onChange={(event) => setIsShowCriticalityAnnotation(event.target.checked)}
                            checked={isShowCriticalityAnnotation}
                        />
                    </div>,
                    <AddWidget
                        key={'AddWidget'}
                        componentKey={componentKey}
                        title={title}
                        projectId={projectId}
                        contractId={contractId}
                        widgetId={widgetId}
                        route={route}
                        filters={filters}
                    />,
                    <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                    <ExportDropdown key={'export'} title={title} subTitle={subTitle} />,
                ]}
                titleFilters={[
                    editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                    widgetId && formatedFilter.length > 0 ? (
                        <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formatedFilter} />
                    ) : null,
                ]}
            >
                {criticality?.dataset?.length > 0 && (
                    <CriticalAnalysisIntensity data={criticality} isShowAnnotation={isShowCriticalityAnnotation} />
                )}
                {criticalityLoading && !criticality && (
                    <LoaderContainer>
                        <OverlayWithSpinner />
                    </LoaderContainer>
                )}
                {!criticalityLoading && (!criticality || criticality?.dataset?.length === 0) && <NoData />}
            </WidgetWithTitle>
        </ErrorBoundary>
    );
};

export default React.memo(RenderCriticalAnalysisIntensity);
