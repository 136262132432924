import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useMutation, useQuery } from 'react-query';
import moment from 'moment-timezone';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

const getActivities = (params) => {
    return network.post(API_URL().FETCH_PROGRAM_DASHBOARD_TOP_DELAY_DRIVERS, params).then((response) => {
        return {
            id: 4,
            title: 'Top Critical Activities & Delay Drivers',
            info: response.data.info,
            startDate: response.data.startDate,
            endDate: response.data.finishDate,
            data: {
                rows: response.data.delayDrivers.splice(0, 100),
            },
        };
    });
};

const getTopDurations = (params) => {
    return network
        .post(API_URL().FETCH_TOP_DURATION_OVERRUN_RISK_DATA, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getTopOverdue = (params) => {
    return network.post(API_URL().FETCH_TOP_OVERRUN_ACTIVITIES_DATA, params).then((response) => {
        return {
            info: response.data.info,
            startDate: response.data.startDate,
            endDate: response.data.finishDate,
            data: {
                rows: response.data.delayDrivers.splice(0, 100),
            },
        };
    });
};

const useQueryActivities = (params) => {
    return useQuery(['activities', params], () => getActivities(params), {
        // TODO: find better solution to the enabled option
        enabled: params?.id.length > 0,
        placeholderData: {
            id: 0,
            title: '',
            info: undefined,
            data: { rows: [] },
            startDate: moment(),
            endDate: moment(),
        },
    });
};

const extractDelayDriverCsv = (params) => {
    const url = API_URL().EXTRACT_DELAY_DRIVER_CSV;

    return network
        .post(url, { fileMetaDataId: params.fileMetaDataId, filter: { filters: params.filter } })
        .then((response) => {
            const blob = new Blob([response.data], {
                type: 'text/plain',
            });
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = `${params.fileName}.csv`;
            a.click();

            return true;
        })
        .catch((error) => {
            throw error;
        });
};

const useQueryTopDurations = (params) => {
    return useQuery(['topDurations', params], () => getTopDurations(params), {
        enabled: params.id.length > 0,
    });
};

const useQueryTopOverdue = (params) => {
    return useQuery(['topOverdue', params], () => getTopOverdue(params), {
        enabled: Boolean(params.id) && Boolean(params.compareVersionId),
    });
};

const useMutationExtractDelayDriverCsv = () => {
    return useMutation<unknown, QueryError, IExtractDelayDriverCsvPayload>((params) => extractDelayDriverCsv(params));
};

export { useQueryActivities, useQueryTopDurations, useQueryTopOverdue, useMutationExtractDelayDriverCsv };

interface IExtractDelayDriverCsvPayload {
    fileMetaDataId: number;
    fileName: string;
    filter: IFilter[] | undefined;
}
