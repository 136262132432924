import { ProjectCell } from 'components/Dashboards/Portfolio/PortfolioOverview/cells/projectCell';
import { FinishDateCell } from 'components/Dashboards/Portfolio/PortfolioOverview/cells/finishDate';
import { SubmittedCompletionDurationCell } from 'components/Dashboards/Portfolio/PortfolioOverview/cells/submittedCompletionDuration';
import { ScheduleAdherenceCell } from 'components/Dashboards/Portfolio/PortfolioOverview/cells/scheduleAdherence';
import { ColoredDotCell } from 'components/common/Tables/Cells/ColoredDotCell';
import { getColorFromStatus, getElementByName } from 'components/Dashboards/Portfolio/PortfolioOverview/helper';
import { DefaultColumnFilter } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryFilter/programLibraryFilter';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import { ITableColumnState } from 'hooks/useTableCurrentState';
export const columns = [
    {
        Header: 'Project / Sub-program',

        ...columnsCommonConfig.projectNameSubProgram,
        accessor: (rowOriginal) => `${rowOriginal.project.name} / ${rowOriginal.contract.name}`,

        Filter: ({ column }) => <DefaultColumnFilter placeholder={'Search by name'} column={column} />,
        Cell: ({ row }) => {
            const original = row.original;
            const title = `${original.project.name} / ${original.contract.name}`;
            return <ProjectCell title={title} versionHashCode={original.version_hash_code} />;
        },
    },
    {
        Header: 'Progress',

        ...columnsCommonConfig.progress,
        accessor: (rowOriginal) => rowOriginal.progress.value.toFixed(2),
        Cell: ({ row }) => {
            const progress = row.original.progress;
            return (
                <ColoredDotCell
                    color={getColorFromStatus(progress.fluxIndex, 'Progress')}
                    toolTipContent={String(progress.value)}
                />
            );
        },
    },
    {
        Header: 'Risk',
        ...columnsCommonConfig.risk,
        accessor: (rowOriginal) => rowOriginal.risk.value.toFixed(2),
        Cell: ({ row }) => {
            const risk = row.original.risk;
            return (
                <ColoredDotCell
                    color={getColorFromStatus(risk.fluxIndex, 'Risk')}
                    toolTipContent={String(risk.value)}
                />
            );
        },
    },
    {
        Header: 'Quality',
        ...columnsCommonConfig.quality,
        accessor: (rowOriginal) => rowOriginal.quality.value.toFixed(2),
        Cell: ({ row }) => {
            const quality = row.original.quality;

            return (
                <ColoredDotCell
                    color={getColorFromStatus(quality.fluxIndex, 'Quality')}
                    toolTipContent={String(quality.value)}
                />
            );
        },
    },
    {
        Header: 'Finish Date',
        ...columnsCommonConfig.finishDate,
        accessor: (rowOriginal) => getElementByName(rowOriginal.finish_date.value, 'date').value,
        Cell: ({ row }) => <FinishDateCell row={row} />,
    },
    {
        Header: 'Submitted Completion (Total Duration)',
        ...columnsCommonConfig.submittedCompletionDate,
        accessor: (rowOriginal) => Number(rowOriginal.submitted_completion.value).toFixed(2),
        Cell: ({ row }) => <SubmittedCompletionDurationCell row={row} />,
    },
    {
        Header: 'Schedule Performance by Criticality',
        ...columnsCommonConfig.criticalityAdherence,
        accessor: (rowOriginal) =>
            Number(getElementByName(rowOriginal.criticality_adherence.value, 'result').value).toFixed(2),
        Cell: ({ row }) => {
            const criticalityAdherence = row.original.criticality_adherence;
            const fluxIndex = getElementByName(criticalityAdherence.value, 'result').fluxIndex;
            return (
                <ScheduleAdherenceCell
                    value={criticalityAdherence.value}
                    color={getColorFromStatus(fluxIndex, 'CriticalityAdherence')}
                />
            );
        },
    },
    {
        Header: 'Schedule Performance by Milestone',
        ...columnsCommonConfig.milestoneAdherence,
        accessor: (rowOriginal) =>
            Number(getElementByName(rowOriginal.milestone_adherence.value, 'result').value).toFixed(2),
        Cell: ({ row }) => {
            const milestoneAdherence = row.original.milestone_adherence;
            const fluxIndex = getElementByName(milestoneAdherence.value, 'result').fluxIndex;
            return (
                <ScheduleAdherenceCell
                    value={milestoneAdherence.value}
                    color={getColorFromStatus(fluxIndex, 'MilestoneAdherence')}
                />
            );
        },
    },
];

export const initialState: ITableColumnState = {
    sortBy: [{ id: 'projectNameSubProgram' }],
};
