import { create } from 'zustand';
import { ICreateEditCommentPayload } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/commentsMutation';

interface IDEFAULT_STORE_VALUES {
    commentData: ICreateEditCommentPayload;
    commentId: number | boolean;
}

const DEFAULT_STORE_VALUES: IDEFAULT_STORE_VALUES = {
    commentData: {
        cardId: undefined,
        comment: '',
        id: null,
        mentionedList: [],
        isVirtual: false,
    },
    commentId: false,
};

const commentsStore = (set) => ({
    ...DEFAULT_STORE_VALUES,
    setCommentData: (value) => set({ commentData: value }),
    setCommentId: (value) => set({ commentId: value }),
    resetCommentData: () => set(DEFAULT_STORE_VALUES),
});

export const useCommentsStore = create(commentsStore);
