import React, { useState } from 'react';
import { IAddWidget, IDashboard } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import useUserHook from 'hooks/useUserHook';
import { useDashboardStore } from 'components/Dashboards/Project/Components/CustomDashboard/zustand_store/dashboardStore';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryGetDashboards } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQueryCustomDashboard';
import { addWidgetToDashboard } from 'components/Dashboards/Project/Components/CustomDashboard/customDashboard.utils';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import Icon from 'components/common/Icons/icon';
import { ListWrapper } from 'components/Dashboards/Project/Components/CustomDashboard/ListWrapper/listWrapper';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import {
    useMutationCreateDashboard,
    useMutationSetDashboardById,
} from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySetDashboardById';
import classes from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget.module.scss';
import { Link } from 'react-router-dom';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';

/**
 * Modal for adding widget to the selected pinboard.
 *
 * @param {keyof IHashmap}  componentKey - unique string determines which component is rendering.
 * @param {number}  projectId - widget project ID (optional for pages without project & contract).
 * @param {number}  contractId - widget contract ID (optional for pages without project & contract).
 * @param {string}  widgetId - unique ID created when adding a new widget to a specific pinboard.
 * @param {string}  elementId -  unique id determines which tableConfig property to use tableWidgetWrapper (optional only for tables).
 * @param {string}  route - url of original widget place - used in pinboard widgets in link back to original component location in the application.
 * @param {IFilterLegacy[]}  filters - list of filters used in the API for fetching data.
 * @param {ILocalFilters}  localFilters - list of filters used to filter fetched data from API in the component.
 * @returns  the Widget JSX Component
 * */
export const AddWidget = ({
    componentKey,
    title,
    projectId,
    contractId,
    widgetId,
    elementId,
    route,
    filters,
    localFilters,
    callBack,
}: IAddWidget) => {
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [dropdownAnchor, setDropdownAnchor] = useState(null);
    const [modalManagerOpen, setModalManagerOpen] = useState<boolean>(false);
    const { setSelectedDashboard } = useDashboardStore();
    const { handleSnackBar } = useCustomSnackBar();
    const { data, refetch } = useQueryGetDashboards();
    const dashboards: IDashboard[] = data?.dashboardDetails || [];
    const { mutate: mutateCreateDashboard } = useMutationCreateDashboard();
    const { mutate: mutateSetDashboardById } = useMutationSetDashboardById();

    const { ability } = useUserHook();

    const { sendEventWithDimensions } = useEventWithDimensions();

    const handleDropdownOpen = (event) => {
        setDropdownAnchor(event.currentTarget);
        setDropdownOpen((isOpen) => !isOpen);
    };

    const handleAddWidgetToDashboard = ({
        dashboardId,
        dashboards,
    }: {
        dashboardId: number | undefined;
        dashboards: IDashboard[] | undefined;
    }) => {
        if (!dashboards) return null;
        const dashboard = dashboards?.find((item) => item.id === dashboardId);
        const updatedDashboard = addWidgetToDashboard({
            componentKey,
            title,
            projectId,
            contractId,
            elementId,
            dashboard,
            route,
            filters,
            localFilters,
        });

        dashboard &&
            mutateSetDashboardById(
                { dashboard: updatedDashboard },
                {
                    onSuccess: (response) => {
                        const newWidgets = response ? response.widgets : [];
                        const widgetId = newWidgets[newWidgets.length - 1].id;
                        callBack && widgetId && callBack(widgetId);
                        setDropdownOpen(false);
                        handleSnackBar(
                            <>
                                Component successfully added to Pinboard{' '}
                                <Link
                                    to={`/dashboard/project/${projectConfig.pinboards.link}/${dashboard.id}`}
                                    className={classes.link}
                                >
                                    {dashboard.name}
                                </Link>
                            </>,
                            'success',
                        );

                        setSelectedDashboard({
                            dashboard: dashboardId,
                        });
                        sendEventWithDimensions({
                            category: 'Pinboard',
                            action: 'Add To Pinboard',
                            label: dashboard?.name,
                        });
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                    },
                    onSettled: () => refetch(),
                },
            );
    };
    const handleCreateDashboard = (values) => {
        const name = values.dashboard;
        mutateCreateDashboard(
            { name },
            {
                onSuccess: (response) => {
                    const dashboardId = response.id;
                    setModalManagerOpen(false);
                    refetch().then((response) => {
                        handleSnackBar('Dashboard created successfully', 'success');
                        handleAddWidgetToDashboard({ dashboardId, dashboards: response?.data?.dashboardDetails });
                        sendEventWithDimensions({
                            category: 'Pinboard',
                            action: 'Create Pinboard',
                            label: name,
                        });
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                },
            },
        );
    };

    if (!ability.can('update', 'PinboardsEdit') || widgetId) {
        return null;
    }

    return (
        <>
            <CustomizedTooltip
                tooltipPosition={TooltipPosition.Top}
                tooltipContent={`Add to Custom Pinboard`}
                triggerElement={
                    <CustomizedRoundIcon onClick={handleDropdownOpen}>
                        <Icon name={'add-widget'} />
                    </CustomizedRoundIcon>
                }
            />

            <ListWrapper
                title={'Add To:'}
                dropdownAnchor={dropdownAnchor}
                setDropdownOpen={setDropdownOpen}
                dropdownOpen={dropdownOpen}
                handleCreateDashboard={handleCreateDashboard}
                dashboards={dashboards}
                handleClick={handleAddWidgetToDashboard}
                modalManagerOpen={modalManagerOpen}
                setModalManagerOpen={setModalManagerOpen}
            />
        </>
    );
};
