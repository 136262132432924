import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Settings/Components/UserGroups/components/userGroups.module.scss';
import { Controller, useForm } from 'react-hook-form';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { CustomizedButton, CustomizedFormErrorMessage, MyCustomSelect } from 'components/common';
import {
    mapper,
    projectsMapperFromBe,
    rolesListMapper,
} from 'components/Settings/Components/UserGroups/userGroups.utils';
import { MenuListSelectAll } from 'components/common/MyCustomSelect/myCustomSelect';
import useUserHook from 'hooks/useUserHook';
import {
    useMutationUpdateUserGroup,
    useQueryGetAllUserGroups,
    useQueryGetRolesList,
} from 'components/Settings/Components/UserGroups/queries/userGroupsQuery';
import { useQueryGetProgramGeneralHierarchyList } from 'components/common/TreeProgramFilter/Queries/useQueryGetProgramGeneralHierarchyList';
import { IUserGroupForm } from 'components/Settings/Components/UserGroups/userGroups.utils.type';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useHistory, useParams } from 'react-router-dom';
import { useMemo } from 'react';

export const UserGroupsEdit = () => {
    const { ability } = useUserHook();
    const { data: groups } = useQueryGetAllUserGroups({ enabled: ability.can('view', 'UserManagement') });
    const { data: rolesList } = useQueryGetRolesList();
    const { data: projectHierarchyList } = useQueryGetProgramGeneralHierarchyList('TreeProgramFilter');
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm<IUserGroupForm>();
    const { handleSnackBar } = useCustomSnackBar();
    const { customerId } = useUserHook();
    const { mutate, isLoading } = useMutationUpdateUserGroup();
    const { userGroupId } = useParams<{ userGroupId: string }>();
    const group = useMemo(() => groups?.find((item) => item.group_id === Number(userGroupId)), [groups, userGroupId]);
    const history = useHistory();

    const handleSave = (data) => {
        if (!customerId) return null;
        mutate(
            {
                customer_id: customerId,
                group_name: group.group_name,
                projects_list: data.projects_list.map((item) => item.value),
                roles_list: data.roles_list.map((item) => item.value),
                newName: data.group_name.trim(),
            },
            {
                onSuccess: () => {
                    handleSnackBar('Successfully updated!', 'success');
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
                onSettled: () => {
                    history.goBack();
                },
            },
        );
    };

    const handleCancel = () => {
        history.goBack();
    };

    const rolesOptions = rolesList.map(mapper);
    const projectsOptions = projectHierarchyList?.children.map(mapper);

    return (
        <WidgetWithTitle title={'User Groups'}>
            {group && (
                <form onSubmit={handleSubmit(handleSave)}>
                    <div className={classes.container}>
                        <div className={classes.row}>
                            <label className={classes.label}>Group Name</label>
                            <div className={classes.inputWrapper}>
                                <Controller
                                    render={({ field }) => (
                                        <TextSearchInput
                                            {...field}
                                            id={'usergroups-groupName'}
                                            isSearch={false}
                                            className={classes.input}
                                            placeholder="Enter group name..."
                                            maxLength={60}
                                            handleClear={() => {
                                                setValue('group_name', '');
                                            }}
                                            handleChange={(e) => setValue('group_name', e.target.value)}
                                        />
                                    )}
                                    defaultValue={group.group_name}
                                    name="group_name"
                                    control={control}
                                    rules={{
                                        validate: {
                                            min: (v) => v.length > 2 || 'must be at least 2 characters',
                                            max: (v) => v.length < 60 || 'must be no more than 60 characters',
                                        },
                                    }}
                                />
                                {errors.group_name && <CustomizedFormErrorMessage text={errors.group_name.message} />}
                            </div>
                        </div>
                        <div className={classes.row}>
                            <label className={classes.label}>Roles</label>
                            <div className={classes.inputWrapper}>
                                <Controller
                                    render={({ field }) => (
                                        <MyCustomSelect
                                            {...field}
                                            options={rolesOptions}
                                            isClearable={true}
                                            isMulti
                                            id={'usergroups-roles'}
                                            sortOption={{ key: 'label', order: 'asc' }}
                                        />
                                    )}
                                    defaultValue={group.roles_list
                                        .filter((item) => item.role_name !== 'ROLE_DEFAULT')
                                        .map(rolesListMapper)}
                                    name="roles_list"
                                    control={control}
                                    rules={{
                                        validate: {
                                            required: (value) => value.length > 0 || 'Must select at least one role',
                                        },
                                    }}
                                />
                                {errors.roles_list && <CustomizedFormErrorMessage text={errors.roles_list.message} />}
                            </div>
                        </div>
                        <div className={classes.row}>
                            <label className={classes.label}>Projects</label>
                            <div className={classes.inputWrapper}>
                                <Controller
                                    render={({ field }) => (
                                        <MyCustomSelect
                                            {...field}
                                            options={projectsOptions}
                                            isClearable={true}
                                            isMulti
                                            id={'usergroups-projects'}
                                            components={{
                                                MenuList: MenuListSelectAll,
                                            }}
                                            sortOption={{ key: 'label', order: 'asc' }}
                                        />
                                    )}
                                    defaultValue={group.projects_list?.map(projectsMapperFromBe)}
                                    name="projects_list"
                                    control={control}
                                    rules={{
                                        validate: {
                                            required: (value) => value.length > 0 || 'Must select at least one project',
                                        },
                                    }}
                                />
                                {errors.projects_list && (
                                    <CustomizedFormErrorMessage text={errors.projects_list.message} />
                                )}
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.actionWrapper}>
                                <CustomizedButton type="submit" isLoading={isLoading} disabled={isLoading}>
                                    Save
                                </CustomizedButton>
                                <CustomizedButton onClick={handleCancel} color={'secondary'}>
                                    Cancel
                                </CustomizedButton>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </WidgetWithTitle>
    );
};
