import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const store = (set) => ({
    calendarViewRange: 'year',
    showActivityLabels: false,
    showProgressLine: false,
    showBaseline: true,
    update: (value) => set(() => ({ ...value })),
});

export const useGanttSettingsStore = create(persist(store, { name: 'ganttSettings' }));
