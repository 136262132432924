import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { IDashboardStore } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

export const DEFAULT_STORE_VALUES = {
    selectedDashboard: {
        dashboard: null,
    },
};

const store = (set): IDashboardStore => ({
    ...DEFAULT_STORE_VALUES,
    setSelectedDashboard: (dashboardToSelect) => set(() => ({ selectedDashboard: dashboardToSelect })),
    resetDashboardStore: () => set(DEFAULT_STORE_VALUES),
});

export const useDashboardStore = create(persist(store, { name: 'selectedDashboard' }));
