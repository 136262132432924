import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const DEFAULT_STORE_VALUES = {
    myCustomSelector: {
        showOnlyAliasStatus: false,
    },
};

const myCustomSelectorStore = (set) => ({
    ...DEFAULT_STORE_VALUES,
    setShowOnlyAliasStatus: (showOnlyAliasStatus) =>
        set(() => ({ myCustomSelector: { showOnlyAliasStatus: showOnlyAliasStatus } })),
});

export const useMyCustomSelectorStatus = create(persist(myCustomSelectorStore, { name: 'myCustomSelectorStore' }));
