import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { IProgramVersion } from 'components/common/VersionCompareSelectorSection/VersionCompareSelectorWrapper/VersionCompareSelectorWrapper';

export const prepareVersionSelectTitle = (version?: IProgramVersion) => {
    if (!version) return moment().format(constants.formats.date.default);
    return `${version?.versionName} (${moment(version?.versionDate).format(constants.formats.date.default)})${
        version?.isBaseline ? '| Baseline' : ''
    }${version?.isLatest ? '| Latest' : ''}`;
};
export const prepareVersionExcelFileName = (version?: IProgramVersion) => {
    if (!version) return moment().format(constants.formats.date.default);
    return `${version?.versionName} (${moment(version?.versionDate).format(constants.formats.date.default)})`;
};
export const getProjectAndContractNameByLatestVersion = (version?: IProgramVersion) => {
    if (!version) return moment().format(constants.formats.date.default);
    return `${version?.projectName}, ${version?.contractName}, ${prepareVersionSelectTitle(version)}`;
};

export const getExcelFileName = (version?: IProgramVersion) => {
    if (!version) return moment().format(constants.formats.date.default);
    return `${version?.projectName}_${version?.contractName}_${prepareVersionExcelFileName(version)}`;
};
