import classes from './ToggleCollapse.module.scss';
import colorsVars from 'styles/colors.module.scss';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

interface Props {
    collapse: boolean;
    setCollapse: React.Dispatch<React.SetStateAction<boolean>>;
    id: string;
}

export const ToggleCollapse = ({ collapse, setCollapse, id }: Props): JSX.Element => {
    const title = collapse ? 'More' : 'Less';
    return (
        <div
            id={`${id}_collapse`}
            className={classes.collapseToggle}
            onClick={() => setCollapse(!collapse)}
            title={title}
        >
            <div className={`${classes.svgContainer} ${collapse && classes.isCollapse}`}>
                <MuiIcon icon="keyboard_double_arrow_down" color={colorsVars.linkColor} />
            </div>
        </div>
    );
};
