import { CustomizedTabs } from 'components/common/CustomizedTabs/CustomizedTabs';
import classes from './TabsSection.module.scss';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export type IActiveTab = 'cardDetails' | 'activityDetails' | 'activityRelationships' | 'relatedCards' | 'auditLogs';

export const TabsSection = ({ tabs, activeTab, setActiveTab }) => {
    const history = useHistory();
    const { search } = useLocation();

    useEffect(() => {
        history.replace({
            hash: activeTab,
            search,
        });
    }, [activeTab]);

    return (
        <div className={classes.tabsSectionContainer}>
            <CustomizedTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>
    );
};
