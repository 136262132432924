import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ITreeHeaderStore } from 'components/common/TreeProgramFilter/zustand_store/treeHeaderStore.types';

export const DEFAULT_STORE_VALUES = {
    selectedProject: {
        project: null,
        contract: null,
    },
};

const store = (set): ITreeHeaderStore => ({
    ...DEFAULT_STORE_VALUES,
    setSelectedProject: (projectToSelect) => set(() => ({ selectedProject: projectToSelect })),
    resetProjectStore: () => set(DEFAULT_STORE_VALUES),
});

export const useProjectStore = create(persist(store, { name: 'selectedProject' }));
