import { useEffect } from 'react';
import classes from 'components/Dashboards/Portfolio/phasePerformance/components/filtersWrapper.module.scss';
import { TopHeader } from 'components/Dashboards/Portfolio/phasePerformance/topHeader';
import { first, orderBy, sortBy } from 'lodash';
import {
    getName,
    projectsMapper,
    statusOptions,
    templateMapper,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.utils';

import { MyCustomSelect } from 'components/common';
import { InputOption, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import {
    IPhasePerformanceProject,
    IProjectStatus,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.types';
import { usePhasePerformanceStore } from 'components/Dashboards/Portfolio/phasePerformance/zustand_store/phasePerformanceStore';
import {
    useQueryPhasePerformanceGetTemplateRelatedProjects,
    useQueryPhasePerformanceGetTemplates,
} from 'components/Dashboards/Portfolio/phasePerformance/queries/useQueryPhasePerformance';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useBasePath } from 'hooks/useBasePath';
import { useQueryGetTags } from 'components/Settings/Components/Tags/queries/useTagsQuery';
import useUserHook from 'hooks/useUserHook';

export const FiltersWrapper = () => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const basePath = useBasePath();
    const { customerId } = useUserHook();
    const template = usePhasePerformanceStore((store) => store.template);
    const { templateId: templateIdFromParams } = useParams<{ templateId?: string }>();
    const { data: templatesData } = useQueryPhasePerformanceGetTemplates();
    const { data: projectsData } = useQueryPhasePerformanceGetTemplateRelatedProjects({ templateId: template?.id });
    const selectedProjects = usePhasePerformanceStore((store) => store.selectedProjects);
    const projectsOptions = usePhasePerformanceStore((store) => store.projectsOptions);
    const gatesOptions = usePhasePerformanceStore((store) => store.gatesOptions);
    const selectedTags = usePhasePerformanceStore((store) => store.selectedTags);
    const selectedGates = usePhasePerformanceStore((store) => store.selectedGates);
    const selectedStatus = usePhasePerformanceStore((store) => store.selectedStatus);
    const {
        setSelectedProjects,
        setSelectedStatus,
        setSelectedGates,
        setSelectedTags,
        setTemplate,
        setProjectsOption,
        setGatesOptions,
    } = usePhasePerformanceStore();

    const { data: tagsData } = useQueryGetTags({ enabled: true, customerId });

    const handleSelectedProjects = (item: ISelectOption<number>[]) => {
        setSelectedProjects(
            projectsOptions.filter((project) => item.map((item) => item.value).includes(project.contract_id)),
        );
    };

    const handleSelectTemplate = (item?: ISelectOption<number>) => {
        item ? history.push({ pathname: generatePath(path, { templateId: item.value }) }) : history.push(basePath);
    };

    const tagsOptions =
        tagsData?.map((item) => {
            return {
                id: item.tagId,
                label: item.name,
                value: item.tagId,
            };
        }) || [];

    useEffect(() => {
        if (templatesData) {
            const sortedTemplates = sortBy(templatesData.templates, 'name').map(templateMapper);
            const selectedTemplate = templateIdFromParams
                ? sortedTemplates.find((item) => item.value === Number(templateIdFromParams))
                : first(sortedTemplates);

            setTemplate(selectedTemplate || null);
            handleSelectTemplate(selectedTemplate);
        }
    }, [templatesData, templateIdFromParams]);

    useEffect(() => {
        if (projectsData && projectsData.length > 0) {
            const projects = orderBy(projectsData, 'project_name').map(
                (project): IPhasePerformanceProject => ({
                    contract_id: project.contract_id,
                    name: getName(project),
                }),
            );
            const gates = projectsData[0].phases.map((item): ISelectOption<number> => {
                return {
                    id: item.id,
                    value: item.sequence,
                    label: `${item.start_gate} → ${item.finish_gate}`,
                };
            });
            setProjectsOption(projects);
            setSelectedProjects(projects);
            setGatesOptions(gates);
        } else {
            setProjectsOption([]);
            setSelectedProjects([]);
            setGatesOptions([]);
        }
    }, [projectsData]);

    return (
        <div className={classes.filtersWrapper}>
            <TopHeader
                template={template}
                templateOptions={sortBy(templatesData?.templates, 'name').map(templateMapper)}
                info={templatesData?.info}
                handleSelectTemplate={handleSelectTemplate}
            />
            <div className={classes.filters}>
                Filters:
                <MyCustomSelect
                    value={selectedProjects.map(projectsMapper)}
                    options={projectsOptions.map(projectsMapper)}
                    onChange={(value) => handleSelectedProjects(value as ISelectOption<number>[])}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isMulti={true}
                    title={'Projects'}
                    id={'phase_performance_select_projects'}
                    placeholder={'Select Projects...'}
                    components={{
                        Option: InputOption,
                        ValueContainer: ValueContainer,
                    }}
                />
                <MyCustomSelect
                    value={selectedStatus}
                    options={statusOptions}
                    onChange={(value) => setSelectedStatus(value as ISelectOption<IProjectStatus>[])}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isMulti={true}
                    title={'Status'}
                    id={'phase_performance_select_status'}
                    placeholder={'Select Status...'}
                    components={{
                        Option: InputOption,
                        ValueContainer: ValueContainer,
                    }}
                />
                <MyCustomSelect
                    value={selectedTags}
                    options={tagsOptions}
                    onChange={(value) => setSelectedTags(value as ISelectOption<number>[])}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={true}
                    isMulti={true}
                    title={'Tags'}
                    id={'phase_performance_select_tags'}
                    placeholder={'Select Tags...'}
                />
                <MyCustomSelect
                    value={selectedGates}
                    options={gatesOptions}
                    onChange={(value) => setSelectedGates(value as ISelectOption<number> | null)}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    title={'Phase'}
                    id={'phase_performance_select_phase'}
                    placeholder={'Select Phase...'}
                    isClearable={true}
                />
            </div>
        </div>
    );
};
