import { ConfirmSaveModal } from 'components/common';
import { useProjectStore } from 'components/common/TreeProgramFilter/zustand_store/treeHeaderStore';
import { useMutationRefreshProgramAnalysisData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/queries/useQueryProgramLibrary';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import useEventWithDimensions from 'hooks/useEventWithDimensions';

export const ConfirmRefreshSubProgramModal = ({ handleModalClose, isShowModal }) => {
    const { contract } = useProjectStore((store) => store.selectedProject);
    const { mutate, isLoading } = useMutationRefreshProgramAnalysisData();
    const { handleSnackBar } = useCustomSnackBar();
    const { sendEventWithDimensions } = useEventWithDimensions();

    const handleRefreshSubProgram = () => {
        if (contract) {
            mutate(
                { contractId: contract?.id },
                {
                    onSuccess: () => {
                        handleSnackBar('Refresh started. Please wait for re-upload to complete', 'success');

                        sendEventWithDimensions({
                            category: 'Library',
                            action: 'Refresh Sub-Program',
                            label: contract?.title,
                        });
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                    },
                    onSettled: () => handleModalClose(),
                },
            );
        }
    };

    return (
        <ConfirmSaveModal
            isShowModal={isShowModal}
            onHideModal={handleModalClose}
            onConfirm={handleRefreshSubProgram}
            isLoading={isLoading}
            actionButtonLabel={'Refresh'}
        >
            Are you sure you want to refresh this sub-program ({contract?.title})?
        </ConfirmSaveModal>
    );
};
