import { useUserStore } from 'components/Login/store/userStore';
import { token as tokenStorage } from 'services/SessionStorage/sessionStorage';
import analytics from 'utilitys/helpers/Analytics/analytics';
import { useQueryClient } from 'react-query';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Userpilot } from 'userpilot';
export const Logout = () => {
    const queryClient = useQueryClient();
    const history = useHistory();
    const logout = useUserStore((state) => state.logout);

    useEffect(() => {
        tokenStorage.clear();
        analytics.mixpanelReset();
        queryClient.clear();
        logout();
        try {
            Userpilot.clean();
            Userpilot.destroy();
        } catch (e) {
            console.log(e);
            console.log('error userpilot');
        }
        history.push('/');
    }, []);

    return null;
};
