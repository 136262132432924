import React from 'react';
import classes from './customizeFilter.module.scss';
import Icon from 'components/common/Icons/icon';
import { CustomizedButton, CustomizedDropdownMenu, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

interface ICustomizeFilterProps {
    listOptions: {
        [key: string]: { id: number; label: string; isSelected: boolean };
    };
    onSelection: (id: number) => void;
}

const CustomizeFilter = ({ listOptions, onSelection }: ICustomizeFilterProps) => {
    const handleSelection = (id) => {
        onSelection(id);
    };

    return (
        <div data-testid={'more-filters-button'}>
            <CustomizedDropdownMenu
                triggerElement={
                    <CustomizedTooltip
                        tooltipPosition={TooltipPosition.Top}
                        tooltipContent={`More Filters`}
                        triggerElement={
                            <CustomizedButton
                                endIcon={<Icon name={'header-arrow-down'} size="1.5rem" color={'#2C8FA5'} />}
                                startIcon={<MuiIcon icon={'more_horiz'} fontSize="1.5rem" color={'#2C8FA5'} />}
                                color={'secondary'}
                            >
                                More Filters
                            </CustomizedButton>
                        }
                    />
                }
            >
                {Object.entries(listOptions).map(([key, item]) => (
                    <div key={key} onClick={() => handleSelection(key)}>
                        <div className={classes.customizeFilterContainerRow}>
                            <span>{item.label}</span>
                            {item.isSelected ? (
                                <span className={classes.remove}>
                                    <Icon name="filter-x-icon" size="2rem" color="#cccccc" /> <span>Remove</span>
                                </span>
                            ) : (
                                <span className={classes.add}>
                                    <Icon name="Governance-top-right-tick" size="1.3rem" color="#cccccc" />{' '}
                                    <span>Add</span>
                                </span>
                            )}
                        </div>
                    </div>
                ))}
            </CustomizedDropdownMenu>
        </div>
    );
};

export default React.memo(CustomizeFilter);
