import classes from 'components/Settings/Components/UserProfile/components/userProfile.module.scss';
import { Controller } from 'react-hook-form';
import { CustomizedFormErrorMessage, MyCustomSelect } from 'components/common';
import { useQueryGetAllUserGroups } from 'components/Settings/Components/UserGroups/queries/userGroupsQuery';
import { IUserGroups } from 'components/Settings/Components/UserProfile/userProfile.utils.type';
import useUserHook from 'hooks/useUserHook';

const groupsMapper = (item) => ({ id: item.group_id, value: item.group_id, label: item.group_name });

export const UserGroups = ({ errors, control }: IUserGroups) => {
    const { ability } = useUserHook();
    const { data: userGroups } = useQueryGetAllUserGroups({ enabled: ability.can('view', 'UserManagement') });
    const options = userGroups?.map(groupsMapper);
    return (
        <div className={classes.associatedRolesContainer}>
            <label>User Groups*</label>
            <Controller
                render={({ field }) => (
                    <MyCustomSelect
                        {...field}
                        options={options}
                        isClearable={true}
                        isMulti
                        sortOption={{ key: 'label', order: 'asc' }}
                        menuPlacement={'auto'}
                    />
                )}
                name="userGroups"
                control={control}
                rules={{
                    validate: {
                        required: (value) => value.length > 0 || 'Must select at least one user groups',
                    },
                }}
            />

            {errors.userGroups && <CustomizedFormErrorMessage text={errors.userGroups.message} />}
        </div>
    );
};
