import classes from './activityTypeSelection.module.scss';
import React from 'react';

const selectionButtons = [
    { label: 'Milestones', value: 'milestones' },
    { label: 'ALL', value: 'all' },
];

interface Props {
    activityTypeSelection: string;
    setActivityTypeSelection: React.Dispatch<React.SetStateAction<string>>;
}

export const ActivityTypeSelection = ({ activityTypeSelection, setActivityTypeSelection }: Props): JSX.Element => {
    const handleSelection = (value) => {
        setActivityTypeSelection(value);
    };

    return (
        <div className={classes.activityTypeSelectionContainer}>
            <div className={classes.filterTitle}>Activity type:</div>
            {selectionButtons.map((item) => (
                <div
                    key={item.label}
                    className={`${classes.filterButton} ${activityTypeSelection === item.value ? classes.active : ''}`}
                    onClick={() => handleSelection(item.value)}
                >
                    {item.label}
                </div>
            ))}
        </div>
    );
};
