import { defaultBaseLinePerVersionList } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/ProjectPreferences.utils';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import {
    useMutationSetBaseLineDateOption,
    useQueryGetBaseLineDateOption,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/queries/useQueryProjectPreferences';
import { useMemo, useState } from 'react';
import { ConfirmSaveModal, MyCustomSelect } from 'components/common';
import {
    Project,
    SelectValue,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/ProjectPreferencesTypes';

export const DefaultBaseLinePerVersion = ({ project }: { project: Project }) => {
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<SelectValue | undefined>(undefined);
    const { handleSnackBar } = useCustomSnackBar();
    const mutationBaseLineDateOption = useMutationSetBaseLineDateOption();
    const { data: defaultBaseLinePerVersion, refetch } = useQueryGetBaseLineDateOption({
        projectId: project?.id,
    });

    const currentValue = useMemo(() => {
        return defaultBaseLinePerVersionList.find((item) => item.value === defaultBaseLinePerVersion);
    }, [defaultBaseLinePerVersion]);

    const handleDefaultBaseLinePerVersion = (value: SelectValue) => {
        if (value.id !== currentValue?.id) {
            setShowConfirmModal(true);
            setSelectedValue(value);
        }
    };

    const handleHideModal = () => {
        setShowConfirmModal(false);
        setSelectedValue(currentValue);
    };

    const handleSave = () => {
        setShowConfirmModal(false);
        mutationBaseLineDateOption.mutate(
            { projectId: project?.id, option: selectedValue?.value },
            {
                onSuccess: () => handleSnackBar('Default Baseline per Version has changed', 'success'),
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
                onSettled: () => refetch(),
            },
        );
    };

    return (
        <>
            <span>Default Baseline per Version:</span>
            <MyCustomSelect
                id={'project-preferences-default-compared-version'}
                value={currentValue}
                options={defaultBaseLinePerVersionList}
                onChange={handleDefaultBaseLinePerVersion}
                isSearchable={false}
            />
            <ConfirmSaveModal
                isShowModal={showConfirmModal}
                onHideModal={handleHideModal}
                onConfirm={handleSave}
                isLoading={mutationBaseLineDateOption.isLoading}
            >
                You have made changes. Would you like to save them?
            </ConfirmSaveModal>
        </>
    );
};
