import classes from './list.module.scss';
import Icon from 'components/common/Icons/icon';
import React from 'react';

export const List = ({ items, callback }) => {
    return (
        <>
            {items.map((node, index) => (
                <div
                    className={classes.listContainer}
                    key={node.uuid}
                    onClick={() => callback(node)}
                    id={`${node.type}_${index}`}
                >
                    <div className={classes.listItem}>
                        {node.name}
                        {node.children && <Icon name={'header-arrow-right'} size={'1.8rem'} color={'#8FA3C3'} />}
                    </div>
                </div>
            ))}
        </>
    );
};
