import { ITab } from 'components/common/CustomizedTabs/CustomizedTabs';
import { ITableData } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

import TableTab from 'components/Dashboards/Project/Components/VersionCompare/TableTab/tableTab';
import RecommendationTableTab from 'components/Dashboards/Project/Components/VersionCompare/TableTab/recommendationTableTab';

export const generateTabs = ({ data }: { data: ITableData }): ITab[] => {
    if (data && data.periodItemsList) {
        const prevData = data.periodItemsList.find((item) => item.id === 'prev');
        const nextData = data.periodItemsList.find((item) => item.id === 'next');
        const allData = data.periodItemsList.find((item) => item.id === 'all');

        if (prevData && nextData && allData) {
            return [
                {
                    tabKey: 'prev',
                    component: <TableTab data={prevData} />,
                },
                {
                    tabKey: 'next',
                    component: <TableTab data={nextData} />,
                },
                {
                    tabKey: 'all',
                    component: <TableTab data={allData} />,
                },
            ];
        }
    }
    return [];
};

//TODO: fix data typing - maybe when separating tableWrapper component
export const generateRecommendationsTabs = ({ data }: { data: any }): ITab[] => {
    if (data && data.recommendationResponse) {
        const categories = {
            PROGRESS: 0,
            RISK: 0,
            QUALITY: 0,
            all: data.recommendationResponse.length,
        };

        data.recommendationResponse.forEach((item) => {
            const index = item.category.name;
            if (index) {
                categories[index] = categories[index] + 1;
            }
        });

        return [
            {
                tabKey: 'all',
                component: <RecommendationTableTab title={`All (${categories.all})`} />,
            },
            {
                tabKey: 'PROGRESS',
                component: <RecommendationTableTab title={`Progress (${categories.PROGRESS})`} />,
            },
            {
                tabKey: 'RISK',
                component: <RecommendationTableTab title={`Risk (${categories.RISK})`} />,
            },
            {
                tabKey: 'QUALITY',
                component: <RecommendationTableTab title={`Quality (${categories.QUALITY})`} />,
            },
        ];
    }

    return [];
};

export const arrayBufferToString = (buffer) => String.fromCharCode.apply(null, new Uint8Array(buffer));
