import { useMutation, useQuery } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';

const getActivityCodesAliases = (contractId) => {
    return network
        .get(`${API_URL().FETCH_PROGRAM_GET_SUB_PROGRAM_GET_ACTIVITY_CODES_ALIASES}?contractId=${contractId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getActivityCodesByContract = (contractId) => {
    return network
        .get(`${API_URL().FETCH_PROGRAM_GET_SUB_PROGRAM_GET_ACTIVITY_CODES_BY_CONTRACT}?contractId=${contractId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const createAlias = (params) => {
    return network
        .post(API_URL().FETCH_PROGRAM_SET_SUB_PROGRAM_CREATE_ALIAS, params)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const deleteAlias = (id) => {
    const url = `${API_URL().FETCH_PROGRAM_SET_SUB_PROGRAM_DELETE_ALIAS}?id=${id}`;
    return network
        .delete(url)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const editAlias = (params) => {
    return network
        .post(API_URL().FETCH_PROGRAM_SET_SUB_PROGRAM_EDIT_ALIAS, params)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const useQueryGetActivityCodesAliases = ({ contractId }) => {
    return useQuery(['useQueryGetActivityCodesAliases', contractId], () => getActivityCodesAliases(contractId), {
        enabled: Boolean(contractId),
    });
};

const useQueryGetActivityCodesByContract = ({ contractId }) => {
    return useQuery(['useQueryGetActivityCodesByContract', contractId], () => getActivityCodesByContract(contractId), {
        enabled: Boolean(contractId),
    });
};

const useMutationCreateAlias = () => {
    return useMutation<any, QueryError, CreateAliasProps>((params) => createAlias(params));
};

const useMutationDeleteAlias = () => {
    return useMutation<any, QueryError, { id: number | undefined }>((id) => deleteAlias(id));
};

const useMutationEditAlias = () => {
    return useMutation<any, QueryError, EditAliasProps>((params) => editAlias(params));
};

export {
    useQueryGetActivityCodesAliases,
    useQueryGetActivityCodesByContract,
    useMutationCreateAlias,
    useMutationDeleteAlias,
    useMutationEditAlias,
};

interface CreateAliasProps {
    alias: string;
    contractId: number | undefined;
    name: string;
    scope: string;
}

interface EditAliasProps {
    alias: string;
    id: number;
}
