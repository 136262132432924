import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import classes from 'components/Dashboards/ExcellenceApps/LogicChecker/LogicChecker.module.scss';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { useQueryGetLogicCheckerCategoryResults } from 'components/Dashboards/ExcellenceApps/LogicChecker/queries/useQueryLogicChecker';
import { summaryColumns } from 'components/Dashboards/ExcellenceApps/LogicChecker/helper';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { useLogicCheckerFilterHook } from 'components/Dashboards/ExcellenceApps/LogicChecker/hooks/useLogicCheckerFilterHook';

export const SummaryTable = () => {
    const { id: versionId } = useParams<{ id: string }>();
    const { idList, versionList } = useHashmapConverter(versionId);
    const projectId = versionList[0]?.projectId;
    const contractId = versionList[0]?.contractId;
    const fileMetaDataId = idList.length > 0 ? parseInt(idList.join(',')) : undefined;
    const filters = useLogicCheckerFilterHook();
    const { data: logicCheckerData, isLoading } = useQueryGetLogicCheckerCategoryResults({
        fileMetaDataId,
        filter: {
            filters,
        },
    });

    const generatedCols = useMemo(() => {
        return summaryColumns(versionId);
    }, [versionId]);
    const generatedData = useMemo(() => {
        return logicCheckerData?.categoriesSummaryRows || [];
    }, [logicCheckerData]);

    const title = 'Categories Summary';
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId: idList[0] });

    return (
        <WidgetWithTitle
            title={title}
            titleComponents={[<ExportDropdown key={'export'} title={title} subTitle={subTitle} />]}
        >
            <div className={classes.tableContainer}>
                <MainTable columns={generatedCols} data={generatedData} isLoading={isLoading} />
            </div>
        </WidgetWithTitle>
    );
};
