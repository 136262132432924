import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryTopDurations } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/queries/activitiesQuery';
import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { CustomizedRoundIcon, CustomizedTooltip, MyCustomSelect } from 'components/common';
import { morefilterItems } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/utils';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import {
    filterMapper,
    FilterNames,
    getFiltersData,
} from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { InputOption, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import { statusOptions } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/criticalAnalysisDelayDriversFilter';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { IComponentProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/style/table.module.scss';

import {
    initialState,
    columns,
    defaultPeriod,
    TOP_OVERDUE_OVERRUN_FILTERS,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/TopDurationOverrunRiskComponent/utils';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { useQueryState } from 'hooks/useQueryState';
import { List } from 'components/common/ListWrapper/list';
import { isBetweenRange } from 'components/Dashboards/Project/Components/helper';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useMutationSetWidgetColumns } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySelectedTableColumns';
import MainTable from 'components/common/Tables/MainTable/MainTable';

const groupByOptions: ISelectOption<ColumnCommonConfig>[] = [
    { value: 'clusterName', label: 'Cluster' },
    { value: 'parentName', label: 'WBS' },
];

import { ColumnCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import SwitchComponent from 'components/common/Switch/Switch';

const RenderTopDurationOverrunRisk = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    filter,
    localFilters,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: IComponentProps) => {
    const [isInstance, setIsInstance] = useState(false);
    const tableRef = useRef(null);
    const id = 'topDurationOverrunRisk';
    const componentKey = 'topDurationOverrunRisk';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const [groupBy, setGroupBy] = useState<ISelectOption<ColumnCommonConfig>[]>([groupByOptions[0]]);
    const mergedWithGroupBy = useMemo(() => {
        return { ...initialState, groupBy: groupBy.map((item) => item.value) };
    }, [groupBy]);
    const updatedInitialState = useTableCurrentState({
        initialState: mergedWithGroupBy,
        componentKey,
        widgetId,
    });

    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const idList = useMemo(() => (latestVersionId ? [latestVersionId] : []), [latestVersionId]);
    const [status, setStatus] = useState<ISelectOption<string>[]>([]);
    const [isCriticalPath, setIsCriticalPath] = useState<boolean>(false);
    const [statusFilter, setStatusFilter] = useState<ISelectOption<string>[]>([
        {
            id: 1,
            value: 'NOT_STARTED',
            label: 'To Do',
        },
        {
            id: 2,
            value: 'IN_PROGRESS',
            label: 'In Progress',
        },
    ]);
    const [nextFilter, setNextFilter] = useQueryState('nextFilter');

    const numOfMonthsToBeSelected: string = useMemo(() => {
        return widgetId && localFilters?.numOfMonths ? localFilters.numOfMonths.value : nextFilter;
    }, [widgetId, localFilters, nextFilter]);

    const [numOfMonths, setNumOfMonths] = useState<ISelectOption<string> | undefined>(undefined);

    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes,
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                      [FilterNames.OPTIONS_STATUS]: statusFilter.map((item) => item.value).map(filterMapper),
                      [FilterNames.OPTIONS_TYPE]: isCriticalPath
                          ? [...status.map((item) => item.value).map(filterMapper), 'CRITICAL_PATH']
                          : status.map((item) => item.value).map(filterMapper),
                  }),
        [filter, globalFilterData, statusFilter, status, isCriticalPath],
    );
    const { data, isLoading, isFetching } = useQueryTopDurations({
        id: idList,
        filter: {
            filters,
        },
    });

    const mergedFilters =
        widgetId && filter?.filters
            ? {
                  filters: [
                      ...filter?.filters,
                      ...getFiltersData({
                          [FilterNames.OVERRUN_RISK_SELECTED_PERIOD]:
                              Number(localFilters?.numOfMonths?.value) > 0
                                  ? [`${localFilters?.numOfMonths?.value}M`]
                                  : ['All'],
                      }),
                  ],
              }
            : undefined;

    const { generateFilters: formatedFilter } = useGlobalFiltersHook({
        widgetId,
        filter: mergedFilters,
        latestVersionId,
    });
    const title = 'Top Duration Overrun Risk';
    const { sendEventWithDimensions } = useEventWithDimensions();
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate: mutateSetWidgetColumns } = useMutationSetWidgetColumns();

    const handleStatus = useCallback((value) => {
        const selected = value.map((item) => item.value);
        setStatus(value);

        sendEventWithDimensions({
            category: 'Activities Table(s)',
            action: 'Status Filter click',
            label: selected.length > 0 ? selected.join(',') : 'Deselect All',
        });
    }, []);

    const filteredRows = useMemo(() => {
        return data?.delayDrivers
            ? data.delayDrivers.filter((item) =>
                  isBetweenRange({
                      numOfMonths: Number(numOfMonthsToBeSelected),
                      startDate: item.currentStartDate,
                      endDate: item.currentFinishDate,
                  }),
              )
            : [];
    }, [data, numOfMonthsToBeSelected]);

    const handleStatusType = (value) => {
        setStatusFilter(value);
    };

    const handleInstance = (instance) => {
        if (instance) {
            tableRef.current = instance;
            setIsInstance(true); // need for render component after getting instance
        }
    };
    const handleTimePeriodClick = (filter: ISelectOption<string>) => {
        const value = filter.value;
        sendEventWithDimensions({
            category: 'Activities Table(s)',
            action: 'Next Selection',
            label: value,
        });
        setNextFilter(value);
        setNumOfMonths(filter);
    };
    const setWidgetColumns = (widgetId: string) => {
        if (tableRef.current) {
            const { hiddenColumns, columnOrder } = tableRef.current.state;
            mutateSetWidgetColumns(
                { columns: { hiddenColumns, columnOrder }, widgetId },
                {
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                    },
                },
            );
        }
    };
    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);

    useEffect(() => {
        if (!nextFilter && !widgetId) {
            setNextFilter(defaultPeriod);
        }
        if (nextFilter && !widgetId) {
            const defaultNumOfMonths = TOP_OVERDUE_OVERRUN_FILTERS.find(
                (filter) => filter.value === numOfMonthsToBeSelected,
            );
            setNumOfMonths(defaultNumOfMonths);
        }
    }, [nextFilter, widgetId, numOfMonthsToBeSelected]);
    useEffect(() => {
        if (tableRef?.current) {
            tableRef.current.setGroupBy(groupBy.map((item) => item.value));
        }
    }, [groupBy]);
    const handleCriticalPath = (event) => {
        const isChecked = event.target.checked;
        setIsCriticalPath(isChecked);
    };
    return (
        <ErrorBoundary>
            <WidgetWithTitle
                id={id}
                title={title}
                tooltip={data?.info}
                titleComponents={[
                    externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                    <CustomizedTooltip
                        key={'tableColumns'}
                        tooltipPosition={TooltipPosition.Top}
                        tooltipContent={'Customize table columns'}
                        triggerElement={
                            <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                                <Icon name={'tune-filter'} />
                            </CustomizedRoundIcon>
                        }
                    />,
                    <AddWidget
                        key={'AddWidget'}
                        componentKey={componentKey}
                        title={title}
                        projectId={projectId}
                        contractId={contractId}
                        widgetId={widgetId}
                        route={route}
                        filters={filters}
                        localFilters={{ numOfMonths }}
                        callBack={setWidgetColumns}
                    />,
                    <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                    isInstance && (
                        <ExportDropdown
                            title={title}
                            data={filteredRows}
                            tableInstance={tableRef.current}
                            subTitle={subTitle}
                        />
                    ),
                ]}
                titleFilters={[
                    editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                    widgetId && formatedFilter.length > 0 ? (
                        <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formatedFilter} />
                    ) : (
                        <div key={'filters'} className={classes.filters}>
                            <div className={classes.selectWrapper}>
                                <div className={classes.title}>Group By</div>
                                <MyCustomSelect
                                    value={groupBy}
                                    options={groupByOptions}
                                    isMulti={true}
                                    onChange={(value) => setGroupBy(value)}
                                    placeholder={'Group by...'}
                                />
                            </div>
                            <div className={classes.selectWrapper}>
                                <div className={classes.title}>Status</div>
                                <MyCustomSelect
                                    value={statusFilter}
                                    options={statusOptions}
                                    onChange={handleStatusType}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    isMulti={true}
                                    title={'Status'}
                                    placeholder={'Select Status...'}
                                    components={{
                                        Option: InputOption,
                                        ValueContainer: ValueContainer,
                                    }}
                                />
                            </div>
                            <div>
                                Critical Path Only
                                <SwitchComponent onChange={handleCriticalPath} checked={isCriticalPath} />
                            </div>
                            <div className={classes.selectWrapper}>
                                <div className={classes.title}>More filters</div>
                                <MyCustomSelect
                                    value={status}
                                    options={morefilterItems}
                                    onChange={handleStatus}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    isMulti={true}
                                    title={'More Filters'}
                                    placeholder={'Select More Filters...'}
                                    components={{
                                        Option: InputOption,
                                        ValueContainer: ValueContainer,
                                    }}
                                />
                            </div>

                            <div className={classes.selectWrapper}>
                                <div className={classes.title}>Next</div>
                                <div className={classes.filtersWrapper}>
                                    <List
                                        onChange={handleTimePeriodClick}
                                        options={TOP_OVERDUE_OVERRUN_FILTERS}
                                        value={numOfMonths}
                                    />
                                </div>
                            </div>
                        </div>
                    ),
                ]}
            >
                <div className={classes.tableContainer}>
                    {updatedInitialState && (
                        <MainTable
                            getInstanceCallback={handleInstance}
                            columns={columns}
                            data={filteredRows}
                            initialState={updatedInitialState}
                            pageSize={1000}
                            isLoading={isLoading || isFetching}
                        />
                    )}
                </div>

                {tableRef?.current && (
                    <CustomTableColumnsModal
                        onHideModal={() => setModalColumnsOpen(false)}
                        showModal={modalColumnsOpen}
                        widgetId={widgetId}
                        componentKey={componentKey}
                        tableInstance={tableRef.current}
                        initialState={initialState}
                        columns={columns}
                    />
                )}
            </WidgetWithTitle>
        </ErrorBoundary>
    );
};

export default React.memo(RenderTopDurationOverrunRisk);
