import { useEffect, useState } from 'react';
import { useQueryState } from 'hooks/useQueryState';
import { token as tokenService } from 'services/SessionStorage/sessionStorage';
import { network } from 'services/network';
import { useHistory } from 'react-router-dom';
import { useQueryGetUserDetail } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import { useUserStore } from 'components/Login/store/userStore';
import { Userpilot } from 'userpilot';
import analytics from 'utilitys/helpers/Analytics/analytics';
import { FullStory } from '@fullstory/browser';
import moment from 'moment-timezone';

export const ExternalLogin = () => {
    const url = new URL(document.URL);
    const [token] = useQueryState('token');
    const [emailFromQuery] = useQueryState('email');
    const [email, setEmail] = useState<string | undefined>(undefined);
    const setUser = useUserStore((state) => state.setUser);
    const history = useHistory();
    const { data: user } = useQueryGetUserDetail({
        email: email,
    });
    useEffect(() => {
        if (token && emailFromQuery) {
            tokenService.set(token);
            network.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            setEmail(emailFromQuery);
        }
    }, [token, emailFromQuery]);

    useEffect(() => {
        if (user) {
            const emailSuffix = user.username.split('@')[1];
            setUser(user);

            Userpilot.identify(user.username, {
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
                created_at: moment().valueOf(),
                company: {
                    id: user.customerId,
                    created_at: moment().valueOf(),
                },
            });
            analytics.mixpanelIdentify(user.username);
            analytics.mixpanelPeopleSet({ emailSuffix, $email: user.username });
            analytics.mixpanelRegister({
                customerId: user.customerId,
                username: user.username,
                domain: url.hostname,
            });
            FullStory('setIdentity', {
                uid: user.username,
                properties: {
                    displayName: `${user.firstName} ${user.lastName}`,
                    email: user.username,
                    customerId: user.customerId,
                    username: user.username,
                    domain: url.hostname,
                },
            });
            history.push('/');
        }
    }, [user]);
    return null;
};
