import { create } from 'zustand';
import { DisplayStyle } from 'components/common/ToggleView/ToggleView';
import { initialFiltersState } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';

const battlecardsStore = (set) => ({
    filterData: initialFiltersState,
    setFilterData: (value) => set({ filterData: value }),

    displayStyle: DisplayStyle.ROWS,
    setDisplayStyle: (value) => set({ displayStyle: value }),
});

export const useBattlecardsStore = create(battlecardsStore);
