import classes from './SideMenu.module.scss';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import {
    CustomizedConfirmDeleteModal,
    CustomizedDropdownMenu,
    CustomizedRoundIcon,
    CustomizedTooltip,
} from 'components/common';
import Icon from 'components/common/Icons/icon';
import { ActionMenuItemWithIcon } from 'components/common/ActionMenuItemWithIcon/actionMenuItemWithIcon';
import React, { useCallback, useState } from 'react';
import { useMutationDeleteCard } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/cardModalMutation';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useVersionStore } from 'components/common/TreeProgramFilter/VersionSelect/store/versionSelectStore';

interface ISideMenuProps {
    title: string;
    id: number;
    handleModalClose: () => void;
    deleteCardCallback: () => void;
}

export const SideMenu = ({ title, id, handleModalClose, deleteCardCallback }: ISideMenuProps) => {
    const [deleteCardModalShow, setDeleteCardModalShow] = useState<boolean>(false);
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate: deleteCardMutate, isLoading: deleteCardLoading } = useMutationDeleteCard();
    const version = useVersionStore((state) => state.version);

    const handleCardDelete = useCallback(() => {
        setDeleteCardModalShow(false);
        if (id && version) {
            deleteCardMutate(
                { cardId: id, versionId: version.id },
                {
                    onSuccess: () => {
                        handleSnackBar('Card Deleted Successfully', 'success');
                        handleModalClose();
                        deleteCardCallback();
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                    },
                },
            );
        }
    }, []);

    return (
        <div className={classes.sideMenuContainer}>
            <ShareInsightButton title={`activity "${title}"`} link={window.location.href.split('?')[0]} size={'3rem'} />

            <CustomizedDropdownMenu
                triggerElement={
                    <CustomizedTooltip
                        tooltipContent={`Manage Activity`}
                        triggerElement={
                            <CustomizedRoundIcon size={'3rem'}>
                                <Icon name={'three-dot-icon'} />
                            </CustomizedRoundIcon>
                        }
                    />
                }
            >
                <ActionMenuItemWithIcon
                    handleClick={() => {
                        setDeleteCardModalShow(true);
                    }}
                    iconText={'Delete'}
                    iconType={'delete-icon'}
                    iconSize={'1.6rem'}
                />
            </CustomizedDropdownMenu>

            <CustomizedConfirmDeleteModal
                isShowModal={deleteCardModalShow}
                hideModal={() => {
                    setDeleteCardModalShow(false);
                }}
                text={'Are you sure you want to delete this Card?'}
                onConfirm={handleCardDelete}
                isLoading={deleteCardLoading}
            />
        </div>
    );
};
