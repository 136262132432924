import moment from 'moment-timezone';
import {
    activityCodesTag,
    milestoneFragnetTag,
    numOfDaysToBeUrgentTitle,
    timePeriodTitle,
    widgetFilterTitle,
} from 'components/Dashboards/Project/Components/CustomDashboard/customDashboard.utils';
import {
    IActivityCodesResponse,
    IMilestoneFragnetResponse,
    IWBSResponse,
} from 'components/common/GlobalFilterComponent/queries/GlobalFilterComponentQuery';
import { IFilter, IFilterData } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { FilterNames } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { find } from 'lodash';
import { mapper as wbsMapper } from 'components/common/GlobalFilterComponent/components/WbsFilter/utils';

export const generateFilterByType = (
    item: { name: FilterNames; data: IFilterData },
    //TODO: try to make IFilterData generic so will not need to check typeof in mappers
    // ( widgetFilterTitle,timePeriodTitle & numOfDaysToBeUrgentTitle
    activityCodes?: IActivityCodesResponse[],
    milestoneFragnet?: IMilestoneFragnetResponse[],
    wbs?: IWBSResponse[],
): IFilter => {
    switch (item.name) {
        case FilterNames.START_DATE:
        case FilterNames.FINISH_DATE:
        case FilterNames.DURATION:
            return { ...item, data: item.data?.map((date) => moment(date).format('MMM yyyy')) };
        case FilterNames.ACTIVITY_CODES:
        case FilterNames.ACTIVITY_CODE_CARD_BOARD:
            return {
                ...item,
                data: item.data?.map((item) => {
                    return activityCodesTag({ item, activityCodes });
                }),
            };
        case FilterNames.FRAGNET_SEARCH:
        case FilterNames.FRAGNET_MILESTONE_ID_CARD_BOARD:
            return {
                ...item,
                data: item.data?.map((item) => {
                    return milestoneFragnetTag({ item, milestoneFragnet });
                }),
            };
        case FilterNames.WBS_SEARCH:
        case FilterNames.WBS_SEARCH_ID_CARD_BOARD:
            return {
                ...item,
                data: item.data?.map((item) => {
                    const wbsItem = wbs && find(wbs, { task_version_hash_code: item });
                    if (!wbsItem)
                        return 'Selected item not found in this version - Click ‘Open Source Component’ to amend filter.';
                    return wbsMapper(wbsItem).label;
                }),
            };
        case FilterNames.MIN_WBS_LEVEL:
        case FilterNames.MAX_WBS_LEVEL:
        case FilterNames.OPTIONS_STATUS:
        case FilterNames.OPTIONS_TYPE:
        case FilterNames.activity_type:
        case FilterNames.category:
        case FilterNames.severity:
            return { ...item, data: item.data?.map(widgetFilterTitle) };
        case FilterNames.NAME_SEARCH:
            return item;
        case FilterNames.TIME_PERIOD:
            return { ...item, data: item.data?.map(timePeriodTitle) };
        case FilterNames.NUM_OF_DAYS_TO_BE_URGENT:
            return { ...item, data: item.data?.map(numOfDaysToBeUrgentTitle) };
        case FilterNames.CRITICAL_PATH:
            return { ...item, data: [item.data && item.data[0] === 1 ? 'True' : 'False'] };
        case FilterNames.SUBMITTED_COMPLETION_CARD_BOARD:
            return { ...item, data: item.data && [`${item.data[0]} - ${item.data[1]}%`] };
        default:
            return item;
    }
};
