import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import { Pills } from 'components/common/pills/pills';
import moment from 'moment/moment';
import constants from 'components/common/Constants/constants';
import React from 'react';
import colorsVars from 'styles/colors.module.scss';
import { ActivityCell } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/relationships/relationships.utils';
import { sortFloat } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/utils';
import { ITableColumnState } from 'hooks/useTableCurrentState';

const {
    color8: orange,
    delayDriverColor,
    lightTableText,
    todoCardBackground,
    inProgressCardBackground,
    doneCardBackground,
} = colorsVars;

export const initialState: ITableColumnState = {
    sortBy: [
        {
            id: 'finishDate',
            desc: true,
        },
    ],
    hiddenColumns: ['externalRelation'],
};

export const columns = [
    {
        Header: 'Activity ID',
        accessor: 'activityCode',
        isDisableReorder: true,
        isDisableVisibility: true,
        Cell: ({ value }) => <TooltipCellWithCopy text={value} showAlways={true} />,
    },
    {
        Header: 'Activity Name ',
        accessor: 'activityName',
        isDisableReorder: true,
        isDisableVisibility: true,
        Cell: ({ value, row }) => <ActivityCell row={row} value={value} />,
    },
    {
        Header: 'Status',
        accessor: 'activityStatus',
        width: 110,
        isDisableReorder: true,
        isDisableVisibility: true,
        Cell: ({ value }) => {
            let color = 'initial';
            switch (value) {
                case 'To Do':
                    color = todoCardBackground;
                    break;
                case 'Done':
                    color = doneCardBackground;
                    break;
                case 'In Progress':
                    color = inProgressCardBackground;
                    break;
            }

            return <span style={{ color }}>{value}</span>;
        },
    },
    {
        Header: 'Relation Type',
        ...columnsCommonConfig.relationshipType,
        accessor: 'type',
        sortType: 'basic',
        isDisableReorder: true,
        isDisableVisibility: true,
    },
    {
        Header: 'Float',
        accessor: 'float',
        ...columnsCommonConfig.float,
        Cell: ({ value }) => {
            return <span style={{ color: lightTableText }}>{value}</span>;
        },
        sortType: sortFloat,
    },
    {
        Header: 'Start Variance',
        accessor: 'startVariance',
        ...columnsCommonConfig.lateStart,
        Cell: ({ value, row }) => {
            const { activityStatus } = row.original;
            if (activityStatus === 'To Do' && value > 0) {
                return <Pills type={'risk'}>{value}</Pills>;
            }

            return <span style={{ color: lightTableText }}>{value}</span>;
        },
    },
    {
        Header: 'Finish Variance',
        accessor: 'finishVariance',
        ...columnsCommonConfig.lateFinish,
        Cell: ({ value, row }) => {
            const { activityStatus } = row.original;
            if ((activityStatus === 'In Progress' || activityStatus === 'To Do') && value > 0) {
                return <Pills type={'tableRisk'}>{value}</Pills>;
            }

            const color = activityStatus === 'Done' && value > 0 ? orange : lightTableText;
            return <span style={{ color }}>{value}</span>;
        },
    },
    {
        Header: 'Start Date',
        accessor: 'startDate',
        sortType: 'basic',
        Cell: ({ value }) => {
            return <span>{moment(value).format(constants.formats.date.default)}</span>;
        },
    },
    {
        Header: 'Finish Date',
        accessor: 'finishDate',
        sortType: 'basic',
        Cell: ({ value }) => {
            return <span>{moment(value).format(constants.formats.date.default)}</span>;
        },
    },
    {
        Header: 'Lag/Lead',
        accessor: 'lag',
        Cell: ({ value }) => {
            return <span style={{ color: lightTableText }}>{value}</span>;
        },
    },
    {
        Header: 'Duration Overrun',
        accessor: 'durationOverun',
        ...columnsCommonConfig.durationOverrun,
        Cell: ({ value }) => {
            const color = value > 0 ? orange : lightTableText;
            return <span style={{ color }}>{value}</span>;
        },
    },
    {
        Header: 'Critical Path',
        ...columnsCommonConfig.criticalPath,
        accessor: (row) => (row.criticalPath ? 'Yes' : 'No'),
    },
    {
        Header: 'Criticality Score',
        accessor: 'criticalityScore',
        ...columnsCommonConfig.criticalityScore,
        Cell: ({ value, row }) => {
            const delayDriver = row.original.delayDriver;
            const color = delayDriver ? delayDriverColor : lightTableText;
            return <span style={{ color }}>{value}</span>;
        },
    },
    {
        Header: 'External Link',
        accessor: 'externalRelation',
        Cell: ({ value }) => {
            return <span>{value ? 'Yes' : 'No'}</span>;
        },
    },
];
