import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useQueryProjectScoreCardsSummary } from 'components/Dashboards/Portfolio/PortfolioOverview/queries/portfolioSummaryQueries';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';
import { prepareVersionSelectTitle } from 'components/common/TreeProgramFilter/VersionSelect/versionSelect.utils';
import { serializeScoreCardsData } from 'components/Dashboards/Portfolio/PortfolioOverview/helper';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { columns, initialState } from 'components/Dashboards/widgets/myProjectScoreCard/utils';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import classes from 'components/Dashboards/Portfolio/PortfolioOverview/PortfolioOverview.module.scss';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
const scoreCardId = 'score-card';

export const MyProjectScoreCardWidget = () => {
    const tableRef = useRef(null);
    const {
        data: scoreCards,
        isLoading: isLoadingScoreCards,
        isFetching: isFetchingScoreCards,
    } = useQueryProjectScoreCardsSummary();
    const componentKey = 'myProjectScorecards';
    const title = 'My Projects';
    const subTitleForExport = `${portfolioConfig.portfolioOverview.title}, ${prepareVersionSelectTitle()}`;
    const scoreCardsTableData = useMemo(() => {
        return scoreCards ? serializeScoreCardsData(scoreCards?.componentsList) : [];
    }, [scoreCards]);
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const updatedInitialState = useTableCurrentState({ initialState, componentKey });
    const handleInstance = useCallback((instance) => (tableRef.current = instance), []);

    return (
        <WidgetWithTitle
            title={title}
            tooltip={scoreCards?.info}
            id={scoreCardId}
            titleComponents={[
                <CustomizedTooltip
                    key={'tableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
                <ShareInsightButton
                    title={title}
                    link={`${getStringWithoutHash(window.location.href)}#${scoreCardId}`}
                />,
                <ExportDropdown title={title} key={'export'} subTitle={subTitleForExport} />,
            ]}
        >
            <div className={classes.tableContainer}>
                {updatedInitialState && (
                    <MainTable
                        getInstanceCallback={handleInstance}
                        initialState={updatedInitialState}
                        columns={columns}
                        data={scoreCardsTableData}
                        maxHeight={500}
                        isLoading={isLoadingScoreCards || isFetchingScoreCards}
                    />
                )}

                {tableRef?.current && (
                    <CustomTableColumnsModal
                        onHideModal={() => setModalColumnsOpen(false)}
                        showModal={modalColumnsOpen}
                        componentKey={componentKey}
                        tableInstance={tableRef.current}
                        initialState={initialState}
                        columns={columns}
                    />
                )}
            </div>
        </WidgetWithTitle>
    );
};
