import { useGlobalFilterDataStore } from 'components/common/GlobalFilterComponent/store/globalFilterDataStore';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { useMemo } from 'react';

export const useLogicCheckerFilterHook = () => {
    const globalFilterData = useGlobalFilterDataStore((state) => state.globalFilterData);
    const fetchFiltersDataObj = {
        [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes,
        [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
        [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
            ?.filter((item) => !item.value.isExclude)
            .map((i) => i.value.name),
        [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
            ?.filter((item) => item.value.isExclude)
            .map((i) => i.value.name),
    };
    return useMemo(() => getFiltersData(fetchFiltersDataObj), [globalFilterData]);
};
