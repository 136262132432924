import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

export type QueryStateValue = string | qs.ParsedQs | string[] | qs.ParsedQs[] | undefined;

export const useQueryState = (query: string): [string | undefined, (value: any) => void] => {
    const history = useHistory();

    const setQuery = useCallback(
        (value) => {
            const existingQueries = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
            });

            const queryString = qs.stringify(
                { ...existingQueries, [query]: value },
                { skipNulls: true, encode: false },
            );
            history.replace({
                search: queryString,
            });
        },
        [history, query],
    );

    const value = useMemo(() => {
        return qs.parse(window.location.search, { ignoreQueryPrefix: true })[query] as string | undefined;
    }, [window.location.search]);

    return [value, setQuery];
};
