import Slider, { marks } from 'components/common/Slider/Slider';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SliderWrapper from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/SliderWrapper';
import {
    useQueryGanttChart,
    useQueryActivitiesSummary,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/queries/ganttChartQuery';
import GanttChartDisplay, {
    progressEnum,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/GanttChartDisplay';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { IComponentProps, IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { GanttSettingsModal } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttSettingsModal';
import { Gantt } from '@bryntum/gantt';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import {
    FilterNames,
    getFiltersData,
    IGlobalFilterData,
} from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';

interface IParams {
    filter: {
        filters: IFilter[];
    };
    id: string;
    maxLevel: number;
    minLevel: number;
}

export interface IGanttChartProps extends IComponentProps {
    initialMaxLevel: number;
    globalFilterData: IGlobalFilterData | undefined;
}
export const GanttChart = ({
    latestVersionId,
    projectId,
    contractId,
    initialMaxLevel,
    widgetId,
    localFilters,
    editNarrative,
    externalComponents,
    setSubTitleForExport,
    globalFilterData,
    filter,
}: IGanttChartProps) => {
    const [progress, setProgress] = useState<string>(localFilters ? localFilters.progress : progressEnum.Criticality);
    const [isCriticalPath, setIsCriticalPath] = useState<boolean>(localFilters ? localFilters?.isCriticalPath : false);
    const [isWbs, setIsWbs] = useState<boolean>(localFilters ? localFilters?.isWbs : false);
    const [maxLevel, setMaxLevel] = useState<number>(localFilters ? localFilters?.maxLevel : 5);
    const [params, setParams] = useState<IParams | null>(null);
    const { data: summaryData } = useQueryActivitiesSummary(latestVersionId);
    const { data: ganttData, isFetching } = useQueryGanttChart(params);
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
    const [showSettings, setShowSettings] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const ganttInstance = useRef<Gantt | null>(null);

    const getInstance = useCallback((gantt) => {
        if (gantt) {
            ganttInstance.current = gantt;
        }
    }, []);

    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters || []
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes,
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                  }),

        [filter, globalFilterData],
    );

    useEffect(() => {
        if (latestVersionId && maxLevel) {
            setParams({
                filter: {
                    filters: filters,
                },
                id: String(latestVersionId),
                maxLevel,
                minLevel: 0,
            });
        }
    }, [maxLevel, latestVersionId, filters]);

    useEffect(() => {
        const handleFullScreen = () => {
            if (document.fullscreenElement) {
                setIsFullScreen(true);
            } else {
                setIsFullScreen(false);
            }
        };

        document.addEventListener('fullscreenchange', handleFullScreen);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreen);
        };
    }, []);

    const handleFullscreen = () => {
        if (ref.current) {
            if (!document.fullscreenElement) {
                ref.current.requestFullscreen();
            } else if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };

    const zoomToFit = () => {
        const instance = ganttInstance.current;
        if (instance) {
            instance.zoomToFit();
        }
    };

    const { generateFilters: formattedFilter } = useGlobalFiltersHook({
        widgetId,
        filter: {
            filters: [
                { name: 'Progress', data: [localFilters?.progress] },
                { name: 'Max Level', data: [localFilters?.maxLevel] },
                { name: 'Critical Path Only', data: [localFilters?.isCriticalPath ? 'True' : 'False'] },
                { name: 'WBS Only', data: [localFilters?.isWbs ? 'True' : 'False'] },
                ...filters,
            ],
        },
    });

    const title = 'Program Chart';

    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });

    const route = `${getStringWithoutHash(window.location.href)}`;

    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle]);

    return (
        <>
            <WidgetWithTitle
                id={'Program Chart'}
                title={title}
                tooltip={ganttData?.info}
                titleComponents={[
                    externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                    !widgetId && (
                        <SliderWrapper key={'Slider'}>
                            <Slider
                                defaultValue={5}
                                step={1}
                                min={1}
                                max={initialMaxLevel}
                                marks={marks(0, initialMaxLevel)}
                                onChangeCommitted={(e, value) => setMaxLevel(value)}
                            />
                        </SliderWrapper>
                    ),
                    <CustomizedTooltip
                        key={'zoomToFit'}
                        tooltipPosition={TooltipPosition.Top}
                        tooltipContent={`Zoom To Fit`}
                        triggerElement={
                            <CustomizedRoundIcon onClick={zoomToFit}>
                                <MuiIcon icon={'zoom_in_map'} fontSize={'2.4rem'} />
                            </CustomizedRoundIcon>
                        }
                    />,
                    <CustomizedTooltip
                        key={'fullScreen'}
                        tooltipPosition={TooltipPosition.Top}
                        tooltipContent={`Full Screen`}
                        triggerElement={
                            <CustomizedRoundIcon onClick={handleFullscreen}>
                                <MuiIcon icon={'fullscreen'} fontSize={'2.8rem'} />
                            </CustomizedRoundIcon>
                        }
                    />,
                    !widgetId && (
                        <CustomizedTooltip
                            tooltipPosition={TooltipPosition.Top}
                            tooltipContent={`Gantt Settings`}
                            triggerElement={
                                <CustomizedRoundIcon onClick={() => setShowSettings(true)}>
                                    <MuiIcon icon={'tune'} fontSize={'2.4rem'} />
                                </CustomizedRoundIcon>
                            }
                        />
                    ),
                    <AddWidget
                        key={'addWidget'}
                        title={title}
                        componentKey={'ganttChart'}
                        projectId={projectId}
                        contractId={contractId}
                        widgetId={widgetId}
                        filters={filters}
                        route={route}
                        localFilters={{ progress, isCriticalPath, isWbs, maxLevel }}
                    />,
                    <ExportDropdown title={title} key={'export'} subTitle={subTitle} />,
                ]}
                titleFilters={[
                    editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                    widgetId && formattedFilter.length > 0 ? (
                        <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formattedFilter} />
                    ) : null,
                ]}
            >
                <div ref={ref}>
                    <GanttChartDisplay
                        isFullScreen={isFullScreen}
                        ganttData={ganttData}
                        isLoading={isFetching}
                        summaryData={summaryData}
                        getInstanceAgain={getInstance}
                        widgetId={widgetId}
                        progress={progress}
                        setProgress={setProgress}
                        isCriticalPath={isCriticalPath}
                        setIsCriticalPath={setIsCriticalPath}
                        isWbs={isWbs}
                        setIsWbs={setIsWbs}
                        milestoneFragnet={globalFilterData?.milestoneFragnet}
                    />
                </div>
            </WidgetWithTitle>
            <GanttSettingsModal showModal={showSettings} onHideModal={() => setShowSettings(false)} />
        </>
    );
};
