export interface ProgramStatusUrlParams {
    versionToCompareId?: string;
    id: string;
}

export interface IFilterValue {
    name: string;
    default: boolean;
}

interface CardTitle {
    title: string;
    status: string;
    cardType: ScoreCardType;
}

export interface Threshold {
    lowThreshold: number;
    mediumThreshold: number;
    highThreshold: number;
}

export interface CardOverallScore {
    value: number;
    threshold: Threshold;
}

export interface MetricItem {
    id: string;
    name: string;
    value: number | ComparableValue;
    link: string;
    info: IToolTip;
    threshold: Threshold;
    valueType: MetricValueTypes;
    order: number;
    representation: MetricRepresentationTypes;
}

interface CardProperties {
    metrics: MetricItem[];
}

interface CardTrend {
    graphPoints: GraphPoint[];
}

export interface GraphPoint {
    versionName: string;
    versionDate: number;
    score: number;
    status: string;
    versionHashCode: number;
}

export interface CardData {
    cardTitle: CardTitle;
    overallScore: CardOverallScore;
    cardProperties: CardProperties;
    cardTrends: CardTrend[];
    collapse: boolean;
    setCollapse: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ComparableValue {
    value: number;
    compared: number;
    change: string;
    changeType: string;
    changePercent: number;
}

export interface ScoreCardsData {
    info?: IToolTip;
    summaryCards: CardData[];
}

export enum MetricRepresentationTypes {
    text = 'text',
    color = 'color',
    noData = 'no_data',
}

export enum MetricValueTypes {
    number = 'number',
    percent = 'percent',
    date = 'date',
}

export enum VersionSummaryItemStatus {
    low = 'LOW',
    medium = 'MEDIUM',
    high = 'HIGH',
}

export enum ScoreCardType {
    qualityCard = 'QualityCard',
    riskCard = 'RiskCard',
    progressCard = 'ProgressCard',
}
