import { MyTrackedActivitiesWidget } from 'components/Dashboards/widgets/myTrackedActivities/myTrackedActivitiesWidget';
import { useParams } from 'react-router-dom';
import { ProgramStatusUrlParams } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReportTypes';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { useGlobalFilterDataStore } from 'components/common/GlobalFilterComponent/store/globalFilterDataStore';

export const MyTrackedActivitiesWrapper = () => {
    const hashcode = Object.values(useParams<ProgramStatusUrlParams>()).filter(Boolean).join(',');
    const { versionList: versionFromHashMap } = useHashmapConverter(hashcode);
    const projectId = versionFromHashMap[0]?.projectId;
    const contractId = versionFromHashMap[0]?.contractId;
    const latestVersionId = versionFromHashMap[0]?.versionDbId;
    const compareVersionId = versionFromHashMap[1]?.versionDbId;

    return (
        <MyTrackedActivitiesWidget
            projectId={projectId}
            contractId={contractId}
            latestVersionId={latestVersionId}
            compareVersionId={compareVersionId}
        />
    );
};
