import ColumnsBoard from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/ColumnsBoard/ColumnsBoard';
import { useBattlecardsStore } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/store/battlecardsStore';
import {DisplayStyle} from "components/common/ToggleView/ToggleView";


export const ColumnsBoardWrapper = () => {
    const { displayStyle } = useBattlecardsStore();
    if (displayStyle === DisplayStyle.ROWS) return null;

    return (
        <ColumnsBoard />
    );
};
