import { CardTab } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/CardTab/CardTab';
import produce from 'immer';
import { statusOptions } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import { IProgramActivityPayload } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/ProgramActivityCard.types';

export const tabsArrData = [
    {
        tabKey: 'cardDetails',
        component: <CardTab title={'Card Details'} />,
    },
    {
        tabKey: 'activityDetails',
        component: <CardTab title={'Activity Details'} />,
    },
    {
        tabKey: 'activityRelationships',
        component: <CardTab title={'Activity Relationships'} />,
    },
    {
        tabKey: 'relatedCards',
        component: <CardTab title={'Associated Activities'} />,
    },
    {
        tabKey: 'auditLogs',
        component: <CardTab title={'Audit Log'} />,
    },
];

export const getStatusOptionsByCategory = (category, originalStatus) => {
    if (category && category.toLowerCase().includes('milestone')) {
        return produce(statusOptions, (draft) => {
            return draft
                .filter((item) => item.name !== 'IN_PROGRESS')
                .map((i) => {
                    return { ...i, originalStatus: originalStatus };
                });
        });
    }

    return statusOptions.map((i) => {
        return { ...i, originalStatus: originalStatus };
    });
};

export const buildProgramActivityCardPayload = (data): IProgramActivityPayload => {
    return {
        id: data.id,
        projectId: data.project.id,
        contractId: data.contract.id,
        activityId: data.activityId ? data.activityId.id : null,
        assigneeUserName: data.assignee.value,
        category: data.category.value,
        details: data.details,
        mentionedList: data.mentionedList,
        startDate: data.startDate,
        endDate: data.endDate,
        externalLinks: data.externalLinks,
        status: data.status.name,
        tagList: data.tagList.map((item) => ({ name: item.value })),
        title: data.title,
        relatedCardIds: null,
        type: data.type,
        isImportant: data.isImportant,
        versionId: data.versionId,
        remainingDurationInDays: data.remainingDurationInDays,
        submittedDurationComplete: data.submittedDurationComplete,
    };
};
